exports.jobRoles = [
  // Accounting -accounting
  { key: 'account-officer-accounting', label: 'Account Officer' },
  { key: 'accounting-clerk-accounting', label: 'Accounting Clerk' },
  { key: 'accounting-manager-accounting', label: 'Accounting Manager' },
  { key: 'accounting-supervisor-accounting', label: 'Accounting Supervisor' },
  { key: 'accounts-payable-manager-accounting', label: 'Accounts Payable Manager' },
  { key: 'accounts-manager-accounting', label: 'Accounts Manager' },
  { key: 'assistant-controller-accounting', label: 'Assistant Controller' },
  { key: 'auditor-accounting', label: 'Auditor' },
  { key: 'billing-coordinator-accounting', label: 'Billing Coordinator' },
  { key: 'budget-analyst-accounting', label: 'Budget Analyst' },
  { key: 'controller-accounting', label: 'Controller' },
  { key: 'credit-controller-accounting', label: 'Credit Controller' },
  { key: 'finance-director-accounting', label: 'Finance Director' },
  { key: 'financial-coordinator-accounting', label: 'Financial Coordinator' },
  { key: 'junior-accountant-accounting', label: 'Junior Accountant' },
  { key: 'senior-accountant-accounting', label: 'Senior Accountant' },
  { key: 'payroll-coordinator-accounting', label: 'Payroll Coordinator' },
  { key: 'payroll-specialist-accounting', label: 'Payroll Specialist' },
  { key: 'staff-accountant-accounting', label: 'Staff Accountant' },
  { key: 'tax-manager-accounting', label: 'Tax Manager' },
  { key: 'treasury-assistant-accounting', label: 'Treasury Assistant' },
  // { key: 'Accountant', label: 'Accountant' }, -accountant
  { key: 'accounting-coordinator-accountant', label: 'Accounting Coordinator' },
  { key: 'accounting-officer-accountant', label: 'Accounting Officer' },
  { key: 'accounts-assistant-accountant', label: 'Accounts Assistant' },
  { key: 'accounts-receivable-accountant', label: 'Accounts Receivable' },
  { key: 'audit-associate-accountant', label: 'Audit Associate' },
  { key: 'billing-analyst-accountant', label: 'Billing Analyst' },
  { key: 'billing-specialist-accountant', label: 'Billing Specialist' },
  { key: 'chartered-accountant-accountant', label: 'Chartered Accountant' },
  { key: 'cost-accountant-accountant', label: 'Cost Accountant' },
  { key: 'entry-level-accountant-accountant', label: 'Entry Level Accountant' },
  { key: 'financial-accountant-accountant', label: 'Financial Accountant' },
  { key: 'full-charge-bookkeeper-accountant', label: 'Full Charge Bookkeeper' },
  { key: 'payroll-administrator-accountant', label: 'Payroll Administrator' },
  { key: 'payroll-manager-accountant', label: 'Payroll Manager' },
  { key: 'project-accountant-accountant', label: 'Project Accountant' },
  { key: 'tax-accountant-accountant', label: 'Tax Accountant' },
  { key: 'tax-preparer-accountant', label: 'Tax Preparer' },
// Accountancyandfinancialmanagement -accountancyandfinancialmanagement
  { key: 'accountant-accountancyandfinancialmanagement', label: 'Accountant' },
  { key: 'accounting-technician-accountancyandfinancialmanagement', label: 'Accounting Technician' },
  { key: 'auditor-accountancyandfinancialmanagement', label: 'Auditor' },
  { key: 'corporate-treasurer-accountancyandfinancialmanagement', label: 'Corporate Treasurer' },
  { key: 'financial-manager-accountancyandfinancialmanagement', label: 'Financial Manager' },
  { key: 'management-accountant-accountancyandfinancialmanagement', label: 'Management Accountant' },
  { key: 'tax-inspector-accountancyandfinancialmanagement', label: 'Tax Inspector' },
// Civilandstructuralengineering -civilandstructuralengineering
  { key: 'civil-engineer-civilandstructuralengineering', label: 'Civil Engineer' },
  { key: 'engineering-geologist-civilandstructuralengineering', label: 'Engineering Geologist' },
  { key: 'geomatics-land-surveyor-civilandstructuralengineering', label: 'Geomatics/land Surveyor' },
  { key: 'site-engineer-civilandstructuralengineering', label: 'Site Engineer' },
  { key: 'structural-engineer-civilandstructuralengineering', label: 'Structural Engineer' },
  { key: 'transportation-planner-civilandstructuralengineering', label: 'Transportation Planner' },
// PublicServices -publicservices
  { key: 'generalist-publicservices', label: 'Generalist (Public Sector)' },
  { key: 'academic-librarian-publicservices', label: 'Academic Librarian' },
  { key: 'advice-worker-publicservices', label: 'Advice Worker' },
  { key: 'aid-workerhumanitarian-worker-publicservices', label: 'Aid Worker/Humanitarian Worker' },
  { key: 'amenity-horticulturist-publicservices', label: 'Amenity Horticulturist' },
  { key: 'archivist-publicservices', label: 'Archivist' },
  { key: 'armed-forces-officer-publicservices', label: 'Armed Forces Officer' },
  { key: 'arts-administrator-publicservices', label: 'Arts Administrator' },
  { key: 'border-force-officer-publicservices', label: 'Border Force Officer' },
  {
    key: 'building-control-officersurveyor-publicservices',
    label: 'Building Control Officer/Surveyor',
  },
  {
    key: 'charities-administrator-publicservices',
    label: 'Charities Administrator',
  },
  {
    key: 'charities-fundraiser-publicservices',
    label: 'Charities Fundraiser',
  },
  {
    key: 'civil-service-administrator-publicservices',
    label: 'Civil Service Administrator',
  },
  {
    key: 'community-arts-worker-publicservices',
    label: 'Community arts Worker',
  },
  { key: 'community-worker-publicservices', label: 'Community Worker' },
  {
    key: 'consumer-rights-adviser-publicservices',
    label: 'Consumer rights Adviser',
  },
  { key: 'customs-officer-publicservices', label: 'Customs Officer' },
  {
    key: 'debtfinance-adviser-publicservices',
    label: 'Debt/finance Adviser',
  },
  {
    key: 'diplomatic-service-publicservices',
    label: 'Diplomatic Service',
  },
  { key: 'economist-publicservices', label: 'Economist' },
  {
    key: 'employment-advice-worker-publicservices',
    label: 'Employment Advice Worker',
  },
  {
    key: 'energy-conservation-officer-publicservices',
    label: 'Energy Conservation Officer',
  },
  { key: 'energy-consultant-publicservices', label: 'Energy Consultant' },
  {
    key: 'environmental-education-officer-publicservices',
    label: 'Environmental Education Officer',
  },
  {
    key: 'environmental-health-officer-publicservices',
    label: 'Environmental Health Officer',
  },
  {
    key: 'environmental-manager-publicservices',
    label: 'Environmental Manager',
  },
  {
    key: 'equal-opportunities-officer-publicservices',
    label: 'Equal Opportunities Officer',
  },
  {
    key: 'equality-and-diversity-officer-publicservices',
    label: 'Equality and Diversity Officer',
  },
  {
    key: 'european-commission-administrators-publicservices',
    label: 'European Commission Administrators',
  },
  { key: 'firefighter-publicservices', label: 'Firefighter' },
  {
    key: 'fisheries-enforcement-officer-publicservices',
    label: 'Fisheries Enforcement Officer',
  },
  {
    key: 'government-research-officer-publicservices',
    label: 'Government Research Officer',
  },
  {
    key: 'health-and-safety-adviser-publicservices',
    label: 'Health and Safety Adviser',
  },
  {
    key: 'health-and-safety-inspector-publicservices',
    label: 'Health and Safety Inspector',
  },
  { key: 'heritage-manager-publicservices', label: 'Heritage Manager' },
  {
    key: 'homeless-support-worker-publicservices',
    label: 'Homeless Support Worker',
  },
  { key: 'housing-adviser-publicservices', label: 'Housing adviser' },
  {
    key: 'immigration-officer-publicservices',
    label: 'Immigration Officer',
  },
  { key: 'interpreter-publicservices', label: 'Interpreter' },
  {
    key: 'local-government-administrator-publicservices',
    label: 'Local Government Administrator',
  },
  {
    key: 'museum-education-officer-publicservices',
    label: 'Museum Education Officer',
  },
  {
    key: 'nature-conservation-officer-publicservices',
    label: 'Nature Conservation Officer',
  },
  {
    key: 'planning-and-development-surveyor-publicservices',
    label: 'Planning and Development Surveyor',
  },
  {
    key: 'planning-technician-publicservices',
    label: 'Planning Technician',
  },
  { key: 'police-officer-publicservices', label: 'Police Officer' },
  {
    key: 'political-party-agent-publicservices',
    label: 'Political Party Agent',
  },
  {
    key: 'political-researcher-publicservices',
    label: 'Political Researcher',
  },
  { key: 'prison-officer-publicservices', label: 'Prison Officer' },
  { key: 'probation-officer-publicservices', label: 'Probation Officer' },
  { key: 'public-librarian-publicservices', label: 'Public Librarian' },
  { key: 'recycling-officer-publicservices', label: 'Recycling Officer' },
  {
    key: 'scene-of-crime-officer-publicservices',
    label: 'Scene of Crime Officer',
  },
  { key: 'social-worker-publicservices', label: 'Social Worker' },
  { key: 'tax-inspector', label: 'Tax Inspector' },
  {
    key: 'town-and-country-planner-publicservices',
    label: 'Town and Country Planner',
  },
  {
    key: 'trade-union-official-publicservices',
    label: 'Trade Union Official',
  },
  {
    key: 'trade-union-research-officer-publicservices',
    label: 'Trade Union Research Officer',
  },
  {
    key: 'trading-standards-officer-publicservices',
    label: 'Trading Standards Officer',
  },
  {
    key: 'transportation-planner-publicservices',
    label: 'Transportation Planner',
  },
  {
    key: 'volunteer-work-organiser-job-description-publicservices',
    label: 'Volunteer Work Organiser: Job Description',
  },
  {
    key: 'waste-management-officer-publicservices',
    label: 'Waste Management Officer',
  },
  {
    key: 'water-conservation-officer-publicservices',
    label: 'Water Conservation Officer',
  },
  {
    key: 'welfare-rights-adviser-publicservices',
    label: 'Welfare Rights Adviser',
  },
  { key: 'youth-worker-publicservices', label: 'Youth Worker' },
  { key: 'student-nurse-publicservices', label: 'Student Nurse', isGrade: true },
  { key: 'staff-nurse-publicservices', label: 'Staff Nurse', isGrade: true },
  { key: 'senior-staff-nurse-publicservices', label: 'Senior Staff Nurse', isGrade: true },
  { key: 'advanced-nurse-publicservices', label: 'Advanced Nurse', isGrade: true },
  { key: 'chief-nurse-publicservices', label: 'Chief Nurse', isGrade: true },
  { key: 'consultant-nurse-publicservices', label: 'Consultant Nurse', isGrade: true },
  { key: 'foundation-doctor-publicservices', label: 'Foundation Doctor', isGrade: true },
  { key: 'specialty-registrar-publicservices', label: 'Specialty Registrar', isGrade: true },
  { key: 'general-practitioner-publicservices', label: 'General Practitioner', isGrade: true },
  { key: 'consultant-doctor-publicservices', label: 'Consultant Doctor', isGrade: true },
  { key: 'constable-garda-publicservices', label: 'Constable / Garda', isGrade: true },
  { key: 'sergeant-publicservices', label: 'Sergeant (Army)', isGrade: true },
  { key: 'inspector-publicservices', label: 'Inspector', isGrade: true },
  { key: 'chief-Inspector-publicservices', label: 'Chief Inspector', isGrade: true },
  { key: 'superintendent-publicservices', label: 'Superintendent', isGrade: true },
  { key: 'chief-superintendent-publicservices', label: 'Chief Superintendent', isGrade: true },
  { key: 'office-cadet-publicservices', label: 'Officer Cadet', isGrade: true },
  { key: 'second-lieutenant-publicservices', label: 'Second Lieutenant', isGrade: true },
  { key: 'lieutenant-publicservices', label: 'Lieutenant', isGrade: true },
  { key: 'captain-publicservices', label: 'Captain', isGrade: true },
  { key: 'major-publicservices', label: 'Major', isGrade: true },
  { key: 'lieutenant-colonel-publicservices', label: 'Lieutenant Colonel', isGrade: true },
  { key: 'colonel-publicservices', label: 'Colonel', isGrade: true },
  { key: 'brigadier-publicservices', label: 'Brigadier (1 Star)', isGrade: true },
  { key: 'major-general-publicservices', label: 'Major General (2 Star)', isGrade: true },
  { key: 'lieutenant-general-publicservices', label: 'Lieutenant General (3 Star)', isGrade: true },
  { key: 'general-publicservices', label: 'General (4 Star)', isGrade: true },
  { key: 'field-marshal-publicservices', label: 'Field Marshal', isGrade: true },
  {
    key: 'clerical-officer-administrative-officer-administrative-assistant-publicservices',
    label:
      'Clerical Officer / Administrative Officer (UK) / Administrative Assistant (CO / AO / AA)',
    isGrade: true,
  },
  { key: 'executive-officer-publicservices', label: 'Executive Officer (EO)', isGrade: true },
  {
    key: 'administrative-officer-senior-executive-officer-higher-executive-officer-publicservices',
    label:
      'Administrative Officer / Senior Executive Officer / Higher Executive Officer (AO / SEO / HEO)',
    isGrade: true,
  },
  {
    key: 'assistant-principle-officer-grades-6-and-7-publicservices',
    label: 'Assistant Principle Officer / Grades 6 and 7',
    isGrade: true,
  },
  {
    key: 'principle-officer-grades-7-publicservices',
    label: 'Principle Officer / Grades 7',
    isGrade: true,
  },
  {
    key: 'assistant-secretary-senior-civil-service-publicservices',
    label: 'Assistant Secretary / Senior Civil Service',
    isGrade: true,
  },
  {
    key: 'secretary-general-senior-civil-service-publicservices',
    label: 'Secretary General / Senior Civil Service',
    isGrade: true,
  },
  { key: 'president-academic-publicservices', label: 'President (Academic)', isGrade: true },
  { key: 'provost-publicservices', label: 'Provost', isGrade: true },
  { key: 'assistant-provost-publicservices', label: 'Assistant Provost', isGrade: true },
  { key: 'associate-provost-publicservices', label: 'Associate Provost', isGrade: true },
  { key: 'dean-publicservices', label: 'Dean', isGrade: true },
  { key: 'advisor-academic-publicservices', label: 'Advisor (Academic)', isGrade: true },
  { key: 'dean-assistant-publicservices', label: 'Dean (Assistant)', isGrade: true },
  { key: 'dean-associate-publicservices', label: 'Dean (Associate)', isGrade: true },
  { key: 'chair-academic-publicservices', label: 'Chair (Academic)', isGrade: true },
  { key: 'associate-chair-academic-publicservices', label: 'Associate Chair (Academic)', isGrade: true },
  { key: 'assistant-chair-academic-publicservices', label: 'Assistant Chair (Academic)', isGrade: true },
  { key: 'director-academic-publicservices', label: 'Director (Academic)', isGrade: true },
  {
    key: 'assistant-director-academic-publicservices',
    label: 'Assistant Director (Academic)',
    isGrade: true,
  },
  { key: 'faculty-member-academic-publicservices', label: 'Faculty Member (Academic)', isGrade: true },
  { key: 'principle-publicservices', label: 'Principle', isGrade: true },
  { key: 'assistant-principle-publicservices', label: 'Assistant Principle', isGrade: true },
// nonPublicRoles -nonpublicroles
  { key: 'operational-level-nonpublicroles', label: 'Operational Level' },
  { key: 'front-line-management-nonpublicroles', label: 'Front Line Management' },
  { key: 'middle-management-nonpublicroles', label: 'Middle Management' },
  { key: 'senior-management-nonpublicroles', label: 'Senior Management' },
// publicRoles -publicroles
  { key: 'aa-co-so-eo-publicroles', label: 'AA / CO / SO / EO' },
  { key: 'ao-heo-fs-seo-publicroles', label: 'AO / HEO / FS-HEO / SEO' },
  { key: 'grade-5-6-n-7-apo-po-publicroles', label: 'Grade 5, 6 & 7 / APO / PO' },
  {
    key: 'grade-2-assistant-secretary-perm-sec-sec-gen-publicroles',
    label: 'Grade 2 / Assistant Secretary / Perm. Sec. / Sec. Gen.',
  },
// AdministrationAndOfficeSupport -administrationandofficesupport
  { key: 'administrative-assistant-administrationandofficesupport', label: 'Administrative Assistant' },
  { key: 'administrative-manager-administrationandofficesupport', label: 'Administrative Manager' },
  { key: 'administrative-services-manager-administrationandofficesupport', label: 'Administrative Services Manager' },
  { key: 'assistant-director-administrationandofficesupport', label: 'Assistant Director' },
  { key: 'branch-manager-administrationandofficesupport', label: 'Branch Manager' },
  { key: 'church-administrator-administrationandofficesupport', label: 'Church Administrator' },
  { key: 'client-services-aoordinator-administrationandofficesupport', label: 'Client Services Coordinator' },
  { key: 'data-entry-clerk-administrationandofficesupport', label: 'Data Entry Clerk' },
  { key: 'dental-office-manager-administrationandofficesupport', label: 'Dental Office Manager' },
  { key: 'director-of-facilities-administrationandofficesupport', label: 'Director of Facilities' },
  { key: 'documentation-specialist-administrationandofficesupport', label: 'Documentation Specialist' },
  { key: 'executive-assistant-administrationandofficesupport', label: 'Executive Assistant' },
  { key: 'facility-manager-administrationandofficesupport', label: 'Facility Manager' },
  { key: 'healthcare-administrator-administrationandofficesupport', label: 'Healthcare Administrator' },
  { key: 'intern-administrationandofficesupport', label: 'Intern' },
  { key: 'inventory-manager-administrationandofficesupport', label: 'Inventory Manager' },
  { key: 'key-holder-administrationandofficesupport', label: 'Key Holder' },
  { key: 'librarian-administrationandofficesupport', label: 'Librarian' },
  { key: 'mail-clerk-administrationandofficesupport', label: 'Mail Clerk' },
  { key: 'manager-administrationandofficesupport', label: 'Manager' },
  { key: 'medical-office-assistant-administrationandofficesupport', label: 'Medical Office Assistant' },
  { key: 'membership-coordinator-administrationandofficesupport', label: 'Membership Coordinator' },
  { key: 'office-clerk-administrationandofficesupport', label: 'Office Clerk' },
  { key: 'office-supervisor-administrationandofficesupport', label: 'Office Supervisor' },
  { key: 'operations-assistant-administrationandofficesupport', label: 'Operations Assistant' },
  { key: 'operations-specialist-administrationandofficesupport', label: 'Operations Specialist' },
  { key: 'practice-manager-administrationandofficesupport', label: 'Practice Manager' },
  { key: 'program-assistant-administrationandofficesupport', label: 'Program Assistant' },
  { key: 'program-manager-administrationandofficesupport', label: 'Program Manager' },
  { key: 'project-coordinator-administrationandofficesupport', label: 'Project Coordinator' },
  { key: 'quality-assurance-manager-administrationandofficesupport', label: 'Quality Assurance Manager' },
  { key: 'quality-manager-administrationandofficesupport', label: 'Quality Manager' },
  { key: 'research-assistant-administrationandofficesupport', label: 'Research Assistant' },
  { key: 'sales-support-specialist-administrationandofficesupport', label: 'Sales Support Specialist' },
  { key: 'senior-executive-assistant-administrationandofficesupport', label: 'Senior Executive Assistant' },
  { key: 'sports-administration-administrationandofficesupport', label: 'Sports Administration' },
  { key: 'technical-assistant-administrationandofficesupport', label: 'Technical Assistant' },
  { key: 'translator-administrationandofficesupport', label: 'Translator' },
  { key: 'warranty-clerk-administrationandofficesupport', label: 'Warranty Clerk' },
  { key: 'administrative-associate-administrationandofficesupport', label: 'Administrative Associate' },
  { key: 'administrative-officer-administrationandofficesupport', label: 'Administrative Officer' },
  { key: 'administrator-administrationandofficesupport', label: 'Administrator' },
  { key: 'assistant-project-manager-administrationandofficesupport', label: 'Assistant Project Manager' },
  { key: 'business-manager-administrationandofficesupport', label: 'Business Manager' },
  { key: 'city-clerk-administrationandofficesupport', label: 'City Clerk' },
  { key: 'clinical-director-administrationandofficesupport', label: 'Clinical Director' },
  { key: 'data-entry-typist-administrationandofficesupport', label: 'Data Entry Typist' },
  { key: 'department-manager-administrationandofficesupport', label: 'Department Manager' },
  { key: 'dispatcher-administrationandofficesupport', label: 'Dispatcher' },
  { key: 'enrollment-specialist-administrationandofficesupport', label: 'Enrollment Specialist' },
  { key: 'executive-secretary-administrationandofficesupport', label: 'Executive Secretary' },
  { key: 'file-clerk-administrationandofficesupport', label: 'File Clerk' },
  { key: 'home-based-data-entry-typist-administrationandofficesupport', label: 'Home Based Data Entry Typist' },
  { key: 'inventory-clerk-administrationandofficesupport', label: 'Inventory Clerk' },
  { key: 'inventory-specialist-administrationandofficesupport', label: 'Inventory Specialist' },
  { key: 'kitchen-manager-administrationandofficesupport', label: 'Kitchen Manager' },
  { key: 'library-assistant-administrationandofficesupport', label: 'Library Assistant' },
  { key: 'mail-handler-assistant-administrationandofficesupport', label: 'Mail Handler Assistant' },
  { key: 'master-scheduler-administrationandofficesupport', label: 'Master Scheduler' },
  { key: 'medical-office-manager-administrationandofficesupport', label: 'Medical Office Manager' },
  { key: 'office-administrator-administrationandofficesupport', label: 'Office Administrator' },
  { key: 'office-coordinator-administrationandofficesupport', label: 'Office Coordinator' },
  { key: 'operations-administrator-administrationandofficesupport', label: 'Operations Administrator' },
  { key: 'operations-associate-administrationandofficesupport', label: 'Operations Associate' },
  { key: 'personal-assistant-administrationandofficesupport', label: 'Personal Assistant' },
  { key: 'program-administrator-administrationandofficesupport', label: 'Program Administrator' },
  { key: 'program-coordinator-administrationandofficesupport', label: 'Program Coordinator' },
  { key: 'project-administrator-administrationandofficesupport', label: 'Project Administrator' },
  { key: 'project-manager-administrationandofficesupport', label: 'Project Manager' },
  { key: 'quality-assurance-specialist-administrationandofficesupport', label: 'Quality Assurance Specialist' },
  { key: 'receptionist-administrationandofficesupport', label: 'Receptionist' },
  { key: 'risk-manager-administrationandofficesupport', label: 'Risk Manager' },
  { key: 'secretary-administrationandofficesupport', label: 'Secretary' },
  { key: 'senior-project-manager-administrationandofficesupport', label: 'Senior Project Manager' },
  { key: 'student-assistant-administrationandofficesupport', label: 'Student Assistant' },
  { key: 'technical-project-manager-administrationandofficesupport', label: 'Technical Project Manager' },
  { key: 'unit-secretary-administrationandofficesupport', label: 'Unit Secretary' },
  { key: 'administrative-coordinator-administrationandofficesupport', label: 'Administrative Coordinator' },
  { key: 'administrative-secretary-administrationandofficesupport', label: 'Administrative Secretary' },
  { key: 'assistant-administrationandofficesupport', label: 'Assistant' },
  { key: 'back-office-executive-administrationandofficesupport', label: 'Back Office Executive' },
  { key: 'certified-medical-assistant-administrationandofficesupport', label: 'Certified Medical Assistant' },
  { key: 'clerk-administrationandofficesupport', label: 'Clerk' },
  { key: 'coordinator-administrationandofficesupport', label: 'Coordinator' },
  { key: 'day-porter-administrationandofficesupport', label: 'Day Porter' },
  { key: 'director-of-administration-administrationandofficesupport', label: 'Director of Administration' },
  { key: 'district-manager-administrationandofficesupport', label: 'District Manager' },
  { key: 'executive-administrative-assistant-administrationandofficesupport', label: 'Executive Administrative Assistant' },
  { key: 'facilities-manager-administrationandofficesupport', label: 'Facilities Manager' },
  { key: 'front-desk-clerk-administrationandofficesupport', label: 'Front Desk Clerk' },
  { key: 'hospital-registrar-administrationandofficesupport', label: 'Hospital Registrar' },
  { key: 'inventory-coordinator-administrationandofficesupport', label: 'Inventory Coordinator' },
  { key: 'junior-project-manager-administrationandofficesupport', label: 'Junior Project Manager' },
  { key: 'legal-secretary-administrationandofficesupport', label: 'Legal Secretary' },
  { key: 'library-clerk-administrationandofficesupport', label: 'Library Clerk' },
  { key: 'mail-processing-clerk-administrationandofficesupport', label: 'Mail Processing Clerk' },
  { key: 'medical-administrative-assistant-administrationandofficesupport', label: 'Medical Administrative Assistant' },
  { key: 'medical-transcriptionist-administrationandofficesupport', label: 'Medical Transcriptionist' },
  { key: 'office-assistant-administrationandofficesupport', label: 'Office Assistant' },
  { key: 'office-manager-administrationandofficesupport', label: 'Office Manager' },
  { key: 'operations-analyst-administrationandofficesupport', label: 'Operations Analyst' },
  { key: 'operations-coordinator-administrationandofficesupport', label: 'Operations Coordinator' },
  { key: 'personal-executive-assistant-administrationandofficesupport', label: 'Personal Executive Assistant' },
  { key: 'program-analyst-administrationandofficesupport', label: 'Program Analyst' },
  { key: 'program-director-administrationandofficesupport', label: 'Program Director' },
  { key: 'project-analyst-administrationandofficesupport', label: 'Project Analyst' },
  { key: 'project-specialist-administrationandofficesupport', label: 'Project Specialist' },
  { key: 'quality-control-manager-administrationandofficesupport', label: 'Quality Control Manager' },
  { key: 'regional-manager-administrationandofficesupport', label: 'Regional Manager' },
  { key: 'senior-administrative-assistant-administrationandofficesupport', label: 'Senior Administrative Assistant' },
  { key: 'service-delivery-manager-administrationandofficesupport', label: 'Service Delivery Manager' },
  { key: 'supervisor-administrationandofficesupport', label: 'Supervisor' },
  { key: 'traffic-coordinator-administrationandofficesupport', label: 'Traffic Coordinator' },
  { key: 'virtual-assistant-administrationandofficesupport', label: 'Virtual Assistant' },
// AdvertisingArtsAndMedia -advertisingartsandmedia
  { key: 'actor-advertisingartsandmedia', label: 'Actor' },
  { key: 'art-director-advertisingartsandmedia', label: 'Art Director' },
  { key: 'author-advertisingartsandmedia', label: 'Author' },
  { key: 'comedian-advertisingartsandmedia', label: 'Comedian' },
  { key: 'curator-advertisingartsandmedia', label: 'Curator' },
  { key: 'disc-jockey-advertisingartsandmedia', label: 'Disc Jockey' },
  { key: 'executive-producer-advertisingartsandmedia', label: 'Executive Producer' },
  { key: 'film-director-advertisingartsandmedia', label: 'Film Director' },
  { key: 'guitarist-advertisingartsandmedia', label: 'Guitarist' },
  { key: 'junior-graphic-designer-advertisingartsandmedia', label: 'Junior Graphic Designer' },
  { key: 'marketing-associate-advertisingartsandmedia', label: 'Marketing Associate' },
  { key: 'media-manager-advertisingartsandmedia', label: 'Media Manager' },
  { key: 'music-editor-advertisingartsandmedia', label: 'Music Editor' },
  { key: 'photographer-assistant-advertisingartsandmedia', label: 'Photographer Assistant' },
  { key: 'production-coordinator-advertisingartsandmedia', label: 'Production Coordinator' },
  { key: 'senior-copywriter-advertisingartsandmedia', label: 'Senior Copywriter' },
  { key: 'social-media-coordinator-advertisingartsandmedia', label: 'Social Media Coordinator' },
  { key: 'social-media-intern-advertisingartsandmedia', label: 'Social Media Intern' },
  { key: 'talent-manager-advertisingartsandmedia', label: 'Talent Manager' },
  { key: 'video-editor-advertisingartsandmedia', label: 'Video Editor' },
  { key: 'vlogger-advertisingartsandmedia', label: 'Vlogger' },
  { key: 'advertising-manager-advertisingartsandmedia', label: 'Advertising Manager' },
  { key: 'assistant-film-director-advertisingartsandmedia', label: 'Assistant Film Director' },
  { key: 'band-manager-advertisingartsandmedia', label: 'Band Manager' },
  { key: 'creative-director-advertisingartsandmedia', label: 'Creative Director' },
  { key: 'digital-producer-advertisingartsandmedia', label: 'Digital Producer' },
  { key: 'drummer-advertisingartsandmedia', label: 'Drummer' },
  { key: 'fashion-designer-advertisingartsandmedia', label: 'Fashion Designer' },
  { key: 'film-editor-advertisingartsandmedia', label: 'Film Editor' },
  { key: 'illustrator-advertisingartsandmedia', label: 'Illustrator' },
  { key: 'literary-agent-advertisingartsandmedia', label: 'Literary Agent' },
  { key: 'marketing-consultant-advertisingartsandmedia', label: 'Marketing Consultant' },
  { key: 'model-advertisingartsandmedia', label: 'Model' },
  { key: 'music-producer-advertisingartsandmedia', label: 'Music Producer' },
  { key: 'producer-advertisingartsandmedia', label: 'Producer' },
  { key: 'radio-dj-advertisingartsandmedia', label: 'Radio DJ' },
  { key: 'senior-graphic-designer-advertisingartsandmedia', label: 'Senior Graphic Designer' },
  { key: 'social-media-designer-advertisingartsandmedia', label: 'Social Media Designer' },
  { key: 'stage-manager-advertisingartsandmedia', label: 'Stage Manager' },
  { key: 'tour-manager-advertisingartsandmedia', label: 'Tour Manager' },
  { key: 'videographer-advertisingartsandmedia', label: 'Videographer' },
  { key: 'youtuber-advertisingartsandmedia', label: 'YouTuber' },
  { key: 'animator-advertisingartsandmedia', label: 'Animator' },
  { key: 'associate-creative-director-advertisingartsandmedia', label: 'Associate Creative Director' },
  { key: 'choir-director-advertisingartsandmedia', label: 'Choir Director' },
  { key: 'creative-writer-advertisingartsandmedia', label: 'Creative Writer' },
  { key: 'director-of-photography-advertisingartsandmedia', label: 'Director of Photography' },
  { key: 'editor-in-chief-advertisingartsandmedia', label: 'Editor in Chief' },
  { key: 'fashion-director-advertisingartsandmedia', label: 'Fashion Director' },
  { key: 'graphic-designer-advertisingartsandmedia', label: 'Graphic Designer' },
  { key: 'journalist-advertisingartsandmedia', label: 'Journalist' },
  { key: 'marketing-analyst-advertisingartsandmedia', label: 'Marketing Analyst' },
  { key: 'media-director-advertisingartsandmedia', label: 'Media Director' },
  { key: 'movie-producer-advertisingartsandmedia', label: 'Movie Producer' },
  { key: 'photo-editor-advertisingartsandmedia', label: 'Photo Editor' },
  { key: 'production-assistant-advertisingartsandmedia', label: 'Production Assistant' },
  { key: 'record-producer-advertisingartsandmedia', label: 'Record Producer' },
  { key: 'social-media-assistant-advertisingartsandmedia', label: 'Social Media Assistant' },
  { key: 'social-media-influencer-advertisingartsandmedia', label: 'Social Media Influencer' },
  { key: 'studio-manager-advertisingartsandmedia', label: 'Studio Manager' },
  { key: 'tv-producer-advertisingartsandmedia', label: 'TV Producer' },
  { key: 'visual-designer-advertisingartsandmedia', label: 'Visual Designer' },
// BankingAndFinancialServices -bankingandfinancialservices
  { key: 'bank-teller-bankingandfinancialservices', label: 'Bank Teller' },
  { key: 'collections-officer-bankingandfinancialservices', label: 'Collections Officer' },
  { key: 'credit-analyst-bankingandfinancialservices', label: 'Credit Analyst' },
  { key: 'debt-collector-bankingandfinancialservices', label: 'Debt Collector' },
  { key: 'equity-research-associate-bankingandfinancialservices', label: 'Equity Research Associate' },
  { key: 'finance-manager-bankingandfinancialservices', label: 'Finance Manager' },
  { key: 'financial-consultant-bankingandfinancialservices', label: 'Financial Consultant' },
  { key: 'financial-planner-bankingandfinancialservices', label: 'Financial Planner' },
  { key: 'insurance-advisor-bankingandfinancialservices', label: 'Insurance Advisor' },
  { key: 'loan-processor-bankingandfinancialservices', label: 'Loan Processor' },
  { key: 'mortgage-underwriter-bankingandfinancialservices', label: 'Mortgage Underwriter' },
  { key: 'relationship-executive-bankingandfinancialservices', label: 'Relationship Executive' },
  { key: 'tax-specialist-bankingandfinancialservices', label: 'Tax Specialist' },
  { key: 'transaction-manager-bankingandfinancialservices', label: 'Transaction Manager' },
  { key: 'banker-bankingandfinancialservices', label: 'Banker' },
  { key: 'cost-analyst-bankingandfinancialservices', label: 'Cost Analyst' },
  { key: 'credit-manager-bankingandfinancialservices', label: 'Credit Manager' },
  { key: 'director-of-finance-bankingandfinancialservices', label: 'Director of Finance' },
  { key: 'escrow-assistant-bankingandfinancialservices', label: 'Escrow Assistant' },
  { key: 'financial-advisor-bankingandfinancialservices', label: 'Financial Advisor' },
  { key: 'financial-manager-bankingandfinancialservices', label: 'Financial Manager' },
  { key: 'financial-services-representative-bankingandfinancialservices', label: 'Financial Services Representative' },
  { key: 'investment-banker-bankingandfinancialservices', label: 'Investment Banker' },
  { key: 'mortgage-closer-bankingandfinancialservices', label: 'Mortgage Closer' },
  { key: 'personal-banker-bankingandfinancialservices', label: 'Personal Banker' },
  { key: 'senior-financial-analyst-bankingandfinancialservices', label: 'Senior Financial Analyst' },
  { key: 'teller-bankingandfinancialservices', label: 'Teller' },
  { key: 'transfer-agent-bankingandfinancialservices', label: 'Transfer Agent' },
  { key: 'broker-bankingandfinancialservices', label: 'Broker' },
  { key: 'cost-estimator-bankingandfinancialservices', label: 'Cost Estimator' },
  { key: 'credit-specialist-bankingandfinancialservices', label: 'Credit Specialist' },
  { key: 'economist-bankingandfinancialservices', label: 'Economist' },
  { key: 'finance-assistant-bankingandfinancialservices', label: 'Finance Assistant' },
  { key: 'Financial Analyst-bankingandfinancialservices', label: 'Financial Analyst' },
  { key: 'financial-officer-bankingandfinancialservices', label: 'Financial Officer' },
  { key: 'chief-financial-officer-bankingandfinancialservices', label: 'Chief Financial Officer' },
  { key: 'head-of-finance-bankingandfinancialservices', label: 'Head of Finance' },
  { key: 'loan-officer-bankingandfinancialservices', label: 'Loan Officer' },
  { key: 'mortgage-processor-bankingandfinancialservices', label: 'Mortgage Processor' },
  { key: 'portfolio-manager-bankingandfinancialservices', label: 'Portfolio Manager' },
  { key: 'stockbroker-bankingandfinancialservices', label: 'Stockbroker' },
  { key: 'trader-bankingandfinancialservices', label: 'Trader' },
  { key: 'wealth-manager-bankingandfinancialservices', label: 'Wealth Manager' },
// CallCenterAndCustomerService -callcenterandcustomerservice
  {
    key: 'bilingual-customer-service-representative-callcenterandcustomerservice',
    label: 'Bilingual Customer Service Representative',
  },
  { key: 'call-center-representative-callcenterandcustomerservice', label: 'Call Center Representative' },
  { key: 'client-services-manager-callcenterandcustomerservice', label: 'Client Services Manager' },
  { key: 'customer-relationship-manager-callcenterandcustomerservice', label: 'Customer Relationship Manager' },
  { key: 'customer-service-manager-callcenterandcustomerservice', label: 'Customer Service Manager' },
  { key: 'customer-success-manager-callcenterandcustomerservice', label: 'Customer Success Manager' },
  { key: 'customer-support-representative-callcenterandcustomerservice', label: 'Customer Support Representative' },
  { key: 'relationship-manager-callcenterandcustomerservice', label: 'Relationship Manager' },
  { key: 'team-leader-callcenterandcustomerservice', label: 'Team Leader' },
  { key: 'telemarketing-executive-callcenterandcustomerservice', label: 'Telemarketing Executive' },
  {
    key: 'call-center-customer-service-representative-callcenterandcustomerservice',
    label: 'call-center-customer-service-representative',
  },
  { key: 'call-center-supervisor-callcenterandcustomerservice', label: 'Call Center Supervisor' },
  { key: 'client-servicing-executive-callcenterandcustomerservice', label: 'Client Servicing Executive' },
  { key: 'customer-service-associate-callcenterandcustomerservice', label: 'Customer Service Associate' },
  { key: 'customer-service-representative-callcenterandcustomerservice', label: 'Customer Service Representative' },
  { key: 'customer-support-executive-callcenterandcustomerservice', label: 'Customer Support Executive' },
  { key: 'fast-food-cashier-callcenterandcustomerservice', label: 'Fast Food Cashier' },
  { key: 'service-manager-callcenterandcustomerservice', label: 'Service Manager' },
  { key: 'technical-account-manager-callcenterandcustomerservice', label: 'Technical Account Manager' },
  { key: 'call-center-manager-callcenterandcustomerservice', label: 'Call Center Manager' },
  { key: 'client-relationship-manager-callcenterandcustomerservice', label: 'Client Relationship Manager' },
  { key: 'concierge-callcenterandcustomerservice', label: 'Concierge' },
  { key: 'customer-service-coordinator-callcenterandcustomerservice', label: 'Customer Service Coordinator' },
  { key: 'customer-service-supervisor-callcenterandcustomerservice', label: 'Customer Service Supervisor' },
  { key: 'customer-support-manager-callcenterandcustomerservice', label: 'Customer Support Manager' },
  { key: 'member-service-representative-callcenterandcustomerservice', label: 'Member Service Representative' },
  { key: 'service-writer-callcenterandcustomerservice', label: 'Service Writer' },
  { key: 'telemarketer-callcenterandcustomerservice', label: 'Telemarketer' },
// CommunityServicesAndDevelopment -communityservicesanddevelopment
  { key: 'activities-assistant-communityservicesanddevelopment', label: 'Activities Assistant' },
  { key: 'caseworker-communityservicesanddevelopment', label: 'Caseworker' },
  { key: 'city-planner-communityservicesanddevelopment', label: 'City Planner' },
  { key: 'daycare-worker-communityservicesanddevelopment', label: 'Daycare Worker' },
  { key: 'executive-pastor-communityservicesanddevelopment', label: 'Executive Pastor' },
  { key: 'fundraiser-communityservicesanddevelopment', label: 'Fundraiser' },
  { key: 'grant-writer-communityservicesanddevelopment', label: 'Grant Writer' },
  { key: 'lifeguard-communityservicesanddevelopment', label: 'Lifeguard' },
  { key: 'mortician-communityservicesanddevelopment', label: 'Mortician' },
  { key: 'resident-assistant-communityservicesanddevelopment', label: 'Resident Assistant' },
  { key: 'school-social-worker-communityservicesanddevelopment', label: 'School Social Worker' },
  { key: 'support-worker-communityservicesanddevelopment', label: 'Support Worker' },
  { key: 'volunteer-coordinator-communityservicesanddevelopment', label: 'Volunteer Coordinator' },
  { key: 'youth-pastor-communityservicesanddevelopment', label: 'Youth Pastor' },
  { key: 'youth-support-worker-communityservicesanddevelopment', label: 'Youth Support Worker' },
  { key: 'care-manager-communityservicesanddevelopment', label: 'Care Manager' },
  { key: 'childcare-worker-communityservicesanddevelopment', label: 'Childcare Worker' },
  { key: 'community-outreach-coordinator-communityservicesanddevelopment', label: 'Community Outreach Coordinator' },
  { key: 'development-assistant-communityservicesanddevelopment', label: 'Development Assistant' },
  { key: 'family-advocate-communityservicesanddevelopment', label: 'Family Advocate' },
  { key: 'fundraising-manager-communityservicesanddevelopment', label: 'Fundraising Manager' },
  { key: 'grants-manager-communityservicesanddevelopment', label: 'Grants Manager' },
  { key: 'major-gifts-officer-communityservicesanddevelopment', label: 'Major Gifts Officer' },
  { key: 'outreach-coordinator-communityservicesanddevelopment', label: 'Outreach Coordinator' },
  { key: 'residential-aide-communityservicesanddevelopment', label: 'Residential Aide' },
  { key: 'service-coordinator-communityservicesanddevelopment', label: 'Service Coordinator' },
  { key: 'victim-advocate-communityservicesanddevelopment', label: 'Victim Advocate' },
  { key: 'volunteer-manager-communityservicesanddevelopment', label: 'Volunteer Manager' },
  { key: 'youth-specialist-communityservicesanddevelopment', label: 'Youth Specialist' },
  { key: 'correctional-treatment-specialist-communityservicesanddevelopment', label: 'Correctional Treatment Specialist' },
  { key: 'direct-support-professional-communityservicesanddevelopment', label: 'Direct Support Professional' },
  { key: 'firefighter-communityservicesanddevelopment', label: 'Firefighter' },
  { key: 'funeral-service-manager-communityservicesanddevelopment', label: 'Funeral Service Manager' },
  { key: 'housing-specialist-communityservicesanddevelopment', label: 'Housing Specialist' },
  { key: 'mentor-communityservicesanddevelopment', label: 'Mentor' },
  { key: 'priest-communityservicesanddevelopment', label: 'Priest' },
  { key: 'residential-counselor-communityservicesanddevelopment', label: 'Residential Counselor' },
  { key: 'social-worker-communityservicesanddevelopment', label: 'Social Worker' },
  { key: 'volunteer-communityservicesanddevelopment', label: 'Volunteer' },
  { key: 'volunteer-mentor-communityservicesanddevelopment', label: 'Volunteer Mentor' },
  { key: 'youth-sports-instructor-communityservicesanddevelopment', label: 'Youth Sports Instructor' },
// Construction -construction
  { key: 'asphalt-laborer-construction', label: 'Asphalt Laborer' },
  { key: 'bricklayer-construction', label: 'Bricklayer' },
  {
    key: 'commercial-construction-project-manager-construction',
    label: 'commercial-construction-project-manager',
  },
  { key: 'concrete-laborer-construction', label: 'Concrete Laborer' },
  { key: 'construction-inspector-construction', label: 'Construction Inspector' },
  { key: 'construction-superintendent-construction', label: 'Construction Superintendent' },
  { key: 'contractor-construction', label: 'Contractor' },
  { key: 'equipment-operator-construction', label: 'Equipment Operator' },
  { key: 'flagger-construction', label: 'Flagger' },
  { key: 'heavy-equipment-operator-construction', label: 'Heavy Equipment Operator' },
  { key: 'metal-stud-framer-construction', label: 'Metal Stud Framer' },
  { key: 'roofer-construction', label: 'Roofer' },
  { key: 'site-supervisor-construction', label: 'Site Supervisor' },
  { key: 'traffic-marshall-construction', label: 'Traffic Marshall' },
  { key: 'assistant-site-manager-construction', label: 'Assistant Site Manager' },
  { key: 'building-inspector-construction', label: 'Building Inspector' },
  { key: 'concrete-finisher-construction', label: 'Concrete Finisher' },
  { key: 'construction-coordinator-construction', label: 'Construction Coordinator' },
  { key: 'construction-laborer-construction', label: 'Construction Laborer' },
  { key: 'construction-supervisor-construction', label: 'Construction Supervisor' },
  { key: 'crane-operator-construction', label: 'Crane Operator' },
  { key: 'fence-installer-construction', label: 'Fence Installer' },
  { key: 'foreman-construction', label: 'Foreman' },
  { key: 'land-surveyor-construction', label: 'Land Surveyor' },
  { key: 'quantity-surveyor-construction', label: 'Quantity Surveyor' },
  { key: 'safety-officer-construction', label: 'Safety Officer' },
  { key: 'superintendent-construction', label: 'Superintendent' },
  { key: 'assistant-superintendent-construction', label: 'Assistant Superintendent' },
  { key: 'building-surveyor-construction', label: 'Building Surveyor' },
  { key: 'concrete-foreman-construction', label: 'Concrete Foreman' },
  { key: 'construction-estimator-construction', label: 'Construction Estimator' },
  { key: 'construction-manager-construction', label: 'Construction Manager' },
  { key: 'construction-worker-construction', label: 'Construction Worker' },
  { key: 'demolition-laborer-construction', label: 'Demolition Laborer' },
  { key: 'field-supervisor-construction', label: 'Field Supervisor' },
  { key: 'framing-carpenter-construction', label: 'Framing Carpenter' },
  { key: 'lead-carpenter-construction', label: 'Lead Carpenter' },
  { key: 'rigger-construction', label: 'Rigger' },
  { key: 'senior-estimator-construction', label: 'Senior Estimator' },
  { key: 'surveyor-construction', label: 'Surveyor' },
// ConsultingAndStrategy -consultingandstrategy
  { key: 'administrative-analyst-consultingandstrategy', label: 'Administrative Analyst' },
  { key: 'business-consultant-consultingandstrategy', label: 'Business Consultant' },
  { key: 'business-development-specialist-consultingandstrategy', label: 'Business Development Specialist' },
  { key: 'career-counselor-consultingandstrategy', label: 'Career Counselor' },
  { key: 'compliance-officer-consultingandstrategy', label: 'Compliance Officer' },
  { key: 'development-coordinator-consultingandstrategy', label: 'Development Coordinator' },
  { key: 'freelance-writer-consultingandstrategy', label: 'Freelance Writer' },
  { key: 'independent-consultant-consultingandstrategy', label: 'Independent Consultant' },
  { key: 'job-coach-consultingandstrategy', label: 'Job Coach' },
  { key: 'mediator-consultingandstrategy', label: 'Mediator' },
  { key: 'product-developer-consultingandstrategy', label: 'Product Developer' },
  { key: 'research-analyst-consultingandstrategy', label: 'Research Analyst' },
  { key: 'research-and-development-manager', label: 'R&D Manager' },
  { key: 'senior-business-analyst-consultingandstrategy', label: 'Senior Business Analyst' },
  { key: 'strategic-planner-consultingandstrategy', label: 'Strategic Planner' },
  { key: 'strategy-manager-consultingandstrategy', label: 'Strategy Manager' },
  { key: 'technical-specialist-consultingandstrategy', label: 'Technical Specialist' },
  { key: 'wedding-consultant-consultingandstrategy', label: 'Wedding Consultant' },
  { key: 'analyst-consultingandstrategy', label: 'Analyst' },
  { key: 'business-development-analyst-consultingandstrategy', label: 'Business Development Analyst' },
  {
    key: 'business-intelligence-bi-developer-consultingandstrategy',
    label: 'Business Intelligence (BI) Developer',
  },
  { key: 'compliance-analyst-consultingandstrategy', label: 'Compliance Analyst' },
  { key: 'conciliator-consultingandstrategy', label: 'Conciliator' },
  { key: 'development-director-consultingandstrategy', label: 'Development Director' },
  { key: 'freelancer-consultingandstrategy', label: 'Freelancer' },
  { key: 'independent-contractor-consultingandstrategy', label: 'Independent Contractor' },
  { key: 'market-research-analyst-consultingandstrategy', label: 'Market Research Analyst' },
  { key: 'operations-research-analyst-consultingandstrategy', label: 'Operations Research Analyst' },
  { key: 'product-specialist-consultingandstrategy', label: 'Product Specialist' },
  { key: 'safety-coordinator-consultingandstrategy', label: 'Safety Coordinator' },
  { key: 'senior-product-marketing-manager-consultingandstrategy', label: 'Senior Product Marketing Manager' },
  { key: 'strategic-sourcing-analyst-consultingandstrategy', label: 'Strategic Sourcing Analyst' },
  { key: 'subject-matter-expert-consultingandstrategy', label: 'Subject Matter Expert' },
  { key: 'travel-consultant-consultingandstrategy', label: 'Travel Consultant' },
  { key: 'wedding-planner-consultingandstrategy', label: 'Wedding Planner' },
  { key: 'business-advisor-consultingandstrategy', label: 'Business Advisor' },
  { key: 'business-development-consultant-consultingandstrategy', label: 'Business Development Consultant' },
  { key: 'business-intelligence-analyst-consultingandstrategy', label: 'Business Intelligence Analyst' },
  { key: 'compliance-manager-consultingandstrategy', label: 'Compliance Manager' },
  { key: 'consultant-consultingandstrategy', label: 'Consultant' },
  { key: 'field-officer-consultingandstrategy', label: 'Field Officer' },
  { key: 'image-consultant-consultingandstrategy', label: 'Image Consultant' },
  { key: 'industrial-hygienist-consultingandstrategy', label: 'Industrial Hygienist' },
  { key: 'mathematician-consultingandstrategy', label: 'Mathematician' },
  { key: 'product-designer-consultingandstrategy', label: 'Product Designer' },
  { key: 'reporting-analyst-consultingandstrategy', label: 'Reporting Analyst' },
  { key: 'safety-manager-consultingandstrategy', label: 'Safety Manager' },
  { key: 'statistician-consultingandstrategy', label: 'Statistician' },
  { key: 'strategist-consultingandstrategy', label: 'Strategist' },
  { key: 'technical-advisor-consultingandstrategy', label: 'Technical Advisor' },
  { key: 'vp-of-operations-consultingandstrategy', label: 'VP of Operations' },
// DesignAndArchitecture -sesignandarchitecture
  { key: 'architect-sesignandarchitecture', label: 'Architect' },
  { key: 'cad-operator-sesignandarchitecture', label: 'CAD Operator' },
  { key: 'draftsman-sesignandarchitecture', label: 'Draftsman' },
  { key: 'kitchen-designer-sesignandarchitecture', label: 'Kitchen Designer' },
  { key: 'screen-printer-sesignandarchitecture', label: 'Screen Printer' },
  { key: 'autocad-drafter-sesignandarchitecture', label: 'AutoCAD Drafter' },
  { key: 'cad-technician-sesignandarchitecture', label: 'CAD Technician' },
  { key: 'game-designer-sesignandarchitecture', label: 'Game Designer' },
  { key: 'landscape-architect-sesignandarchitecture', label: 'Landscape Architect' },
  { key: 'senior-architect-sesignandarchitecture', label: 'Senior Architect' },
  { key: 'cad-designer-sesignandarchitecture', label: 'CAD Designer' },
  { key: 'cnc-programmer-sesignandarchitecture', label: 'CNC Programmer' },
  { key: 'interior-designer-sesignandarchitecture', label: 'Interior Designer' },
  { key: 'pattern-maker-sesignandarchitecture', label: 'Pattern Maker' },
  { key: 'stonemason-sesignandarchitecture', label: 'Stonemason' },
// EducationAndTraining -educationandtraining
  { key: 'academic-advisor-educationandtraining', label: 'Academic Advisor' },
  { key: 'admissions-counselor-educationandtraining', label: 'Admissions Counselor' },
  { key: 'art-teacher-educationandtraining', label: 'Art Teacher' },
  { key: 'assistant-professor-educationandtraining', label: 'Assistant Professor' },
  { key: 'biology-teacher-educationandtraining', label: 'Biology Teacher' },
  { key: 'chief-invigilator-educationandtraining', label: 'Chief Invigilator' },
  { key: 'childcare-teacher-educationandtraining', label: 'Childcare Teacher' },
  { key: 'corporate-trainer-educationandtraining', label: 'Corporate Trainer' },
  { key: 'day-care-director-educationandtraining', label: 'Day Care Director' },
  { key: 'early-childhood-educator-educationandtraining', label: 'Early Childhood Educator' },
  { key: 'english-teacher-educationandtraining', label: 'English Teacher' },
  { key: 'facilitator-educationandtraining', label: 'Facilitator' },
  { key: 'graduate-teaching-assistant-educationandtraining', label: 'Graduate Teaching Assistant' },
  { key: 'high-school-english-teacher-educationandtraining', label: 'High School English Teacher' },
  { key: 'infant-teacher-educationandtraining', label: 'Infant Teacher' },
  { key: 'invigilator-educationandtraining', label: 'Invigilator' },
  { key: 'lead-teacher-educationandtraining', label: 'Lead Teacher' },
  { key: 'learning-specialist-educationandtraining', label: 'Learning Specialist' },
  { key: 'moderator-educationandtraining', label: 'Moderator' },
  { key: 'music-teacher-educationandtraining', label: 'Music Teacher' },
  { key: 'online-english-teacher-educationandtraining', label: 'Online English Teacher' },
  { key: 'paraprofessional-educationandtraining', label: 'Paraprofessional' },
  { key: 'pre-k-teacher-educationandtraining', label: 'Pre-K Teacher' },
  { key: 'principal-educationandtraining', label: 'Principal' },
  { key: 'registrar-educationandtraining', label: 'Registrar' },
  { key: 'school-administrator-educationandtraining', label: 'School Administrator' },
  { key: 'school-psychologist-educationandtraining', label: 'School Psychologist' },
  { key: 'school-principal-educationandtraining', label: 'School Principal' },
  { key: 'spanish-teacher-educationandtraining', label: 'Spanish Teacher' },
  { key: 'success-coach-educationandtraining', label: 'Success Coach' },
  { key: 'teacher-aide-educationandtraining', label: 'Teacher Aide' },
  { key: 'technical-trainer-educationandtraining', label: 'Technical Trainer' },
  { key: 'toddler-teacher-educationandtraining', label: 'Toddler Teacher' },
  { key: 'training-manager-educationandtraining', label: 'Training Manager' },
  { key: 'training-supervisor-educationandtraining', label: 'Training Supervisor' },
  { key: 'vice-principal-educationandtraining', label: 'Vice Principal' },
  { key: 'workforce-development-instructor-educationandtraining', label: 'Workforce Development Instructor' },
  { key: 'academic-coach-educationandtraining', label: 'Academic Coach' },
  { key: 'admissions-director-educationandtraining', label: 'Admissions Director' },
  { key: 'assessor-educationandtraining', label: 'Assessor' },
  { key: 'associate-professor-educationandtraining', label: 'Associate Professor' },
  { key: 'center-director-educationandtraining', label: 'Center Director' },
  { key: 'child-care-provider-educationandtraining', label: 'Child Care Provider' },
  { key: 'clinical-professor-educationandtraining', label: 'Clinical Professor' },
  { key: 'cosmetology-instructor-educationandtraining', label: 'Cosmetology Instructor' },
  { key: 'dean-of-students-educationandtraining', label: 'Dean of Students' },
  { key: 'education-director-educationandtraining', label: 'Education Director' },
  { key: 'esl-teacher-educationandtraining', label: 'ESL Teacher' },
  { key: 'french-teacher-educationandtraining', label: 'French Teacher' },
  { key: 'graduate-trainee-educationandtraining', label: 'Graduate Trainee' },
  { key: 'historian-educationandtraining', label: 'Historian' },
  { key: 'instructional-designer-educationandtraining', label: 'Instructional Designer' },
  { key: 'kindergarten-teacher-educationandtraining', label: 'Kindergarten Teacher' },
  { key: 'lead-toddler-teacher-educationandtraining', label: 'Lead Toddler Teacher' },
  { key: 'lecturer-educationandtraining', label: 'Lecturer' },
  { key: 'montessori-teacher-educationandtraining', label: 'Montessori Teacher' },
  { key: 'nanny-educationandtraining', label: 'Nanny' },
  { key: 'online-esl-teacher-educationandtraining', label: 'Online ESL Teacher' },
  { key: 'physics-teacher-educationandtraining', label: 'Physics Teacher' },
  { key: 'preschool-director-educationandtraining', label: 'Preschool Director' },
  { key: 'professor-educationandtraining', label: 'Professor' },
  { key: 'researcher-educationandtraining', label: 'Researcher' },
  { key: 'school-bus-driver-educationandtraining', label: 'School Bus Driver' },
  { key: 'science-teacher-educationandtraining', label: 'Science Teacher' },
  { key: 'sunday-school-teacher-educationandtraining', label: 'Sunday School Teacher' },
  { key: 'teacher-assistant-educationandtraining', label: 'Teacher Assistant' },
  { key: 'technical-writer-educationandtraining', label: 'Technical Writer' },
  { key: 'trainer-educationandtraining', label: 'Trainer' },
  { key: 'training-officer-educationandtraining', label: 'Training Officer' },
  { key: 'tutor-educationandtraining', label: 'Tutor' },
  { key: 'visiting-faculty-educationandtraining', label: 'Visiting Faculty' },
  { key: 'adjunct-professor-educationandtraining', label: 'Adjunct Professor' },
  { key: 'apprentice-educationandtraining', label: 'Apprentice' },
  { key: 'assistant-engineer-educationandtraining', label: 'Assistant Engineer' },
  { key: 'babysitter-educationandtraining', label: 'Babysitter' },
  { key: 'chemistry-teacher-educationandtraining', label: 'Chemistry Teacher' },
  { key: 'childcare-assistant-educationandtraining', label: 'Childcare Assistant' },
  { key: 'computer-teacher-educationandtraining', label: 'Computer Teacher' },
  { key: 'curriculum-designer-educationandtraining', label: 'Curriculum Designer' },
  { key: 'driving-instructor-educationandtraining', label: 'Driving Instructor' },
  { key: 'elementary-school-teacher-educationandtraining', label: 'Elementary School Teacher' },
  { key: 'examiner-educationandtraining', label: 'Examiner' },
  { key: 'graduate-assistant-educationandtraining', label: 'Graduate Assistant' },
  { key: 'guidance-counselor-educationandtraining', label: 'Guidance Counselor' },
  { key: 'history-teacher-educationandtraining', label: 'History Teacher' },
  { key: 'intervention-specialist-educationandtraining', label: 'Intervention Specialist' },
  { key: 'lead-preschool-teacher-educationandtraining', label: 'Lead Preschool Teacher' },
  { key: 'learning-designer-educationandtraining', label: 'Learning Designer' },
  { key: 'math-teacher-educationandtraining', label: 'Math Teacher' },
  { key: 'motivational-speaker-educationandtraining', label: 'Motivational Speaker' },
  { key: 'nurse-educator-educationandtraining', label: 'Nurse Educator' },
  { key: 'online-teacher-educationandtraining', label: 'Online Teacher' },
  { key: 'piano-teacher-educationandtraining', label: 'Piano Teacher' },
  { key: 'preschool-teacher-educationandtraining', label: 'Preschool Teacher' },
  { key: 'public-speaker-educationandtraining', label: 'Public Speaker' },
  { key: 'sales-intern-educationandtraining', label: 'Sales Intern' },
  { key: 'school-counselor-educationandtraining', label: 'School Counselor' },
  { key: 'social-studies-teacher-educationandtraining', label: 'Social Studies Teacher' },
  { key: 'substitute-teacher-educationandtraining', label: 'Substitute Teacher' },
  { key: 'teacher-educationandtraining', label: 'Teacher' },
  { key: 'teacher-trainer-educationandtraining', label: 'Teacher Trainer' },
  { key: 'tenured-professor-educationandtraining', label: 'Tenured Professor' },
  { key: 'training-coordinator-educationandtraining', label: 'Training Coordinator' },
  { key: 'training-specialist-educationandtraining', label: 'Training Specialist' },
  { key: 'vice-chancellor-educationandtraining', label: 'Vice Chancellor' },
  { key: 'vocal-coach-educationandtraining', label: 'Vocal Coach' },
// Engineering -engineering
  { key: 'aerospace-engineer-engineering', label: 'Aerospace Engineer' },
  { key: 'biomedical-engineer-engineering', label: 'Biomedical Engineer' },
  { key: 'civil-engineer-engineering', label: 'Civil Engineer' },
  { key: 'electrical-project-engineer-engineering', label: 'Electrical Project Engineer' },
  { key: 'engineering-manager-engineering', label: 'Engineering Manager' },
  { key: 'field-engineer-engineering', label: 'Field Engineer' },
  { key: 'industrial-engineer-engineering', label: 'Industrial Engineer' },
  { key: 'mechanical-engineer-engineering', label: 'Mechanical Engineer' },
  { key: 'nanosystems-engineer-engineering', label: 'Nanosystems Engineer' },
  { key: 'principal-software-engineer-engineering', label: 'Principal Software Engineer' },
  { key: 'project-engineer-engineering', label: 'Project Engineer' },
  { key: 'senior-electrical-engineer-engineering', label: 'Senior Electrical Engineer' },
  { key: 'structural-engineer-engineering', label: 'Structural Engineer' },
  { key: 'test-engineer-engineering', label: 'Test Engineer' },
  { key: 'tunnel-engineer-engineering', label: 'Tunnel Engineer' },
  { key: 'architectural-engineer-engineering', label: 'Architectural Engineer' },
  { key: 'chemical-engineer-engineering', label: 'Chemical Engineer' },
  { key: 'design-engineer-engineering', label: 'Design Engineer' },
  { key: 'electronics-engineer-engineering', label: 'Electronics Engineer' },
  { key: 'engineering-technician-engineering', label: 'Engineering Technician' },
  { key: 'geotechnical-engineer-engineering', label: 'Geotechnical Engineer' },
  { key: 'maintenance-engineer-engineering', label: 'Maintenance Engineer' },
  { key: 'mechanical-project-engineer-engineering', label: 'Mechanical Project Engineer' },
  { key: 'petroleum-engineer-engineering', label: 'Petroleum Engineer' },
  { key: 'process-engineer-engineering', label: 'Process Engineer' },
  { key: 'quality-engineer-engineering', label: 'Quality Engineer' },
  { key: 'senior-mechanical-engineer-engineering', label: 'Senior Mechanical Engineer' },
  { key: 'technical-engineer-engineering', label: 'Technical Engineer' },
  { key: 'tooling-engineer-engineering', label: 'Tooling Engineer' },
  { key: 'welding-engineer-engineering', label: 'Welding Engineer' },
  { key: 'biochemical-engineer-engineering', label: 'Biochemical Engineer' },
  { key: 'chief-engineer-engineering', label: 'Chief Engineer' },
  { key: 'electrical-engineer-engineering', label: 'Electrical Engineer' },
  { key: 'engineer-engineering', label: 'Engineer' },
  { key: 'environmental-engineer-engineering', label: 'Environmental Engineer' },
  { key: 'graduate-engineer-trainee-engineering', label: 'Graduate Engineer Trainee' },
  { key: 'manufacturing-engineer-engineering', label: 'Manufacturing Engineer' },
  { key: 'mechatronics-engineer-engineering', label: 'Mechatronics Engineer' },
  { key: 'planning-engineer-engineering', label: 'Planning Engineer' },
  { key: 'production-engineer-engineering', label: 'Production Engineer' },
  { key: 'robotics-engineer-engineering', label: 'Robotics Engineer' },
  { key: 'site-engineer-engineering', label: 'Site Engineer' },
  { key: 'test-automation-engineer-engineering', label: 'Test Automation Engineer' },
  { key: 'trainee-engineer-engineering', label: 'Trainee Engineer' },
  { key: 'junior-engineer-engineering', label: 'Junior Engineer' },
// Executive -executive
  { key: 'board-member-executive', label: 'Board Member' },
  { key: 'ceo-executive', label: 'CEO' },
  { key: 'chief-administrative-officer-executive', label: 'Chief Administrative Officer' },
  { key: 'chief-strategy-officer-executive', label: 'Chief Strategy Officer' },
  { key: 'director-of-development-executive', label: 'Director of Development' },
  { key: 'director-of-operations-executive', label: 'Director of Operations' },
  { key: 'head-of-operations-executive', label: 'Head of Operations' },
  { key: 'managing-director-executive', label: 'Managing Director' },
  { key: 'operations-officer-executive', label: 'Operations Officer' },
  { key: 'business-administrator-executive', label: 'Business Administrator' },
  { key: 'cfo-executive', label: 'CFO' },
  { key: 'chief-development-officer-executive', label: 'Chief Development Officer' },
  { key: 'coo-executive', label: 'COO' },
  { key: 'director-of-engineering-executive', label: 'Director of Engineering' },
  { key: 'executive-director-executive', label: 'Executive Director' },
  { key: 'management-analyst-executive', label: 'Management Analyst' },
  { key: 'managing-partner-executive', label: 'Managing Partner' },
  { key: 'president-executive', label: 'President' },
  { key: 'business-operations-manager-executive', label: 'Business Operations Manager' },
  { key: 'chancellor-executive', label: 'Chancellor' },
  { key: 'chief-investment-officer-executive', label: 'Chief Investment Officer' },
  { key: 'cto-executive', label: 'CTO' },
  { key: 'director-of-marketing-executive', label: 'Director of Marketing' },
  { key: 'general-manager-executive', label: 'General Manager' },
  { key: 'management-consultant-executive', label: 'Management Consultant' },
  { key: 'operations-manager-executive', label: 'Operations Manager' },
  { key: 'vice-president-executive', label: 'Vice President' },
// FarmingAnimalsAndConservation -farminganimalsandconservation
  { key: 'agronomist-farminganimalsandconservation', label: 'Agronomist' },
  { key: 'associate-veterinarian-farminganimalsandconservation', label: 'Associate Veterinarian' },
  { key: 'dog-handler-farminganimalsandconservation', label: 'Dog Handler' },
  { key: 'ecologist-farminganimalsandconservation', label: 'Ecologist' },
  { key: 'farmer-farminganimalsandconservation', label: 'Farmer' },
  { key: 'oceanographer-farminganimalsandconservation', label: 'Oceanographer' },
  { key: 'pet-sitter-farminganimalsandconservation', label: 'Pet Sitter' },
  { key: 'veterinarian-farminganimalsandconservation', label: 'Veterinarian' },
  { key: 'veterinary-office-manager-farminganimalsandconservation', label: 'Veterinary Office Manager' },
  { key: 'zookeeper-farminganimalsandconservation', label: 'Zookeeper' },
  { key: 'animal-breeder-farminganimalsandconservation', label: 'Animal Breeder' },
  { key: 'beekeeper-farminganimalsandconservation', label: 'Beekeeper' },
  { key: 'dog-trainer-farminganimalsandconservation', label: 'Dog Trainer' },
  { key: 'environmental-specialist-farminganimalsandconservation', label: 'Environmental Specialist' },
  { key: 'forestry-technician-farminganimalsandconservation', label: 'Forestry Technician' },
  { key: 'park-ranger-farminganimalsandconservation', label: 'Park Ranger' },
  { key: 'taxidermist-farminganimalsandconservation', label: 'Taxidermist' },
  { key: 'veterinary-assistant-farminganimalsandconservation', label: 'Veterinary Assistant' },
  { key: 'veterinary-receptionist-farminganimalsandconservation', label: 'Veterinary Receptionist' },
  { key: 'zoologist-farminganimalsandconservation', label: 'Zoologist' },
  { key: 'animal-trainer-farminganimalsandconservation', label: 'Animal Trainer' },
  { key: 'commercial-fisherman-farminganimalsandconservation', label: 'Commercial Fisherman' },
  { key: 'dog-walker-farminganimalsandconservation', label: 'Dog Walker' },
  { key: 'farm-manager-farminganimalsandconservation', label: 'Farm Manager' },
  { key: 'kennel-attendant-farminganimalsandconservation', label: 'Kennel Attendant' },
  { key: 'pet-groomer-farminganimalsandconservation', label: 'Pet Groomer' },
  { key: 'tractor-driver-farminganimalsandconservation', label: 'Tractor Driver' },
  { key: 'veterinary-nurse-farminganimalsandconservation', label: 'Veterinary Nurse' },
  { key: 'veterinary-technician-farminganimalsandconservation', label: 'Veterinary Technician' },
// HealthcareAndMedical -healthcareandmedical
  { key: 'acupuncturist-healthcareandmedical', label: 'Acupuncturist' },
  { key: 'anesthesiologist-healthcareandmedical', label: 'Anesthesiologist' },
  { key: 'art-therapist-healthcareandmedical', label: 'Art Therapist' },
  { key: 'audiologist-healthcareandmedical', label: 'Audiologist' },
  { key: 'behavior-technician-healthcareandmedical', label: 'Behavior Technician' },
  { key: 'behavioral-specialist-healthcareandmedical', label: 'Behavioral Specialist' },
  { key: 'cardiologist-healthcareandmedical', label: 'Cardiologist' },
  { key: 'care-coordinator-healthcareandmedical', label: 'Care Coordinator' },
  { key: 'case-manager-healthcareandmedical', label: 'Case Manager' },
  { key: 'certified-nurse-midwife-healthcareandmedical', label: 'Certified Nurse Midwife' },
  { key: 'chief-surgeon-healthcareandmedical', label: 'Chief Surgeon' },
  { key: 'chiropractic-assistant-healthcareandmedical', label: 'Chiropractic Assistant' },
  { key: 'clinic-manager-healthcareandmedical', label: 'Clinic Manager' },
  { key: 'clinical-nurse-specialist-healthcareandmedical', label: 'Clinical Nurse Specialist' },
  { key: 'clinical-research-coordinator-healthcareandmedical', label: 'Clinical Research Coordinator' },
  { key: 'clinical-technician-healthcareandmedical', label: 'Clinical Technician' },
  { key: 'cna-healthcareandmedical', label: 'CNA' },
  { key: 'contact-tracer-healthcareandmedical', label: 'Contact Tracer' },
  { key: 'counselor-healthcareandmedical', label: 'Counselor' },
  { key: 'ct-technologist-healthcareandmedical', label: 'CT Technologist' },
  { key: 'dental-hygienist-healthcareandmedical', label: 'Dental Hygienist' },
  { key: 'dermatologist-healthcareandmedical', label: 'Dermatologist' },
  { key: 'dietary-aide-healthcareandmedical', label: 'Dietary Aide' },
  { key: 'director-of-nursing-healthcareandmedical', label: 'Director of Nursing' },
  { key: 'ed-tech-healthcareandmedical', label: 'ED Tech' },
  { key: 'emt-healthcareandmedical', label: 'EMT' },
  { key: 'endoscopy-nurse-healthcareandmedical', label: 'Endoscopy Nurse' },
  { key: 'er-nurse-healthcareandmedical', label: 'ER Nurse' },
  { key: 'family-nurse-practitioner-healthcareandmedical', label: 'Family Nurse Practitioner' },
  { key: 'forensic-nurse-healthcareandmedical', label: 'Forensic Nurse' },
  { key: 'general-surgeon-healthcareandmedical', label: 'General Surgeon' },
  { key: 'geriatrician-healthcareandmedical', label: 'Geriatrician' },
  {
    key: 'health-care-customer-service-representative-healthcareandmedical',
    label: 'Health Care Customer Service Representative',
  },
  { key: 'health-unit-coordinator-healthcareandmedical', label: 'Health Unit Coordinator' },
  { key: 'healthcare-data-analyst-healthcareandmedical', label: 'Healthcare Data Analyst' },
  { key: 'home-care-nurse-healthcareandmedical', label: 'Home Care Nurse' },
  { key: 'hospital-administrator-healthcareandmedical', label: 'Hospital Administrator' },
  { key: 'house-supervisor-nurse-healthcareandmedical', label: 'House Supervisor Nurse' },
  { key: 'immunologist-healthcareandmedical', label: 'Immunologist' },
  { key: 'kinesiologist-healthcareandmedical', label: 'Kinesiologist' },
  { key: 'licensed-professional-counselor-healthcareandmedical', label: 'Licensed Professional Counselor' },
  { key: 'lvn-healthcareandmedical', label: 'LVN' },
  { key: 'med-tech-healthcareandmedical', label: 'Med Tech' },
  { key: 'medical-biller-healthcareandmedical', label: 'Medical Biller' },
  { key: 'medical-director-healthcareandmedical', label: 'Medical Director' },
  { key: 'medical-interpreter-healthcareandmedical', label: 'Medical Interpreter' },
  { key: 'medical-records-technician-healthcareandmedical', label: 'Medical Records Technician' },
  { key: 'medical-scribe-healthcareandmedical', label: 'Medical Scribe' },
  { key: 'mental-health-counselor-healthcareandmedical', label: 'Mental Health Counselor' },
  { key: 'mental-health-worker-healthcareandmedical', label: 'Mental Health Worker' },
  { key: 'mri-technician-healthcareandmedical', label: 'MRI Technician' },
  { key: 'neonatal-nurse-healthcareandmedical', label: 'Neonatal Nurse' },
  { key: 'nephrologist-healthcareandmedical', label: 'Nephrologist' },
  { key: 'neuroscientist-healthcareandmedical', label: 'Neuroscientist' },
  { key: 'nuclear-medicine-technologist-healthcareandmedical', label: 'Nuclear Medicine Technologist' },
  { key: 'nurse-assistant-healthcareandmedical', label: 'Nurse Assistant' },
  { key: 'nurse-navigator-healthcareandmedical', label: 'Nurse Navigator' },
  { key: 'nursery-nurse-healthcareandmedical', label: 'Nursery Nurse' },
  { key: 'nutritionist-healthcareandmedical', label: 'Nutritionist' },
  { key: 'occupational-therapist-healthcareandmedical', label: 'Occupational Therapist' },
  { key: 'ocularist-healthcareandmedical', label: 'Ocularist' },
  { key: 'ophthalmologist-healthcareandmedical', label: 'Ophthalmologist' },
  { key: 'optometric-technician-healthcareandmedical', label: 'Optometric Technician' },
  { key: 'oral-surgeon-healthcareandmedical', label: 'Oral Surgeon' },
  { key: 'orthodontist-healthcareandmedical', label: 'Orthodontist' },
  { key: 'osteopathic-doctor-healthcareandmedical', label: 'Osteopathic Doctor' },
  { key: 'pathologist-healthcareandmedical', label: 'Pathologist' },
  { key: 'patient-advocate-healthcareandmedical', label: 'Patient Advocate' },
  { key: 'patient-liaison-healthcareandmedical', label: 'Patient Liaison' },
  { key: 'patient-sitter-healthcareandmedical', label: 'Patient Sitter' },
  { key: 'pediatric-geneticist-healthcareandmedical', label: 'Pediatric Geneticist' },
  { key: 'pediatric-oncologist-healthcareandmedical', label: 'Pediatric Oncologist' },
  { key: 'pediatrician-healthcareandmedical', label: 'Pediatrician' },
  { key: 'pharmacist-healthcareandmedical', label: 'Pharmacist' },
  { key: 'pharmacy-manger-healthcareandmedical', label: 'Pharmacy Manger' },
  { key: 'phlebotomy-technician-healthcareandmedical', label: 'Phlebotomy Technician' },
  { key: 'physical-therapist-assistant-healthcareandmedical', label: 'Physical Therapist Assistant' },
  { key: 'physician-assistant-healthcareandmedical', label: 'Physician Assistant' },
  { key: 'plastic-surgeon-healthcareandmedical', label: 'Plastic Surgeon' },
  { key: 'prosthodontist-healthcareandmedical', label: 'Prosthodontist' },
  { key: 'psychologist-healthcareandmedical', label: 'Psychologist' },
  { key: 'public-health-nurse-healthcareandmedical', label: 'Public Health Nurse' },
  { key: 'radiation-therapist-healthcareandmedical', label: 'Radiation Therapist' },
  { key: 'radiology-technician-healthcareandmedical', label: 'Radiology Technician' },
  { key: 'rehabilitation-counselor-healthcareandmedical', label: 'Rehabilitation Counselor' },
  { key: 'respiratory-care-practitioner-healthcareandmedical', label: 'Respiratory Care Practitioner' },
  { key: 'rheumatologist-healthcareandmedical', label: 'Rheumatologist' },
  { key: 'school-nurse-healthcareandmedical', label: 'School Nurse' },
  { key: 'speech-language-pathologist-healthcareandmedical', label: 'Speech Language Pathologist' },
  { key: 'spiritual-healer-healthcareandmedical', label: 'Spiritual Healer' },
  { key: 'surgical-assistant-healthcareandmedical', label: 'Surgical Assistant' },
  { key: 'surgical-technologist-healthcareandmedical', label: 'Surgical Technologist' },
  { key: 'therapeutic-support-staff-healthcareandmedical', label: 'Therapeutic Support Staff' },
  { key: 'toxicologist-healthcareandmedical', label: 'Toxicologist' },
  { key: 'trauma-surgeon-healthcareandmedical', label: 'Trauma Surgeon' },
  { key: 'urologist-healthcareandmedical', label: 'Urologist' },
  { key: 'x-ray-technician-healthcareandmedical', label: 'X-Ray Technician' },
  { key: 'acute-care-nurse-practitioner-healthcareandmedical', label: 'Acute Care Nurse Practitioner' },
  { key: 'anesthetist-healthcareandmedical', label: 'Anesthetist' },
  { key: 'assistant-director-of-nursing-healthcareandmedical', label: 'Assistant Director of Nursing' },
  { key: 'ayurvedic-practitioner-healthcareandmedical', label: 'Ayurvedic Practitioner' },
  { key: 'behavior-therapist-healthcareandmedical', label: 'Behavior Therapist' },
  { key: 'bereavement-counselor-healthcareandmedical', label: 'Bereavement Counselor' },
  { key: 'cardiothoracic-surgeon-healthcareandmedical', label: 'Cardiothoracic Surgeon' },
  { key: 'care-provider-healthcareandmedical', label: 'Care Provider' },
  { key: 'certified-diabetes-educator-healthcareandmedical', label: 'Certified Diabetes Educator' },
  { key: 'charge-nurse-healthcareandmedical', label: 'Charge Nurse' },
  { key: 'child-psychiatrist-healthcareandmedical', label: 'Child Psychiatrist' },
  { key: 'chiropractor-healthcareandmedical', label: 'Chiropractor' },
  { key: 'clinical-psychologist-healthcareandmedical', label: 'Clinical Psychologist' },
  { key: 'clinical-specialist-healthcareandmedical', label: 'Clinical Specialist' },
  { key: 'clinical-trial-manager-healthcareandmedical', label: 'Clinical Trial Manager' },
  { key: 'companion-healthcareandmedical', label: 'Companion' },
  { key: 'coroner-healthcareandmedical', label: 'Coroner' },
  { key: 'credentialing-coordinator-healthcareandmedical', label: 'Credentialing Coordinator' },
  { key: 'dental-assistant-healthcareandmedical', label: 'Dental Assistant' },
  { key: 'dental-receptionist-healthcareandmedical', label: 'Dental Receptionist' },
  { key: 'dialysis-nurse-healthcareandmedical', label: 'Dialysis Nurse' },
  { key: 'dietary-manager-healthcareandmedical', label: 'Dietary Manager' },
  { key: 'doctor-healthcareandmedical', label: 'Doctor' },
  { key: 'ekg-technician-healthcareandmedical', label: 'EKG Technician' },
  { key: 'endocrinologist-healthcareandmedical', label: 'Endocrinologist' },
  { key: 'epidemiologist-healthcareandmedical', label: 'Epidemiologist' },
  { key: 'esthetician-healthcareandmedical', label: 'Esthetician' },
  { key: 'financial-counselor-healthcareandmedical', label: 'Financial Counselor' },
  { key: 'gastroenterologist-healthcareandmedical', label: 'Gastroenterologist' },
  { key: 'genetic-counselor-healthcareandmedical', label: 'Genetic Counselor' },
  { key: 'gestalt-therapist-healthcareandmedical', label: 'Gestalt Therapist' },
  { key: 'health-coach-healthcareandmedical', label: 'Health Coach' },
  { key: 'healthcare-analyst-healthcareandmedical', label: 'Healthcare Analyst' },
  { key: 'hematologist-healthcareandmedical', label: 'Hematologist' },
  { key: 'home-health-aide-healthcareandmedical', label: 'Home Health Aide' },
  { key: 'hospital-sitter-healthcareandmedical', label: 'Hospital Sitter' },
  { key: 'hypnotherapist-healthcareandmedical', label: 'Hypnotherapist' },
  { key: 'intake-coordinator-healthcareandmedical', label: 'Intake Coordinator' },
  { key: 'labor-and-delivery-nurse-healthcareandmedical', label: 'Labor and Delivery Nurse' },
  { key: 'life-coach-healthcareandmedical', label: 'Life Coach' },
  { key: 'marriage-and-family-therapist-healthcareandmedical', label: 'Marriage and Family Therapist' },
  { key: 'medical-anthropologist-healthcareandmedical', label: 'Medical Anthropologist' },
  { key: 'medical-coder-healthcareandmedical', label: 'Medical Coder' },
  { key: 'medical-equipment-preparer-healthcareandmedical', label: 'Medical Equipment Preparer' },
  { key: 'medical-receptionist-healthcareandmedical', label: 'Medical Receptionist' },
  { key: 'medical-representative-healthcareandmedical', label: 'Medical Representative' },
  { key: 'medical-secretary-healthcareandmedical', label: 'Medical Secretary' },
  { key: 'mental-health-technician-healthcareandmedical', label: 'Mental Health Technician' },
  { key: 'midwife-healthcareandmedical', label: 'Midwife' },
  { key: 'mri-technologist-healthcareandmedical', label: 'MRI Technologist' },
  { key: 'neonatal-nurse-practitioner-healthcareandmedical', label: 'Neonatal Nurse Practitioner' },
  { key: 'neurologist-healthcareandmedical', label: 'Neurologist' },
  { key: 'neurosurgeon-healthcareandmedical', label: 'Neurosurgeon' },
  { key: 'nurse-healthcareandmedical', label: 'Nurse' },
  { key: 'nurse-manager-healthcareandmedical', label: 'Nurse Manager' },
  { key: 'nurse-practitioner-healthcareandmedical', label: 'Nurse Practitioner' },
  { key: 'nursing-assistant-healthcareandmedical', label: 'Nursing Assistant' },
  { key: 'ob-gyn-healthcareandmedical', label: 'OB GYN' },
  { key: 'occupational-therapist-assistant-healthcareandmedical', label: 'Occupational Therapist Assistant' },
  { key: 'oncologist-healthcareandmedical', label: 'Oncologist' },
  { key: 'optician-healthcareandmedical', label: 'Optician' },
  { key: 'optometrist-healthcareandmedical', label: 'Optometrist' },
  { key: 'orderly-attendant-healthcareandmedical', label: 'Orderly Attendant' },
  { key: 'orthopedic-doctor-healthcareandmedical', label: 'Orthopedic Doctor' },
  { key: 'otolaryngologist-healthcareandmedical', label: 'Otolaryngologist' },
  { key: 'patient-access-representative-healthcareandmedical', label: 'Patient Access Representative' },
  { key: 'patient-care-coordinator-healthcareandmedical', label: 'Patient Care Coordinator' },
  { key: 'patient-navigator-healthcareandmedical', label: 'Patient Navigator' },
  { key: 'pca-healthcareandmedical', label: 'PCA' },
  { key: 'pediatric-medical-assistant-healthcareandmedical', label: 'Pediatric Medical Assistant' },
  { key: 'pediatric-physical-therapist-healthcareandmedical', label: 'Pediatric Physical Therapist' },
  { key: 'periodontist-healthcareandmedical', label: 'Periodontist' },
  { key: 'pharmacologist-healthcareandmedical', label: 'Pharmacologist' },
  { key: 'pharmacy-technician-healthcareandmedical', label: 'Pharmacy Technician' },
  { key: 'physiatrist-healthcareandmedical', label: 'Physiatrist' },
  { key: 'physical-therapy-aide-healthcareandmedical', label: 'Physical Therapy Aide' },
  { key: 'physiologist-healthcareandmedical', label: 'Physiologist' },
  { key: 'podiatrist-healthcareandmedical', label: 'Podiatrist' },
  { key: 'psychiatric-nurse-healthcareandmedical', label: 'Psychiatric Nurse' },
  { key: 'psychometrist-healthcareandmedical', label: 'Psychometrist' },
  { key: 'pulmonologist-healthcareandmedical', label: 'Pulmonologist' },
  { key: 'radiologic-technologist-healthcareandmedical', label: 'Radiologic Technologist' },
  { key: 'registered-medical-assistant-healthcareandmedical', label: 'Registered Medical Assistant' },
  { key: 'reiki-healer-healthcareandmedical', label: 'Reiki Healer' },
  { key: 'respiratory-therapist-healthcareandmedical', label: 'Respiratory Therapist' },
  { key: 'rn-case-manager-healthcareandmedical', label: 'RN Case Manager' },
  { key: 'sonographer-healthcareandmedical', label: 'Sonographer' },
  { key: 'speech-pathologist-healthcareandmedical', label: 'Speech Pathologist' },
  { key: 'sports-medicine-physician-healthcareandmedical', label: 'Sports Medicine Physician' },
  { key: 'sterile-processing-technician-healthcareandmedical', label: 'Sterile Processing Technician' },
  { key: 'surgical-coordinator-healthcareandmedical', label: 'Surgical Coordinator' },
  { key: 'telemedicine-physician-healthcareandmedical', label: 'Telemedicine Physician' },
  { key: 'therapist-healthcareandmedical', label: 'Therapist' },
  {
    key: 'transcranial-magnetic-stimulation-tms-technician-healthcareandmedical',
    label: 'Transcranial Magnetic Stimulation (TMS) Technician',
  },
  { key: 'travel-nurse-healthcareandmedical', label: 'Travel Nurse' },
  { key: 'vascular-surgeon-healthcareandmedical', label: 'Vascular Surgeon' },
  { key: 'addiction-psychiatrist-healthcareandmedical', label: 'Addiction Psychiatrist' },
  { key: 'aromatherapist-healthcareandmedical', label: 'Aromatherapist' },
  { key: 'associate-dentist-healthcareandmedical', label: 'Associate Dentist' },
  { key: 'behavior-analyst-healthcareandmedical', label: 'Behavior Analyst' },
  { key: 'behavioral-health-technician-healthcareandmedical', label: 'Behavioral Health Technician' },
  { key: 'cancer-registrar-healthcareandmedical', label: 'Cancer Registrar' },
  { key: 'cardiovascular-surgeon-healthcareandmedical', label: 'Cardiovascular Surgeon' },
  { key: 'carer-healthcareandmedical', label: 'Carer' },
  { key: 'certified-medication-aide-healthcareandmedical', label: 'Certified Medication Aide' },
  { key: 'chief-medical-officer-healthcareandmedical', label: 'Chief Medical Officer' },
  { key: 'child-psychologist-healthcareandmedical', label: 'Child Psychologist' },
  { key: 'clinic-assistant-healthcareandmedical', label: 'Clinic Assistant' },
  { key: 'clinical-liaison-healthcareandmedical', label: 'Clinical Liaison' },
  { key: 'clinical-research-associate-healthcareandmedical', label: 'Clinical Research Associate' },
  { key: 'clinical-supervisor-healthcareandmedical', label: 'Clinical Supervisor' },
  { key: 'clinician-healthcareandmedical', label: 'Clinician' },
  { key: 'company-nurse-healthcareandmedical', label: 'Company Nurse' },
  { key: 'counseling-psychologist-healthcareandmedical', label: 'Counseling Psychologist' },
  { key: 'crna-healthcareandmedical', label: 'CRNA' },
  { key: 'dental-biller-healthcareandmedical', label: 'Dental Biller' },
  { key: 'dentist-healthcareandmedical', label: 'Dentist' },
  { key: 'dialysis-technician-healthcareandmedical', label: 'Dialysis Technician' },
  { key: 'dietitian-healthcareandmedical', label: 'Dietitian' },
  { key: 'doula-healthcareandmedical', label: 'Doula' },
  { key: 'embryologist-healthcareandmedical', label: 'Embryologist' },
  { key: 'endodontist-healthcareandmedical', label: 'Endodontist' },
  { key: 'er-doctor-healthcareandmedical', label: 'ER Doctor' },
  { key: 'family-doctor-healthcareandmedical', label: 'Family Doctor' },
  { key: 'flight-nurse-healthcareandmedical', label: 'Flight Nurse' },
  { key: 'general-practitioner-healthcareandmedical', label: 'General Practitioner' },
  { key: 'geriatric-physician-healthcareandmedical', label: 'Geriatric Physician' },
  { key: 'gynecologist-healthcareandmedical', label: 'Gynecologist' },
  { key: 'health-educator-healthcareandmedical', label: 'Health Educator' },
  { key: 'healthcare-assistant-healthcareandmedical', label: 'Healthcare Assistant' },
  { key: 'hepatologist-healthcareandmedical', label: 'Hepatologist' },
  { key: 'home-health-nurse-healthcareandmedical', label: 'Home Health Nurse' },
  { key: 'hospitalist-healthcareandmedical', label: 'Hospitalist' },
  { key: 'icu-nurse-healthcareandmedical', label: 'ICU Nurse' },
  { key: 'internist-healthcareandmedical', label: 'Internist' },
  { key: 'licensed-massage-therapist-healthcareandmedical', label: 'Licensed Massage Therapist' },
  { key: 'lpn-healthcareandmedical', label: 'LPN' },
  { key: 'massage-therapist-healthcareandmedical', label: 'Massage Therapist' },
  { key: 'medical-assistant-healthcareandmedical', label: 'Medical Assistant' },
  { key: 'medical-courier-healthcareandmedical', label: 'Medical Courier' },
  { key: 'medical-examiner-healthcareandmedical', label: 'Medical Examiner' },
  { key: 'medical-records-clerk-healthcareandmedical', label: 'Medical Records Clerk' },
  { key: 'medical-sales-representative-healthcareandmedical', label: 'Medical Sales Representative' },
  { key: 'medical-technologist-healthcareandmedical', label: 'Medical Technologist' },
  { key: 'mental-health-therapist-healthcareandmedical', label: 'Mental Health Therapist' },
  { key: 'mobile-phlebotomist-healthcareandmedical', label: 'Mobile Phlebotomist' },
  { key: 'music-therapist-healthcareandmedical', label: 'Music Therapist' },
  { key: 'neonatologist-healthcareandmedical', label: 'Neonatologist' },
  { key: 'nicu-nurse-healthcareandmedical', label: 'NICU Nurse' },
  { key: 'nurse-anesthetist-healthcareandmedical', label: 'Nurse Anesthetist' },
  { key: 'nurse-midwife-healthcareandmedical', label: 'Nurse Midwife' },
  { key: 'nurse-technician-healthcareandmedical', label: 'Nurse Technician' },
  { key: 'nursing-supervisor-healthcareandmedical', label: 'Nursing Supervisor' },
  { key: 'occupational-health-nurse-healthcareandmedical', label: 'Occupational Health Nurse' },
  { key: 'occupational-therapy-assistant-healthcareandmedical', label: 'Occupational Therapy Assistant' },
  { key: 'operating-room-nurse-healthcareandmedical', label: 'Operating Room Nurse' },
  { key: 'optometric-assistant-healthcareandmedical', label: 'Optometric Assistant' },
  { key: 'oral-and-maxillofacial-surgeon-healthcareandmedical', label: 'Oral and Maxillofacial Surgeon' },
  { key: 'orthodontic-dental-assistant-healthcareandmedical', label: 'Orthodontic Dental Assistant' },
  { key: 'orthopedic-surgeon-healthcareandmedical', label: 'Orthopedic Surgeon' },
  { key: 'paramedic-healthcareandmedical', label: 'Paramedic' },
  { key: 'patient-account-representative-healthcareandmedical', label: 'Patient Account Representative' },
  { key: 'patient-care-technician-healthcareandmedical', label: 'Patient Care Technician' },
  { key: 'patient-representative-healthcareandmedical', label: 'Patient Representative' },
  { key: 'pediatric-cardiologist-healthcareandmedical', label: 'Pediatric Cardiologist' },
  { key: 'pediatric-nurse-healthcareandmedical', label: 'Pediatric Nurse' },
  { key: 'pediatric-surgeon-healthcareandmedical', label: 'Pediatric Surgeon' },
  {
    key: 'pharmaceutical-sales-representative-healthcareandmedical',
    label: 'Pharmaceutical Sales Representative',
  },
  { key: 'pharmacy-assistant-healthcareandmedical', label: 'Pharmacy Assistant' },
  { key: 'phlebotomist-healthcareandmedical', label: 'Phlebotomist' },
  { key: 'physical-therapist-healthcareandmedical', label: 'Physical Therapist' },
  { key: 'physician-healthcareandmedical', label: 'Physician' },
  { key: 'physiotherapist-healthcareandmedical', label: 'Physiotherapist' },
  { key: 'progressive-care-nurse-healthcareandmedical', label: 'Progressive Care Nurse' },
  { key: 'psychiatrist-healthcareandmedical', label: 'Psychiatrist' },
  { key: 'psychotherapist-healthcareandmedical', label: 'Psychotherapist' },
  { key: 'radiation-oncologist-healthcareandmedical', label: 'Radiation Oncologist' },
  { key: 'radiologist-healthcareandmedical', label: 'Radiologist' },
  { key: 'registered-nurse-healthcareandmedical', label: 'Registered Nurse' },
  { key: 'reproductive-endocrinologist-healthcareandmedical', label: 'Reproductive Endocrinologist' },
  { key: 'revenue-cycle-manager-healthcareandmedical', label: 'Revenue Cycle Manager' },
  { key: 'scheduler-healthcareandmedical', label: 'Scheduler' },
  { key: 'specimen-processor-healthcareandmedical', label: 'Specimen Processor' },
  { key: 'speech-therapist-healthcareandmedical', label: 'Speech Therapist' },
  { key: 'sports-psychologist-healthcareandmedical', label: 'Sports Psychologist' },
  { key: 'substance-abuse-counselor-healthcareandmedical', label: 'Substance Abuse Counselor' },
  { key: 'surgical-nurse-healthcareandmedical', label: 'Surgical Nurse' },
  { key: 'telemetry-nurse-healthcareandmedical', label: 'Telemetry Nurse' },
  { key: 'thoracic-surgeon-healthcareandmedical', label: 'Thoracic Surgeon' },
  { key: 'trauma-nurse-healthcareandmedical', label: 'Trauma Nurse' },
  { key: 'ultrasound-technician-healthcareandmedical', label: 'Ultrasound Technician' },
  { key: 'virologist-healthcareandmedical', label: 'Virologist' },
// HospitalityAndTourism -hospitalityandtourism
  { key: 'assistant-restaurant-manager-hospitalityandtourism', label: 'Assistant Restaurant Manager' },
  { key: 'bar-back-hospitalityandtourism', label: 'Bar Back' },
  { key: 'bartender-hospitalityandtourism', label: 'Bartender' },
  { key: 'cafe-manager-hospitalityandtourism', label: 'Cafe Manager' },
  { key: 'catering-coordinator-hospitalityandtourism', label: 'Catering Coordinator' },
  { key: 'chef-de-cuisine-hospitalityandtourism', label: 'Chef de Cuisine' },
  { key: 'commis-chef-hospitalityandtourism', label: 'Commis Chef' },
  { key: 'crew-member-hospitalityandtourism', label: 'Crew Member' },
  { key: 'event-manager-hospitalityandtourism', label: 'Event Manager' },
  { key: 'event-staff-hospitalityandtourism', label: 'Event Staff' },
  { key: 'floor-staff-hospitalityandtourism', label: 'Floor Staff' },
  { key: 'food-runner-hospitalityandtourism', label: 'Food Runner' },
  { key: 'front-desk-agent-hospitalityandtourism', label: 'Front Desk Agent' },
  { key: 'ground-staff-hospitalityandtourism', label: 'Ground Staff' },
  { key: 'hotel-front-desk-hospitalityandtourism', label: 'Hotel Front Desk' },
  { key: 'housekeeping-supervisor-hospitalityandtourism', label: 'Housekeeping Supervisor' },
  { key: 'kitchen-helper-hospitalityandtourism', label: 'Kitchen Helper' },
  { key: 'laundry-attendant-hospitalityandtourism', label: 'Laundry Attendant' },
  { key: 'night-auditor-hospitalityandtourism', label: 'Night Auditor' },
  { key: 'pizza-maker-hospitalityandtourism', label: 'Pizza Maker' },
  { key: 'restaurant-cook-hospitalityandtourism', label: 'Restaurant Cook' },
  { key: 'restaurant-server-hospitalityandtourism', label: 'Restaurant Server' },
  { key: 'sandwich-artist-hospitalityandtourism', label: 'Sandwich Artist' },
  { key: 'shift-manager-hospitalityandtourism', label: 'Shift Manager' },
  { key: 'sous-chef-hospitalityandtourism', label: 'Sous Chef' },
  { key: 'table-games Dealer-hospitalityandtourism', label: 'Table Games Dealer' },
  { key: 'valet-hospitalityandtourism', label: 'Valet' },
  { key: 'waitress-hospitalityandtourism', label: 'Waitress' },
  { key: 'banquet-manager-hospitalityandtourism', label: 'Banquet Manager' },
  { key: 'bar-manager-hospitalityandtourism', label: 'Bar Manager' },
  { key: 'breakfast-attendant-hospitalityandtourism', label: 'Breakfast Attendant' },
  { key: 'caterer-hospitalityandtourism', label: 'Caterer' },
  { key: 'catering-manager-hospitalityandtourism', label: 'Catering Manager' },
  { key: 'chef-de-partie-hospitalityandtourism', label: 'Chef de Partie' },
  { key: 'cook-hospitalityandtourism', label: 'Cook' },
  { key: 'dishwasher-hospitalityandtourism', label: 'Dishwasher' },
  { key: 'event-planner-hospitalityandtourism', label: 'Event Planner' },
  { key: 'executive-chef-hospitalityandtourism', label: 'Executive Chef' },
  { key: 'food-handler-hospitalityandtourism', label: 'Food Handler' },
  { key: 'food-server-hospitalityandtourism', label: 'Food Server' },
  { key: 'front-of-house-staff-hospitalityandtourism', label: 'Front of House Staff' },
  { key: 'guest-service-representative-hospitalityandtourism', label: 'Guest Service Representative' },
  { key: 'hotel-manager-hospitalityandtourism', label: 'Hotel Manager' },
  { key: 'immigration-consultant-hospitalityandtourism', label: 'Immigration Consultant' },
  { key: 'kitchen-porter-hospitalityandtourism', label: 'Kitchen Porter' },
  { key: 'lead-line-cook-hospitalityandtourism', label: 'Lead Line Cook' },
  { key: 'pastry-chef-hospitalityandtourism', label: 'Pastry Chef' },
  { key: 'prep-cook-hospitalityandtourism', label: 'Prep Cook' },
  { key: 'restaurant-general-manager-hospitalityandtourism', label: 'Restaurant General Manager' },
  { key: 'restaurant-supervisor-hospitalityandtourism', label: 'Restaurant Supervisor' },
  { key: 'server-hospitalityandtourism', label: 'Server' },
  { key: 'short-order-cook-hospitalityandtourism', label: 'Short Order Cook' },
  { key: 'steward-hospitalityandtourism', label: 'Steward' },
  { key: 'tour-guide-hospitalityandtourism', label: 'Tour Guide' },
  { key: 'wait-staff-hospitalityandtourism', label: 'Wait Staff' },
  { key: 'banquet-server-hospitalityandtourism', label: 'Banquet Server' },
  { key: 'barista-hospitalityandtourism', label: 'Barista' },
  { key: 'busboy-hospitalityandtourism', label: 'Busboy' },
  { key: 'catering-assistant-hospitalityandtourism', label: 'Catering Assistant' },
  { key: 'chef-hospitalityandtourism', label: 'Chef' },
  { key: 'cocktail-waitress-hospitalityandtourism', label: 'Cocktail Waitress' },
  { key: 'crew-leader-hospitalityandtourism', label: 'Crew Leader' },
  { key: 'event-coordinator-hospitalityandtourism', label: 'Event Coordinator' },
  { key: 'event-specialist-hospitalityandtourism', label: 'Event Specialist' },
  { key: 'flight-attendant-hospitalityandtourism', label: 'Flight Attendant' },
  { key: 'food-preparation-worker-hospitalityandtourism', label: 'Food Preparation Worker' },
  { key: 'food-service-worker-hospitalityandtourism', label: 'Food Service Worker' },
  { key: 'grill-cook-hospitalityandtourism', label: 'Grill Cook' },
  { key: 'hostess-hospitalityandtourism', label: 'Hostess' },
  { key: 'housekeeper-hospitalityandtourism', label: 'Housekeeper' },
  { key: 'kitchen-assistant-hospitalityandtourism', label: 'Kitchen Assistant' },
  { key: 'kitchen-staff-hospitalityandtourism', label: 'Kitchen Staff' },
  { key: 'line-cook-hospitalityandtourism', label: 'Line Cook' },
  { key: 'pastry-cook-hospitalityandtourism', label: 'Pastry Cook' },
  { key: 'reservation-agent-hospitalityandtourism', label: 'Reservation Agent' },
  { key: 'restaurant-manager-hospitalityandtourism', label: 'Restaurant Manager' },
  { key: 'room-attendant-hospitalityandtourism', label: 'Room Attendant' },
  { key: 'server-assistant-hospitalityandtourism', label: 'Server Assistant' },
  { key: 'sommelier-hospitalityandtourism', label: 'Sommelier' },
  { key: 'sushi-chef-hospitalityandtourism', label: 'Sushi Chef' },
  { key: 'travel-agent-hospitalityandtourism', label: 'Travel Agent' },
  { key: 'waiter-hospitalityandtourism', label: 'Waiter' },
// HumanResourcesAndRecruitment -humanresourcesandrecruitment
  { key: 'agency-manager-humanresourcesandrecruitment', label: 'Agency Manager' },
  { key: 'benefits-coordinator-humanresourcesandrecruitment', label: 'Benefits Coordinator' },
  { key: 'club-manager-humanresourcesandrecruitment', label: 'Club Manager' },
  { key: 'department-head-humanresourcesandrecruitment', label: 'Department Head' },
  { key: 'employment-specialist-humanresourcesandrecruitment', label: 'Employment Specialist' },
  { key: 'headhunter-humanresourcesandrecruitment', label: 'Headhunter' },
  { key: 'hr-assistant-humanresourcesandrecruitment', label: 'HR Assistant' },
  { key: 'hr-coordinator-humanresourcesandrecruitment', label: 'HR Coordinator' },
  { key: 'hr-intern-humanresourcesandrecruitment', label: 'HR Intern' },
  { key: 'hr-recruiter-humanresourcesandrecruitment', label: 'HR Recruiter' },
  { key: 'hris-manager-humanresourcesandrecruitment', label: 'HRIS Manager' },
  { key: 'payroll-assistant-humanresourcesandrecruitment', label: 'Payroll Assistant' },
  { key: 'recruiting-manager-humanresourcesandrecruitment', label: 'Recruiting Manager' },
  { key: 'sales-recruiter-humanresourcesandrecruitment', label: 'Sales Recruiter' },
  { key: 'talent-acquisition-manager-humanresourcesandrecruitment', label: 'Talent Acquisition Manager' },
  { key: 'technical-sourcer-humanresourcesandrecruitment', label: 'Technical Sourcer' },
  { key: 'assistant-general-manager-humanresourcesandrecruitment', label: 'Assistant General Manager' },
  { key: 'benefits-representative-humanresourcesandrecruitment', label: 'Benefits Representative' },
  { key: 'compensation-analyst-humanresourcesandrecruitment', label: 'Compensation Analyst' },
  { key: 'duty-manager-humanresourcesandrecruitment', label: 'Duty Manager' },
  { key: 'executive-recruiter-humanresourcesandrecruitment', label: 'Executive Recruiter' },
  { key: 'hiring-manager-humanresourcesandrecruitment', label: 'Hiring Manager' },
  { key: 'hr-associate-humanresourcesandrecruitment', label: 'HR Associate' },
  { key: 'hr-director-humanresourcesandrecruitment', label: 'HR Director' },
  { key: 'hr-manager-humanresourcesandrecruitment', label: 'HR Manager' },
  { key: 'hr-specialist-humanresourcesandrecruitment', label: 'HR Specialist' },
  { key: 'junior-recruiter-humanresourcesandrecruitment', label: 'Junior Recruiter' },
  { key: 'physician-recruiter-humanresourcesandrecruitment', label: 'Physician Recruiter' },
  { key: 'recruitment-consultant-humanresourcesandrecruitment', label: 'Recruitment Consultant' },
  { key: 'staffing-coordinator-humanresourcesandrecruitment', label: 'Staffing Coordinator' },
  { key: 'talent-acquisition-specialist', label: 'Talent Acquisition Specialist' },
  { key: 'virtual-recruiter-humanresourcesandrecruitment', label: 'Virtual Recruiter' },
  { key: 'assistant-supervisor-humanresourcesandrecruitment', label: 'Assistant Supervisor' },
  { key: 'benefits-specialist-humanresourcesandrecruitment', label: 'Benefits Specialist' },
  { key: 'country-manager-humanresourcesandrecruitment', label: 'Country Manager' },
  { key: 'employee-relations-specialist-humanresourcesandrecruitment', label: 'Employee Relations Specialist' },
  { key: 'field-operations-manager-humanresourcesandrecruitment', label: 'Field Operations Manager' },
  { key: 'hr-administrator-humanresourcesandrecruitment', label: 'HR Administrator' },
  { key: 'hr-business-partner-humanresourcesandrecruitment', label: 'HR Business Partner' },
  { key: 'hr-generalist-humanresourcesandrecruitment', label: 'HR Generalist' },
  { key: 'hr-officer-humanresourcesandrecruitment', label: 'HR Officer' },
  { key: 'hr-supervisor-humanresourcesandrecruitment', label: 'HR Supervisor' },
  { key: 'management-trainee-humanresourcesandrecruitment', label: 'Management Trainee' },
  { key: 'recruiter-humanresourcesandrecruitment', label: 'Recruiter' },
  { key: 'recruitment-coordinator-humanresourcesandrecruitment', label: 'Recruitment Coordinator' },
  { key: 'talent-acquisition-coordinator-humanresourcesandrecruitment', label: 'Talent Acquisition Coordinator' },
  { key: 'technical-recruiter-humanresourcesandrecruitment', label: 'Technical Recruiter' },
// InformationTechnology -informationtechnology
  { key: 'dnet-developer-informationtechnology', label: '.NET Developer' },
  { key: 'angularjs-developer-informationtechnology', label: 'AngularJS Developer' },
  { key: 'aspdnet-developer-informationtechnology', label: 'ASP.NET Developer' },
  { key: 'business-analyst-informationtechnology', label: 'Business Analyst' },
  { key: 'certified-ethical-hacker-informationtechnology', label: 'Certified Ethical Hacker' },
  { key: 'clinical-analyst-informationtechnology', label: 'Clinical Analyst' },
  { key: 'computer-engineer-informationtechnology', label: 'Computer Engineer' },
  { key: 'computer-programmer-informationtechnology', label: 'Computer Programmer' },
  { key: 'cyber-security-engineer-informationtechnology', label: 'Cyber Security Engineer' },
  { key: 'data-coordinator-informationtechnology', label: 'Data Coordinator' },
  { key: 'data-scientist-informationtechnology', label: 'Data Scientist' },
  { key: 'datastage-developer-informationtechnology', label: 'Datastage Developer' },
  { key: 'developer-informationtechnology', label: 'Developer' },
  { key: 'digital-project-manager-informationtechnology', label: 'Digital Project Manager' },
  { key: 'etl-developer-informationtechnology', label: 'ETL Developer' },
  { key: 'full-stack-engineer-informationtechnology', label: 'Full Stack Engineer' },
  { key: 'health-information-technology-informationtechnology', label: 'Health Information Technology' },
  { key: 'help-desk-technician-informationtechnology', label: 'Help Desk Technician' },
  { key: 'information-security-officer-informationtechnology', label: 'Information Security Officer' },
  { key: 'ios-developer-informationtechnology', label: 'iOS Developer' },
  { key: 'it-business-analyst-informationtechnology', label: 'IT Business Analyst' },
  { key: 'it-engineer-informationtechnology', label: 'IT Engineer' },
  { key: 'it-supervisor-informationtechnology', label: 'IT Supervisor' },
  { key: 'it-technician-informationtechnology', label: 'IT Technician' },
  { key: 'java-software-engineer-informationtechnology', label: 'Java Software Engineer' },
  { key: 'junior-software-developer-informationtechnology', label: 'Junior Software Developer' },
  { key: 'mobile-developer-informationtechnology', label: 'Mobile Developer' },
  { key: 'network-technician-informationtechnology', label: 'Network Technician' },
  { key: 'pega-developer-informationtechnology', label: 'Pega Developer' },
  { key: 'programmer-analyst-informationtechnology', label: 'Programmer Analyst' },
  { key: 'reactdjs-developer-informationtechnology', label: 'React.js Developer' },
  { key: 'salesforce-developer-informationtechnology', label: 'Salesforce Developer' },
  { key: 'security-administrator-informationtechnology', label: 'Security Administrator' },
  { key: 'senior-dnet-developer-informationtechnology', label: 'Senior .NET Developer' },
  { key: 'senior-developer-informationtechnology', label: 'Senior Developer' },
  { key: 'senior-software-developer-informationtechnology', label: 'Senior Software Developer' },
  { key: 'senior-web-developer-informationtechnology', label: 'Senior Web Developer' },
  { key: 'seo-specialist-informationtechnology', label: 'SEO Specialist' },
  { key: 'software-developer-informationtechnology', label: 'Software Developer' },
  { key: 'software-tester-informationtechnology', label: 'Software Tester' },
  { key: 'support-engineer-informationtechnology', label: 'Support Engineer' },
  { key: 'systems-analyst-informationtechnology', label: 'Systems Analyst' },
  { key: 'technical-architect-informationtechnology', label: 'Technical Architect' },
  { key: 'technical-manager-informationtechnology', label: 'Technical Manager' },
  { key: 'technical-support-engineer-informationtechnology', label: 'Technical Support Engineer' },
  { key: 'ui-ux-designer-informationtechnology', label: 'UI UX Designer' },
  { key: 'unix-system-administrator-informationtechnology', label: 'UNIX System Administrator' },
  { key: 'video-game-tester-informationtechnology', label: 'Video Game Tester' },
  { key: 'web-analyst-informationtechnology', label: 'Web Analyst' },
  { key: 'web-development-manager-informationtechnology', label: 'Web Development Manager' },
  { key: 'website-manager-informationtechnology', label: 'Website Manager' },
  { key: 'wordpress-developer-informationtechnology', label: 'WordPress Developer' },
  { key: 'analytics-manager-informationtechnology', label: 'Analytics Manager' },
  { key: 'application-developer-informationtechnology', label: 'Application Developer' },
  { key: 'back-end-developer-informationtechnology', label: 'Back end Developer' },
  { key: 'business-systems-analyst-informationtechnology', label: 'Business Systems Analyst' },
  { key: 'chief-data-officer-informationtechnology', label: 'Chief Data Officer' },
  { key: 'cloud-architect-informationtechnology', label: 'Cloud Architect' },
  { key: 'computer-hardware-engineer-informationtechnology', label: 'Computer Hardware Engineer' },
  { key: 'computer-systems-analyst-informationtechnology', label: 'Computer Systems Analyst' },
  { key: 'data-analyst-informationtechnology', label: 'Data Analyst' },
  { key: 'data-engineer-informationtechnology', label: 'Data Engineer' },
  { key: 'database-administrator-informationtechnology', label: 'Database Administrator' },
  { key: 'desktop-support-engineer-informationtechnology', label: 'Desktop Support Engineer' },
  { key: 'devops-architect-informationtechnology', label: 'DevOps Architect' },
  { key: 'embedded-software-engineer-informationtechnology', label: 'Embedded Software Engineer' },
  { key: 'front-end-developer-informationtechnology', label: 'Front End Developer' },
  { key: 'game-developer-informationtechnology', label: 'Game Developer' },
  { key: 'help-desk-analyst-informationtechnology', label: 'Help Desk Analyst' },
  { key: 'implementation-specialist-informationtechnology', label: 'Implementation Specialist' },
  { key: 'information-technology-manager-informationtechnology', label: 'Information Technology Manager' },
  { key: 'it-architect-informationtechnology', label: 'IT Architect' },
  { key: 'it-consultant-informationtechnology', label: 'IT Consultant' },
  { key: 'it-project-manager-informationtechnology', label: 'IT Project Manager' },
  { key: 'it-support-analyst-informationtechnology', label: 'IT Support Analyst' },
  { key: 'java-architect-informationtechnology', label: 'Java Architect' },
  { key: 'java-technical-lead-informationtechnology', label: 'Java Technical Lead' },
  { key: 'magento-developer-informationtechnology', label: 'Magento Developer' },
  { key: 'network-administrator-informationtechnology', label: 'Network Administrator' },
  { key: 'noc-engineer-informationtechnology', label: 'NOC Engineer' },
  { key: 'php-developer-informationtechnology', label: 'PHP Developer' },
  { key: 'qa-automation Engineer-informationtechnology', label: 'QA Automation Engineer' },
  { key: 'ruby-on-rails-developer-informationtechnology', label: 'Ruby on Rails Developer' },
  { key: 'scrum-master-informationtechnology', label: 'Scrum Master' },
  { key: 'security-analyst-informationtechnology', label: 'Security Analyst' },
  { key: 'senior-consultant-informationtechnology', label: 'Senior Consultant' },
  { key: 'senior-devops-engineer-informationtechnology', label: 'Senior DevOps Engineer' },
  { key: 'senior-software-engineer-informationtechnology', label: 'Senior Software Engineer' },
  { key: 'seo-analyst-informationtechnology', label: 'SEO Analyst' },
  { key: 'sharepoint-developer-informationtechnology', label: 'SharePoint Developer' },
  { key: 'software-engineer-informationtechnology', label: 'Software Engineer' },
  { key: 'solution-architect-informationtechnology', label: 'Solution Architect' },
  { key: 'support-specialist-informationtechnology', label: 'Support Specialist' },
  { key: 'it-support-specialist-informationtechnology', label: 'IT Support Specialist' },
  { key: 'systems-engineer-informationtechnology', label: 'Systems Engineer' },
  { key: 'technical-business-analyst-informationtechnology', label: 'Technical Business Analyst' },
  { key: 'technical-support-informationtechnology', label: 'Technical Support' },
  { key: 'ui-developer-informationtechnology', label: 'UI Developer' },
  { key: 'uiux-architect-informationtechnology', label: 'UI/UX Architect' },
  { key: 'ux-designer-informationtechnology', label: 'UX Designer' },
  { key: 'voip-network-engineer-informationtechnology', label: 'VoIP Network Engineer' },
  { key: 'web-designer-informationtechnology', label: 'Web Designer' },
  { key: 'web-technician-informationtechnology', label: 'Web Technician' },
  { key: 'websphere-administrator-informationtechnology', label: 'Websphere Administrator' },
  { key: 'android-developer-informationtechnology', label: 'Android Developer' },
  { key: 'application-support-engineer-informationtechnology', label: 'Application Support Engineer' },
  { key: 'big-data-engineer-informationtechnology', label: 'Big Data Engineer' },
  { key: 'c-developer-informationtechnology', label: 'C Developer' },
  { key: 'cio-informationtechnology', label: 'CIO' },
  { key: 'computer-analyst-informationtechnology', label: 'Computer Analyst' },
  { key: 'computer-operator-informationtechnology', label: 'Computer Operator' },
  { key: 'computer-technician-informationtechnology', label: 'Computer Technician' },
  { key: 'data-architect-informationtechnology', label: 'Data Architect' },
  { key: 'data-manager-informationtechnology', label: 'Data Manager' },
  { key: 'database-developer-informationtechnology', label: 'Database Developer' },
  { key: 'desktop-support-technician-informationtechnology', label: 'Desktop Support Technician' },
  { key: 'devops-engineer-informationtechnology', label: 'DevOps Engineer' },
  { key: 'enterprise-architect-informationtechnology', label: 'Enterprise Architect' },
  { key: 'full-stack-developer-informationtechnology', label: 'Full Stack Developer' },
  { key: 'hadoop-developer-informationtechnology', label: 'Hadoop Developer' },
  { key: 'help-desk-specialist-informationtechnology', label: 'Help Desk Specialist' },
  { key: 'information-security-analyst-informationtechnology', label: 'Information Security Analyst' },
  { key: 'infrastructure-engineer-informationtechnology', label: 'Infrastructure Engineer' },
  { key: 'it-auditor-informationtechnology', label: 'IT Auditor' },
  { key: 'it-director-informationtechnology', label: 'IT Director' },
  { key: 'it-manager-informationtechnology', label: 'IT Manager' },
  { key: 'it-specialist-informationtechnology', label: 'IT Specialist' },
  { key: 'it-support-engineer-informationtechnology', label: 'IT Support Engineer' },
  { key: 'java-developer-informationtechnology', label: 'Java Developer' },
  { key: 'javascript-developer-informationtechnology', label: 'JavaScript Developer' },
  { key: 'mis-executive-informationtechnology', label: 'MIS Executive' },
  { key: 'network-engineer-informationtechnology', label: 'Network Engineer' },
  { key: 'oracle-database-administrator-informationtechnology', label: 'Oracle Database Administrator' },
  { key: 'product-owner-informationtechnology', label: 'Product Owner' },
  { key: 'qa-tester-informationtechnology', label: 'QA Tester' },
  { key: 'sales-applications-engineer-informationtechnology', label: 'Sales Applications Engineer' },
  { key: 'sdet-informationtechnology', label: 'SDET' },
  { key: 'security-architect-informationtechnology', label: 'Security Architect' },
  { key: 'senior-data-scientist-informationtechnology', label: 'Senior Data Scientist' },
  { key: 'senior-network-engineer-informationtechnology', label: 'Senior Network Engineer' },
  { key: 'senior-software-manager-informationtechnology', label: 'Senior Software Manager' },
  { key: 'seo-manager-informationtechnology', label: 'SEO Manager' },
  { key: 'software-architect-informationtechnology', label: 'Software Architect' },
  { key: 'software-test-engineer-informationtechnology', label: 'Software Test Engineer' },
  { key: 'solutions-engineer-informationtechnology', label: 'Solutions Engineer' },
  { key: 'system-administrator-informationtechnology', label: 'System Administrator' },
  { key: 'tableau-developer-informationtechnology', label: 'Tableau Developer' },
  { key: 'technical-consultant-informationtechnology', label: 'Technical Consultant' },
  { key: 'technical-support-analyst-informationtechnology', label: 'Technical Support Analyst' },
  { key: 'ui-engineer-informationtechnology', label: 'UI Engineer' },
  { key: 'unity-developer-informationtechnology', label: 'Unity Developer' },
  { key: 'video-game-developer-informationtechnology', label: 'Video Game Developer' },
  { key: 'vp-of-engineering-informationtechnology', label: 'VP of Engineering' },
  { key: 'web-developer-informationtechnology', label: 'Web Developer' },
  { key: 'webmaster-informationtechnology', label: 'Webmaster' },
  { key: 'windows-administrator-informationtechnology', label: 'Windows Administrator' },
// Insurance -insurance
  { key: 'actuary-insurance', label: 'Actuary' },
  { key: 'claims-specialist-insurance', label: 'Claims Specialist' },
  { key: 'insurance-agent-insurance', label: 'Insurance Agent' },
  {
    key: 'insurance-customer-service-representative-insurance',
    label: 'Insurance Customer Service Representative',
  },
  {
    key: 'arogressive-claims-adjuster-trainee-insurance',
    label: 'Progressive Claims Adjuster Trainee',
  },
  { key: 'claims-adjuster-insurance', label: 'Claims Adjuster' },
  { key: 'dental-insurance-coordinator-insurance', label: 'Dental Insurance Coordinator' },
  { key: 'insurance-broker-insurance', label: 'Insurance Broker' },
  { key: 'insurance-sales-manager-insurance', label: 'Insurance Sales Manager' },
  { key: 'underwriter-insurance', label: 'Underwriter' },
  { key: 'claims-examiner-insurance', label: 'Claims Examiner' },
  { key: 'field-inspector-insurance', label: 'Field Inspector' },
  { key: 'insurance-consultant-insurance', label: 'Insurance Consultant' },
  { key: 'life-insurance-agent-insurance', label: 'Life Insurance Agent' },
// Legal -legal
  { key: 'arbitrator-legal', label: 'Arbitrator' },
  { key: 'barrister-legal', label: 'Barrister' },
  { key: 'civil-rights-attorney-legal', label: 'Civil Rights Attorney' },
  { key: 'contract-specialist-legal', label: 'Contract Specialist' },
  { key: 'court-clerk-legal', label: 'Court Clerk' },
  { key: 'criminal-lawyer-legal', label: 'Criminal Lawyer' },
  { key: 'criminologist-legal', label: 'Criminologist' },
  { key: 'field-investigator-legal', label: 'Field Investigator' },
  { key: 'forensic-scientist-legal', label: 'Forensic Scientist' },
  { key: 'law-clerk-legal', label: 'Law Clerk' },
  { key: 'legal-advisor-legal', label: 'Legal Advisor' },
  { key: 'legal-receptionist-legal', label: 'Legal Receptionist' },
  { key: 'parent-coordinator-legal', label: 'Parent Coordinator' },
  { key: 'police-sergeant-legal', label: 'Police Sergeant', isGrade: true },
  { key: 'prosecuter-legal', label: 'Prosecuter' },
  { key: 'real-estate-lawyer-legal', label: 'Real Estate Lawyer' },
  { key: 'associate-attorney-legal', label: 'Associate Attorney' },
  { key: 'bounty-hunter-legal', label: 'Bounty Hunter' },
  { key: 'compliance-coordinator-legal', label: 'Compliance Coordinator' },
  { key: 'corporate-lawyer-legal', label: 'Corporate Lawyer' },
  { key: 'crime-scene-investigator-legal', label: 'Crime Scene Investigator' },
  { key: 'criminal-profiler-legal', label: 'Criminal Profiler' },
  { key: 'district-attorney-investigator-legal', label: 'District Attorney Investigator' },
  { key: 'forensic-psychologist-legal', label: 'Forensic Psychologist' },
  { key: 'general-counsel-legal', label: 'General Counsel' },
  { key: 'lawyer-legal', label: 'Lawyer' },
  { key: 'legal-analyst-legal', label: 'Legal Analyst' },
  { key: 'litigation-paralegal-legal', label: 'Litigation Paralegal' },
  { key: 'patent-lawyer-legal', label: 'Patent Lawyer' },
  { key: 'private-investigator-legal', label: 'Private Investigator' },
  { key: 'public-defender-legal', label: 'Public Defender' },
  { key: 'school-resource-officer-legal', label: 'School Resource Officer' },
  { key: 'attorney-legal', label: 'Attorney' },
  { key: 'business-lawyer-legal', label: 'Business Lawyer' },
  { key: 'contract-administrator-legal', label: 'Contract Administrator' },
  { key: 'corrections-officer-legal', label: 'Corrections Officer' },
  { key: 'criminal-investigator-legal', label: 'Criminal Investigator' },
  { key: 'criminalist-legal', label: 'Criminalist' },
  { key: 'family-lawyer-legal', label: 'Family Lawyer' },
  { key: 'forensic-science-technician-legal', label: 'Forensic Science Technician' },
  { key: 'human-rights-advocate-legal', label: 'Human Rights Advocate' },
  { key: 'legal-administrative-assistant-legal', label: 'Legal Administrative Assistant' },
  { key: 'legal-assistant-legal', label: 'Legal Assistant' },
  { key: 'paralegal-legal', label: 'Paralegal' },
  { key: 'police-officer-legal', label: 'Police Officer' },
  { key: 'probation-officer-legal', label: 'Probation Officer' },
  { key: 'public-safety-officer-legal', label: 'Public Safety Officer' },
  { key: 'solicitor-legal', label: 'Solicitor' },
// ManufacturingTransportAndLogistics -manufacturingtransportandlogistics
  { key: 'air-traffic-controller-manufacturingtransportandlogistics', label: 'Air Traffic Controller' },
  { key: 'az-driver-manufacturingtransportandlogistics', label: 'AZ Driver' },
  { key: 'bookbinder-manufacturingtransportandlogistics', label: 'Bookbinder' },
  { key: 'car-porter-manufacturingtransportandlogistics', label: 'Car Porter' },
  { key: 'chemical-operator-manufacturingtransportandlogistics', label: 'Chemical Operator' },
  { key: 'company-driver-manufacturingtransportandlogistics', label: 'Company Driver' },
  { key: 'delivery-driver-manufacturingtransportandlogistics', label: 'Delivery Driver' },
  { key: 'driver-manufacturingtransportandlogistics', label: 'Driver' },
  { key: 'dz-driver-manufacturingtransportandlogistics', label: 'DZ Driver' },
  { key: 'fabricator-manufacturingtransportandlogistics', label: 'Fabricator' },
  { key: 'forklift-operator-manufacturingtransportandlogistics', label: 'Forklift Operator' },
  { key: 'industrial-electrician-manufacturingtransportandlogistics', label: 'Industrial Electrician' },
  { key: 'local-truck-driver-manufacturingtransportandlogistics', label: 'Local Truck Driver' },
  { key: 'logistics-coordinator-manufacturingtransportandlogistics', label: 'Logistics Coordinator' },
  { key: 'lot-attendant-manufacturingtransportandlogistics', label: 'Lot Attendant' },
  { key: 'material-handler-manufacturingtransportandlogistics', label: 'Material Handler' },
  { key: 'messenger-manufacturingtransportandlogistics', label: 'Messenger' },
  { key: 'order-selector-manufacturingtransportandlogistics', label: 'Order Selector' },
  { key: 'package-handler-manufacturingtransportandlogistics', label: 'Package Handler' },
  { key: 'personal-driver-manufacturingtransportandlogistics', label: 'Personal Driver' },
  { key: 'pizza-delivery-driver-manufacturingtransportandlogistics', label: 'Pizza Delivery Driver' },
  { key: 'production-associate-manufacturingtransportandlogistics', label: 'Production Associate' },
  { key: 'production-scheduler-manufacturingtransportandlogistics', label: 'Production Scheduler' },
  { key: 'purchasing-agent-manufacturingtransportandlogistics', label: 'Purchasing Agent' },
  { key: 'quality-analyst-manufacturingtransportandlogistics', label: 'Quality Analyst' },
  { key: 'quality-technician-manufacturingtransportandlogistics', label: 'Quality Technician' },
  { key: 'receiving-clerk-manufacturingtransportandlogistics', label: 'Receiving Clerk' },
  { key: 'senior-qa-engineer-manufacturingtransportandlogistics', label: 'Senior QA Engineer' },
  { key: 'shipping-coordinator-manufacturingtransportandlogistics', label: 'Shipping Coordinator' },
  { key: 'street-sweeper-operator-manufacturingtransportandlogistics', label: 'Street Sweeper Operator' },
  { key: 'supply-technician-manufacturingtransportandlogistics', label: 'Supply Technician' },
  { key: 'tractor-trailer-truck-driver-manufacturingtransportandlogistics', label: 'Tractor-trailer Truck Driver' },
  { key: 'transportation-coordinator-manufacturingtransportandlogistics', label: 'Transportation Coordinator' },
  { key: 'verification-engineer-manufacturingtransportandlogistics', label: 'Verification Engineer' },
  { key: 'warehouse-driver-manufacturingtransportandlogistics', label: 'Warehouse Driver' },
  { key: 'warehouse-operator-manufacturingtransportandlogistics', label: 'Warehouse Operator' },
  { key: 'yard-hostler-manufacturingtransportandlogistics', label: 'Yard Hostler' },
  { key: 'assembler-manufacturingtransportandlogistics', label: 'Assembler' },
  { key: 'az-dz-driver-manufacturingtransportandlogistics', label: 'AZ DZ Driver' },
  { key: 'brewmaster-manufacturingtransportandlogistics', label: 'Brewmaster' },
  { key: 'cdl-driver-manufacturingtransportandlogistics', label: 'CDL Driver' },
  { key: 'cnc-machinist-manufacturingtransportandlogistics', label: 'CNC Machinist' },
  { key: 'controls-engineer-manufacturingtransportandlogistics', label: 'Controls Engineer' },
  { key: 'demand-planner-manufacturingtransportandlogistics', label: 'Demand Planner' },
  { key: 'driver-helper-manufacturingtransportandlogistics', label: 'Driver Helper' },
  { key: 'estimator-manufacturingtransportandlogistics', label: 'Factory Manager' },
  { key: 'freight-broker-manufacturingtransportandlogistics', label: 'Freight Broker' },
  { key: 'industrial-maintenance-technician-manufacturingtransportandlogistics', label: 'Industrial Maintenance Technician' },
  { key: 'locomotive-engineer-manufacturingtransportandlogistics', label: 'Locomotive Engineer' },
  { key: 'logistics-manager-manufacturingtransportandlogistics', label: 'Logistics Manager' },
  { key: 'machine-operator-manufacturingtransportandlogistics', label: 'Machine Operator' },
  { key: 'material-planner-manufacturingtransportandlogistics', label: 'Material Planner' },
  { key: 'metal-fabricator-manufacturingtransportandlogistics', label: 'Metal Fabricator' },
  { key: 'otr-driver-manufacturingtransportandlogistics', label: 'OTR Driver' },
  { key: 'packager-manufacturingtransportandlogistics', label: 'Packager' },
  { key: 'picker-manufacturingtransportandlogistics', label: 'Picker' },
  { key: 'plant-manager-manufacturingtransportandlogistics', label: 'Plant Manager' },
  { key: 'production-manager-manufacturingtransportandlogistics', label: 'Production Manager' },
  { key: 'production-supervisor-manufacturingtransportandlogistics', label: 'Production Supervisor' },
  { key: 'purchasing-manager-manufacturingtransportandlogistics', label: 'Purchasing Manager' },
  { key: 'quality-coordinator-manufacturingtransportandlogistics', label: 'Quality Coordinator' },
  { key: 'railroad-conductor-manufacturingtransportandlogistics', label: 'Railroad Conductor' },
  { key: 'route-driver-manufacturingtransportandlogistics', label: 'Route Driver' },
  { key: 'shift-supervisor-manufacturingtransportandlogistics', label: 'Shift Supervisor' },
  { key: 'shuttle-bus-driver-manufacturingtransportandlogistics', label: 'Shuttle Bus Driver' },
  { key: 'supply-chain-analyst-manufacturingtransportandlogistics', label: 'Supply Chain Analyst' },
  { key: 'taxi-driver-manufacturingtransportandlogistics', label: 'Taxi Driver' },
  { key: 'train-conductor-manufacturingtransportandlogistics', label: 'Train Conductor' },
  { key: 'truck-driver-manufacturingtransportandlogistics', label: 'Truck Driver' },
  { key: 'warehouse-associate-manufacturingtransportandlogistics', label: 'Warehouse Associate' },
  { key: 'warehouse-manager-manufacturingtransportandlogistics', label: 'Warehouse Manager' },
  { key: 'warehouse-supervisor-manufacturingtransportandlogistics', label: 'Warehouse Supervisor' },
  { key: 'yard-jockey-manufacturingtransportandlogistics', label: 'Yard Jockey' },
  { key: 'assistant-production-manager-manufacturingtransportandlogistics', label: 'Assistant Production Manager' },
  { key: 'bike-courier-manufacturingtransportandlogistics', label: 'Bike Courier' },
  { key: 'candle-maker-manufacturingtransportandlogistics', label: 'Candle Maker' },
  { key: 'chauffeur-manufacturingtransportandlogistics', label: 'Chauffeur' },
  { key: 'cnc-operator-manufacturingtransportandlogistics', label: 'CNC Operator' },
  { key: 'delivery-boy-manufacturingtransportandlogistics', label: 'Delivery Boy' },
  { key: 'dock-worker-manufacturingtransportandlogistics', label: 'Dock Worker' },
  { key: 'dump-truck-driver-manufacturingtransportandlogistics', label: 'Dump Truck Driver' },
  { key: 'expeditor-manufacturingtransportandlogistics', label: 'Expeditor' },
  { key: 'fleet-manager-manufacturingtransportandlogistics', label: 'Fleet Manager' },
  { key: 'fulfillment-associate-manufacturingtransportandlogistics', label: 'Fulfillment Associate' },
  {
    key: 'industrial-truck-and-tractor-operator-manufacturingtransportandlogistics',
    label: 'Industrial Truck and Tractor Operator',
  },
  { key: 'logistics-assistant-manufacturingtransportandlogistics', label: 'Logistics Assistant' },
  { key: 'longshoreman-manufacturingtransportandlogistics', label: 'Longshoreman' },
  { key: 'machinist-manufacturingtransportandlogistics', label: 'Machinist' },
  { key: 'materials-manager-manufacturingtransportandlogistics', label: 'Materials Manager' },
  { key: 'millwright-manufacturingtransportandlogistics', label: 'Millwright' },
  { key: 'owner-operator-manufacturingtransportandlogistics', label: 'Owner Operator' },
  { key: 'packer-manufacturingtransportandlogistics', label: 'Packer' },
  { key: 'pilot-manufacturingtransportandlogistics', label: 'Pilot' },
  { key: 'process-associate-manufacturingtransportandlogistics', label: 'Process Associate' },
  { key: 'production-planner-manufacturingtransportandlogistics', label: 'Production Planner' },
  { key: 'production-worker-manufacturingtransportandlogistics', label: 'Production Worker' },
  { key: 'purchasing-specialist-manufacturingtransportandlogistics', label: 'Purchasing Specialist' },
  { key: 'quality-inspector-manufacturingtransportandlogistics', label: 'Quality Inspector' },
  { key: 'ramp-agent-manufacturingtransportandlogistics', label: 'Ramp Agent' },
  { key: 'sanitation-supervisor-manufacturingtransportandlogistics', label: 'Sanitation Supervisor' },
  { key: 'shipping-clerk-manufacturingtransportandlogistics', label: 'Shipping Clerk' },
  { key: 'sourcing-specialist-manufacturingtransportandlogistics', label: 'Sourcing Specialist' },
  { key: 'supply-chain-manager-manufacturingtransportandlogistics', label: 'Supply Chain Manager' },
  { key: 'toll-collector-manufacturingtransportandlogistics', label: 'Toll Collector' },
  { key: 'transportation-analyst-manufacturingtransportandlogistics', label: 'Transportation Analyst' },
  { key: 'vendor-manager-manufacturingtransportandlogistics', label: 'Vendor Manager' },
  { key: 'warehouse-clerk-manufacturingtransportandlogistics', label: 'Warehouse Clerk' },
  { key: 'warehouse-operations-assistant-manufacturingtransportandlogistics', label: 'Warehouse Operations Assistant' },
  { key: 'warehouse-worker-manufacturingtransportandlogistics', label: 'Warehouse Worker' },
// MarketingAndCommunications -marketingandcommunications
  { key: 'blogger-marketingandcommunications', label: 'Blogger' },
  { key: 'brand-strategist-marketingandcommunications', label: 'Brand Strategist' },
  { key: 'communications-coordinator-marketingandcommunications', label: 'Communications Coordinator' },
  { key: 'communications-officer-marketingandcommunications', label: 'Communications Officer' },
  { key: 'content-creator-marketingandcommunications', label: 'Content Creator' },
  { key: 'content-marketing-manager-marketingandcommunications', label: 'Content Marketing Manager' },
  { key: 'copy-editor-marketingandcommunications', label: 'Copy Editor' },
  { key: 'digital-marketing-consultant-marketingandcommunications', label: 'Digital Marketing Consultant' },
  { key: 'digital-strategist-marketingandcommunications', label: 'Digital Strategist' },
  { key: 'event-promoter-marketingandcommunications', label: 'Event Promoter' },
  { key: 'marketer-marketingandcommunications', label: 'Marketer' },
  { key: 'marketing-director-marketingandcommunications', label: 'Marketing Director' },
  { key: 'marketing-management-marketingandcommunications', label: 'Marketing Management' },
  { key: 'marketing-specialist-marketingandcommunications', label: 'Marketing Specialist' },
  { key: 'online-marketer-marketingandcommunications', label: 'Online Marketer' },
  { key: 'physician-liaison-marketingandcommunications', label: 'Physician Liaison' },
  { key: 'product-marketing-manager-marketingandcommunications', label: 'Product Marketing Manager' },
  { key: 'public-relations-marketingandcommunications', label: 'Public Relations' },
  { key: 'publicist-marketingandcommunications', label: 'Publicist' },
  { key: 'seo-executive-marketingandcommunications', label: 'SEO Executive' },
  { key: 'social-media-specialist-marketingandcommunications', label: 'Social Media Specialist' },
  { key: 'vp-of-marketing-marketingandcommunications', label: 'VP of Marketing' },
  { key: 'brand-ambassador-marketingandcommunications', label: 'Brand Ambassador' },
  { key: 'campaign-manager-marketingandcommunications', label: 'Campaign Manager' },
  { key: 'communications-director-marketingandcommunications', label: 'Communications Director' },
  { key: 'communications-specialist-marketingandcommunications', label: 'Communications Specialist' },
  { key: 'content-editor-marketingandcommunications', label: 'Content Editor' },
  { key: 'content-strategist-marketingandcommunications', label: 'Content Strategist' },
  { key: 'copywriter-marketingandcommunications', label: 'Copywriter' },
  { key: 'digital-marketing-coordinator-marketingandcommunications', label: 'Digital Marketing Coordinator' },
  { key: 'editor-marketingandcommunications', label: 'Editor' },
  { key: 'liaison-marketingandcommunications', label: 'Liaison' },
  { key: 'marketing-assistant-marketingandcommunications', label: 'Marketing Assistant' },
  { key: 'marketing-executive-marketingandcommunications', label: 'Marketing Executive' },
  { key: 'marketing-manager-marketingandcommunications', label: 'Marketing Manager' },
  { key: 'media-coordinator-marketingandcommunications', label: 'Media Coordinator' },
  { key: 'online-marketing-executive-marketingandcommunications', label: 'Online Marketing Executive' },
  { key: 'presenter-marketingandcommunications', label: 'Presenter' },
  { key: 'promoter-marketingandcommunications', label: 'Promoter' },
  { key: 'public-relations-director-marketingandcommunications', label: 'Public Relations Director' },
  { key: 'recruitment-marketer-marketingandcommunications', label: 'Recruitment Marketer' },
  { key: 'social-media-executive-marketingandcommunications', label: 'Social Media Executive' },
  { key: 'social-media-strategist-marketingandcommunications', label: 'Social Media Strategist' },
  { key: 'writer-marketingandcommunications', label: 'Writer' },
  { key: 'brand-manager-marketingandcommunications', label: 'Brand Manager' },
  { key: 'chief-marketing-officer-marketingandcommunications', label: 'Chief Marketing Officer' },
  { key: 'communications-manager-marketingandcommunications', label: 'Communications Manager' },
  { key: 'community-manager-marketingandcommunications', label: 'Community Manager' },
  { key: 'content-manager-marketingandcommunications', label: 'Content Manager' },
  { key: 'content-writer-marketingandcommunications', label: 'Content Writer' },
  { key: 'digital-marketing-assistant-marketingandcommunications', label: 'Digital Marketing Assistant' },
  { key: 'digital-marketing-manager-marketingandcommunications', label: 'Digital Marketing Manager' },
  { key: 'email-marketing-specialist-marketingandcommunications', label: 'Email Marketing Specialist' },
  { key: 'magazine-editor-marketingandcommunications', label: 'Magazine Editor' },
  { key: 'marketing-coordinator-marketingandcommunications', label: 'Marketing Coordinator' },
  { key: 'marketing-intern-marketingandcommunications', label: 'Marketing Intern' },
  { key: 'marketing-officer-marketingandcommunications', label: 'Marketing Officer' },
  { key: 'media-planner-marketingandcommunications', label: 'Media Planner' },
  { key: 'online-marketing-manager-marketingandcommunications', label: 'Online Marketing Manager' },
  { key: 'product-analyst-marketingandcommunications', label: 'Product Analyst' },
  { key: 'public-information-officer-marketingandcommunications', label: 'Public Information Officer' },
  { key: 'public-relations-manager-marketingandcommunications', label: 'Public Relations Manager' },
  { key: 'sales-and-marketing-manager-marketingandcommunications', label: 'Sales and Marketing Manager' },
  { key: 'social-media-manager-marketingandcommunications', label: 'Social Media Manager' },
  { key: 'sports-marketing-manager-marketingandcommunications', label: 'Sports Marketing Manager' },
// RealEstateAndProperty -realestateandproperty
  { key: 'apartment-maintenance-technician-realestateandproperty', label: 'Apartment Maintenance Technician' },
  { key: 'commercial-real-estate-broker-realestateandproperty', label: 'Commercial Real Estate Broker' },
  { key: 'leasing-agent-realestateandproperty', label: 'Leasing Agent' },
  { key: 'property-consultant-realestateandproperty', label: 'Property Consultant' },
  { key: 'real-estate-assistant-realestateandproperty', label: 'Real Estate Assistant' },
  { key: 'title-examiner-realestateandproperty', label: 'Title Examiner' },
  { key: 'apartment-manager-realestateandproperty', label: 'Apartment Manager' },
  { key: 'escrow-officer-realestateandproperty', label: 'Escrow Officer' },
  { key: 'leasing-consultant-realestateandproperty', label: 'Leasing Consultant' },
  { key: 'property-manager-realestateandproperty', label: 'Property Manager' },
  { key: 'real-estate-broker-realestateandproperty', label: 'Real Estate Broker' },
  { key: 'assistant-property-manager-realestateandproperty', label: 'Assistant Property Manager' },
  { key: 'facilities-director-realestateandproperty', label: 'Facilities Director' },
  { key: 'leasing-manager-realestateandproperty', label: 'Leasing Manager' },
  { key: 'real-estate-agent-realestateandproperty', label: 'Real Estate Agent' },
  { key: 'realtor-realestateandproperty', label: 'Realtor' },
// RetailAndConsumerProducts -retailandconsumerproducts
  { key: 'assistant-buyer-retailandconsumerproducts', label: 'Assistant Buyer' },
  { key: 'baker-retailandconsumerproducts', label: 'Baker' },
  { key: 'budtender-retailandconsumerproducts', label: 'Budtender' },
  { key: 'cashier-retailandconsumerproducts', label: 'Cashier' },
  { key: 'deli-clerk-retailandconsumerproducts', label: 'Deli Clerk' },
  { key: 'grocery-clerk-retailandconsumerproducts', label: 'Grocery Clerk' },
  { key: 'merchandise-associate-retailandconsumerproducts', label: 'Merchandise Associate' },
  { key: 'nursery-manager-retailandconsumerproducts', label: 'Nursery Manager' },
  { key: 'personal-shopper-retailandconsumerproducts', label: 'Personal Shopper' },
  { key: 'produce-clerk-retailandconsumerproducts', label: 'Produce Clerk' },
  { key: 'purchaser-retailandconsumerproducts', label: 'Purchaser' },
  { key: 'retail-assistant-manager-retailandconsumerproducts', label: 'Retail Assistant Manager' },
  { key: 'retail-merchandiser-retailandconsumerproducts', label: 'Retail Merchandiser' },
  { key: 'retail-store-manager-retailandconsumerproducts', label: 'Retail Store Manager' },
  { key: 'sales-clerk-retailandconsumerproducts', label: 'Sales Clerk' },
  { key: 'salesperson-retailandconsumerproducts', label: 'Salesperson' },
  { key: 'shop-assistant-retailandconsumerproducts', label: 'Shop Assistant' },
  { key: 'stock-associate-retailandconsumerproducts', label: 'Stock Associate' },
  { key: 'store-associate-retailandconsumerproducts', label: 'Store Associate' },
  { key: 'store-manager-retailandconsumerproducts', label: 'Store Manager' },
  { key: 'ticket-booth-supervisor-retailandconsumerproducts', label: 'Ticket Booth Supervisor' },
  { key: 'assistant-manager-retailandconsumerproducts', label: 'Assistant Manager' },
  { key: 'bakery-assistant-retailandconsumerproducts', label: 'Bakery Assistant' },
  { key: 'butcher-retailandconsumerproducts', label: 'Butcher' },
  { key: 'client-services-retailandconsumerproducts', label: 'Client Services' },
  { key: 'distributor-retailandconsumerproducts', label: 'Distributor' },
  { key: 'independent-distributor-retailandconsumerproducts', label: 'Independent Distributor' },
  { key: 'merchandiser-retailandconsumerproducts', label: 'Merchandiser' },
  { key: 'overnight-stocker-retailandconsumerproducts', label: 'Overnight Stocker' },
  { key: 'procurement-manager-retailandconsumerproducts', label: 'Procurement Manager' },
  { key: 'product-engineer-retailandconsumerproducts', label: 'Product Engineer' },
  { key: 'purchasing-assistant-retailandconsumerproducts', label: 'Purchasing Assistant' },
  { key: 'retail-associate-retailandconsumerproducts', label: 'Retail Associate' },
  { key: 'retail-sales-associate-retailandconsumerproducts', label: 'Retail Sales Associate' },
  { key: 'sale-assistant-retailandconsumerproducts', label: 'Sale Assistant' },
  { key: 'sales-promoter-retailandconsumerproducts', label: 'Sales Promoter' },
  { key: 'service-clerk-retailandconsumerproducts', label: 'Service Clerk' },
  { key: 'shop-manager-retailandconsumerproducts', label: 'Shop Manager' },
  { key: 'stock-clerk-retailandconsumerproducts', label: 'Stock Clerk' },
  { key: 'store-clerk-retailandconsumerproducts', label: 'Store Clerk' },
  { key: 'store-supervisor-retailandconsumerproducts', label: 'Store Supervisor' },
  { key: 'visual-merchandiser-retailandconsumerproducts', label: 'Visual Merchandiser' },
  { key: 'assistant-store-manager-retailandconsumerproducts', label: 'Assistant Store Manager' },
  { key: 'bakery-manager-retailandconsumerproducts', label: 'Bakery Manager' },
  { key: 'buyer-retailandconsumerproducts', label: 'Buyer' },
  { key: 'courtesy-clerk-retailandconsumerproducts', label: 'Courtesy Clerk' },
  { key: 'floor-manager-retailandconsumerproducts', label: 'Floor Manager' },
  { key: 'loss-prevention-officer-retailandconsumerproducts', label: 'Loss Prevention Officer' },
  { key: 'mystery-shopper-retailandconsumerproducts', label: 'Mystery Shopper' },
  { key: 'packaging-designer-retailandconsumerproducts', label: 'Packaging Designer' },
  { key: 'procurement-officer-retailandconsumerproducts', label: 'Procurement Officer' },
  { key: 'product-manager-retailandconsumerproducts', label: 'Product Manager' },
  { key: 'purchasing-officer-retailandconsumerproducts', label: 'Purchasing Officer' },
  { key: 'retail-manager-retailandconsumerproducts', label: 'Retail Manager' },
  { key: 'retail-sales-consultant-retailandconsumerproducts', label: 'Retail Sales Consultant' },
  { key: 'sales-advisor-retailandconsumerproducts', label: 'Sales Advisor' },
  { key: 'sales-specialist-retailandconsumerproducts', label: 'Sales Specialist' },
  { key: 'shift-leader-retailandconsumerproducts', label: 'Shift Leader' },
  { key: 'showroom-manager-retailandconsumerproducts', label: 'Showroom Manager' },
  { key: 'stocker-retailandconsumerproducts', label: 'Stocker' },
  { key: 'store-keeper-retailandconsumerproducts', label: 'Store Keeper' },
  { key: 'stylist-retailandconsumerproducts', label: 'Stylist' },
// Sales -sales
  { key: 'account-coordinator-sales', label: 'Account Coordinator' },
  { key: 'account-representative-sales', label: 'Account Representative' },
  { key: 'area-manager-sales', label: 'Area Manager' },
  { key: 'bdc-representative-sales', label: 'BDC Representative' },
  { key: 'business-development-director-sales', label: 'Business Development Director' },
  { key: 'business-development-officer-sales', label: 'Business Development Officer' },
  { key: 'collections-specialist-sales', label: 'Collections Specialist' },
  { key: 'direct-sales-representative-sales', label: 'Direct Sales Representative' },
  { key: 'district-sales-manager-sales', label: 'District Sales Manager' },
  { key: 'field-representative-sales', label: 'Field Representative' },
  { key: 'independent-sales-representative-sales', label: 'Independent Sales Representative' },
  { key: 'inside-sales-manager-sales', label: 'Inside Sales Manager' },
  { key: 'key-account-specialist-sales', label: 'Key Account Specialist' },
  { key: 'process-executive-sales', label: 'Process Executive' },
  { key: 'sales-account-executive-sales', label: 'Sales Account Executive' },
  { key: 'sales-analyst-sales', label: 'Sales Analyst' },
  { key: 'sales-associate-sales', label: 'Sales Associate' },
  { key: 'sales-development-representative-sales', label: 'Sales Development Representative' },
  { key: 'sales-executive-sales', label: 'Sales Executive' },
  { key: 'sales-officer-sales', label: 'Sales Officer' },
  { key: 'senior-account-manager-sales', label: 'Senior Account Manager' },
  { key: 'solar-sales-consultant-sales', label: 'Solar Sales Consultant' },
  { key: 'telecaller-sales', label: 'Telecaller' },
  { key: 'telesales-representative-sales', label: 'Telesales Representative' },
  { key: 'account-executive-sales', label: 'Account Executive' },
  { key: 'advertising-sales-representative-sales', label: 'Advertising Sales Representative' },
  { key: 'area-sales-manager-sales', label: 'Area Sales Manager' },
  { key: 'business-development-associate-sales', label: 'Business Development Associate' },
  { key: 'business-development-executive-sales', label: 'Business Development Executive' },
  { key: 'canvasser-sales', label: 'Canvasser' },
  { key: 'collector-sales', label: 'Collector' },
  { key: 'director-of-business-development-sales', label: 'Director of Business Development' },
  { key: 'e-commerce-associate-sales', label: 'E-Commerce Associate' },
  { key: 'field-sales-manager-sales', label: 'Field Sales Manager' },
  {
    key: 'information-technology-sales-associate-sales',
    label: 'Information Technology Sales Associate',
  },
  { key: 'inside-sales-representative-sales', label: 'Inside Sales Representative' },
  { key: 'outside-sales-representative-sales', label: 'Outside Sales Representative' },
  { key: 'regional-director-sales', label: 'Regional Director' },
  { key: 'sales-account-manager-sales', label: 'Sales Account Manager' },
  { key: 'sales-and-marketing-associate-sales', label: 'Sales and Marketing Associate' },
  { key: 'sales-consultant-sales', label: 'Sales Consultant' },
  { key: 'sales-director-sales', label: 'Sales Director' },
  { key: 'sales-lead-sales', label: 'Sales Lead' },
  { key: 'sales-representative-sales', label: 'Sales Representative' },
  { key: 'senior-sales-executive-sales', label: 'Senior Sales Executive' },
  { key: 'technical-sales-engineer-sales', label: 'Technical Sales Engineer' },
  { key: 'telesales-executive-sales', label: 'Telesales Executive' },
  { key: 'territory-sales-manager-sales', label: 'Territory Sales Manager' },
  { key: 'wholesale-assistant-sales', label: 'Wholesale Assistant' },
  { key: 'account-manager-sales', label: 'Account Manager' },
  { key: 'appointment-setter-sales', label: 'Appointment Setter' },
  { key: 'assistant-sales-manager-sales', label: 'Assistant Sales Manager' },
  { key: 'business-development-coordinator-sales', label: 'Business Development Coordinator' },
  { key: 'business-development-manager-sales', label: 'Business Development Manager' },
  { key: 'car-salesman-sales', label: 'Car Salesman' },
  { key: 'counter-sales-sales', label: 'Counter Sales' },
  { key: 'director-of-sales-sales', label: 'Director of Sales' },
  { key: 'e-commerce-manager-sales', label: 'E-Commerce Manager' },
  { key: 'independent-sales-associate-sales', label: 'Independent Sales Associate' },
  { key: 'inside-sales-executive-sales', label: 'Inside Sales Executive' },
  { key: 'key-account-manager-sales', label: 'Key Account Manager' },
  { key: 'parts-manager-sales', label: 'Parts Manager' },
  { key: 'regional-sales-manager-sales', label: 'Regional Sales Manager' },
  { key: 'sales-administrator-sales', label: 'Sales Administrator' },
  { key: 'sales-and-marketing-executive-sales', label: 'Sales and Marketing Executive' },
  { key: 'sales-coordinator-sales', label: 'Sales Coordinator' },
  { key: 'sales-engineer-sales', label: 'Sales Engineer' },
  { key: 'sales-manager-sales', label: 'Sales Manager' },
  { key: 'senior-account-executive-sales', label: 'Senior Account Executive' },
  { key: 'senior-sales-representative-sales', label: 'Senior Sales Representative' },
  { key: 'technical-sales-executive-sales', label: 'Technical Sales Executive' },
  { key: 'telesales-manager-sales', label: 'Telesales Manager' },
  { key: 'van-salesman-sales', label: 'Van Salesman' },
// ScienceAndTechnology -scienceandtechnology
  { key: 'anthropologist-scienceandtechnology', label: 'Anthropologist' },
  { key: 'biochemist-scienceandtechnology', label: 'Biochemist' },
  { key: 'chemist-scienceandtechnology', label: 'Chemist' },
  { key: 'forensic-anthropologist-scienceandtechnology', label: 'Forensic Anthropologist' },
  { key: 'gerontologist-scienceandtechnology', label: 'Gerontologist' },
  { key: 'hydrologist-scienceandtechnology', label: 'Hydrologist' },
  { key: 'linguist-scienceandtechnology', label: 'Linguist' },
  { key: 'microbiologist-scienceandtechnology', label: 'Microbiologist' },
  { key: 'research-psychologist-scienceandtechnology', label: 'Research Psychologist' },
  { key: 'sociologist-scienceandtechnology', label: 'Sociologist' },
  { key: 'astronomer-scienceandtechnology', label: 'Astronomer' },
  { key: 'biologist-scienceandtechnology', label: 'Biologist' },
  { key: 'climatologist-scienceandtechnology', label: 'Climatologist' },
  { key: 'geneticist-scienceandtechnology', label: 'Geneticist' },
  { key: 'gis-analyst-scienceandtechnology', label: 'GIS Analyst' },
  { key: 'lab-assistant-scienceandtechnology', label: 'Lab Assistant' },
  { key: 'marine-biologist-scienceandtechnology', label: 'Marine Biologist' },
  { key: 'qualitative-researcher-scienceandtechnology', label: 'Qualitative Researcher' },
  { key: 'research-scientist-scienceandtechnology', label: 'Research Scientist' },
  { key: 'astrophysicist-scienceandtechnology', label: 'Astrophysicist' },
  { key: 'botanist-scienceandtechnology', label: 'Botanist' },
  { key: 'food-scientist-scienceandtechnology', label: 'Food Scientist' },
  { key: 'geologist-scienceandtechnology', label: 'Geologist' },
  { key: 'gis-technician-scienceandtechnology', label: 'GIS Technician' },
  { key: 'lab-technician-scienceandtechnology', label: 'Lab Technician' },
  { key: 'meteorologist-scienceandtechnology', label: 'Meteorologist' },
  { key: 'quantitative-researcher-scienceandtechnology', label: 'Quantitative Researcher' },
  { key: 'research-specialist-scienceandtechnology', label: 'Research Specialist' },
// SportsAndRecreation -sportsandrecreation
  { key: 'activities-director-sportsandrecreation', label: 'Activities Director' },
  { key: 'athletic-trainer-sportsandrecreation', label: 'Athletic Trainer' },
  { key: 'camp-director-sportsandrecreation', label: 'Camp Director' },
  { key: 'fitness-instructor-sportsandrecreation', label: 'Fitness Instructor' },
  { key: 'lacrosse-coach-sportsandrecreation', label: 'Lacrosse Coach' },
  { key: 'recreation-assistant-sportsandrecreation', label: 'Recreation Assistant' },
  { key: 'sports-agent-sportsandrecreation', label: 'Sports Agent' },
  { key: 'swimming-coach-sportsandrecreation', label: 'Swimming Coach' },
  { key: 'yoga-instructor-sportsandrecreation', label: 'Yoga Instructor' },
  { key: 'assistant-coach-sportsandrecreation', label: 'Assistant Coach' },
  { key: 'basketball-coach-sportsandrecreation', label: 'Basketball Coach' },
  { key: 'dance-instructor-sportsandrecreation', label: 'Dance Instructor' },
  { key: 'football-coach-sportsandrecreation', label: 'Football Coach' },
  { key: 'martial-arts-instructor-sportsandrecreation', label: 'Martial Arts Instructor' },
  { key: 'recreation-specialist-sportsandrecreation', label: 'Recreation Specialist' },
  { key: 'sports-coach-sportsandrecreation', label: 'Sports Coach' },
  { key: 'tennis-coach-sportsandrecreation', label: 'Tennis Coach' },
  { key: 'youth-sports-coach-sportsandrecreation', label: 'Youth Sports Coach' },
  { key: 'athletic-director-sportsandrecreation', label: 'Athletic Director' },
  { key: 'camp-counselor-sportsandrecreation', label: 'Camp Counselor' },
  { key: 'fitness-director-sportsandrecreation', label: 'Fitness Director' },
  { key: 'head-coach-sportsandrecreation', label: 'Head Coach' },
  { key: 'personal-trainer-sportsandrecreation', label: 'Personal Trainer' },
  { key: 'spin-instructor-sportsandrecreation', label: 'Spin Instructor' },
  { key: 'swim-instructor-sportsandrecreation', label: 'Swim Instructor' },
  { key: 'tumbling-coach-sportsandrecreation', label: 'Tumbling Coach' },
// TradesAndServices -tradesandservices
  { key: 'appliance-repair-technician-tradesandservices', label: 'Appliance Repair Technician' },
  { key: 'audio-video-technician-tradesandservices', label: 'Audio Video Technician' },
  { key: 'auto-glass-technician-tradesandservices', label: 'Auto Glass Technician' },
  { key: 'barber-tradesandservices', label: 'Barber' },
  { key: 'beauty-therapist-tradesandservices', label: 'Beauty Therapist' },
  { key: 'cabinet-maker-tradesandservices', label: 'Cabinet Maker' },
  { key: 'cake-decorator-tradesandservices', label: 'Cake Decorator' },
  { key: 'carpenter-tradesandservices', label: 'Carpenter' },
  { key: 'chemical-technician-tradesandservices', label: 'Chemical Technician' },
  { key: 'cleaner-tradesandservices', label: 'Cleaner' },
  { key: 'custodian-tradesandservices', label: 'Custodian' },
  { key: 'diesel-mechanic-tradesandservices', label: 'Diesel Mechanic' },
  { key: 'electrical-technician-tradesandservices', label: 'Electrical Technician' },
  { key: 'elevator-mechanic-tradesandservices', label: 'Elevator Mechanic' },
  { key: 'fitter-tradesandservices', label: 'Fitter' },
  { key: 'floral-designer-tradesandservices', label: 'Floral Designer' },
  { key: 'general-laborer-tradesandservices', label: 'General Laborer' },
  { key: 'groundskeeper-tradesandservices', label: 'Groundskeeper' },
  { key: 'gutter-installer-tradesandservices', label: 'Gutter Installer' },
  { key: 'handyman-tradesandservices', label: 'Handyman' },
  { key: 'house-cleaner-tradesandservices', label: 'House Cleaner' },
  { key: 'hvac-technician-tradesandservices', label: 'HVAC Technician' },
  { key: 'installation-technician-tradesandservices', label: 'Installation Technician' },
  { key: 'janitor-tradesandservices', label: 'Janitor' },
  { key: 'journeyman-electrician-tradesandservices', label: 'Journeyman Electrician' },
  { key: 'landscaper-tradesandservices', label: 'Landscaper' },
  { key: 'lube-technician-tradesandservices', label: 'Lube Technician' },
  { key: 'maintenance-assistant-tradesandservices', label: 'Maintenance Assistant' },
  { key: 'maintenance-manager-tradesandservices', label: 'Maintenance Manager' },
  { key: 'maintenance-technician-tradesandservices', label: 'Maintenance Technician' },
  { key: 'mall-security-guard-tradesandservices', label: 'Mall Security Guard' },
  { key: 'mason-tradesandservices', label: 'Mason' },
  { key: 'mig-welder-tradesandservices', label: 'MIG Welder' },
  { key: 'music-director-tradesandservices', label: 'Music Director' },
  { key: 'panel-beater-tradesandservices', label: 'Panel Beater' },
  { key: 'personal-stylist-tradesandservices', label: 'Personal Stylist' },
  { key: 'piano-tuner-tradesandservices', label: 'Piano Tuner' },
  { key: 'pipe-welder-tradesandservices', label: 'Pipe Welder' },
  { key: 'pool-service-technician-tradesandservices', label: 'Pool Service Technician' },
  { key: 'residential-cleaner-tradesandservices', label: 'Residential Cleaner' },
  { key: 'seamstress-tradesandservices', label: 'Seamstress' },
  { key: 'service-advisor-tradesandservices', label: 'Service Advisor' },
  { key: 'set-designer-tradesandservices', label: 'Set Designer' },
  { key: 'skilled-laborer-tradesandservices', label: 'Skilled Laborer' },
  { key: 'spa-therapist-tradesandservices', label: 'Spa Therapist' },
  { key: 'tailor-tradesandservices', label: 'Tailor' },
  { key: 'technical-director-tradesandservices', label: 'Technical Director' },
  { key: 'technician-tradesandservices', label: 'Technician' },
  { key: 'tile-setter-tradesandservices', label: 'Tile Setter' },
  { key: 'tow-truck-operator-tradesandservices', label: 'Tow Truck Operator' },
  { key: 'travel-planner-tradesandservices', label: 'Travel Planner' },
  { key: 'upholsterer-tradesandservices', label: 'Upholsterer' },
  { key: 'welder-tradesandservices', label: 'Welder' },
  { key: 'window-cleaning-technician-tradesandservices', label: 'Window Cleaning Technician' },
  { key: 'wood-finisher-tradesandservices', label: 'Wood Finisher' },
  { key: 'arborist-tradesandservices', label: 'Arborist' },
  { key: 'suto-body-technician-tradesandservices', label: 'Auto Body Technician' },
  { key: 'auto-mechanic-tradesandservices', label: 'Auto Mechanic' },
  { key: 'beautician-tradesandservices', label: 'Beautician' },
  { key: 'boilermaker-tradesandservices', label: 'Boilermaker' },
  { key: 'cable-installer-tradesandservices', label: 'Cable Installer' },
  { key: 'calligrapher-tradesandservices', label: 'Calligrapher' },
  { key: 'carpet-cleaning-technician-tradesandservices', label: 'Carpet Cleaning Technician' },
  { key: 'choreographer-tradesandservices', label: 'Choreographer' },
  { key: 'composer-tradesandservices', label: 'Composer' },
  { key: 'dancer-tradesandservices', label: 'Dancer' },
  { key: 'electrical-estimator-tradesandservices', label: 'Electrical Estimator' },
  { key: 'electrician-tradesandservices', label: 'Electrician' },
  { key: 'embroidery-machine-operator-tradesandservices', label: 'Embroidery Machine Operator' },
  { key: 'floor-tech-tradesandservices', label: 'Floor Tech' },
  { key: 'florist-tradesandservices', label: 'Florist' },
  { key: 'glazier-tradesandservices', label: 'Glazier' },
  { key: 'groundsman-tradesandservices', label: 'Groundsman' },
  { key: 'hair-stylist-tradesandservices', label: 'Hair Stylist' },
  { key: 'heavy-equipment-mechanic-tradesandservices', label: 'Heavy Equipment Mechanic' },
  { key: 'house-sitter-tradesandservices', label: 'House Sitter' },
  { key: 'industrial-maintenance-mechanic-tradesandservices', label: 'Industrial Maintenance Mechanic' },
  { key: 'installer-tradesandservices', label: 'Installer' },
  { key: 'janitorial-supervisor-tradesandservices', label: 'Janitorial Supervisor' },
  { key: 'journeyman-plumber-tradesandservices', label: 'Journeyman Plumber' },
  { key: 'lawn-care-technician-tradesandservices', label: 'Lawn Care Technician' },
  { key: 'lyricist-tradesandservices', label: 'Lyricist' },
  { key: 'maintenance-coordinator-tradesandservices', label: 'Maintenance Coordinator' },
  { key: 'maintenance-mechanic-tradesandservices', label: 'Maintenance Mechanic' },
  { key: 'maintenance-worker-tradesandservices', label: 'Maintenance Worker' },
  { key: 'marine-mechanic-tradesandservices', label: 'Marine Mechanic' },
  { key: 'master-electrician-tradesandservices', label: 'Master Electrician' },
  { key: 'mot-tester-tradesandservices', label: 'MOT Tester' },
  { key: 'musician-tradesandservices', label: 'Musician' },
  { key: 'party-planner-tradesandservices', label: 'Party Planner' },
  { key: 'pest-control-technician-tradesandservices', label: 'Pest Control Technician' },
  { key: 'pipe-fitter-tradesandservices', label: 'Pipe Fitter' },
  { key: 'plumber-tradesandservices', label: 'Plumber' },
  { key: 'production-technician-tradesandservices', label: 'Production Technician' },
  { key: 'salon-manager-tradesandservices', label: 'Salon Manager' },
  { key: 'security-guard-tradesandservices', label: 'Security Guard' },
  { key: 'service-plumber-tradesandservices', label: 'Service Plumber' },
  { key: 'sewing-machine-operator-tradesandservices', label: 'Sewing Machine Operator' },
  { key: 'songwriter-tradesandservices', label: 'Songwriter' },
  { key: 'speech-writer-tradesandservices', label: 'Speech Writer' },
  { key: 'tattoo-artist-tradesandservices', label: 'Tattoo Artist' },
  { key: 'technical-lead-tradesandservices', label: 'Technical Lead' },
  { key: 'telecommunications-manager-tradesandservices', label: 'Telecommunications Manager' },
  { key: 'tire-technician-tradesandservices', label: 'Tire Technician' },
  { key: 'transcriber-tradesandservices', label: 'Transcriber' },
  { key: 'tree-groundsman-tradesandservices', label: 'Tree Groundsman' },
  { key: 'utility-worker-tradesandservices', label: 'Utility Worker' },
  { key: 'welder-helper-tradesandservices', label: 'Welder Helper' },
  { key: 'window-tinter-tradesandservices', label: 'Window Tinter' },
  { key: 'yard-worker-tradesandservices', label: 'Yard Worker' },
  { key: 'audio-engineer-tradesandservices', label: 'Audio Engineer' },
  { key: 'auto-electrician-tradesandservices', label: 'Auto Electrician' },
  { key: 'automotive-technician-tradesandservices', label: 'Automotive Technician' },
  { key: 'beauty-advisor-tradesandservices', label: 'Beauty Advisor' },
  { key: 'bridal-stylist-tradesandservices', label: 'Bridal Stylist' },
  { key: 'cable-technician-tradesandservices', label: 'Cable Technician' },
  { key: 'car-detailer-tradesandservices', label: 'Car Detailer' },
  { key: 'carpet-installer-tradesandservices', label: 'Carpet Installer' },
  { key: 'cinematographer-tradesandservices', label: 'Cinematographer' },
  { key: 'cosmetologist-tradesandservices', label: 'Cosmetologist' },
  { key: 'deckhand-tradesandservices', label: 'Deckhand' },
  { key: 'electrical-supervisor-tradesandservices', label: 'Electrical Supervisor' },
  { key: 'electrician-apprentice-tradesandservices', label: 'Electrician Apprentice' },
  { key: 'field-service-technician-tradesandservices', label: 'Field Service Technician' },
  { key: 'flooring-installer-tradesandservices', label: 'Flooring Installer' },
  { key: 'gardener-tradesandservices', label: 'Gardener' },
  { key: 'grip-tradesandservices', label: 'Grip' },
  { key: 'gunsmith-tradesandservices', label: 'Gunsmith' },
  { key: 'hairdresser-tradesandservices', label: 'Hairdresser' },
  { key: 'homemaker-tradesandservices', label: 'Homemaker' },
  { key: 'hvac-service-technician-tradesandservices', label: 'HVAC Service Technician' },
  { key: 'industrial-painter-tradesandservices', label: 'Industrial Painter' },
  { key: 'irrigation-technician-tradesandservices', label: 'Irrigation Technician' },
  { key: 'jeweler-tradesandservices', label: 'Jeweler' },
  { key: 'landscape-foreman-tradesandservices', label: 'Landscape Foreman' },
  { key: 'line-worker-tradesandservices', label: 'Line Worker' },
  { key: 'maid-tradesandservices', label: 'Maid' },
  { key: 'maintenance-electrician-tradesandservices', label: 'Maintenance Electrician' },
  { key: 'maintenance-supervisor-tradesandservices', label: 'Maintenance Supervisor' },
  { key: 'makeup-artist-tradesandservices', label: 'Makeup Artist' },
  { key: 'marine-service-technician-tradesandservices', label: 'Marine Service Technician' },
  { key: 'mechanic-tradesandservices', label: 'Mechanic' },
  { key: 'mover-tradesandservices', label: 'Mover' },
  { key: 'nail-technician-tradesandservices', label: 'Nail Technician' },
  { key: 'personal-chef-tradesandservices', label: 'Personal Chef' },
  { key: 'pianist-tradesandservices', label: 'Pianist' },
  { key: 'pipe-layer-tradesandservices', label: 'Pipe Layer' },
  { key: 'plumber-helper-tradesandservices', label: 'Plumber Helper' },
  { key: 'refrigeration-technician-tradesandservices', label: 'Refrigeration Technician' },
  { key: 'sanitation-worker-tradesandservices', label: 'Sanitation Worker' },
  { key: 'security-officer-tradesandservices', label: 'Security Officer' },
  { key: 'service-technician-tradesandservices', label: 'Service Technician' },
  { key: 'singer-tradesandservices', label: 'Singer' },
  { key: 'sound-engineer-tradesandservices', label: 'Sound Engineer' },
  { key: 'stagehand-tradesandservices', label: 'Stagehand' },
  { key: 'team-member-tradesandservices', label: 'Team Member' },
  { key: 'technical-officer-tradesandservices', label: 'Technical Officer' },
  { key: 'telecommunications-technician-tradesandservices', label: 'Telecommunications Technician' },
  { key: 'tool-and-die-maker-tradesandservices', label: 'Tool and Die Maker' },
  { key: 'travel-counselor-tradesandservices', label: 'Travel Counselor' },
  { key: 'undertaker-tradesandservices', label: 'Undertaker' },
  {
    key: 'variable-frequency-drive-field-technician-tradesandservices',
    label: 'Variable Frequency Drive Field Technician',
  },
  { key: 'welding-inspector-tradesandservices', label: 'Welding Inspector' },
  { key: 'window-treatment-installer-tradesandservices', label: 'Window Treatment Installer' },
  { key: 'chief-information-officer', label: 'Chief Information Officer' },
  { key: 'senior-marketing-manager', label: 'Senior Marketing Manager' },
  { key: 'chief-sales-officer', label: 'Chief Sales Officer' },
  { key: 'chief-human-resources-officer', label: 'Chief Human Resources Officer' },
].sort(( role1, role2) => role1.key > role2.key ? 1 : role1.key < role2.key ? -1 : 0 );
