
export const itData = {
  mainTitle: "IT Career Mentor",
  accordionData: [
    {
      title: "About Job Specific IT Mentoring",
      description: [
        "In the Information Technology (IT) industry, innovation and evolution are constants. This industry includes a variety of job roles, from software development and cybersecurity to data analytics, AI and cloud computing. It plays an important role in shaping the future and driving global progress.",
        "The IT industry is all about agility and adaptability. Companies within the sector continue to push boundaries and strive to create more efficient systems, enhance connectivity, and revolutionise how businesses operate. The IT industry also influences communication, education, healthcare and commerce on a global scale.",
        "Moreover, the IT sector fosters a culture of continuous learning and collaboration. Professionals in this career are at the forefront of innovation, developing solutions to address emerging challenges. As technology becomes increasingly integrated into daily life, the IT industry's significance continues to grow.",
        "If you’re working in the IT sector, your growth opportunities are unparalleled. However, to navigate this complex career line, you’ll require more than just technical knowledge. Whether you’re looking to enter the IT industry or you want to climb the corporate ladder within the industry, you need strategic guidance and IT mentorship. ",
        "That’s where we come in! Our platform facilitates the connection between mentors and individuals like yourself who are hungry for insights and guidance. You can choose a mentor based on your current job role or the role you’re hoping to achieve.",
        "At Career Navig8r, our IT mentors have years of hands-on experience in this industry and can bring invaluable insights to the table. With their understanding of the challenges, trends and nuances of the IT industry, they can provide you with all the guidance you need. ",
        "This is your chance to gain expertise from those who have walked the same path that you’re on. Register with us and find your IT career mentor today! ",
      ]
    },
    {
      title: "Challenges Faced by Emerging IT Professionals",
      description: [
        "Starting a career in the IT industry comes with a range of challenges. One major obstacle is the rapid pace of technological advancements, which requires constant upskilling to stay in the game. The need for a broad skill set, covering coding languages, cybersecurity, and cloud computing, among others, also creates a stimulating yet overwhelming learning curve.",
        "Additionally, when you enter the IT workforce, you’ll need to face real-world scenarios that may not have been extensively covered in your academic settings. Navigating complex projects, collaborating in cross-functional teams, and maintaining tight deadlines are common challenges that will test the resilience and adaptability of your budding IT skills.",
        "Furthermore, the industry's competitive nature can be intense, with emerging professionals worldwide vying for job opportunities. Aside from technical expertise, getting a job in IT also requires effective communication skills and the ability to showcase your unique value in the industry.",
        "As you deal with these challenges, you could highly benefit from the guidance of a mentor in the IT industry. If you’re looking for someone who has been where you are and can teach you the ins and outs of this competitive industry, a job role-specific IT mentor is exactly what you need.",
        "Apply for our IT mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an IT Career Mentor?",
      description: [
        "If you’re working in the IT industry, regardless of your experience level, an IT career mentor’s advice and guidance would be invaluable for your growth. ",
        "If you’re a new professional, an IT mentorship can help you gain insights into the intricacies of the field and industry trends, and provide you with practical advice on skill development. Your mentors in IT will guide you through early career challenges and help you transition from your academic settings to the dynamic and demanding IT workplace. ",
        "Similarly, if you’re a mid-career professional, IT mentors can help you navigate complex scenarios, refine your skill sets, and assist you in strategising to move forward in your career. With technology evolving, an IT mentor can be your trusted advisor, helping you keep track of the industry changes and adapt proactively. ",
        "Even as a seasoned IT expert, you can benefit from an IT mentorship programme, especially when exploring new specialities, transitioning to leadership roles, or seeking guidance on broader career trajectories. You could run your ideas by your mentor in the IT industry, learn from their experiences, and improve your strategic thinking to tackle your advanced challenges. ",
        "Ultimately, if you want to thrive in the IT industry, an IT career mentor's guidance is just what you need. The relationship between you and your mentor will foster professional development and knowledge transfer, and help you build a supportive network that will contribute to a resilient and successful IT career journey. ",
      ]
    },
    {
      title: "What To Expect from an IT Mentorship?",
      description: [
        "An IT mentorship can be beneficial for both newbies in IT and experienced professionals. Firstly, as a mentee, you can expect personalised guidance tailored to your specific job role, career goals and challenges.",
        "Your IT mentor, who will often be a seasoned industry expert, will share practical knowledge, industry trends, and firsthand experiences with you.They’ll provide you with invaluable insights that go beyond what formal education may have taught you.",
        "In an IT mentorship programme, you’ll get constructive feedback for skill development and performance enhancement.Mentors can offer a fresh perspective to help you identify your strengths and areas for improvement.The mentorship dynamic will also encourage open communication, creating a safe space for you to ask questions, seek advice, and discuss your career aspirations.",
        "Furthermore, mentorship isn’t just about technical skills, it will also help improve your soft skills such as effective communication, leadership and interpersonal dynamics. Your mentors in IT will guide you through the nuances of workplace culture and help you navigate challenges within the IT industry.",
        "Lastly, a successful IT mentorship can also help you build a professional network. You’ll gain access to the mentor's industry connections, which would potentially open doors to new opportunities, collaborations and a broader support system.",
        "In essence, with a mentor in the IT industry, you won’t just gain knowledge; you’ll also be able to grow personally and professionally.",
      ]
    },
    {
      title: "Find an IT Career Mentor at Career Navig8r Today!",
      description: [
        "When choosing a career or growing within it, it’s not uncommon to feel lost and apprehensive.Moreover, if you’ve just begun your career in a new industry, the path to success can seem long- winded and overwhelming. ",
        "You may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring.This is where you could benefit from having a qualified and experienced career mentor.They will help you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now ",
      ]
    },
  ]
};

export const hrData = {
  mainTitle: "HR Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific HR Mentoring",
      description: [
        "The HR (Human Resources) industry is essential in modern organisations as they manage the workforce to drive productivity, engagement and organisational success. At its core, HR includes many and varied unique job roles and career paths including recruitment, talent management, employee relations, training and development, compensation and benefits, and HR technology.",
        "One of the key responsibilities of HR professionals is to attract and retain top talent by creating a positive work environment and offering competitive salary packages. They also handle the recruitment process, from sourcing candidates to conducting interviews and onboarding new hires.",
        "Moreover, HR professionals help create a culture of diversity, equality and inclusion within the workplace to ensure that all employees feel valued and respected.",
        "However, HR professionals often face the challenge of adapting to evolving workforce trends, such as remote work and the gig economy, while also navigating complex legal and regulatory requirements. This is where job-specific HR mentoring could help!",
        "At Career Navig8r, you can choose an HR mentor who will provide you with personalised guidance and support based on a particular aspect of your role or industry. This mentoring style focuses on the intricacies and nuances of specific job functions and can benefit you at any stage of your career in human resources.",
        "Your HR mentor will help you develop skills in a particular area, such as talent acquisition, performance management, employee relations, or HR technology implementation. The mentor in HR will use their own experience and expertise to offer insights, advice and practical strategies tailored to your unique needs and goals.",
        "This will not only accelerate your learning curve as an HR professional but also foster confidence and competence in handling job-specific responsibilities or navigating complex HR scenarios.",
        "If you’re an HR professional looking to enhance your skills, advance your career, and excel in your specialised areas of expertise, sign up with us to find your human resources mentor now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging HR Professionals",
      description: [
        "Whether you’re a budding HR professional or hoping to climb higher up the corporate ladder, you’re likely to encounter a range of challenges as you navigate your career and strive to establish yourself within the industry.",
        "Firstly, if you don’t have extensive hands-on experience, you may struggle to address complex HR issues, handle employee relations matters effectively, or make strategic decisions that impact the organisation.",
        "Additionally, the HR industry is constantly evolving, with new technologies, methods and legal requirements emerging regularly. Keeping up with these changes requires continuous learning and professional development, which can be challenging if you’re new to the field.",
        "Moreover, establishing your credibility and earning the trust of colleagues and senior leaders can be an uphill battle. You have to build relationships, demonstrate competence, and effectively communicate HR initiatives and strategies to gain respect within your organisation.",
        "Furthermore, as an emerging HR professional, you may often find yourself juggling various tasks, from recruitment and onboarding to performance management and employee engagement initiatives, all while managing time constraints and deadlines.",
        "However, you can overcome these obstacles with the help of a mentor in human resources. They will provide learning opportunities, help you build strong networks, and keep you adaptable in the face of change.",
        "To grow and thrive in your HR career, join our HR mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an HR Career Mentor?",
      description: [
        "If you’re just starting your career in human resources, you can greatly benefit from the guidance and support of a mentor. However, HR mentors aren’t only for newcomers to the field. Anyone pursuing a career in HR, regardless of their level of experience, can find value in having a human resources mentor.",
        "If you’re new to HR, you’ll need an HR mentor to navigate the complexities of the field, gain insights into industry best practices, and accelerate your learning curve. A mentor in human resources can provide invaluable advice on building foundational skills, understanding HR processes, and overcoming initial challenges.",
        "If you’re a mid-career HR professional, you can turn to HR mentors for guidance on advancing your career, transitioning into leadership roles, or specialising in specific areas such as talent management, organisational development, or HR technology. A mentor in HR can offer strategic career advice, share their own experiences, and help you find opportunities for growth and development.",
        "Even as a seasoned HR veteran, you could benefit from an HR mentorship programme, especially when you’re facing new challenges, transitioning to different industries or roles, or looking for fresh perspectives. A mentor in human resources can tell you about the challenges they have faced and the skills they have gained over the years, which would help you navigate complex HR issues with confidence.",
        "Overall, whether you’re just starting out or well-established in the field, you can benefit from the guidance, support and wisdom of a trusted mentor in HR. They have walked the same career path and have been exactly where you are. That makes them the ideal navigators to help you achieve your professional goals.",
      ]
    },
    {
      title: "What To Expect from an HR Mentorship Programme?",
      description: [
        "At Career Navig8r, our HR mentorship programme is designed to provide mentees with guidance, support and professional development opportunities within the field of human resources.",
        "When you sign up with us as a mentee, you can expect to receive personalised guidance from experienced HR professionals who are either one or multiple steps ahead of you in the HR industry. These human resources mentors will offer insights, advice and practical strategies tailored to your individual needs, career goals, and areas of interest within HR. That means you can choose a mentor based on the job role you’re hoping to achieve or excel in.",
        "An HR mentorship programme will give you the opportunity to engage in meaningful discussions, share experiences, and seek advice on navigating challenges commonly faced in HR roles. Your mentor in human resources will provide feedback on your work, help you develop new skills, and offer guidance on career advancement.",
        "Furthermore, through a mentorship program, you can connect with other professionals in the field, build relationships, and expand your professional networks. You’ll also gain exposure to different aspects of HR through shadowing experiences, project collaborations, or attending industry events.",
        "Sign up for our HR mentorship programme to receive all the support and guidance you need from experienced professionals in human resources.",
      ]
    },
    {
      title: "Find an HR Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!",
      ]
    },
  ]
};

export const financeData = {
  mainTitle: "Finance Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Finance Mentoring",
      description: [
        "The finance industry encompasses a variety of institutions, services and activities that are essential for the management of money, assets and investments within an economy. From banking and investment firms to insurance companies and stock exchanges, the finance sector plays a major role in facilitating economic growth and stability.",
        "Finance professionals are skilled in managing financial resources, analysing data, and making strategic decisions to maximise profitability and mitigate risks. They work in various sectors of the finance industry, including banking, investment management, insurance, and corporate finance.",
        "These professionals often hold specialised qualifications such as Chartered Financial Analyst (CFA), Certified Public Accountant (CPA), or Chartered Financial Planner (CFP), which demonstrate their expertise and commitment to professional standards.",
        "If you’re a finance professional who advises on investment opportunities, creates financial strategies, or ensures regulatory compliance, you’ll need strong analytical skills and the ability to interpret complex financial data to make informed decisions. Moreover, you have to keep up with market trends, economic developments, and regulatory changes to effectively navigate the dynamic finance industry.",
        "This is where finance mentoring can help you! At Career Navig8r, you can choose a finance mentor based on a specific job role. This could be a role that you already have and want to excel in or a role that you’re aiming to get.",
        "Your chosen mentor in the financial industry will offer insights, advice and practical strategies tailored to your unique needs and goals. They’ll help you develop your skills while giving you the confidence to manage your responsibilities and navigate any complex financial issues that come your way.",
        "If you’re a finance professional looking to benefit from the experience and expertise of the best financial mentors, sign up with us now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging Finance Professionals",
      description: [
        "As a budding finance professional, you’re likely to encounter various challenges as you navigate your career in the dynamic and competitive finance industry. One significant challenge is gaining practical experience and building a strong professional network.",
        "Today, even entry-level positions often require previous experience, which creates a rather tricky situation for recent graduates or career changers. Additionally, if you’re looking to break into certain specialised fields within finance, such as investment banking or hedge fund management, it can be particularly challenging due to the high competition and stringent hiring criteria.",
        "Moreover, you need to adapt to rapid technological advancements and evolving industry regulations. With various innovations and automation reshaping traditional finance practices, you have to continuously update your skills and knowledge to stay relevant.",
        "Another challenge is the pressure to perform in a high-stakes environment where financial decisions can have significant consequences. As an emerging professional, you may face intense workloads, tight deadlines, and demanding clients or supervisors, which can lead to stress and burnout.",
        "Furthermore, in the finance industry, long hours and unpredictable schedules are common. This can make it difficult to balance your professional ambitions with personal commitments while maintaining your physical and mental well-being.",
        "To navigate these obstacles, you’ll need resilience, adaptability, and a proactive approach to continuous learning and professional development. This can be achieved through finance mentoring.",
      ]
    },
    {
      title: "Who Needs a Finance Career Mentor?",
      description: [
        "Regardless of which stage you’re at in your finance career, you can benefit from having the best financial mentors to guide and support you.",
        "If you’re a recent graduate entering the finance industry, you can gain valuable insights and advice from finance mentors who have navigated similar career paths. These mentors can provide guidance on job search strategies, networking opportunities, and skill development to help you establish yourself in the field.",
        "If you’re a mid-career professional looking to advance or transition within the finance industry, you can also benefit from a finance mentorship programme. Mentors can offer guidance on career progression, leadership development, and navigating organisational dynamics. They can provide valuable perspectives and help you identify opportunities for growth and advancement.",
        "Even as an experienced finance professional, having a mentor can be helpful, especially if you’re facing new challenges or want to expand your expertise. A mentor in the financial industry can offer insights based on their extensive experience, provide strategic advice on complex financial matters, and help you navigate career transitions or leadership roles.",
        "Moreover, if you’re from an underrepresented or marginalised background in the finance industry, such as women or minorities, a finance mentor can provide support, advocacy and guidance to help you overcome barriers and achieve success.",
        "At Career Navig8r, our mentors from the financial industry have been exactly where you are and they’ve dealt with the same hurdles you’re experiencing. So, whether you’re seeking to grow, develop or excel in your finance career, the guidance, support and wisdom gained through finance mentoring can be invaluable for you.",
      ]
    },
    {
      title: "What To Expect from a Finance Mentorship Programme?",
      description: [
        "When you sign up for our finance mentorship programme, you’ll get to learn the ins and outs of your specific job role from experienced professionals within the finance industry.",
        "As a mentee, you can expect personalised guidance tailored to your career goals, interests and skill sets. Your mentor will provide individualised support, helping you navigate challenges and capitalise on opportunities specific to your career aspirations.",
        "This finance mentorship programme will also focus on skill development, allowing you to enhance your technical expertise, soft skills, and industry knowledge. Your mentor may provide training, recommend resources for self-study, and help you make better decisions in critical situations.",
        "Through mentorship, you’ll also get valuable networking opportunities, allowing you to connect with industry professionals, potential employers and peers. Your mentor may facilitate introductions, recommend networking events, or teach you how to build a professional network within the finance industry.",
        "Moreover, you can expect ongoing support as you advance in your career. Whether you’re aiming for promotions, job transitions, or skill development opportunities, your mentors will be right there to help you. They’ll offer advice on career planning, goal setting, and strategies for professional growth, and they’ll provide feedback to help you overcome challenges and achieve success in your finance career.",
        "Need help navigating the finance industry? Join our finance mentorship programme now!",
      ]
    },
    {
      title: "Find the Best Financial Mentors at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!"
      ]
    },
  ]
};

export const itManagerData = {
  mainTitle: "IT Manager Career Mentor",
  accordionData: [
    {
      title: "About IT Manager Mentoring",
      description: [
        "Have you just begun your career as an IT manager? Or have you been working in this role for a while and are looking to enhance your skills?",
        "At Career Navig8r, we offer IT manager training courses to guide IT managers like you in enhancing their leadership skills, technical knowledge, and strategic thinking.",
        "You’ll be able to choose experienced IT managers as your job-specific mentors, who’ll share their own personal insights and experience related to project management, team collaboration, and industry best practices. Your IT manager mentoring sessions will help you to improve your problem-solving, gain essential skills to advance in your career, and adapt to technological changes.",
        "Your IT manager mentor will provide personal feedback and use their own real-world experience to help you build confidence and competence. The result is that you’ll grow professionally and use your mentorship knowledge to contribute to a more skilled and efficient IT workforce.",
        "Want to excel in your chosen IT career path? Sign up for our IT management courses today.",
      ]
    },
    {
      title: "The Job Role of an IT Manager",
      description: [
        "The role of an IT manager is essential for the success of an organisation's technological infrastructure.",
        "Whether you’re aspiring to become an IT manager, you’ve recently achieved this position, or you’re fairly experienced but want to keep climbing the corporate ladder, you need to understand the full extent of what this career entails.",
        "In this role, you’ll be responsible for overseeing and coordinating your company’s IT department, ensuring that all systems, networks, and data are secure and operating efficiently. You’ll manage teams of IT professionals, delegate tasks, and provide guidance on complex technical issues. Additionally, you’ll need to plan and implement IT projects, set strategic goals, and use the appropriate technology to support your organisation’s business objectives.",
        "As an IT manager, you’ll also play an important role in budgeting, negotiating with vendors, and ensuring that the technology solutions used by your business are cost-effective. You’ll need to stay updated with the latest technological advancements and cybersecurity trends to protect your organisation from potential threats.",
        "It’s essential that you have great communication skills in this role, as you’ll need to liaise between technical teams and non-technical stakeholders. This will likely involve translating complex IT concepts into understandable terms.",
        "Ultimately, as an IT manager, your job is to ensure all technology is running properly within your organisation, allowing work to proceed without interruptions and keeping employees productive.",
      ]
    },
    {
      title: "Challenges Faced by IT Managers in the Industry",
      description: [
        "As an IT manager, you’re likely to face a myriad of challenges in your role, which are only complicated further by the rapidly evolving nature of technology and the increasing complexity of your company’s organisational needs.",
        "One of the primary challenges you’ll encounter is keeping up with the fast pace of technological advancements. You’ll need to continuously update your knowledge and skills to stay relevant and implement the latest technologies effectively.",
        "Cybersecurity threats are another big challenge. Cyberattacks and data breaches are a massive deal, and you’ll need to ensure that strong security measures are in place to protect sensitive information. This involves constant monitoring, updating your security protocols as and when necessary, and educating your company’s staff about best practices to mitigate risks.",
        "You might also face challenges due to budget constraints, as the advanced technology you need may exceed the financial limitations of your organisation. You’ll need to make strategic decisions to prioritise essential investments while making the most of your existing resources.",
        "Additionally, managing a diverse IT team is not an easy job. You’ll have to encourage your team to collaborate effectively, address skill gaps, and maintain high morale even when you’re facing tight deadlines and high-pressure situations. Effective communication is also required to bridge the gap between the technical and non-technical stakeholders within and outside of your organisation, ensuring that everyone understands the company's goals.",
        "Complying with regulations and standards is another constant challenge. You’ll need to stay on top of the ever-changing legal and industry-specific requirements, ensuring that your organisation remains compliant to avoid penalties and maintain trust.",
        "You’ll need to learn to patiently and expertly handle the challenges you’ll face as an IT manager. This is where you could benefit from the guidance of an IT manager mentor.",
        "Sign up for our IT service management courses now.",
      ]
    },
    {
      title: "Why do IT Managers Need Mentoring?",
      description: [
        "With IT manager mentoring, you’ll be able to work through the complexities of your role and become more effective in leading technology initiatives. Through our IT management course, you’ll receive valuable guidance and support from experienced professionals who can share their own insights and best practices based on their real-world experiences.",
        "This guidance is important for developing your strategic thinking and decision-making skills, which are essential for successfully managing IT projects.",
        "One of the key benefits of joining our IT manager training course is that it’ll give you the opportunity to learn how to handle the diverse challenges that come with your job role, with the support of experts who have dealt with the same issues. Whether you’re struggling with cybersecurity threats, budget constraints, or teamwork issues, they’ll share the most effective solutions based on their own experiences. ",
        "Your IT manager mentor will offer advice on how you can prioritise tasks, optimise your resources, and implement robust security measures. They’ll also help you improve your leadership and communication skills, so you can manage your team and interact with technical and non-technical stakeholders more effectively.",
        "Our IT manager mentoring helps you stay updated with the latest technological trends and industry standards. You probably don’t need us to tell you that this ongoing learning process is essential in the IT industry, where technology is constantly evolving.",
        "With our IT manager training, you’ll have a constant support system and a trusted mentor who will be happy to discuss the challenges you’re facing, explore solutions to your problems, and help you gain confidence in your abilities.",
        "If you’re looking to achieve your full potential as an IT manager and drive organisational success, now’s the time to sign up for our IT service management course.",
      ]
    },
    {
      title: "What to Expect from IT Manager Training Online?",
      description: [
        "At Career Navig8r, our online IT manager training course can give you a learning experience like no other. It’s designed to equip professionals like you with all the essential skills and knowledge to excel as an IT manager.",
        "During our IT management course, your chosen IT manager mentor will cover a wide range of topics, including project management, cybersecurity, team leadership, and strategic planning. They may also conduct assessments and quizzes to test your knowledge and track your progress.",
        "Through this IT service management course, you’ll improve your skills in using various IT tools and software and build on your understanding of industry best practices and emerging technologies. Your IT manager mentor will teach you the ins and outs of IT management and train you on how to use your knowledge in real-world situations. They’ll draw from their own experiences to help you improve your problem-solving and decision-making skills.",
        "Our IT manager mentoring programme may also give you access to networking opportunities. You’ll be able to interact with and learn from others in your industry, enhancing your career prospects.",
      ]
    },
    {
      title: "Find an IT Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const marketingManagerData = {
  mainTitle: "Marketing Manager Career Mentor",
  accordionData: [
    {
      title: "About Marketing Manager Mentoring",
      description: [
        "Have you just started your new job as a marketing manager and want to learn how to excel in the position? Or are you looking to become a marketing manager but need tips on how to improve your skills in this specific role?",
        "At Career Navig8r, our structured marketing manager mentoring programme is designed to guide aspiring or junior marketers like you towards professional growth. Through one-on-one sessions online, you’ll learn the ins and outs of the industry from some of the best marketing manager mentors.",
        "Your mentor will offer insights on strategy development, campaign management, and leadership skills. This marketing manager training course will also help you handle challenges like brand positioning, data-driven decision-making, and market research. Additionally, you’ll receive practical advice, feedback, and hands-on learning to build your confidence and expertise.",
        "By sharing real-world experiences and industry trends, your marketing manager mentor will help you enhance your creative and analytical abilities. They’ll prepare you for leadership roles while allowing you to learn more about the marketing industry.",
        "Ready to advance in this specific job role? Sign up for Career Navig8r’s marketing manager course today.",
      ]
    },
    {
      title: "The Job Role of a Marketing Manager",
      description: [
        "To be an excellent marketing manager, you need to have a good understanding of your duties and purpose in this role.",
        "As a marketing manager, it’s your job to help your company grow by developing and executing strategic marketing initiatives. Your responsibilities will include market research, identifying target audiences, and creating compelling campaigns that align with business goals.",
        "You’ll oversee brand management and ensure that your company’s messaging is consistent across all platforms, from digital to traditional media. You’ll also collaborate with cross-functional teams, including sales, product development, and creative departments, to create cohesive marketing strategies.",
        "Aside from campaign management, you’ll handle budgeting for all marketing projects, analyse the effectiveness of your marketing efforts, and adjust your strategies based on performance data. ",
        "A key part of your role involves keeping up with industry trends and competitor activities to maintain a competitive edge. You’ll also need to focus on building customer relationships and brand loyalty and encouraging customer engagement.",
        "With a balance of creative vision and analytical thinking, you’ll build brand awareness and help your company to increase its revenue and succeed in the long run.",
      ]
    },
    {
      title: "Challenges Faced by Marketing Managers in the Industry",
      description: [
        "When you’re working as a marketing manager, you’re likely to come across some unique challenges that you’ve probably never faced before.",
        "One of the biggest hurdles is staying updated with rapidly changing digital trends, such as social media algorithms and emerging platforms. You’ll need to constantly adapt to these changes and make quick decisions so your campaigns remain effective.",
        "Another challenge is handling data overload. With access to vast amounts of customer data, you’ll need to sift through analytics to extract valuable insights while avoiding analysis paralysis. It’s not easy to balance creativity with data-driven strategies.",
        "Budget constraints can also cause issues, and you’ll often be asked to do more with less, optimising your campaigns for maximum return on investment. Additionally, competition is fierce, which means you have to innovate to stand out and engage customers effectively.",
        "You’ll also face internal pressures, such as aligning your marketing strategies with other departments’ goals and managing team dynamics. All of this can further complicate your role.",
        "To overcome these challenges, you’ll need to be adaptable and creative and have strategic foresight. This is where you could benefit from the guidance of a marketing manager mentor who has faced similar issues and can steer you in the right direction.",
        "Your mentor will share their own experiences of tackling industry-specific obstacles. They can give you practical advice on managing high-pressure situations and making better campaign decisions.",
        "Through our marketing manager training course, you’ll gain a stronger understanding of modern marketing and be able to grow into a more confident, resourceful leader within your organisation.",
      ]
    },
    {
      title: "What to Expect from Marketing Manager Training Online?",
      description: [
        "Career Navig8r’s online marketing manager mentoring programme offers a convenient and personalised approach to professional development.",
        "Through our virtual sessions, you’ll receive tailored guidance from an experienced marketing manager mentor who was once exactly where you are now in your career. They’ll teach you all about campaign management, brand development, and data analysis, helping you refine your creative and analytical abilities.",
        "Our marketing manager course allows for flexible scheduling, making it easier to fit around your work commitments. You’ll also get regular feedback, constructive critiques of your work, and real-world advice on handling common challenges, such as budget limitations, cross-departmental collaboration, and adapting to digital marketing changes.",
        "Another advantage of our marketing manager training course is that you get access to a diverse pool of mentors, allowing you to learn from and network with professionals across different industries and global markets.",
        "Overall, our course will help you develop leadership capabilities, stay competitive, and build confidence to excel in your marketing career. Sign up for our marketing manager course today.",
      ]
    },
    {
      title: "Find a Marketing Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const complianceManagerData = {
  mainTitle: "Compliance Manager Career Mentor",
  accordionData: [
    {
      title: "About Compliance Manager Mentoring",
      description: [
        "Want to learn how to be a compliance manager? Or have you recently begun working in this role and need compliance manager career guidance to grow and improve your skills?",
        "Career Navig8r’s compliance manager mentoring programme is designed to help professionals like you learn about regulatory requirements and corporate ethics.",
        "Your chosen compliance manager mentor will teach you all about the best practices and evolving laws, helping you become a better leader. They’ll also share their real-world insights, so you can handle audits and internal policies more effectively.",
        "Through our compliance manager training, you’ll be able to tap into expert advice on how to solve problems and make smarter decisions to create a strong ethical culture within your organisation.",
        "If you’re looking to refine your expertise in this role, your mentor can give you valuable compliance manager career support. Their experience will help you build your confidence in your role and avoid expensive mistakes, as well as ensure your organisation meets legal and ethical standards efficiently.",
        "Need compliance manager career advice to climb the corporate ladder? Sign up for our compliance manager mentorship today."
      ]
    },
    {
      title: "The Job Role of a Compliance Manager",
      description: [
        "As a compliance manager, you’ll be responsible for ensuring your company follows all laws, regulations, and internal policies.",
        "You’ll need to develop and implement compliance programmes to prevent legal issues and maintain ethical business practices. Your job will also include monitoring industry regulations and conducting risk assessments, as well as training other employees on compliance matters.",
        "Within your role, you’ll be expected to investigate any violations and take corrective actions to address risks. You’ll work closely with legal teams, executives, and regulators to maintain transparency and avoid penalties.",
        "Your duties will also involve creating reports and updating policies as needed, as well as promoting a culture of integrity within your organisation. You’ll need to stay ahead of regulatory changes to help your company operate on the right side of the law.",
        "As you learn how to be a compliance manager, you’ll need to work on your problem-solving skills and become more detail-oriented. Your ability to handle complex regulations will play a huge role in how you protect your company’s reputation."
      ]
    },
    {
      title: "Challenges Faced by Compliance Managers in the Industry",
      description: [
        "When you’re working as a compliance manager, you’ll often face challenges as you strive to ensure your business follows laws and regulations.",
        "One of the biggest challenges is keeping up with constantly changing regulations. As laws vary by industry and location, you’ll likely find it difficult to stay updated and ensure your company’s compliance.",
        "Another challenge is managing risks. It’s not uncommon for a company to face legal and financial risks, and you’ll be expected to identify potential issues before they become serious problems. You’ll also be tasked with training employees and promoting a culture of compliance, which can be difficult if any of your new policies are met with resistance.",
        "Investigating violations is another tough task in your role. If your company breaks the rules, it’ll be your responsibility to handle internal investigations and take corrective actions to prevent future issues.",
        "Plus, given that some regulations may slow down operations, you'll need to find ways to meet legal requirements without affecting your company’s productivity.",
        "If you’re struggling to tackle these challenges alone, you could significantly benefit from compliance manager career advice from an expert with years of experience in this specific role. That’s where our compliance manager mentorship comes in.",
        "Your chosen compliance manager mentor has likely dealt with these challenges before, so they can easily guide you through the ups and downs of the role. They can be a sounding board for your ideas and concerns, helping you make better decisions in your job.",
        "Keen to reap the benefits of compliance manager career guidance from an expert? Sign up for our compliance manager training programme today."
      ]
    },
    {
      title: "What to Expect from Compliance Manager Training Online?",
      description: [
        "Career Navig8r’s online compliance manager mentoring programme is designed to guide you through every aspect of the role. Through virtual meetings, your compliance manager mentor will offer their own personalised advice, helping you stay updated with legal changes and industry standards.",
        "You’ll get a chance to learn about compliance strategies and policy development from someone who knows what they’re talking about, as well as get tips on how to handle audits and regulatory challenges.",
        "Your mentor will share their real-world experiences, helping you to better understand what’s involved in risk assessment and problem-solving. They’ll also help you improve your leadership and decision-making skills, teaching you how to communicate better. Plus, they may introduce you to their professional network, which can help you tap into more job opportunities in the future.",
        "Our compliance manager mentorship is conducted entirely online, which means you can access expert guidance from anywhere and at any time. Whether you're new to the role or seeking growth, you’ll get all the compliance manager career support you need to boost your confidence and expertise.",
        "If you’re ready to learn how to be a compliance manager from experienced professionals in the industry, sign up for our compliance manager mentoring programme today."
      ]
    },
    {
      title: "Find a Compliance Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const dataScientistData = {
  mainTitle: "Data Scientist Career Mentor",
  accordionData: [
    {
      title: "About Data Scientist Mentoring",
      description: [
        "Are you wondering how to become a data scientist and excel at the job role? Or are you already a data scientist looking to expand your skill set and advance your career?",
        "At Career Navig8r, we’ve created a comprehensive data scientist training course that can give you personalised guidance. If you’re an aspiring or current data professional, this data scientist training mentoring programme will help you get to grips with the complexities of data science.",
        "You can select your own data scientist training mentor, benefiting from their industry expertise and insights on essential skills like machine learning, data analysis, and coding best practices. Through one-on-one online sessions, your mentor will offer career advice, project feedback, and technical assistance, ensuring that you stay on track with your learning goals.",
        "This data scientist training programme will create a supportive environment, address real-world challenges, and help you build confidence in your abilities. Whether you're new to data science or looking to advance in your existing role, mentorship can accelerate your journey towards success.",
        "Sign up for our data scientist training course today.",
      ]
    },
    {
      title: "The Job Role of a Data Scientist",
      description: [
        "As a data scientist, your job is to extract valuable insights from complex datasets to inform business decisions. You’ll need to collect, clean, and organise large volumes of data, using tools like Python, R, and SQL for analysis.",
        "By applying statistical techniques, machine learning algorithms, and data visualisation tools, you’ll need to uncover patterns, trends, and correlations that can help solve business challenges or optimise operations.",
        "You’ll often collaborate with cross-functional teams, including data engineers, business analysts, and stakeholders, to get a thorough understanding of the problem at hand and offer actionable insights. Your role may also involve creating predictive models, forecasting outcomes, and developing data-driven strategies to enhance product development, marketing, customer experience, or risk management.",
        "To excel at this job role, you’ll need strong problem-solving abilities, critical thinking, and a good understanding of data architecture. As businesses become more data-driven, your ability to translate raw data into meaningful conclusions that lead to impactful decisions will be an indispensable asset to any organisation.",
      ]
    },
    {
      title: "Challenges Faced by Data Scientists in the Industry",
      description: [
        "As a data scientist, you’ll likely face many challenges in the industry, especially as you climb the corporate ladder.",
        "A key issue is ensuring data quality and availability. Sometimes, the data you work with may be incomplete, inconsistent, or unstructured, which means you’ll need to clean it up before analysis can even begin. This process can be time-consuming and may affect the accuracy of your model.",
        "Another challenge is managing large volumes of data. As your business generates more data, handling and processing it efficiently can become difficult, especially when you’re dealing with big data infrastructure and determining feasible scalability solutions.",
        "Aligning your data-driven insights with your company’s goals can also be tricky. Aside from having technical expertise, you should also be able to understand the business context to ensure your findings are actionable and relevant. Miscommunication between technical teams and non-technical stakeholders can further complicate this.",
        "Keeping up with rapidly evolving technology is consistently challenging in your role. With constant advancements in machine learning algorithms, tools, and programming languages, you need to keep learning to stay competitive and deliver the best results.",
        "Dealing with these challenges can be extremely stressful, especially if you’re doing it alone. This is where data scientist training mentoring will benefit you.",
        "Through our data scientist training course, you’ll have the opportunity to enhance your skills with guidance from an experienced professional who has achieved great success in the same job role and has more than likely faced similar issues. Based on their own experiences, they can help you select the right tools and techniques for solving real-world problems.",
        "Your data scientist training mentor can offer personalised advice, helping you understand industry standards and avoid common pitfalls, ensuring you stay focused on relevant trends. Additionally, they can build your confidence by offering feedback on projects, assisting in code optimisation, and sharing best practices for handling complex data.",
        "Join our data scientist training course today.",
      ]
    },
    {
      title: "What to Expect from Data Scientist Training Online?",
      description: [
        "At Career Navig8r, our online data scientist training mentoring programme will help you learn and grow professionally in a flexible and personalised setting.",
        "You can attend one-on-one sessions with experts in the industry who’ll give you tailored guidance on various aspects of data science, including machine learning, data visualisation, statistical modelling, and coding in languages like Python and R. ",
        "Your data scientist training mentor will help you build a strong foundation by working with you on real-world projects, offering feedback, and sharing industry best practices. Whether you're new to the field or looking to advance your skills, our data scientist training course will teach you how to tackle challenges like data cleaning, handling large datasets, and applying algorithms effectively.",
        "Your mentor will also offer career advice, from resume building and interview preparation to networking within the industry. This can be invaluable for your future in this field.",
        "The online nature of our data scientist training mentoring allows for flexibility in your scheduling, making it easier to fit into your busy routine. You can expect a supportive, collaborative environment that helps accelerate your learning and boosts your confidence in handling an array of complex data problems.",
      ]
    },
    {
      title: "Find a Data Scientist Training Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const facilitiesManagerData = {
  mainTitle: "Facilities Manager Career Mentor",
  accordionData: [
    {
      title: "About Facilities Manager Mentoring",
      description: [
        "Are you aiming to become a facilities manager and want to learn more about the role? Or have you recently started working in this job role and need expertise on how to excel in your career?",
        "At Career Navig8r, we’ve designed a facilities manager mentoring programme to support and develop emerging professionals like you in this industry. You’ll be paired with experienced mentors who will help you improve your job skills, tackle common challenges in your role, and advance your career in facility operations, maintenance, and project management.",
        "Your chosen facilities manager mentor will guide you on best practices, leadership, and strategic planning, drawing from their personal experience. Our facilities manager training course will help you share and gain knowledge and enhance your problem-solving abilities, as well as build your confidence—all of which can benefit you and your organisation.",
        "Overall, facilities manager mentoring is ideal for anyone looking to grow in this field. If you’re ready to take your career to the next level, sign up for our facilities manager course today.",
      ]
    },
    {
      title: "The Job Role of a Facilities Manager",
      description: [
        "As a facilities manager, your main job is to ensure the smooth operation and maintenance of your organisation's physical environment. You’ll be responsible for overseeing building maintenance, safety, and services, including HVAC systems, plumbing, electrical setups, security, and cleaning.",
        "You need to ensure that the facility is safe, efficient, and compliant with relevant regulations and standards. Your duties will also include managing budgets, coordinating with contractors and service providers, and implementing cost-saving initiatives.",
        "Space management, procurement of supplies, and workplace design may also fall under your job description. Another duty you may be tasked with is emergency preparedness, ensuring that systems are in place for quick response to unforeseen situations like power outages or safety breaches.",
        "Strong communication and problem-solving skills are essential in this role, as you’ll often need to work with various departments to ensure efficiency in all operations. Your role is integral to creating a comfortable, functional work environment that supports productivity and business continuity.",
      ]
    },
    {
      title: "Challenges Faced by Facilities Managers in the Industry",
      description: [
        "In your job role as a facilities manager, you’re bound to face numerous challenges as the industry continues to evolve.",
        "One key challenge is maintaining an ageing infrastructure. This is where you’ll need to balance repairs with ongoing operations, which can be complex and costly. Budget constraints can add further pressure to this issue, as you’ll have to ensure that you don't cut corners on necessary costs.",
        "Regulatory compliance is another hurdle, requiring you to stay up-to-date with health, safety, and environmental regulations, which vary by location and industry. Additionally, as energy efficiency is increasingly prioritised these days, you’ll need to come up with innovative strategies to reduce consumption while maintaining comfort and functionality.",
        "Managing vendor relationships and service contracts can also be challenging, particularly when you’re negotiating to receive cost-effective yet high-quality solutions. You may also have to deal with unexpected emergencies, like equipment failures or natural disasters, which require quick, effective responses, putting pressure on your problem-solving abilities.",
        "As workplaces evolve, you’ll need to adapt to changing technologies, such as smart building systems. You’ll have to integrate them into daily operations and ensure that the new systems enhance rather than disrupt business activities.",
        "This role demands a diverse skill set, ranging from technical expertise to leadership. That’s where our facilities manager training course comes in.",
        "Our facilities manager mentoring programme will give you a chance to learn from a professional who has years of experience in the same job role and has tackled similar challenges. Your mentor can provide insights into industry best practices, innovative solutions for complex problems, and effective ways to manage your teams and projects.",
        "This guidance will help you identify areas for improvement, set realistic goals, and build confidence in your abilities. With a mentor, you’ll be better equipped to overcome these challenges while growing personally and professionally.",
        "Sign up for our facilities manager course today.",
      ]
    },
    {
      title: "What to Expect from Facilities Manager Training Online?",
      description: [
        "At Career Navig8r, our facilities manager mentoring programme online offers a flexible, convenient way for you to gain valuable guidance and industry insights.",
        "Once you sign up, you can expect personalised one-on-one sessions with experienced mentors, tailored to your unique career goals and challenges. In our virtual meetings, you’ll receive practical advice on problem-solving, leadership development, and operational strategies in facilities management.",
        "Our facilities manager training course will give you a safe and supportive space to gain knowledge, ask questions, and discuss your doubts and concerns regarding your industry and job role. The online format also allows you to pick the best time for these sessions, ensuring that your daily schedule isn’t disrupted.",
        "Additionally, you’ll have access to a wider pool of facilities manager mentors from different regions and sectors. This broader perspective can help to diversify your insights and learn from other professionals on how they solve problems creatively. You’ll also be able to tap into beneficial networking opportunities.",
        "Ultimately, our online facilities manager course will give you the knowledge, skills, and confidence to excel in your role, no matter the size or complexity of the facilities you manage.",
      ]
    },
    {
      title: "Find a Facilities Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const customerServiceRepresentativeData = {
  mainTitle: "Customer Service Representative Career Mentor",
  accordionData: [
    {
      title: "About Customer Service Mentoring",
      description: [
        "Are you a newly promoted customer service representative, or do you want to learn how to be a customer service representative? Or, have you been working in this role for a while and want to improve your skills?",
        "Career Navig8r’s customer service mentoring programme is designed to help new or less experienced customer reps like you excel in their job roles. Through one-on-one guidance from our seasoned customer service mentors, you’ll develop essential skills for your role, such as active listening, effective communication, problem-solving, and empathy.",
        "Our customer service training course offers a supportive environment for learning the nuances of customer interactions, handling difficult situations, and improving your performance. It’ll also help you grow personally and professionally and enhance your job satisfaction, keeping you motivated by offering continuous feedback and encouragement.",
        "With proper customer service training, you’ll become more confident, capable, and customer-focused. You’ll learn to provide exceptional service for your clients and ensure long-term success in your company.",
        "Want the best tips and tricks on how to work in customer service? Sign up for our customer service training course today.",
      ]
    },
    {
      title: "The Job Role of a Customer Service Representative",
      description: [
        "Wondering how to get a customer service role? First, you need to understand what skills and responsibilities this job demands.",
        "As a customer service representative, you’re responsible for maintaining positive relationships between your company and its customers. You’ll be tasked with handling customer inquiries, complaints, and issues, and resolving them in a timely and satisfactory manner.",
        "This job often involves answering phone calls, responding to emails or live chats, and managing social media queries. You should have strong communication and problem-solving skills, as you’ll need to understand customers' needs and provide appropriate solutions.",
        "You’ll often work with multiple departments to resolve issues related to orders, billing, technical support, or product information. You’ll also be responsible for maintaining accurate records of customer interactions and ensuring that customer satisfaction remains high.",
        "To do well in this role, empathy and patience are essential, especially when you’re dealing with complicated situations or angry customers. You’ll be acting as the voice of the company, ensuring smooth communication and building trust, which will encourage customer loyalty and lead to long-term business success.",
      ]
    },
    {
      title: "Challenges Faced by Customer Service Representatives in the Industry",
      description: [
        "When you’re working as a customer service representative, you’re likely to face some common challenges as you try to balance customer satisfaction with your company’s policies.",
        "One of the main challenges is dealing with difficult or frustrated customers. To handle harsh complaints, negative feedback, and emotional responses, you need to be patient and empathetic and have strong problem-solving skills.",
        "You’ll also face customers who have high expectations for quick and effective solutions. You’ll often find yourself under pressure to resolve issues in real time across multiple communication channels, such as over the phone, by email, or through chat.",
        "Inadequate resources or insufficient training can also make it harder to address complex queries. This can lead to frustration for you and the customer.",
        "Additionally, burnout and stress are common, especially in fast-paced environments with high call volumes. You’ll need to find a work-life balance and learn to handle repetitive tasks, constant interaction, and sometimes unrealistic performance targets, which can lead to mental fatigue.",
        "To tackle these challenges, you’ll need strong interpersonal skills and resilience. You need to be able to adapt to the evolving demands of customer service, which can be easier with the expert guidance of a customer service mentor.",
        "Want to learn how to be a customer service representative? Find your mentor with our customer service training course.",
      ]
    },
    {
      title: "Why do Customer Service Representatives Need Mentoring?",
      description: [
        "Want to learn how to get a customer service role and excel in it? Our customer service mentoring programme can help enhance your skills, confidence, and job performance in this demanding role.",
        "In our customer service training, you’ll get personalised guidance from experienced professionals, who can teach you how to deal with complex customer interactions and improve your problem-solving abilities. ",
        "You’ll gain a deeper understanding of company policies, customer expectations, and communication techniques, which are essential for delivering effective and empathetic support.",
        "Your chosen customer service mentor can also help you manage stress and avoid burnout by offering emotional support and practical advice on how to handle difficult customers or high-pressure situations. When you receive regular feedback and coaching, you can continuously improve your performance, which should boost your job satisfaction and keep you motivated.",
        "Our customer service training course can help you move towards promotions and senior job roles in your career by nurturing your leadership skills and fostering professional growth. It’ll give you a supportive environment where you feel empowered to ask questions, share your concerns, and refine your approach to customer service.",
        "If you want to learn how to work in customer service and become more competent and confident in your job, customer service training can benefit you and your organisation.",
      ]
    },
    {
      title: "What to Expect from Customer Service Training Online?",
      description: [

        "At Career Navig8r, our customer service mentoring online gives you a flexible, accessible way to enhance your skills and gain confidence. If you’re wondering how to be a customer service representative or how to get a customer service role, this course is for you.",
        "In our virtual sessions, you can expect personalised guidance from an experienced customer service mentor who’ll provide valuable insights into effective communication, conflict resolution, and problem-solving strategies. These sessions will often include exercises, assessments, feedback on customer interactions, and tips on handling difficult situations.",
        "Our online customer service training allows you to learn at your own pace, anytime and anywhere. Your mentor will connect with you via video calls, chat platforms, and emails to provide ongoing support and address any questions or concerns in real-time.",
        "You can expect to gain practical knowledge on using digital customer service platforms and managing various communication channels, such as email, live chat, and social media. You’ll also get to network with other professionals, which can help you take your career to the next level.",
        "The convenience of our flexible online customer service training means you can balance learning with your daily responsibilities, making it an effective solution for continuously improving your customer service skills.",
      ]
    },
    {
      title: "Find a Customer Service Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const accountingClerkData = {
  mainTitle: "Accounting Clerk Career Mentor",
  accordionData: [
    {
      title: "About Accounting Clerk Mentoring",
      description: [
        "Have you just entered the accounts and finance industry as an accounting clerk? Are you looking to enhance your skills in this job role and climb the corporate ladder?",
        "Career Navig8r offers an accounting clerk mentor programme that guides entry-level employees like you to develop their skills and advance in their careers. Your chosen accounting clerk career mentor, usually an experienced accountant or senior clerk, will provide insights into the best practices, industry standards, and professional ethics in this career.",
        "They’ll help you understand complex financial procedures and improve your accuracy in bookkeeping, and teach you how to become proficient in various accounting software. Mentoring with Career Navig8r also gives you access to regular feedback and performance reviews.",
        "Your accounting clerk mentor will offer career advice and help you set and achieve your professional goals. This relationship will give you the support you need to confidently and competently handle accounting tasks.",
        "If you’re looking to rise through the ranks in your accounting job role, sign up with us to find the best accounting clerk career coach today.",
      ]
    },
    {
      title: "The Job Role of an Accounting Clerk",
      description: [
        "To excel as an accounting clerk, you need to have a good understanding of your tasks and duties in this job role.",
        "As an accounting clerk, you’ll play an important role in the financial operations of your organisation. Your primary responsibilities will include maintaining financial records, processing transactions, and ensuring accuracy in financial documentation. You’ll handle tasks such as data entry, updating ledgers, and reconciling bank statements.",
        "You’ll also be responsible for managing accounts payable and receivable, preparing invoices, and processing payments. Additionally, you’ll assist in payroll processing by calculating and verifying employee time and attendance records.",
        "Your duty as an accounting clerk is to support the accounting team by providing necessary documentation for audits and preparing financial reports. You’ll also need to ensure compliance with company policies and financial regulations.",
        "To be successful in this role, you’ll need to have exceptional attention to detail and strong organisational skills to maintain accuracy and prevent errors. You’ll also need to be proficient in accounting software and have at least basic mathematical skills.",
        "Overall, your job is to ensure the smooth and efficient operation of the accounting department, contributing to the financial health and stability of your organisation.",
      ]
    },
    {
      title: "Challenges Faced by Accounting Clerks in the Industry",
      description: [
        "As an accounting clerk, you’re bound to encounter a variety of challenges in your role, which could impact your efficiency and job satisfaction.",
        "One major challenge is keeping up with the high volume of transactions you’ll need to process daily. Keeping on top of your heavy workload means you’re more likely to make errors, particularly when coupled with tight deadlines. This is where maintaining accuracy is key, as even small mistakes can have substantial financial repercussions.",
        "Another challenge is staying updated with the constantly evolving software and technologies available to accountants today. As your company adopts new systems to enhance efficiency, you’ll need to continuously learn and adapt, which you may find time-consuming and stressful.",
        "Additionally, you’ll have to get to grips with complex regulatory requirements and compliance standards, which vary from one industry to the next. Keeping up with these regulations is essential to avoid legal issues and ensure that all your financial reports are accurate.",
        "You’ll also often face pressure to perform under strict deadlines, especially during financial closing periods or audits. This pressure can lead to burnout and affect your job satisfaction.",
        "Communication is another hurdle, as you’ll need to liaise with various departments and external stakeholders. If you make any communication errors, you might end up with delayed payments or inaccuracies in financial records.",
        "Another common challenge in an accounting clerk’s job role is managing the monotony that may arise from the repetitive nature of your accounting tasks, leading you to feel disengaged with your job role. It can be difficult to find motivation and maintain focus in your working environment.",
        "Despite these obstacles, as an accounting clerk, your role is key to ensuring the financial integrity and stability of your organisation. Being willing to persevere and adapt in your career is essential for your success.",
      ]
    },
    {
      title: "Why do Accounting Clerks Need Mentoring?",
      description: [

        "Career Navig8r’s accounting clerk mentor programme can significantly enhance your professional growth and job performance.",
        "If you’re a newcomer to the industry, you’re likely to face a steep learning curve. Our mentorship course will provide you with the guidance and support you need to build on your understanding of complex financial systems and procedures.",
        "You’ll be able to choose an accounting clerk career mentor who has been exactly where you are now. As they’ve dealt with the same issues, they can offer their own practical insights and share best practices that may not have been covered in your formal training.",
        "Our accounting clerk mentoring will help you develop a deeper understanding of your role, and you’ll pick up new skills for mastering accounting software and interpreting financial data accurately. You’ll receive regular feedback from your mentor, helping you to identify and correct mistakes and improving your accuracy and efficiency.",
        "Your accounting clerk career coach will also ensure you’re up-to-date with the latest regulatory requirements and industry standards, reducing your risk of errors. They’ll also help you develop your soft skills, such as effective communication and time management, which are important for interacting with other departments and meeting deadlines.",
        "Your job-specific mentor will provide emotional support, helping you manage the pressures of high-volume workloads and tight deadlines. This will build your confidence and prevent you from feeling isolated in your role, helping you to feel more satisfied with your career. ",
        "Overall, our accounting clerk career mentorship course will equip you with the skills, knowledge, and resilience needed to thrive in your role and advance in your career.",
      ]
    },
    {
      title: "What to Expect from an Accounting Clerk Mentor Programme Online?",
      description: [
        "At Career Navig8r, our online accounting clerk mentor programme offers a flexible and comprehensive learning experience tailored to the needs of accounting professionals.",
        "When you sign up as a mentee, you can expect personalised guidance from mentors who are experienced in your particular job role. Your chosen accounting clerk career coach will cover essential topics such as bookkeeping, financial reporting, and the use of accounting software.",
        "They’ll provide practical tips and share their best practices, teaching you how to handle complex accounting tasks and improve your accuracy. Additionally, they’ll offer regular feedback and conduct performance reviews to track your progress and address areas that you might need to improve in.",
        "One of the biggest benefits of our accounting clerk mentor programme online is that it allows you to learn at your own pace, making it easier to balance work and your other commitments. This course gives you the opportunity to learn from mentors who truly understand the problems you’re facing because they’ve been there and done that too.",
        "This programme will provide you with a solid support system and may also lead to great networking opportunities that could take your career to new heights.",
        "Overall, when you sign up for our accounting clerk mentorship course, you can expect to build valuable skills, improve your confidence, and gain access to a supportive network, contributing to your professional development and career advancement.",
      ]
    },
    {
      title: "Find an Accounting Clerk Mentor at Career Navig8r Today",
      description: [
        "Whether you’re choosing a new career or keen to excel in your existing job role, you may not know the best route to success. And if you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about the industry you’re breaking into, such as what to expect, which skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const staffAccountantData = {
  mainTitle: "Staff Accountant Career Mentor",
  accordionData: [
    {
      title: "About Staff Accountant Mentoring",
      description: [
        "Have you just started your career as a staff accountant and want to enhance your job skills? Or have you been in the job role for a while and are looking to rise through the ranks?",
        "Career Navig8r offers a staff accountant mentoring programme designed to guide and develop junior accounting professionals like you. Throughout this course, you’ll gain valuable insights, technical knowledge, and practical skills from experienced staff accountants who will act as your career mentors.",
        "Our staff accountant course focuses on enhancing your competency in financial reporting, tax preparation, auditing, and compliance. You’ll also develop your soft skills, such as communication, problem-solving, and time management. In this supportive learning environment, you’ll gain confidence and proficiency in your role, helping you to improve your job performance and putting you in a better position to advance in your career.",
        "Our effective staff accountant career mentoring can bridge the gap between your existing academic knowledge and real-world application, equipping you to become part of a well-rounded and competent accounting workforce.",
        "To find your ideal staff accountant career coach and grow in your career, sign up with Career Navig8r now.",
      ]
    },
    {
      title: "The Job Role of a Staff Accountant",
      description: [
        "To excel in your role as a staff accountant, you need to have a clear understanding of what your job role entails.",
        "In this role, you’ll be required to handle a wide range of financial tasks essential to the smooth operation of your organisation. Primarily, you’ll be responsible for maintaining accurate financial records, including preparing and analysing financial statements, managing ledgers, and ensuring compliance with accounting principles and regulations. You’ll handle routine accounting functions, such as accounts payable and receivable, payroll processing, and bank reconciliations.",
        "Additionally, you’ll typically be required to assist in the preparation of tax returns, budget reports, and audit documentation. You’ll play an important role in month-end and year-end closing processes, ensuring that all financial transactions are accurately recorded and reported. You’ll also analyse financial data to identify trends, discrepancies, and opportunities for cost savings and efficiency improvements.",
        "Beyond these technical tasks, you’ll often need to collaborate with other departments to provide financial insights and support decision-making processes. You’ll need meticulous attention to detail, strong analytical skills, and an ability to work under pressure if you want to competently maintain the financial health and integrity of your organisation.",
      ]
    },
    {
      title: "Challenges Faced by Staff Accountants in the Industry",
      description: [
        "As a staff accountant, you’ll encounter numerous challenges in your role, often stemming from the complex nature of the financial industry.",
        "One significant challenge is keeping up with the UK’s ever-changing accounting standards and regulations. You’ll need to constantly update your knowledge and adapt to new rules, which can be demanding and time-consuming.",
        "You may also struggle with your workload and time management. You’ll have to get used to juggling multiple responsibilities, from daily financial transactions to preparing comprehensive reports and assisting with audits. During peak periods, such as the fiscal year-end or tax season, your workload will intensify, so you’ll need to work longer hours with increased pressure to meet deadlines.",
        "The technological advancements will also come with opportunities and challenges. While new accounting software and tools can enhance your efficiency, they require you to continuously learn and adapt to their interfaces. You’ll need to stay proficient with evolving technologies and ensure data accuracy and security, especially with the rise of cyber threats.",
        "Another challenge is the need for meticulous attention to detail. Are any errors in your financial records will result in significant consequences, including financial loss and even reputational damage. That’s why this job demands a high level of precision and diligence.",
        "Additionally, you may find it difficult to communicate  effectively. You’ll have to translate complex financial data into clear, actionable insights for non-financial stakeholders, which requires strong interpersonal skills and the ability to simplify technical jargon. Balancing your technical expertise with soft skills is essential for success in this role.",
        "To effectively tackle these challenges, you’ll greatly benefit from the guidance of someone who understands the ins and out of your job role. A staff accountant career mentor can help you easily work through these complexities. Sign up to get started today.",
      ]
    },
    {
      title: "Why do Staff Accountants Need Mentoring?",
      description: [
        "Staff accountant mentoring can help you learn how to apply your academic knowledge in real situations.",
        "If you’re a fresh graduate, you’ll enter the workforce with theoretical understanding but a lack of real-world experience. Your staff accountant career coach will provide you with the practical insights and hands-on skills necessary to excel in your role.",
        "Another key reason for staff accountant coaching is to help you get to grips with  accounting regulations and standards. A staff accountant career mentor with experience can help you stay compliant and up-to-date with everything you need to know to do your job safely and properly. This guidance will help you build on your understanding of financial reporting, tax laws, and auditing requirements.",
        "Staff accountant career coaching can also help you develop your essential soft skills. Effective communication, problem-solving, and time management are important for success but are often not emphasised in formal education. Through mentorship, you can learn to convey financial information clearly to non-financial stakeholders, manage multiple tasks efficiently, and develop your critical thinking skills.",
        "From a mental health perspective, staff accounting mentoring will provide you with a much-needed support system. The transition from an academic environment to a professional setting can be daunting. Having a mentor can give you the emotional and professional support you need, boosting your confidence and reducing your anxiety. Your mentor will also give you feedback, encouraging you to keep learning, improving, and striving to be the best.",
        "Overall, our staff accountant career coaching programme will equip you with the necessary tools, knowledge, and confidence to advance in your career and contribute effectively to your organisation.",
      ]
    },
    {
      title: "What to Expect from a Staff Accountant Mentor Programme Online?",
      description: [
        "At Career Navig8r, our online staff accountant course offers a comprehensive and flexible approach to professional development.",
        "Once you sign up, you can expect personalised guidance from a staff accountant mentor who’s experienced in this specific job role. They’ll provide insights into accounting best practices, industry standards, and career advancement strategies.",
        "The programme will typically include virtual one-on-one sessions with your chosen staff accountant career coach, covering key topics such as financial reporting, tax preparation, and auditing techniques.",
        "As a mentee, you’ll benefit from assessments and real-time feedback on your work, helping you improve your accuracy and efficiency. You may also have access to networking opportunities with industry professionals, providing you with a supportive community for sharing experiences and advice.",
        "The convenience of our online platform allows for flexible scheduling, so you can balance your professional and personal commitments effectively. You can choose exactly when you’d like to have your mentoring sessions with your staff accountant coach.",
      ]
    },
    {
      title: "Find a Staff Accountant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re choosing a new career or keen to excel in your existing job role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about the industry you’re breaking into, such as what to expect, which skills you'll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const itDirectorData = {
  mainTitle: "IT Director Career Mentor",
  accordionData: [
    {
      title: "About IT Director Mentoring",
      description: [
        "Have you recently taken on the role of an IT director and want to learn more about your responsibilities? Or have you been in this position for a while and want to become better at tackling challenges or climb the corporate ladder?",
        "Career Navig8r’s IT director mentoring programme is designed to help guide aspiring IT leaders like you through the complex challenges of technology management. This mentorship opportunity enables you to develop your strategic thinking, leadership skills, and technical expertise.",
        "You’ll be able to choose an experienced IT director mentor of your choice, and benefit from their insights and experience to help you manage teams and align your IT strategies with business goals. As a mentee, you’ll gain valuable perspectives on industry trends, risk management, and exciting developments in your field.",
        "Our IT director training programme provides a collaborative environment where you can grow through real-world experiences and personalised guidance. Whether you're an emerging leader or an experienced professional, our IT Director course will encourage you to advance your career with confidence.",
        "Ready to find your ideal IT director mentor? Sign up with Career Navig8r today.",
      ]
    },
    {
      title: "The Job Role of an IT Director",
      description: [
        "As an IT director, you play an important role in shaping and executing your organisation's technology strategy. You’re responsible for overseeing the entire IT department and ensuring that all systems, networks, and applications run smoothly and align with business objectives.",
        "Your duties include managing IT budgets, setting technology goals, and leading a team of IT professionals, including developers, network administrators, and support staff. You need to stay updated on the latest technology trends, keep your business safe from cybersecurity threats, and implement industry best practices to make informed decisions.",
        "Your job will involve collaborating closely with other departments to understand their needs and deliver solutions that are efficient and innovative. Strategic planning is another key part of your role, and it requires having a strong understanding of your business and its future needs.",
        "As an IT director, you’ll also play a role in vendor management, and you may be required to handle contract negotiations and ensure compliance with relevant regulations. Overall, your leadership is essential to your company's digital transformation and success.",
      ]
    },
    {
      title: "Challenges Faced by IT Directors in the Industry",
      description: [
        "As an IT director, there’s a good chance that you’ve worked your way up to your role and have years of experience in this industry. But there’s still a good chance that in this specific job role, you may still encounter some challenges that you haven’t faced before.",
        "One of the biggest challenges is keeping up with the pace of technological change. There are new tools, platforms, and innovations constantly emerging, which means you need to continuously update your knowledge and ensure that your organisation's technology infrastructure remains competitive and secure.",
        "Cybersecurity is another major concern. With cyberattacks becoming increasingly sophisticated, you’re probably under constant pressure to protect your company’s sensitive data and maintain the very best security measures. This involves staying ahead of potential threats, implementing strict security protocols, and ensuring you’re compliant with industry regulations.",
        "Budget constraints can also be challenging. As an IT director, you’ll often need to balance the demands for cutting-edge technology with limited financial resources. You’ll need to prioritise certain investments over others, justify expenses to your peers and stakeholders, and find cost-effective solutions that meet your organisational needs without compromising on quality.",
        "Additionally, managing and retaining skilled IT talent is a constant challenge. The demand for experienced professionals is often higher than the supply, which means you’ll need to compete for the most talented staff. A big part of this is creating a positive work environment that attracts employees, and providing ongoing training and career growth opportunities to retain skilled staff.",
        "It’s important that your IT strategies are aligned with the overall business goals, so you should be able to effectively communicate the value of technology investments to non-technical stakeholders, which has the potential to be frustrating.",
        "This is where having an IT director mentor will come in handy. Your mentor was once exactly where you are now, and they’ve dealt with similar challenges. Their expert guidance can help you easily tackle these obstacles.",
        "If you’re ready to get started, sign up for our IT director training course today.",
      ]
    },
    {
      title: "Why do IT Directors Need Mentoring?",
      description: [
        "Regardless of how experienced you are in this job role, you could still benefit from our IT director mentoring programme. ",
        "Your role demands a blend of technical expertise, strategic thinking, and people management skills, and our IT director course will provide you with invaluable guidance for mastering your diverse responsibilities. Your chosen IT director mentor with experience in the field can offer insights into the best practices and help you avoid common pitfalls in your role. They’ll be able to share real-world experiences that textbooks or formal education can’t provide.",
        "One of the key benefits of our IT director training course is that you’ll have the opportunity to refine your leadership skills. Managing diverse teams and making critical decisions under pressure are areas where experienced IT director mentors can provide tailored advice. This essential guidance will help you balance technical demands with business objectives, often under tight deadlines and with limited resources.",
        "IT director mentoring will also help you stay ahead of industry trends and innovations. By learning from someone with a broader or more seasoned perspective, you can anticipate changes and adapt your strategies proactively, rather than reactively.",
        "Additionally, our IT director training course will support your personal development by enhancing your communication, negotiation, and conflict resolution skills, all of which are important for handling the organisational dynamics of this IT leadership role.",
      ]
    },
    {
      title: "What to Expect from IT Director Training Online?",
      description: [
        "At Career Navig8r, we’ve designed our online IT director mentoring programme to give you a flexible and accessible way to gain valuable insights and guidance from seasoned professionals in the field.",
        "When you sign up on our platform, you can choose an IT director mentor who’ll provide you with personalised mentorship tailored to your specific career goals and challenges. Our IT director course typically includes one-on-one sessions and virtual meetings, allowing you to discuss real-world scenarios and develop actionable strategies with your mentor. You’ll also have the opportunity to receive constructive feedback to help you do your job better.",
        "The online format of this IT director training course means you can pick the most convenient time for your sessions, so they don’t disrupt your daily schedule. You’ll be able to connect with mentors from diverse backgrounds and industries, expanding your network and exposure to different perspectives.",
        "In our IT director mentoring programme, you can expect a focus on leadership development, strategic thinking, and technical acumen, with practical advice on sustaining a successful career in IT management. You’ll also receive support in areas like team management, risk assessment, and aligning IT initiatives with business objectives.",
        "Overall, our IT director training online is designed to empower professionals like you with the knowledge and skills needed to confidently advance in their careers.",
      ]
    },
    {
      title: "Find an IT Director Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const seniorAccountantData = {
  mainTitle: "Senior Accountant Career Mentor",
  accordionData: [
    {
      title: "About Senior Accountant Mentoring",
      description: [
        "Are you a newly promoted senior accountant looking to learn the ins and outs of the job role? Or have you been in this role for a while and want to climb the corporate ladder?",
        "Career Navig8r offers a senior accountant career mentoring programme to guide and support accountants in their professional growth. In this course, you can choose a mentor from our pool of experienced senior accountants and benefit from their insights, industry knowledge, and practical advice to help you grow your career. ",
        "Your mentor will help you handle complex financial tasks more effectively, confidently deal with compliance issues, and face leadership challenges head-on. This senior accountant course will also encourage you to work harder and advance in your specific job role. ",
        "In your regular online sessions, your senior accountant career mentor will offer hands-on guidance and constructive feedback to help you sharpen your technical abilities and understand industry trends. They’ll also assist you in developing the strategic thinking necessary to excel in your role and contribute meaningfully to your organisation.",
        "Want to rise up the ranks in your job role? Sign up with Career Navig8r today and find your ideal senior accountant career coach.",
      ]
    },
    {
      title: "The Job Role of a Senior Accountant",
      description: [
        "To succeed in your career as a senior accountant, you need to have a thorough understanding of what your job role entails.",
        "As a senior accountant, you’ll have a broad range of financial responsibilities, making you a key asset to your organisation. You’ll be responsible for preparing accurate financial statements, ensuring compliance with accounting standards and regulations, and managing the general ledger. You’ll also oversee month-end and year-end closing processes, reconcile accounts, and analyse financial data to provide insights for decision-making.",
        "In addition to these tasks, you’ll often need to supervise the junior accounting staff, offering guidance and mentoring to enhance their skills and efficiency. You’ll also collaborate with auditors during internal and external audits, ensuring all financial records are accurate and up to date.",
        "As a senior accountant, you’ll play an important role in budgeting, forecasting, and financial planning, providing management with reports and recommendations to optimise financial performance. Your expertise in accounting principles and attention to detail will help ensure your organisation’s financial health and compliance with regulatory requirements.",
      ]
    },
    {
      title: "Challenges Faced by Senior Accountants in the Industry",
      description: [
        "As a senior accountant, you’re likely to face numerous challenges within the complex financial industry.",
        "One of the primary challenges you’ll face is keeping up with constantly evolving accounting standards, regulations, and tax laws. As these rules change, you’ll need to ensure that your organisation's financial practices remain compliant, which means you need to be willing to continuously learn and adapt.",
        "Another significant challenge is managing the increasing volume of financial data. With the rise of digital technologies and big data, you’ll need to efficiently handle and analyse large datasets to extract meaningful insights. This requires you to be proficient in advanced accounting software and analytical tools, as well as being capable of maintaining accuracy under pressure.",
        "You’ll need to be able to handle tight deadlines in this job role, particularly during month-end and year-end close processes. Balancing multiple priorities, such as preparing financial statements, reconciling accounts, and managing audits, can be stressful, regardless of your experience.",
        "You may also face challenges in managing and mentoring your junior staff. It’s your job to ensure that your team maintains a high level of performance while also developing their skills.",
        "Communicating complex financial information clearly to non-financial stakeholders adds another layer of difficulty. This requires you to have both strong technical knowledge and excellent communication skills.",
        "Overall, these issues demand a high level of expertise, resilience, and adaptability from you as a senior accountant. That’s where the expertise of a senior accountant career coach can come in handy, as you’ll be able to learn from somebody who has extensive experience in dealing with similar challenges in their career.",
      ]
    },
    {
      title: "Why do Senior Accountants Need Mentoring?",
      description: [
        "Whether you’re new to the role or you have extensive experience and knowledge as a senior accountant, you can still greatly benefit from senior accountant coaching. The finance industry requires continuous learning, and a senior accountant course will offer you a structured solution to gain fresh perspectives and insights.",
        "Even as a seasoned professional, you may still face challenges like adapting to new regulations, technological advancements, and evolving industry standards. A senior accountant mentor with specialised expertise can guide you through these changes, offering advice on the best practices and approaches.",
        "Our senior accountant career coaching will also help you refine your leadership and management skills. If you’re keen to improve how you supervise your junior staff, a senior accountant career mentor can provide valuable strategies for effective team management, communication, and conflict resolution. This guidance is important for creating a positive work environment and ensuring the team’s productivity and growth.",
        "Additionally, signing up for a senior accountant career coaching session with us will give you a safe space to discuss your career development. Your mentor can help you to explore new career paths and expand your professional network, or simply stay motivated in your existing job role. They’ll also provide emotional support, helping you handle workplace challenges and maintain a healthy work-life balance.",
        "Overall, senior accountant mentoring can be an essential tool for enhancing your skills, keeping up with industry trends, and continuing to grow both professionally and personally. Sign up for our senior accountant career mentoring course today.",
      ]
    },
    {
      title: "What to Expect from a Senior Accountant Mentoring Course Online?",
      description: [
        "At Career Navig8r, our online senior accountant coaching programme will give you a structured and flexible platform to grow professionally and expand your skill set. By signing up as a mentee, you’ll receive personalised guidance from one of our experienced senior accountant coaches, who will provide insights into everything you need to know to excel in your career.",
        "Our senior accountant course typically includes regular virtual meetings, where you and your mentor can discuss challenges you’re facing in your role, your career goals, and strategies for success. The online format of these one-on-one senior accountant mentoring sessions means you can pick a convenient time that doesn’t disrupt your daily schedule.",
        "As a mentee, you can expect constructive feedback from your mentor, helping you to further refine your technical skills and decision-making abilities. Additionally, our senior accountant coaching programme provides great networking opportunities, connecting you with peers and industry professionals globally.",
        "Our online senior accountant course offers a comprehensive and convenient way for accountants like you to develop their expertise, advance their careers, and stay competitive in a rapidly evolving industry.",
      ]
    },
    {
      title: "Find a Senior Accountant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const accountsManagerData = {
  mainTitle: "Accounts Manager Career Mentor",
  accordionData: [
    {
      title: "About Accounts Manager Mentoring",
      description: [
        "Have you recently become an accounts manager and want to gain a better understanding of your job role and responsibilities? Or do you want to learn how to overcome challenges in this role and steadily rise up the ranks?",
        "Career Navig8r’s accounting manager mentoring programme is designed to help you enhance your job-specific skills and knowledge, guiding you toward becoming a proficient accounts manager.",
        "When you sign up for our mentoring programme, you’ll receive personalised guidance from your chosen accounts manager career coach. Your mentor will draw on their own expertise and extensive experience in the field to teach you all about financial management, client relationships, and strategic decision-making.",
        "Our accounts manager course uses real-world applications to help you build on your leadership development and problem-solving skills, ensuring that you’re well-prepared for higher responsibilities. By giving you a deeper understanding of industry practices and team management, our course will equip you with the tools needed to excel in your job role and advance your career in accounting and finance.",
        "Ready to find your accounts manager career mentor? Sign up with Career Navig8r today.",
      ]
    },
    {
      title: "The Job Role of an Accounts Manager",
      description: [
        "As an accounts manager, your main job is to maintain and grow your company’s financial health. You’re responsible for overseeing and managing client accounts, ensuring that all financial transactions are accurately recorded and all accounts are reconciled on time.",
        "In this role, you’ll need to prepare financial reports, budgets, and forecasts, as well as analyse financial data to provide insights and recommendations for decision-making. You’ll also have to manage relationships with clients, suppliers, and other stakeholders, ensuring that payment terms and contracts are adhered to and that any issues are quickly resolved.",
        "Aside from financial management, your job also includes leading a team of accountants and providing guidance and support to ensure that the department operates efficiently. You need to stay updated on all accounting regulations and industry trends to ensure compliance and implement best practices.",
        "To succeed in your career as an accounts manager, you’ll need effective communication skills and strong analytical skills. You’ll also need good attention to detail to ensure that no essential aspects are overlooked.",
        "Overall, this is a dynamic and demanding job role that you can learn to handle better with the advice and guidance of an experienced accounts manager coach.",
      ]
    },
    {
      title: "Challenges Faced by Accounts Managers in the Industry",
      description: [
        "Even with years of experience in the accounting industry, you’re still likely to encounter some unique challenges that will test your expertise and problem-solving abilities.",
        "One of these challenges is managing complex financial regulations and compliance. As accounting standards and tax laws frequently change, you need to stay updated to ensure that your company's practices remain compliant. This requires continuous learning and vigilance.",
        "Another significant challenge is handling large volumes of data. As an accounts manager, you’ll often deal with vast amounts of financial information, requiring you to implement and maintain accurate, efficient systems for data management and analysis. This is essential not only for internal reporting but also for providing insights that help with strategic business decisions.",
        "Balancing multiple client relationships can also be difficult. It’s your job to ensure that all clients are satisfied, which will likely involve managing expectations, deadlines, and deliverables. For this, you’ll need to have excellent communication and time-management skills, especially when you’re dealing with conflicting priorities.",
        "Additionally, as the finance industry is increasingly relying on technology, you may face some challenges related to cybersecurity. To ensure that your company’s financial data is secure, you need to stay informed about the latest cybersecurity threats and solutions.",
        "Lastly, it can be extremely stressful to manage and motivate a team while ensuring you meet tight deadlines and financial targets. This is where you’ll need strong leadership and interpersonal skills to juggle your responsibilities effectively.",
        "The expert guidance of an accounting manager career coach is just what you need to tackle these challenges. Your mentor was once exactly where you are now in your career, and they’ve most likely dealt with similar problems.",
        "Sign up for our accounts manager course and find your accounting manager mentor today.",
      ]
    },
    {
      title: "Why do Accounts Managers Need Mentoring?",
      description: [
        "No matter how experienced you are in your industry, you could still benefit from our accounts manager coaching programme. It’ll help you become better at doing your job and advance in your career.",
        "Your chosen accounts manager career mentor has extensive experience in your job role, which means they can provide you with all the personalised guidance and support you need. This is particularly important in the rapidly changing finance industry where regulations, technologies, and best practices evolve quickly.",
        "With the help of an accounts manager career coach, you’ll be able to develop or build on your critical skills, such as leadership, communication, and problem-solving, which are essential for managing teams and making informed decisions. By learning from your accounts manager mentor's experiences, you can avoid common pitfalls and improve your understanding of the financial landscape. This will enable you to handle challenges with confidence.",
        "Our accounts manager course is designed to help you grow professionally. You’ll have the opportunity to refine your technical skills and adapt to new technologies, such as advanced accounting software and data analytics tools. This ongoing learning process is essential for staying competitive in the field.",
        "Additionally, our accounting manager mentoring will provide emotional support, helping you cope with the stress and pressure that comes with your responsibilities. Your mentor will encourage you to set and achieve personal and professional goals, ultimately helping you to feel more job satisfaction, advance in your career, or simply make a more significant impact on your organisation.",
      ]
    },
    {
      title: "What to Expect from Our Accounts Manager Mentoring Course Online?",
      description: [
        "At Career Navig8r, our accounts manager course online offers a comprehensive, flexible, and personalised learning experience tailored to the unique needs of aspiring and current accounts managers like you.",
        "When you sign up as a mentee, you’ll be able to schedule mentorship sessions with your accounts manager career coach, covering key areas such as financial reporting, budgeting, client relationship management, and compliance with industry regulations.",
        "In this interactive course, your accounts manager mentor will teach you how to apply your knowledge in real-world situations and provide constructive feedback. They may also give you practical assignments to test your progress.",
        "Throughout these one-on-one mentorship sessions, you’ll gain valuable insights into advanced financial management techniques, empowering you to excel in your role and further develop your leadership skills. The online format also allows you to learn at your own pace, making it easy to balance your daily schedule and responsibilities.",
        "By the end of the course, you’ll be equipped with the knowledge, tools, and confidence to more effectively manage complex accounts, lead a team, and contribute strategically to your organisation’s financial success. Our accounts manager career coaching will provide you with excellent networking opportunities, should you be looking to connect with helpful contacts and expand your social circle at work.",
      ]
    },
    {
      title: "Find an Accounts Manager Career Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const chiefFinancialOfficerData = {
  mainTitle: "Chief Financial Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Financial Officer Mentoring",
      description: [
        "Are you a recently promoted CFO looking for career advice from someone who has extensive experience in this job role? Or have you been working as a CFO for a while and want to improve your job skills and seek a raise or promotion?",
        "Career Navig8r’s specialised chief financial officer mentoring programme is designed to guide aspiring, newly appointed, or current CFOs through the complexities of financial leadership. ",
        "This chief financial officer course will help you enhance your strategic decision-making, financial acumen, and leadership skills, all of which are essential for navigating the high-stakes world of corporate finance.",
        "In this programme, you can select an experienced CFO of your choice to be your chief financial officer career mentor. They’ll provide you with invaluable insights, sharing their own real-world experiences and industry best practices to help you build your confidence and competency in your role. ",
        "You’ll receive personalised guidance from your chief financial officer career coach, who’ll help you build strong financial strategies, learn to better manage risks, and drive organisational growth.",
        "The main outcome of our chief financial officer course is to shape the next generation of financial leaders to excel in their careers. If you’re keen to climb the corporate ladder in your organisation, sign up with us and pair with your chief financial officer mentor today.",
      ]
    },
    {
      title: "The Job Role of a Chief Financial Officer",
      description: [
        "To excel as the CFO in your company, you need to understand exactly what your job role entails and what your responsibilities are.",
        "In your executive role as a chief financial officer, you’re mainly responsible for managing and overseeing all financial aspects within your organisation. Your primary duties include financial planning, risk management, record-keeping, and financial reporting. You’ll work closely with the CEO and other senior executives to develop and implement financial strategies that align with your company’s goals.",
        "You’ll also be tasked with maintaining your organisation’s financial health by monitoring cash flow, managing investments, and optimising the capital structure. You’ll play an essential role in strategic decision-making, providing insights and forecasts that can guide long-term planning and corporate strategy.",
        "Additionally, as the CFO, you’ll need to ensure compliance with financial regulations and standards, while managing relationships with stakeholders, including investors, auditors, and regulators.",
        "If you work in a large organisation, your duties will also include overseeing departments such as accounting, finance, and treasury, which makes your role central to your company’s success and sustainability.",
      ]
    },
    {
      title: "Challenges Faced by Chief Financial Officers in the Industry",
      description: [
        "As a chief financial officer, you probably have years of experience in finance and are well-versed in the ups and downs of this industry. However, in this role, you’re still likely to face challenges that will make your job increasingly complex and demanding.",
        "One of the primary challenges you’ll encounter is managing financial risk amidst economic volatility. Due to the constant fluctuations in global markets, changes in interest rates, and currency exchange risks, you need to be vigilant and proactive in preventing potential financial losses.",
        "Another common challenge is regulatory compliance. With financial regulations continuously evolving, you need to ensure that your organisation follows local and international standards. Non-compliance can result in severe penalties and damage to your company’s reputation.",
        "As a CFO, you’ll also often grapple with technological advancements. To keep up with the rise of digital finance, automation, and big data analytics, you need to stay updated with the latest technologies and integrate them into all financial operations. You’ll also need to ensure that investing in new technologies doesn’t disrupt the cost efficiency of your company.",
        "Talent management is another major issue. As finance teams evolve, it’s important that you attract, retain, and train skilled professionals who are capable of navigating complex financial landscapes.",
        "Finally, in many cases, you’ll be expected to provide strategic leadership beyond financial management. You’ll be required to collaborate with other executives to drive business growth, which means you’ll need a deep understanding of the industry, market trends, and competitive dynamics.",
        "Balancing all of these responsibilities can make your role particularly challenging, especially without the guidance and support of someone who has been there, done that. This is where you could benefit from the expertise of a chief financial officer coach.",
        "These professionals were once in the same position as you, which means they’ve faced and successfully dealt with similar challenges in their careers. That’s what makes them the ideal chief financial officer career mentors for you.",
        "Sign up for our chief financial officer course today.",
      ]
    },
    {
      title: "Why do Chief Financial Officers Need Mentoring?",
      description: [
        "As a CFO, you’re constantly operating in a high-stakes environment where your decisions can significantly impact the financial health and strategic direction of your organisation. Given the complexities of this role, you could greatly benefit from the guidance of a chief financial officer career mentor, particularly if you’re newly appointed or transitioning into more challenging environments.",
        "Our chief financial officer coaching programme will provide you with invaluable support and advice from seasoned professionals who have worked through similar challenges. This guidance will help you develop a deeper understanding of financial strategy, risk management, and leadership, all of which are essential to your success.",
        "Your chosen chief financial officer career mentor can also help you to work on your ability to make informed decisions under pressure and manage stakeholder relationships. They can use their own on-the-job experience to teach you how to effectively communicate complex financial information to non-financial executives and board members.",
        "Additionally, participating in our chief financial officer course will help you stay updated with industry trends and regulatory changes, as well as the latest technological advancements. It’ll also offer a platform for discussing any confidential challenges you may be facing and exploring innovative solutions in a safe and supportive environment.",
        "If you’re a CFO aiming to transition from a purely financial focus to a more strategic leadership role, our chief financial officer career coaching can be instrumental. It’ll help you broaden your perspective, align your financial strategies with your overall business goals, and contribute more effectively to your organisation’s growth.",
      ]
    },
    {
      title: "What to Expect from Our Chief Financial Officer Mentoring Course Online?",
      description: [
        "Career Navig8r’s chief financial officer course online is designed to provide all the guidance and support you need as a CFO.",
        "Once you sign up, you’ll receive personalised chief financial officer career coaching from experienced financial leaders who bring their own real-world insights into the complexities of the CFO role. Throughout the course, your chosen chief financial officer mentor will guide you on topics including strategic financial planning, risk management, regulatory compliance, and leadership skills.",
        "You’ll participate in interactive sessions and receive one-on-one mentoring, equipping you with practical knowledge to handle the challenges you’re likely to face in your role.",
        "Our chief financial officer coaching course will help you develop a strategic mindset, enabling you to align your financial strategies with broader business goals effectively. You’ll also be able to network with numerous industry professionals, enhancing your future career prospects.",
        "The online format of our chief financial officer mentoring programme means that you can choose the most convenient time for your sessions, so you can balance your daily schedule and professional responsibilities.",
        "Whether you're looking to refine your financial awareness, enhance your leadership abilities, or transition into a higher role, this online chief financial officer course offers the tools and mentorship you need to excel.",
        "Find the ideal chief financial officer career coach and get a tailored learning experience that empowers you to lead with confidence.",
      ]
    },
    {
      title: "Find a Chief Financial Officer Career Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },

  ]
};

export const itSupportSpecialistData = {
  mainTitle: "IT Support Specialist Career Mentor",
  accordionData: [
    {
      title: "About IT Support Specialist Mentoring",
      description: [
        "Are you an emerging IT professional looking to become an IT support specialist? Or are you already working in this job role and aiming to enhance your skills and keep growing in the IT industry?",
        "Either way, you’ll greatly benefit from pairing with a career mentor who’s experienced in this specific job role.",
        "Career Navig8r’s IT support specialist mentoring programme can help bridge the gap between your theoretical knowledge and practical experience. Through one-on-one guidance with an experienced professional in this role, you’ll gain real insights from someone who’s been there, done that, deepening your knowledge of problem-solving, customer interaction, and technical troubleshooting.",
        "Your chosen mentors will provide personalised advice, share real-world scenarios, and offer feedback on your performance, supporting you in your professional growth. This job-specific IT mentorship will enhance your skills and confidence while also helping you learn the best practices and gain up-to-date industry knowledge.",
        "With proper IT support specialist training, you’ll become well-prepared, competent, and capable of delivering high-quality IT support services in a rapidly evolving tech landscape.",
      ]
    },
    {
      title: "The Job Role of an IT Support Specialist",
      description: [
        "Whether you’re already an IT support specialist or aspiring to become one, you need to have a clear understanding of what this job role entails.",
        "An IT support specialist helps to ensure the smooth operation of an organisation's IT infrastructure. In this role, you’ll be responsible for diagnosing, troubleshooting, and resolving hardware, software, and network-related issues.",
        "You’ll be tasked with providing technical support to end-users, both remotely and in person, ensuring minimal disruption to your company’s operations. Your key responsibilities will typically include installing and configuring computer systems, updating software applications, and maintaining IT security protocols.",
        "As an IT support specialist, you’ll also be solely or jointly responsible for setting up new devices, managing user accounts, and ensuring that the data backup and recovery processes are in place. You’ll work closely with other IT professionals to implement system updates and upgrades, and you’ll document technical issues and solutions for future reference.",
        "Strong communication skills are essential here, as you have to translate complex technical information into understandable terms for non-technical users. Overall, as an IT support specialist, your job is to ensure the reliability and efficiency of your organisation's IT systems, contributing to overall business productivity and success.",
        "If you’re new to this role and need expert guidance, sign up for our IT support specialist course today.",
      ]
    },
    {
      title: "Challenges Faced by IT Support Specialists in the Industry",
      description: [
        "New and experienced IT Support Specialists face numerous challenges in the industry due to the multi-faceted nature of technology and the evolving demands of organisations.",
        "If you’re a new IT support specialist, the steep learning curve can be overwhelming. You’ll need to quickly acquire a deep understanding of various hardware and software systems, often while juggling multiple other IT support tasks.",
        "Handling a diverse range of issues, from simple password resets to complex network problems, requires you to have both broad and specific knowledge. You’ll also need to develop strong communication skills to assist users who may have limited technical understanding.",
        "If you’re an experienced IT support specialist, you’ll encounter your own unique set of challenges. You’ll be expected to keep up with the rapid pace of technological advancements and continuously update your skills. This includes staying informed about the latest security threats and best practices for mitigating risks. The pressure to resolve issues promptly can also lead to high stress levels, particularly in environments where downtime directly impacts business operations.",
        "Whether you’re a new or experienced specialist, you’ll need to manage customer expectations, often dealing with frustrated or impatient users. Balancing this customer service aspect with technical problem-solving is key to providing top service in your role. You’ll also be expected to work collaboratively within your team and with other departments, ensuring that the IT solutions you’re providing align with broader organisational goals.",
        "If you need help developing these skills, you could benefit from the guidance of someone who has been in your shoes and dealt with the same issues. An IT support specialist coach can help you become adaptable and resilient, equipping you with the expertise and mindset to thrive in this demanding yet rewarding field.",
      ]
    },
    {
      title: "Why do IT Support Specialists Need Mentoring?",
      description: [
        "The IT industry is fast-paced and ever-evolving, and you’ll need to be prepared to invest in continuous learning and adaptation if you want to stay at the top of your game. That’s where job-specific career mentoring can be beneficial. Using Career Navig8r’s platform, you can choose an IT support specialist mentor who’ll help you develop your skills and excel in your job role.",
        "If you’re a graduate looking to take on this job role, mentoring will give you a structured learning path, teaching you how to apply your academic knowledge in real-world situations. Your chosen experienced IT support specialist coach will offer valuable insights, practical tips, and troubleshooting techniques that may not have been covered in your formal education, based on their own firsthand experience.",
        "IT support specialist training can also help build confidence in your problem-solving abilities. By working closely with seasoned professionals, you’ll learn to approach complex issues methodically and efficiently. You’ll learn faster with hands-on guidance, enabling you to handle a broader range of technical problems independently.",
        "If you’re already working in this role, mentoring will help you continue your professional development and stay updated on the latest industry trends, tools, and best practices. Your IT support specialist coach will share their experiences and lessons learned, helping you avoid common pitfalls and make better-informed decisions.",
        "Our IT support specialist online training programme will also help you to enhance your soft skills, including communication, customer service, and teamwork. Your mentor will provide feedback on how to interact effectively with users, manage their expectations, and handle challenging situations.",
        "Overall, our IT support specialist mentoring ensures that you’re well-equipped to grow in the industry and adapt to its changes.",
      ]
    },
    {
      title: "What to Expect from IT Support Specialist Online Training?",
      description: [

        "Career Navig8r’s IT support specialist online training offers a comprehensive and flexible learning experience designed to equip you with essential skills and knowledge.",
        "After you sign up, you’ll choose your IT support specialist mentor. Your mentoring will cover a breadth of key topics, including hardware and software troubleshooting, network configuration, cybersecurity fundamentals, and customer service techniques. ",
        "Through this IT support specialist course, you can also expect to gain proficiency in common tools and technologies, such as operating systems, cloud services, and diagnostic utilities. Your IT support specialist coach may conduct assessments and quizzes to reinforce your understanding and track your progress.",
        "During each one-on-one online session, your chosen IT support specialist mentor will offer guidance and answer questions, ensuring that you have a well-rounded educational experience. They’ll help boost your problem-solving skills and prepare you to handle a variety of IT support scenarios, no matter where you are in your career.",
        "Need effective IT support specialist training to enhance your career? Sign up for our IT support specialist course now.",
      ]
    },
    {
      title: "Find an IT Support Specialist Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best path forward. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about what to expect, what skills are needed, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const prManagerData = {
  mainTitle: "PR Manager Career Mentor",
  accordionData: [
    {
      title: "About PR Manager Mentoring",
      description: [
        "Are you a public relations manager looking to improve your job skills? Or do you want to learn how to be a PR manager and advance your career in the industry?",
        "At Career Navig8r, we’ve designed a PR manager mentoring course that’s tailored to guide public relations professionals like you in enhancing their leadership capabilities. You’ll work with a dedicated PR manager mentor who’s highly experienced at the job and can give you one-on-one support.",
        "Through our PR manager training, you’ll benefit from valuable insights into industry trends, crisis management, media relations, and strategic communication. The goal of our PR manager course is to empower you to tackle challenges confidently and achieve personal and professional growth.",
        "With guidance from your chosen PR professional, you’ll learn how to refine your communication strategies and strengthen client relationships, ultimately improving (and then maintaining) your brand’s reputation. ",
        "If you’re ready to take your PR career to the next level, sign up for our PR manager training course today.",
      ]
    },
    {
      title: "The Job Role of a PR Manager",
      description: [
        "To learn how to be a PR manager, you need to understand what’s required of you in this career.",
        "As a PR manager, your main job is to shape and maintain the public image of your organisation. It’s your responsibility to develop and execute strategic communication plans that help build positive relationships between your company and its target audiences, including the media, customers, and the general public.",
        "You’ll be tasked with managing press releases, media inquiries, and interviews, ensuring that the messaging aligns with your company's goals and values. You’ll also be responsible for crisis communication, which means you’ll need to quickly sort out any negative publicity or unforeseen events to protect your company’s reputation.",
        "You’ll often collaborate with marketing teams to align their promotional activities with broader brand strategies. You’ll also need to monitor media coverage and public sentiment to adjust your approach when needed. ",
        "Additionally, your role will involve building and nurturing relationships with journalists, influencers, and key industry stakeholders to ensure favourable media exposure for your company.",
        "To be an excellent PR manager, you need strong leadership, communication, and problem-solving skills. This is because you’ll be required to balance multiple tasks in a fast-paced, often stressful environment.",

      ]
    },
    {
      title: "Challenges Faced by PR Managers in the Industry",
      description: [
        "When you’re working as a PR manager, you’re likely to encounter some challenges as you establish yourself as a professional in the industry.",
        "One of the most prominent issues is managing crises and negative publicity, especially in the digital age. With social media, news travels fast, which means you’ll need to respond quickly and strategically to protect your brand's reputation. For this, you should have real-time crisis management skills, with the ability to control narratives effectively.",
        "Another challenge is getting to grips with the constantly shifting media landscape. Traditional media is no longer the sole focus; now, you have to adapt to working with influencers, bloggers, and online platforms while maintaining relationships with established media outlets.",
        "You’ll also need to learn how to balance transparency with protecting sensitive company information, which can be a difficult task. It’s your duty to ensure that your brand remains credible without disclosing too much.",
        "Additionally, aligning your company’s public image with its internal culture and goals can be complex, especially as the business naturally changes over time. Managing client expectations and meeting the increasing demands for media attention will only add to the pressure.",
        "All of these challenges can make your job incredibly stressful and exhausting. Without knowing how to deal with the pressures of your career, you may find that it takes a toll on your physical and mental health, especially if you don’t have someone to guide you through the ups and downs of the role.",
        "That’s where our PR manager training course comes in. Our programme allows you to learn from a PR manager mentor who has worked in the same role and dealt with similar challenges in the past. They can give you practical advice and personalised guidance on how to be a PR manager effectively.",
        "Want to experience the benefits of PR manager mentoring? Sign up for our PR manager course today.",
      ]
    },
    {
      title: "What to Expect from PR Manager Training Online?",
      description: [
        "When you enrol in Career Navig8r’s PR manager mentoring programme online, you’ll get the best tips on how to become a PR manager and excel at the job.",
        "As a mentee, you can expect one-on-one sessions with an experienced PR manager mentor who’ll give you strategic advice on the main areas of PR, such as media relations, crisis management, brand communication, and leadership skills.",
        "They’ll also help you deal with real-world scenarios, offering practical solutions to current issues you might be facing. These may include managing your brand’s digital presence, producing professional-standard press releases, and handling media interviews.",
        "Our PR manager training sessions may also focus on career development, such as how you can build a strong professional network, improve your negotiation skills, and advance to a senior position.",
        "The online format of our PR manager course means you can learn at your own pace. This makes your learning accessible and convenient, even if you’re a busy professional seeking growth without compromising your schedule.",
        "Want to improve your job skills and stay updated on industry trends from anywhere? Sign up for our PR manager training course today.",
      ]
    },
    {
      title: "Find a PR Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const registeredNurseData = {
  mainTitle: "Registered Nurse Career Mentor",
  accordionData: [
    {
      title: "About Registered Nurse Mentoring",
      description: [
        "Are you keen to learn how to become a registered nurse? Or have you recently started working as a registered nurse and want to know how to excel in your job?",
        "Career Navig8r’s registered nurse mentoring programme can enhance your professional development in the healthcare industry. You’ll be paired with an experienced registered nurse mentor, who’ll guide you through the ins and outs of this career, providing you with support, knowledge, and practical skills.",
        "With our registered nurse training course, you’ll learn to handle challenges like patient care, time management, and decision-making with confidence. Your chosen registered nurse mentor will work with you in a collaborative environment, focusing on enhancing your clinical competency and improving patient outcomes.",
        "This mentorship will encourage your own personal growth and help you advance in the healthcare field. It’ll give you the tools and knowledge needed to become a skilled, resilient nurse who can thrive in fast-paced, demanding medical environments.",
        "If you’re looking to learn how to work as a registered nurse and secure a promotion to a more senior role in this industry, sign up for our registered nurse training course today.",
      ]
    },
    {
      title: "The Job Role of a Registered Nurse",
      description: [
        "To learn how to be a registered nurse, you first need to understand what this role entails. You’ll also be required to have medical knowledge, compassion, and the ability to think critically.",
        "As an RN, you’ll be responsible for assessing patients' health conditions, administering medications, and developing care plans tailored to each patient’s needs. You’ll monitor vital signs, assist with diagnostic tests, and provide emotional support to patients and their families during challenging times.",
        "You’ll work closely with doctors and other healthcare professionals to ensure comprehensive treatment, often serving as the primary point of contact for patient care. Alongside your clinical duties, you’ll need to educate patients on managing illnesses or injuries and ensure they can transition smoothly through various stages of care.",
        "In high-pressure environments like critical care or emergency units, you’ll need to make quick, life-saving decisions, often under stressful conditions. For this role, you’ll have to be skilled in crisis management.",
        "Whether you’re working in a hospital, clinic, or long-term care facility, you should be able to adapt to the specific needs of the environment. If you’re aspiring to obtain a more senior role, you may also need to take on leadership responsibilities, like mentoring junior staff, overseeing nursing assistants, and helping with quality improvement initiatives.",
        "Overall, your role is essential for ensuring quality patient care and positive health outcomes. This job also requires constant upskilling to stay current with medical advancements.",
      ]
    },
    {
      title: "Challenges Faced by Registered Nurses in the Industry",
      description: [
        "When you’re working as a registered nurse, you’re likely to encounter numerous challenges in the healthcare industry, many of which stem from the high-pressure nature of your work.",
        "One major issue is understaffing, which results in increased workloads and puts you at risk of burnout. With fewer staff members available, you may be responsible for more patients than you can effectively manage, compromising the quality of care you provide as well as your own well-being.",
        "The emotional toll of dealing with critically ill or dying patients is another challenge, and you’ll be expected to constantly balance empathy with maintaining your professional composure. Additionally, working long shifts, particularly in critical care or emergency settings, can result in physical and mental exhaustion.",
        "You’ll also need to get to grips with evolving healthcare technologies and practices, which require you to continuously learn and adapt. Regulatory and administrative burdens, such as extensive paperwork and compliance with healthcare laws, can further add to your workload.",
        "Additionally, workplace violence and exposure to infectious diseases pose safety risks. All of these challenges can collectively impact your job satisfaction in the nursing profession. ",
        "Our registered nurse training course can help you learn to deal with these challenges and avoid their mental toll. If you want to learn how to get a registered nurse role and excel at it, our registered nurse mentoring programme will allow you to get guidance and support from experienced nurses.",
        "These professionals have years of experience working in the role and have faced similar challenges. They can offer you practical advice based on their own experiences and help you tackle these problems more confidently.",
        "From dealing with physical and mental fatigue to managing various administrative tasks, your registered nurse mentor can give you the best tips on how to work as a registered nurse. Sign up to our training course today.",
      ]
    },
    {
      title: "What to Expect from Registered Nurse Training Online?",
      description: [
        "Career Navig8r’s online registered nurse mentoring programme is flexible and easily accessible. It can teach you how to become a registered nurse and further your career in the healthcare industry.",
        "If you’re a new or aspiring nurse, you can virtually connect with an experienced professional who will offer their guidance on clinical skills, patient care, and career advancement. These one-on-one sessions are conducted through video calls or chat-based communication, allowing you to receive personalised feedback and advice from anywhere. ",
        "The online format of our training makes it easier to fit into your busy routine. This approach is highly beneficial if you’re seeking guidance while balancing a demanding work schedule and personal responsibilities.",
        "With our registered nurse training course, you’ll learn within a supportive environment, where your mentor will share their real-world insights and help; you tackle common challenges, such as managing heavy workloads, balancing emotional stress, and making critical decisions in patient care.",
        "During online sessions with your registered nurse mentor, you can also discuss your long-term career goals and any advanced certifications or specialisations you may want to pursue. Additionally, mentorship can lead to networking opportunities that could benefit you in the future.",
        "Ready to learn all about how to be a registered nurse? Sign up for our registered nurse training course today.",
      ]
    },
    {
      title: "Find a Registered Nurse Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const hrManagerData = {
  mainTitle: "HR Manager Career Mentor",
  accordionData: [
    {
      title: "About HR Manager Mentoring",
      description: [
        'Do you want to learn how to be an HR manager? Or are you new to the role and keen to know how you can excel at the job?',
        'Career Navig8r delivers a HR manager mentoring programme that focuses on guiding HR professionals to enhance their skills, leadership, and strategic thinking. We offer personalised mentorship, meaning that an experienced HR leader will help you handle complex employee relations, improve your recruitment strategies, and align your HR goals with business objectives.',
        'Our HR manager training course will encourage you to keep learning and growing in your job role. You’ll gain insights into organisational culture, conflict resolution, and talent development.',
        'Your chosen HR manager mentor will also provide support in areas such as compliance, diversity initiatives, and performance management. This relationship will help you gain the confidence to take on more significant roles within your company, allowing you to build a stronger, more capable HR team.',
        'Keen to reach new heights in the HR industry? Sign up for our HR manager course today.'
      ]
    },
    {
      title: "The Job Role of an HR Manager",
      description: [
        "As a HR manager, your job is to ensure that your company's workforce operates efficiently and harmoniously. You’ll be tasked with overseeing various aspects of human resources, including recruitment, employee relations, performance management, and compliance with labour laws.",
        "Your job role will involve sourcing and hiring talent, ensuring that the right candidates fill open positions while maintaining a positive workplace culture. You’ll also have to handle employee grievances, mediate disputes, and implement policies that support staff and organisational needs.",
        "Additionally, your role will likely require you to monitor performance through appraisals and feedback systems, ensuring that employee growth aligns with your company's goals. Managing compensation, benefits, and employee training programmes will be another important part of your responsibilities.",
        "You’ll also need to strategically align your HR practices with your business’s objectives to ensure proper workforce planning and long-term talent development. This involves balancing your operational and strategic duties to help maintain a productive, compliant, and motivated workforce.",
      ]
    },
    {
      title: "Challenges Faced by HR Managers in the Industry",
      description: [
        "In your role as a HR manager, you’re bound to face numerous challenges, many of which will require you to develop adaptive strategies and innovative solutions.",
        "One big challenge is talent acquisition in a competitive market. To attract and retain skilled employees, you’ll need to handle intense competition, offer competitive compensation, and maintain an appealing company culture.",
        "Employee engagement and retention are also major issues. You’ll need to continuously work to motivate staff, prevent burnout, and create opportunities for growth within your company. ",
        "Additionally, you may need to manage remote or hybrid workforces, which have become common. This adds another layer of complexity to your job, requiring you to ensure smooth communication and maintain productivity when your team is working off-site.",
        "Another major challenge is staying compliant with the changing labour laws and regulations. You’ll need to ensure that your company’s policies align with legal requirements, from wages to workplace safety.",
        "You’ll also face increasing pressure to promote diversity, equity, and inclusion within your company. You’ll need to create an inclusive culture, which involves addressing unconscious biases appropriately and fostering a welcoming environment.",
        "Tackling these challenges can be stressful, especially if you’re new to the role. To learn how to be a HR manager, you’ll need the guidance of someone who has held the same job before and understands how this industry works.",
        "Our HR manager mentoring course allows you to learn from the best HR professionals. From enhancing your leadership skills and developing strategic thinking to improving employee relations and managing conflict more effectively, your HR manager mentor will teach you all you need to know about this job role.",
        "On our HR manager training course, you can take advantage of your mentor’s personalised support, helping you handle emerging issues like remote work, diversity, and workforce planning. You’ll gain all the tools you need to succeed in your career.",
        "Ready to find out how to be an HR manager effectively? Sign up for our HR manager course today.",
      ]
    },
    {
      title: "What to Expect from HR Manager Training Online?",
      description: [
        "At Career Navig8r, our online HR manager mentoring course can be tailored to your unique needs. Once you enrol, you can expect one-on-one sessions with an experienced HR manager mentor who’ll give you insights into HR strategies, leadership, and employee management.",
        "As this HR manager course is conducted online, you have the flexibility to choose the most convenient time for your sessions to avoid interference with your daily responsibilities.",
        "Through virtual meetings, your HR manager mentor will help you address specific challenges you may be facing, from improving recruitment techniques to  managing employee relations and getting to grips with labour laws. They’ll also provide feedback and conduct assessments to track your progress throughout the HR manager training course.",
        "You’ll have the opportunity to learn about the latest industry trends, such as remote work dynamics, diversity and inclusion initiatives, and talent retention strategies. Beyond technical skills, you’ll also be able to work on your leadership skills, emotional intelligence, and problem-solving abilities.",
        "Our online HR manager mentoring programme provides a supportive platform to grow personally and professionally and advance your career.",
        "Learn how to be an HR manager with our training course now.",
      ]
    },
    {
      title: "Find an HR Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const salesManagerData = {
  mainTitle: "Sales Manager Career Mentor",
  accordionData: [
    {
      title: "About Sales Manager Mentoring",
      description: [
        "Are you aiming to become a sales manager? Or do you work in this role and want to learn some tips and tricks to survive and thrive in the industry?",
        "Career Navig8r’s sales manager mentoring programme guides sales leaders or budding sales managers like you to enhance their skills, leadership abilities, and strategic thinking. Through one-on-one coaching online, you’ll work with your chosen sales manager mentor to refine your approach to team management, goal-setting, and performance tracking.",
        "Our sales manager training also covers areas including communication, motivating sales teams, and improving decision-making to drive revenue growth. It’ll help you overcome common challenges, like closing deals or managing underperforming staff, while fostering a growth mindset.",
        "Through our sales manager training course, you’ll gain skills and tools to excel in your job role, boost team morale, and achieve long-term success in today’s competitive market.",
        "Sign up with us and enrol in our sales manager course today.",
      ]
    },
    {
      title: "The Job Role of a Sales Manager",
      description: [
        "As a sales manager, your role is vital to the success of your company’s sales team and overall revenue growth. You’re responsible for overseeing the day-to-day operations of the sales department, which includes setting targets and ensuring that your team meets its goals.",
        "Your primary duties include developing strategic sales plans, managing the sales pipeline, and monitoring key performance indicators (KPIs). You’ll also play a key part in motivating and coaching your team members and guiding them on improving sales techniques, as well as supporting them in customer engagement and closing deals.",
        "Additionally, you’ll need to collaborate with other departments such as marketing and product development to align your strategies and ensure customer satisfaction. You’ll also analyse market trends, competitors, and customer feedback to adjust your approach and stay competitive.",
        "To excel in this role, you have to balance leadership, strategy, and operational oversight to drive consistent sales performance and contribute to your company’s growth and profitability.",
      ]
    },
    {
      title: "Challenges Faced by Sales Managers in the Industry",
      description: [
        "When you’re working as a sales manager, you’ll probably encounter some challenges that you’ve never faced before in the industry.",
        "One major challenge is maintaining consistent sales growth in a competitive and continuously changing market. As customers’ expectations evolve and new competitors emerge, you’ll need to stay ahead through constant innovation and adaptation.",
        "Managing underperforming sales reps is another big hurdle. You’ll need to provide constructive feedback and coaching to improve individual performance without impacting the overall team morale.",
        "High staff turnover can also disrupt the sales process, making it challenging to build and maintain a strong, cohesive team. Additionally, the pressure to meet ambitious quotas can lead to stress and burnout, not just for you but for your team as well.",
        "Balancing short-term targets with long-term relationship building is another struggle. It’s your job to ensure that the customers are happy while also aiming to get the immediate results your company needs.",
        "All of these challenges require strong leadership, strategic planning, and adaptability to succeed in the industry. This is where our sales manager training course can help.",
        "Your chosen sales manager mentor will have extensive experience in the same job role. That means they’ll have the insights and expertise to help you tackle complex challenges like team management, meeting quotas, and adapting to market shifts.",
        "They’ll teach you the best ways to improve your communication and motivate your staff, with tried-and-tested approaches to optimise your sales strategies. Sales manager mentoring will also help you refine your problem-solving abilities and boost your confidence, as well as stay current with industry trends.",
        "With our sales manager course, you’ll grow personally and professionally and become a better leader to help your sales team succeed.",
      ]
    },
    {
      title: "What to Expect from Sales Manager Training Online?",
      description: [
        "Career Navig8r’s sales manager mentoring programme online can help you learn the ins and outs of this job role and advance your career.",
        "When you sign up, you can expect one-on-one sessions with an experienced sales manager mentor who’ll give you tailored guidance on leadership, strategy, and sales management. These sessions will focus on key areas such as improving team performance, meeting sales targets, and developing effective communication skills.",
        "Through our virtual sales manager training course, you’ll learn about real-world scenarios and gain industry insights from your mentor, based on their own experiences. Your mentor will also track your progress, help you set goals, and give you feedback in real time. ",
        "Additionally, you’ll receive actionable advice on overcoming common challenges, such as handling underperforming team members or navigating competitive markets.",
        "The flexibility of our online sales manager course means you can fit sessions into your busy schedule, making it easier to balance professional growth with your day-to-day responsibilities.",
        "Ultimately, our online sales manager training will help you build confidence and enhance your skills, leading your business towards greater success. Sign up for our sales manager training course today.",
      ]
    },
    {
      title: "Find a Sales Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};


export const operationsManagerData = {
  mainTitle: "Operations Manager Career Mentor",
  accordionData: [
    {
      title: "About Operations Manager Mentoring",
      description: [
        "Are you looking to learn how to be an operations manager? Or have you already achieved this role and are keen to improve your job skills and advance your career?",
        "Career Navig8r’s operations manager mentoring programme is designed for professionals who oversee business operations. You’ll get the opportunity to learn from an experienced operations manager who can help you develop your leadership, decision-making, and problem-solving skills, which are essential for managing workflow efficiently.",
        "Your chosen operations manager mentor will share their own insights on the ins and outs of the role, including optimising processes, handling logistics, and managing teams. From managing projects and improving your team’s performance to allocating your company’s resources properly, they’ll support you and help you grow in various key areas of the job.",
        "Your mentor will draw from their own experiences, teaching you how to tackle challenges and avoid common mistakes, as well as the best practices to adopt. You’ll receive structured guidance that will accelerate your career growth, enabling you to lead with confidence and improve the effectiveness of your organisation’s operations.",
        "Ready to find your ideal operations manager mentor? Sign up for our operations manager training course today.",
      ]
    },
    {
      title: "The Job Role of an Operations Manager",
      description: [
        "As an operations manager, your job is to ensure that your company’s day-to-day operations function efficiently. You’ll be responsible for overseeing production, logistics, and process optimisation to meet your organisation’s goals.",
        "You’ll need to coordinate with different departments to streamline workflows and manage budgets, while also ensuring that your existing resource allocation strategies are aligned with your company’s objectives. Additionally, you’ll be tasked with monitoring your company’s performance metrics, identifying inefficiencies, and finding solutions to improve productivity.",
        "Aside from process management, you’ll need to supervise the staff, ensuring that your team members are working effectively and following company policies. You’ll also play a key role in risk management by addressing operational challenges and fixing potential issues before they escalate.",
        "Strategic planning is another important part of your role. You’ll need to continuously assess the market to identify trends, and make data-driven decisions to keep your company’s operations competitive.",
        "Ultimately, it’s your job to make sure that your organisation runs smoothly and meets deadlines, while also achieving its financial and performance targets.",
      ]
    },
    {
      title: "Challenges Faced by Operations Managers in the Industry",
      description: [
        "When you’re working as an operations manager, you’ll come across a range of challenges that will require effective problem-solving and adaptability.",
        "One of the most common challenges is managing supply chain disruptions, which could be caused by anything from delays and shortages to unpredictable external factors. You’ll need to ensure smooth coordination between suppliers, manufacturers, and customers to maintain your company’s operations.",
        "Another common challenge is balancing cost-efficiency with quality. You’ll need to constantly find ways to reduce your business’s operational expenses without compromising the quality of your products or services. This often involves optimising processes and, implementing new technologies, or perhaps improving resource allocation, all while maintaining high productivity levels.",
        "Staff management can also be challenging, as you’ll need to motivate and retain talent. This may involve handling conflicts and ensuring your team works efficiently. Additionally, you’ll need to be able to adapt to changing regulations, market demands, and technological advancements by continuously learning and thinking strategically.",
        "You’ll also be under immense pressure to meet deadlines while maintaining flexibility and adjusting to unexpected changes. This can further complicate your role, making it difficult to decide what your next move should be.",
        "All these challenges can affect your stress and motivation levels, especially if you don’t have a mentor to guide you. That’s where our operations manager training course comes in.",
        "Your chosen operations manager mentor will either currently work in the job role or have extensive experience from a previous role. They’ve dealt with the same problems and can draw from their own experience to help you find the best solutions while also supporting and encouraging you.",
        "Through our operations manager mentoring, you’ll get practical advice and personalised guidance to overcome the specific struggles you’re facing. You’ll learn how to do your job more confidently and excel in your career.",
        "Want the most effective tips on how to be an operations manager? Sign up for our operations manager course today.",
      ]
    },
    {
      title: "What to Expect from Operations Manager Training Online?",
      description: [
        "Career Navig8r’s online operations manager mentoring course offers  a flexible and personalised approach to professional development.",
        "When you enrol, you can expect one-on-one guidance from your chosen operations manager mentor, who will provide valuable insights into managing business operations effectively.",
        "The online format of our operations manager course means that you can schedule sessions at your convenience. That way, you’ll be able to balance learning with your daily routine and work responsibilities.",
        "Within our operations manager training course, your mentor will cover key areas like process optimisation, staff management, resource allocation, and decision-making strategies. You can also seek advice on handling specific challenges you’re facing, such as supply chain issues, improving operational efficiency, or implementing new technologies.",
        "Through virtual discussions and assessments, your operations manager mentor will help you identify your strengths and areas for improvement. They’ll also provide actionable feedback, allowing you to enhance your skills.",
        "Overall, our operations manager mentoring offers a supportive environment where you can learn and grow with practical, real-world advice. You can take our operations manager course to boost your confidence and expertise in leading successful operations.",
        "Sign up and learn how to be an operations manager effectively.",
      ]
    },
    {
      title: "Find an Operations Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const plantManagerData = {
  mainTitle: "Plant Manager Career Mentor",
  accordionData: [
    {
      title: "About Plant Manager Mentoring",
      description: [
        "Do you want to learn how to be a plant manager? Or have you recently become a plant manager and want to know how to keep advancing in your career?",
        "At Career Navig8r, our plant manager mentoring programme is designed to guide new or experienced plant managers like you in honing their leadership and operational skills. Our plant manager training focuses on building your competency in areas such as safety protocols, efficiency optimisation, team management, and problem-solving.",
        "You’ll gain insights from a seasoned plant manager mentor, who’ll share their real-world knowledge and teach you about the industry. This plant manager course will also help you tackle any specific challenges you may face in the role, improve your communication with your staff, and align with your organisation's goals.",
        "Our plant manager mentoring is carried out in a collaborative learning environment, helping you improve your productivity and succeed within your company.",
        "Ready to climb the corporate ladder in your industry? Sign up for our plant manager training course today.",
      ]
    },
    {
      title: "The Job Role of a Plant Manager",
      description: [
        "In your job as a plant manager, you’ll be responsible for overseeing the daily operations of your manufacturing or production facility. You’ll need to ensure that your facility runs efficiently, safely, and within budget.",
        "Your main duties will include managing production schedules and optimising resource allocation. You’ll also be tasked with making sure there’s efficient quality control throughout the production process. Additionally, you’ll supervise your staff, implement safety protocols, and minimise downtime by addressing any technical issues that may arise.",
        "As a plant manager, you’ll be involved in strategic planning, with a focus on improving operational efficiency, reducing costs, and maintaining compliance with industry regulations. You’ll also collaborate with other departments, such as procurement, logistics, and engineering, to streamline all processes and meet your production targets.",
        "To learn how to be a plant manager properly, you’ll need to work on your leadership and communication skills. This is because you’ll often have to motivate your team, resolve conflicts, and create a productive work environment.",
        "By constantly improving your skills, you can ensure that your production goals align with broader business objectives. As a result, you’ll be able to push your company’s manufacturing operations toward success.",
      ]
    },
    {
      title: "Challenges Faced by Plant Managers in the Industry",
      description: [
        "When you’re working as a plant manager, you’ll likely encounter some challenges in the industry while you’re overseeing complex operations and trying to ensure smooth production processes.",
        "One of the main challenges is maintaining operational efficiency while managing costs. With tight budgets and increasing demand for high-quality products, you’ll need to find ways to optimise your resources, reduce waste, and improve the productivity of your workers without sacrificing quality.",
        "Another major challenge is workforce management. It’s your job to ensure that your employees are well-trained, motivated, and working in safe conditions. If there are high turnover rates, labour shortages, or union disputes, your facility will likely face disruptions, affecting your ability to maintain consistent production.",
        "Adapting to technological advancements can also be quite difficult. You’ll need to stay updated on the latest innovations while balancing integration costs and potential job displacement.",
        "Lastly, meeting regulatory and environmental standards can further add to the pressure. It’s your job to comply with strict safety, environmental, and industry regulations while simultaneously pushing for sustainability and trying to reduce your facility’s environmental footprint.",
        "All the challenges of your role can be exhausting to deal with, especially if you don’t have anyone to guide you. We believe anyone in a plant management role, as well as people aspiring to this career, could greatly benefit from the expert advice of someone who has tackled similar issues and knows how best to resolve them.",
        "That is where our plant manager mentoring programme comes in. With expert plant manager training from someone who has extensive experience in the role, you can confidently handle any challenge the job presents.",
        "In our plant manager course, you’ll get the practical advice and personalised guidance you need. Your plant manager mentor will help you learn how to do your job better and excel in your career.",
        "For the best tips on how to be a plant manager, sign up for our plant manager training course today.",
      ]
    },
    {
      title: "What to Expect from Plant Manager Training Online?",
      description: [
        "Career Navig8r’s online plant manager mentoring course is flexible and accessible for plant managers seeking professional development and guidance.",
        "When you enrol in our virtual plant manager training course, you can expect tailored one-on-one sessions with industry experts. Here, you’ll learn insights on managing complex operations, improving efficiency, and handling workforce challenges.",
        "Your chosen plant manager mentor will guide you through key areas such as safety management, process optimisation, and technological advancements. You can also use this opportunity to gain valuable leadership tips, learn how to improve your team dynamics, and develop skills for crisis management.",
        "Additionally, in our plant manager course, you’ll get access to a broader network of industry professionals. This will give you a chance to exchange knowledge and collaborate with other experts in the industry.",
        "The online format of our plant manager training course means that you can receive personalised advice from anywhere, making it easy to balance learning with your busy schedule.",
        "Want to feel more confident in handling your job role? Enrol in our plant manager course today.",
      ]
    },
    {
      title: "Find a Plant Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const hrAssistantData = {
  mainTitle: "HR Assistant Career Mentor",
  accordionData: [
    {
      title: "About HR Assistant Mentoring",
      description: [
        "Are you looking to start a career in HR? Or have you recently entered the industry and need HR training to climb the corporate ladder?",
        "At Career Navig8r, we’ve created a structured HR mentoring programme to guide HR assistants like you in growing their skill sets and handling the tasks involved in the human resources role. Through personalised guidance from experienced HR professionals, you’ll gain insights into key areas like recruitment, employee relations, payroll management, and compliance.",
        "Your HR mentor will support you in solving problems and developing your leadership skills. They’ll guide you as you grow in your career, helping you build confidence and improve your effectiveness.",
        "In our human resources training course, you’ll receive practical advice and professional feedback, helping you to progress using real-world scenarios. Ultimately, our HR course will give you the tools, knowledge, and mindset to excel in your role and advance your career within this field. ",
        "For expert human resources mentoring online, sign up for our HR training course today.",
      ]
    },
    {
      title: "The Job Role of a HR Assistant",
      description: [
        "In your job as a HR assistant, you’ll mainly need to support the human resources department by handling a variety of administrative and operational tasks.",
        "Your primary responsibilities will include managing employee records, processing payroll, coordinating interviews, and assisting with recruitment efforts. You’ll also need to ensure compliance with company policies and labour laws by maintaining accurate documentation and staying up-to-date with all regulations.",
        "Your role will likely see you involved in onboarding new employees, which means helping them with filling out paperwork and ensuring that they’re able to smoothly transition into your company. You’ll often be the first point of contact for employees’ queries related to benefits, attendance, or workplace issues, so you’ll need to assist or direct them to the appropriate HR personnel.",
        "Additionally, you’ll be tasked with organising company events and employee training sessions and maintaining open communication between the staff and management. Your work will support the overall efficiency of the HR department.",
        "With human resources training from experts in the industry, you’ll be able to create a positive work environment and support the smooth running of your organisation.",

      ]
    },
    {
      title: "Challenges Faced by HR Assistants in the Industry",
      description: [
        "When you’re working as a HR assistant, you’ll probably face some challenges in the industry that can complicate your role.",
        "One of the main difficulties you’ll encounter is managing a large volume of administrative tasks, such as handling employee records, processing payroll, and ensuring compliance with labour laws. You’ll have to balance these duties while maintaining accuracy and meeting tight deadlines, which can be stressful.",
        "Another challenge is communication. As the bridge between employees and management, you’ll need to address a wide range of inquiries and concerns. You’ll also have to handle sensitive issues, such as disputes or grievances, which can be emotionally taxing, especially when confidentiality and discretion are needed.",
        "Keeping up with ever-changing labour laws and industry regulations is also a challenge. You’ll be expected to stay informed about legal updates to ensure your company’s practices remain compliant, which requires ongoing learning and adaptation.",
        "Additionally, there are many HR technologies and software that can further complicate your job. To keep up with them, you’ll need to continuously update your technical skills.",
        "Tackling all of these issues will require proper HR training. You’ll need the guidance of a human resources mentor who has previously held the same job role. An HR mentor can give you all the expertise and human resources training you need to excel at your job.",
        "In our human resources course, you’ll learn about the ups and downs of the industry. Our human resources mentoring will help you improve your skills and become more confident in your job, allowing you to steadily move up the corporate ladder.",
        "Ready to take your career to the next level with HR mentoring? Sign up for our HR course today.",
      ]
    },
    {
      title: "What to Expect from HR Assistant Training Online?",
      description: [
        "Career Navig8r’s online HR mentoring course offers a comprehensive learning experience that can help you excel in your HR career. When you enrol, you can expect one-on-one guidance from an experienced HR mentor who’ll give you personalised feedback and career advice.",
        "Through online sessions, your chosen human resources mentor will cover the main HR training topics like recruitment, employee relations, compliance, and payroll management. This will help you gain practical knowledge that you can apply in your role.",
        "As this HR training course is online, you’ll benefit from scheduling flexibility, which means you can learn at your own pace and from any location. This will prevent any disruptions in your daily routine.",
        "Your human resources mentor will give you valuable insights into real-world challenges and share industry trends. They’ll also teach you the best practices that can help you enhance your problem-solving and decision-making skills. You can expect to receive constant feedback regarding your progress.",
        "With our human resources training course, you’ll benefit from continuous support and access to expert advice from the best HR professionals in the industry. HR mentoring will help you advance your HR knowledge, and confidently navigate your career path within the HR industry.",
        "Ready to reap the benefits of extensive HR training? Enrol in our human resources course.",
      ]
    },
    {
      title: "Find a HR Assistant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const paralegalManagerData = {
  mainTitle: "Paralegal Career Mentor",
  accordionData: [
    {
      title: "About Paralegal Mentoring",
      description: [
        "Do you want to learn how to work in paralegal jobs? Or have you recently become a paralegal and want professional advice on improving your skills?",
        "At Career Navig8r, we’ve created a paralegal mentoring programme for anyone entering or advancing within the legal field. You’ll receive guidance from experienced paralegals who can help you get a firm understanding of legal procedures, manage your workloads, and recognize your ethical responsibilities.",
        "In our paralegal or compliance mentoring course, you’ll benefit from personalised advice on career growth and skill development. You’ll also have the opportunity to build a professional network and gain more confidence in your role. ",
        "Our compliance training allows for knowledge-sharing, so newer paralegals like you can get to grips with legal documentation, client interactions, and courtroom preparation.",
        "With the guidance of your chosen paralegal mentor, you can sharpen your expertise, improve your efficiency, and better adapt to the changing demands of your legal profession. ",
        "Ready to find out how to get a compliance role or paralegal role and excel in your career? Sign up for our paralegal training course today.",
      ]
    },
    {
      title: "The Job Role of a Paralegal",
      description: [

        "As a paralegal, your main job is to support lawyers by handling various legal tasks that ensure cases are managed smoothly and clients are satisfied. Your responsibilities include conducting legal research, drafting documents such as contracts, briefs, and pleadings, and organising case files.",
        "You’ll often assist attorneys in preparing materials for trials, hearings, and depositions, ensuring that all legal paperwork is accurate and properly filed. Additionally, you’ll be the point of contact for clients, which involves maintaining communication with them and gathering essential information relevant to the case.",
        "While your job doesn’t involve providing legal advice or representing clients in court, you’ll need to help lawyers streamline processes by managing deadlines and performing administrative tasks like scheduling meetings.",
        "To successfully learn how to work in paralegal jobs, you’ll need to have strong attention to detail and organisational skills, as well as a great understanding of all legal terminology and procedures.",
        "With these qualities, you’ll be able to work in various legal settings, including law firms, corporate legal departments, government agencies, and nonprofit organisations.",
      ]
    },
    {
      title: "Challenges Faced by Paralegal in the Industry",
      description: [
        "When you’re working as a paralegal or compliance assistant, you’re likely to encounter some challenges in the legal industry.",
        "One of the main issues you’ll face is juggling heavy workloads and tight deadlines. Legal work is often fast-paced, which means you’ll need to get good at multitasking. You’ll regularly be tasked with managing case files, conducting research, drafting documents, and ensuring that deadlines are met, all while maintaining meticulous attention to detail.",
        "The need to continue to broaden your legal knowledge can also be demanding. With laws and regulations constantly evolving, you’ll need to stay updated on any legal changes relevant to your work, often without formal training or support from your employers.",
        "Additionally, you’ll face the high expectations of attorneys, clients, and legal teams you work with. This can create a stressful work environment, where even the smallest mistakes could lead to major consequences for a case.",
        "Another challenge is that you may find yourself with limited career advancement opportunities, as you’ll typically be restricted from practising law or providing legal advice. This limitation can leave you feeling frustrated, especially if you want more responsibility or you’re seeking professional growth within the legal field.",
        "To tackle these challenges, you’ll need the guidance of a compliance assistant mentor or paralegal mentor with extensive experience working in the same position and dealing with the same issues. That’s where our compliance training course comes in.",
        "With proper compliance mentoring or paralegal training, you’ll get a better understanding of the legal industry and your place in it. Your compliance assistant mentor or paralegal mentor will guide you through the ups and downs of the role, teaching you how to work in paralegal jobs without making any severe mistakes.",
        "If you’re wondering how to get a compliance role or how to improve your skills, sign up for our paralegal mentoring or compliance training course today.",
      ]
    },
    {
      title: "What to Expect from Paralegal Training Online?",
      description: [
        "Career Navig8r’s compliance mentoring or paralegal training course online will help you gain the skills necessary for a successful legal career. Once you enrol, you’ll have access to one-on-one virtual sessions with your chosen paralegal mentor who’ll cover essential topics like legal research, writing, case management, contract drafting, and ethical responsibilities.",
        "Your compliance assistant mentor will share their knowledge and personalised advice based on their own experiences to help you better understand the industry. They’ll also encourage you to discuss your perspectives and ask any questions you may have about the role, and they may conduct assessments to track your progress.",
        "These sessions may also include practical exercises, such as mock legal scenarios and document preparation, to build your real-world skills. Additionally, you’ll open up your networking opportunities, allowing you to advance your career.",
        "Another major advantage of our compliance training course is that you’ll have the ability to learn at your own pace. This makes it an ideal option if you’re a working professional and don’t want to (or are unable to) disrupt your daily schedule.",
        "If you want to learn how to work in paralegal jobs or how to work in compliance roles, our paralegal training course is for you. It can be your stepping stone toward a role in a law firm, corporate legal department, or government agency.",
      ]
    },
    {
      title: "Find a Paralegal Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const engineeringManagerData = {
  mainTitle: "Engineering Manager Career Mentor",
  accordionData: [
    {
      title: "About Engineering Manager Mentoring",
      description: [
        "Do you want to learn how to be an engineering manager? Or have you recently started working in this role and are looking to improve your job skills?",
        "At Career Navig8r, our engineering manager mentoring programme will guide you as you transition from your technical role to a leadership position. It’ll support your growth in areas like team management, project planning, communication, and decision-making.",
        "Your chosen engineering manager mentor will help you tackle challenges such as balancing technical expertise with people management, learning how to collaborate, and building strong, high-performing teams. You’ll also learn about conflict resolution and effective delegation, and gain strategies to flourish in your career. ",
        "Our engineering manager training course will encourage your professional development, helping you confidently lead your team while maintaining a forward-thinking, innovative mindset.",
        "If your goal is to improve your leadership skills and help your organisation succeed, sign up for our engineering manager course today.",
      ]
    },
    {
      title: "The Job Role of an Engineering Manager",
      description: [
        "In your job as an engineering manager, you’ll need to balance your technical expertise with your leadership responsibilities to ensure that your team stays productive and your engineering projects are successful.",
        "You’ll be tasked with overseeing a team of engineers and guiding them through the planning, development, and execution of projects. You’ll also be responsible for setting clear goals and managing resources while making sure that your team’s work aligns with the business objectives.",
        "Along with project management, you’ll need to know how to effectively build a collaborative environment, so your team of engineers feels motivated and supported and can continue developing their skills. Additionally, you’ll play a key role in recruiting, training, and mentoring new talent, which will help you build a strong team that performs efficiently.",
        "Your duties will involve people management, which means you’ll need to understand how to resolve conflicts, provide feedback, and ensure there’s smooth communication between engineers and other departments. Additionally, as in any role, you’ll be expected to stay up-to-date with industry trends and technology.",
        "Ultimately, your job is to deliver high-quality results efficiently while maintaining a healthy team dynamic.",
      ]
    },
    {
      title: "Challenges Faced by Engineering Managers in the Industry",
      description: [
        "When you’re working as an engineering manager, you’ll face many challenges in the industry, especially as you climb the corporate ladder. ",
        "One significant challenge is managing diverse teams with varying skill levels and personalities. As a manager, your job goes beyond technical ability—you’ll also need to keep your team members motivated, engaged, and aligned with the project goals.",
        "Another challenge is balancing your technical tasks with your managerial duties. You’ll need to stay up-to-date with the latest technologies while also maintaining team dynamics, ensuring you’re meeting project deadlines, and effectively allocating resources. That means you’ll have to find ways to tackle all of these without sacrificing either one aspect, which can be difficult.",
        "Time management is also a big challenge, as you’ll need to deliver all projects on time while handling unexpected issues, such as technical setbacks or team conflicts. Additionally, it’s common to deal with budget constraints and limited resources in this industry, which will only add more pressure, forcing you to optimise the available resources effectively.",
        "Most engineering manager jobs involve cross-departmental communication to ensure that the technical teams and non-technical stakeholders are always on the same page. This can often be a complex, delicate process.",
        "All these challenges can make your job extremely stressful, especially if you’re working alone, without the guidance of an expert. That’s where our engineering manager mentoring programme comes in.",
        "To succeed in this role, you’ll need proper engineering manager training from someone who has years of experience working in the same position and has likely dealt with the same issues. An experienced engineering manager mentor can guide you through the ups and downs of the job, allowing you to become more confident in your role.",
        "Our engineering manager training course gives you the opportunity to learn from the best in the industry, so you have all the tools and knowledge you need to advance in your career. If you want to get the most effective tips on how to be an engineering manager, this programme is the ideal solution for you.",
        "Sign up for our engineering manager course today.",
      ]
    },
    {
      title: "What to Expect from Engineering Manager Training Online?",
      description: [
        "Career Navig8r’s online engineering manager mentoring course will give you tailored guidance for developing your leadership and technical management skills.",
        "When you enrol, you can expect personalised support in areas like team handling, project management, and communication strategies, which will be delivered through virtual one-on-one sessions.",
        "Your engineering manager mentor will help you advance from a hands-on technical role to a more strategic leadership position. They’ll give you all the advice you need on balancing your technical and managerial responsibilities.",
        "Through our engineering manager training course, you can also expect guidance on handling real-world challenges, such as resolving team conflicts and managing diverse talent. Additionally, you’ll learn how to align your engineering goals with your company’s objectives.",
        "Our engineering manager course will cover other career development aspects, like improving your decision-making skills and helping you gain confidence in leadership. You’ll also get access to various networking opportunities.",
        "The flexibility of our engineering manager course also means that you can learn at your own pace, without disrupting your daily schedule.",
      ]
    },
    {
      title: "Find an Engineering Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const salesRepresentativeData = {
  mainTitle: "Sales Representative Career Mentor",
  accordionData: [
    {
      title: "About Sales Rep Mentoring",
      description: [
        "Do you want to become a sales representative with proper sales training? Or are you already working in this career and want sales mentoring to help you improve your skills?",
        "Career Navig8r offers a sales rep mentoring programme that can strategically help new and existing sales team members build their skills, develop their confidence, and achieve peak performance.",
        "In our sales course, you’ll get guidance from an experienced sales rep who’ll share their insights and teach you effective sales techniques, creating a supportive learning environment. This hands-on approach will allow you to learn from real-life experiences and apply the best practices directly in your role.",
        "Our sales rep training course will also help you communicate better, set your goals, and improve your problem-solving skills, with the confidence to handle challenges and close deals successfully.",
        "By taking our sales rep course, you’ll grow personally and professionally, and you’ll learn how to do your job more effectively. This will also benefit your organisation in the long run.",
        "Want to excel in your career as a sales representative? Sign up for our sales rep mentoring course today.",
      ]
    },
    {
      title: "The Job Role of a Sales Representative",
      description: [
        "As a sales representative, your main job is to increase your company’s revenue and expand its customer base. You’ll be tasked with generating leads, managing client relationships, and closing sales. For this, you’ll need to identify the customers’ needs and offer tailored solutions to meet them.",
        "You’ll also need to conduct market research and make cold calls, and your job will often involve working both on the road and in-house, using persuasive communication to build strong customer rapport. You should be skilled in negotiation and able to provide product demonstrations, clarify benefits, and address your clients’ concerns. This will ultimately help you convert interest into sales.",
        "Additionally, you’ll be expected to consistently monitor industry trends and keep up with competitive offerings. This will improve your ability to position your company’s products effectively.",
        "Your role will also involve providing post-sales support, ensuring your customers are satisfied and encouraging repeat business. For this, you’ll need a solid mix of interpersonal skills, persistence, and in-depth product knowledge.",
        "To succeed in your job as a sales representative, you’ll need thorough sales training that will make you invaluable to your company. With the right skill set, you’ll achieve your sales targets and be able to build long-term client relationships.",
      ]
    },
    {
      title: "Challenges Faced by Sales Representatives in the Industry",
      description: [
        "While working as a sales representative in this competitive industry, you’re likely to face numerous challenges.",
        "One major hurdle is meeting ever-evolving customer expectations. As customers become more informed, they want tailored solutions and expect immediate, personalised responses. That means you need to stay knowledgeable and agile.",
        "The pressure to meet and exceed your sales targets can also put you under constant stress, particularly in fluctuating markets where consumer demands can be unpredictable. Additionally, you’ll face time-management issues as you juggle your client meetings, follow-ups, and administrative tasks. This can distract you from your core selling activities.",
        "The rise of digital transformation can also add another layer of complexity. New CRM tools and data analytics platforms are being introduced regularly, and you’ll need to continuously adapt to these technologies to remain efficient.",
        "The competition in sales is fiercer than ever, so you’ll often have to differentiate your offerings in saturated markets. You may need to experiment with various sales techniques until you find one that works in your favour.",
        "Balancing these demands while trying to stay motivated and resilient can be tough. You need to be able to adapt and have a proactive mindset to succeed in sales. This is where you could benefit from our sales rep training course.",
        "Our sales rep course allows you to choose a sales mentor who has years of experience in the role and has faced the same challenges in their career. Your sales rep mentor will have the knowledge and wisdom to guide you through the ups and downs of the sales industry.",
        "With their effective sales rep mentoring, you’ll gain confidence in your abilities and learn how to do your job better. Our sales course will help you avoid mistakes and excel in your career.",
        "Ready to steadily advance your career in sales? Sign up for our sales representative training course today.",
      ]
    },
    {
      title: "What to Expect from Sales Representative Training Online?",
      description: [
        "Career Navig8r’s online sales rep mentoring course will give you a flexible learning environment where you can easily enhance your job skills. Once you sign up, you can expect a structured programme that includes video lessons, one-on-one coaching, and interactive role-play sessions.",
        "Your chosen sales mentor will share the best sales tactics and strategies based on their own experiences. They’ll help you work through real-world scenarios, practising objection handling, lead qualification, and closing techniques, in a virtual space that mimics real sales calls.",
        "Additionally, your sales mentor will conduct assessments and provide feedback, helping you track your progress and identify areas for improvement. Through our sales course, you’ll also gain insights into industry trends, digital sales tools, and CRM management, which will equip you to meet modern demands.",
        "Because our sales rep course is online, you can schedule the session at your convenience. This allows you to balance your professional duties while receiving ongoing support and expert sales training.",
        "Overall, our sales rep mentoring will improve your confidence and adaptability. It’ll give you all the job skills you need to excel in this competitive industry and climb the corporate ladder.",
        "Want effective sales mentoring from the best in the industry? Enrol in our sales rep training course today.",

      ]
    },
    {
      title: "Find a Sales Rep Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const creativeDirectorData = {
  mainTitle: "Creative Director Career Mentor",
  accordionData: [
    {
      title: "About Creative Director Mentoring",
      description: [
        "Are you looking to learn how to be a creative director? Or are you already a creative director and want to improve your skills and advance your career?",
        "At Career Navig8r, our creative director mentoring programme offers personalised guidance for aspiring or established creative professionals like you. Through one-on-one sessions, you’ll gain invaluable insights into the creative process and learn to be a leader.",
        "Your chosen creative director mentor will help you refine your artistic vision, develop brand strategies, and be more confident when making important decisions. They’ll help you develop your creativity and give you practical advice on project management, client relations, and team collaboration. ",
        "Overall, our creative director training course will equip you with the tools you need to excel in this high-level creative role. Whether you want to enhance your portfolio, lead a creative team, or handle a career transition, this programme is for you.",
        "Sign up for our creative director course today.",
      ]
    },
    {
      title: "The Job Role of a Creative Director",
      description: [
        "As a creative director, your main job is to oversee the artistic vision and execution of a brand or project. You’ll lead creative teams, including designers and copywriters, and you’ll ensure cohesive and compelling storytelling across various media. You’ll shape the project’s overall aesthetic, from concept development to final production, aligning your creative output with the business objectives and brand identity.",
        "Your role will involve managing multiple projects, setting deadlines, and collaborating with other departments such as marketing and product development to make sure your creative direction supports broader company goals. You’ll also play a key role in client relations, presenting ideas, and justifying your creative decisions to stakeholders.",
        "Beyond artistic skills, you should have strong leadership and communication abilities to inspire and guide your team to be innovative and creative. You should also be able to adapt and stay current with industry trends, as this will help you push boundaries while maintaining brand consistency.",
        "Ultimately, it’s your job to make sure that brands nail the visual and emotional impact of their messaging. ",

      ]
    },
    {
      title: "Challenges Faced by Creative Directors in the Industry",
      description: [
        "When you’re working as a creative director, you’re likely to face numerous challenges in the industry.",
        "One of the key issues you may struggle with is balancing your artistic vision with the company’s goals. You’ll need to align your creative concepts with the brand’s identity and clients’ expectations. This will often require you to compromise on innovation for the sake of marketability.",
        "Budget constraints can also be a hurdle. You’ll have to create high-quality work while staying within the client’s financial limits, which means you’ll often need to stretch your resources to get the desired impact.",
        "Time management is another major challenge, as multiple projects and tight deadlines can add to the pressure and put you at risk of burnout. You’ll also need to coordinate with various teams while maintaining a unified vision, which requires strong leadership and communication skills.",
        "Additionally, you’ll be expected to constantly adapt to new technologies and trends. You’ll also often face constructive criticism, whether it’s from your clients or internal teams. This can be a helpful way to improve, but it can be difficult to hear, and may sometimes conflict with your vision.",
        "To handle these challenges and succeed in your role, you need to be resilient and flexible, and able to balance artistic integrity and practicality. This is where you could benefit from the expert guidance of a creative director mentor.",
        "Our creative director training course gives you the opportunity to get advice from an industry expert, with years of experience in the same job and who has likely faced the same challenges. Your mentor will help you prepare for the ups and downs of the industry, and how to overcome difficult situations, based on their own experiences.",
        "For any challenge you encounter, your creative director mentor can help you find a solution. They’ll give you the tools and knowledge to gain more confidence, allowing you to steadily advance your career.",
        "Want the best tips on how to be a creative director? Sign up for our creative director training course today.",

      ]
    },
    {
      title: "What to Expect from Creative Director Training Online?",
      description: [
        "Career Navig8r’s online creative director mentoring course offers a flexible and personalised learning experience tailored to your career goals. When you sign up, you can expect one-on-one virtual sessions with an experienced industry professional who’ll guide you through the creative and strategic aspects of the role.",
        "You’ll receive feedback on your portfolio, learn how to effectively lead a creative team, and find innovative ways to develop brand strategies. Your creative director mentor will also help you overcome challenges such as balancing creativity with business objectives, managing client relations, and staying ahead of industry trends.",
        "The online format of our creative director training course also means that you can easily schedule your sessions and have access to mentors from diverse industries and backgrounds. You’ll get global perspectives on creative leadership from mentors who’ll use real-life scenarios and projects to hone your problem-solving and decision-making skills.",
        "Overall, our creative director course will help you sharpen your creative abilities, build confidence in your leadership, and ultimately, climb the corporate ladder.",
      ]
    },
    {
      title: "Find a Creative Director Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const schoolPrincipalData = {
  mainTitle: "School Principal Career Mentor",
  accordionData: [
    {
      title: "About School Principal Mentoring",
      description: [
        "Do you want to know how to get a school principal role? Or have you recently been promoted to this role and want to do your job as effectively as possible?",
        "At Career Navig8r, we’ve created a school principal mentoring programme for new or aspiring principals who want to grow as leaders in educational settings. Through structured guidance, your chosen school principal mentor will help you improve your management skills, get better at making decisions, and ultimately become more confident in your role.",
        "Our school principal training course will teach you how to handle complex school challenges, from student engagement to staff relations and resource allocation. It’ll offer you a safe space to discuss strategies, share your experiences, and receive constructive feedback.",
        "By investing in school principal training, you can become a strong, effective leader, positively impacting your school’s learning environment, staff morale, and student success. ",
        "Want to create a more adaptive and successful school system? Learn how to become a school principal by signing up for our programme today.",
      ]
    },
    {
      title: "The Job Role of a School Principal",
      description: [
        "If you want to know how to work as a school principal, you need to understand the wide range of responsibilities you’ll have, from administrative tasks to leadership duties.",
        "In this job, you’ll be responsible for setting and maintaining a positive learning environment, ensuring that academic standards are met, and creating a culture of growth and inclusion. You’ll oversee daily operations, including curriculum development, staff management, and student discipline.",
        "In addition to managing the teachers and support staff, you’ll handle budgeting, resource allocation, and building maintenance. You’ll also be the primary liaison between the school and the external stakeholders, such as parents, school boards, and the community.",
        "As a principal, you’ll need to make many important decisions regarding educational priorities as well as legal and safety regulations. Additionally, you’ll play a key role in student achievement by guiding instructional practices and encouraging professional development for your staff.",
        "This role will put your leadership skills to the test as you’ll be in charge of ensuring that the academic and extracurricular programmes align with your school's mission and vision. Your main job is to help your students, teachers, and the entire institution succeed.",
      ]
    },
    {
      title: "Challenges Faced by School Principals in the Industry",
      description: [
        "When you’re working as a school principal, you’re likely to face numerous challenges, especially in today's educational landscape.",
        "A key challenge is balancing your administrative duties with instructional leadership. You’ll need to manage your school’s operations—such as budgeting, staff recruitment, and compliance with state regulations—while also ensuring that your staff are providing high-quality teaching and learning experiences.",
        "Addressing student diversity and inclusion can also be tricky. Schools are becoming more culturally, economically, and academically diverse, and while this is inherently a step in the right direction, it means you’ll need to come up with strategies that meet the varied needs of your students while promoting equity. Managing your students’ behaviour and safety, especially in this era of increasing mental health concerns and safety threats, is also a pressing issue.",
        "Additionally, you’ll often struggle with limited resources. Budget constraints can make it difficult to invest in essential programmes, technology, and staff development. This makes it all the more challenging d to keep up with the changing educational policies and expectations from various stakeholders, including parents, school boards, and government bodies.",
        "To tackle all these challenges, you need to be adaptable and resilient, with strong decision-making skills. Your school's success is in your hands, so it’s no surprise that a head teacher’s role is highly stressful, especially without the guidance of an expert.",
        "With our school principal training course, you’ll be able to get personalised advice from a mentor who has extensive experience working in the same role and dealing with the same issues. Your school principal mentor can give you all the guidance you need to excel in your career.",
        "You’ll learn everything you need to know about how to get a school principal role, and your mentor can help you find solutions to the challenges you’re facing. They’ve been where you are now, which means they have a comprehensive understanding of the industry and can give you their own insights on how to avoid mistakes.",
        "Want the best tips on how to become a school principal? Sign up for our school principal mentoring course today.",
      ]
    },
    {
      title: "What to Expect from School Principal Training Online?",
      description: [
        "Career Navig8r’s online school principal mentoring is tailored to the needs of new and aspiring principals. When you enrol, you can expect personalised guidance that will help you handle the demanding role of school management.",
        "Through virtual meetings with your school principal mentor, you’ll receive support on issues like staff management, curriculum planning, student engagement, and conflict resolution. Your mentor will also offer feedback to help you improve your decision-making, problem-solving strategies, and policy implementation.",
        "The online format of our course allows you to have real-time discussions with your mentor at a time that suits you, preventing any disruptions in your daily schedule.",
        "If you’re looking to expand your network in the education industry, our online school principal training course sets the stage for exchanging ideas and solutions with peers across various educational settings.",
        "Through our school principal mentoring programme, you’ll get the same level of depth and impact as in-person mentoring, but with the added benefits of flexibility, convenience, and a diverse pool of expert advice.",
        "Ready to learn how to work as a school principal? Sign up now.",
      ]
    },
    {
      title: "Find a School Principal Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const officeManagerData = {
  mainTitle: "Office Manager Career Mentor",
  accordionData: [
    {
      title: "About Office Manager Mentoring",
      description: [
        "Are you aiming to become an office manager? Or have you already achieved this role and want to further improve your skills?",
        "Career Navig8r’s office manager mentoring programme is designed to help you develop your leadership, organisational, and administrative skills to enhance your efficiency. Through one-on-one guidance from your chosen office manager mentor, you can improve your decision-making, communication, and problem-solving abilities.",
        "Our office manager training course also covers topics like time management, delegation, and how to implement effective office systems. By learning from seasoned professionals, you’ll be able to handle the dynamics of working in an office and streamline your daily operations.",
        "Office manager mentoring will help you strengthen your leadership skills and create a more organised, motivated, and productive work environment. By the end, you’ll be better equipped to ensure your office runs smoothly and meets its operational goals.",
        "Want to advance your career in office management? Sign up for our office manager course today.",
      ]
    },
    {
      title: "The Job Role of an Office Manager",
      description: [
        "As an office manager, your job is to maintain smooth daily operations in your company. You’ll be tasked with overseeing administrative functions, ensuring that all office procedures run efficiently and effectively. Your responsibilities will also include organising meetings, managing office supplies, handling correspondence, and ensuring office equipment is functioning properly.",
        "You’ll supervise your support staff, assign tasks, and create a productive workplace by setting up policies that improve workflow and collaboration. Additionally, your role may involve managing budgets and payroll, and coordinating with other departments to streamline communication across your organisation.",
        "Another big part of your job is to solve problems and address any challenges that arise in the office, from employee conflicts to equipment malfunctions. To succeed as an office manager, you’ll need strong organisational and interpersonal skills, as you’ll often be the point of contact between employees and upper management.",
        "Overall, it’s your responsibility to work towards creating an organised, efficient, and positive working atmosphere.",
      ]
    },
    {
      title: "Challenges Faced by Office Managers in the Industry",
      description: [
        "When you’re working as an office manager, you’ll face a range of challenges that will test your organisational, leadership, and problem-solving abilities.",
        "One of the main challenges is managing diverse teams with varying personalities and work styles, which can lead to communication breakdowns or conflicts. You’ll also need to balance workloads and ensure that all tasks are completed on time without overwhelming your employees, which is another constant struggle.",
        "As with most jobs today, there’s evolving technology to keep up with, and you’ll be responsible for overseeing office equipment and ensuring that software and systems are up to date. To add to the pressure, you’ll likely have to account for budget constraints, which means carefully managing your resources without compromising the efficiency of your office.",
        "You’ll also be tasked with adapting to changing business needs and regulatory compliance, ensuring that your office meets industry standards and legal requirements. Plus, your employers will expect you to tackle crises, such as equipment failures or staff shortages, with quick and effective solutions.",
        "To handle these challenges, you’ll need to be flexible, highly organised, and an excellent multitasker. You’ll also need the expert guidance of someone who has been exactly where you are now and has dealt with the same issues.",
        "That’s where our office manager training course can help. Our office manager mentors have extensive knowledge about the industry, which means they’ll be able to help you find suitable solutions to any challenges you’re facing.",
        "Through our office manager mentoring programme, you’ll gain more confidence in your abilities and learn how to tackle your role better. Want to climb the corporate ladder? Sign up for our office manager course today.",
      ]
    },
    {
      title: "What to Expect from Office Manager Training Online?",
      description: [
        "Career Navig8r’s office manager mentoring online will help you enhance your job skills and grow professionally. When you sign up, you can expect personalised guidance from an office manager mentor who’ll draw from their own experiences to give you insights tailored to your specific challenges and goals.",
        "Our office manager course covers various aspects of this job role. It’ll teach you how to be a better leader, manage your time more efficiently, and improve your team’s collaboration abilities. Your chosen mentor will also share the best strategies they’ve used themselves for solving problems, resolving conflicts, and streamlining office operations.",
        "What makes our office manager training course ideal for a working professional is that it can be undertaken from the convenience of a remote setting. Each interactive session is conducted virtually through video calls or chats, allowing you to ask questions and receive feedback in real time. This flexibility will enable you to learn at a pace that’s best suited to your schedule.",
        "Along with expert advice and tips that you can immediately apply in your workplace, you’ll get to tap into excellent networking opportunities that can help take your career to the next level. You can also expect to build your confidence in managing complex tasks, adapting to new technologies, and improving your communication with your staff and upper management.",
        "Enrol in our office manager course today.",
      ]
    },
    {
      title: "Find an Office Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const systemsAdministratorData = {
  mainTitle: "Systems Administrator Career Mentor",
  accordionData: [
    {
      title: "About IT System Administrator Mentoring",
      description: [
        "Have you recently begun your career as an IT systems administrator? Or are you experienced in the role but looking to learn more and improve your skills?",
        "Career Navig8r offers a system administrator mentoring programme that can help enhance your skills and knowledge in the field. Our experienced systems administrator mentors will help you to deepen your understanding of complex IT concepts, offering insights into best practices, troubleshooting, and system optimisation.",
        "Our systems administrator training covers topics including network management, security protocols, and system maintenance. By giving you the opportunity for hands-on experience and involvement in real-world problem-solving scenarios, your mentors will help you develop your critical thinking and technical expertise.",
        "Effective mentoring will accelerate your professional growth and ensure you can reliably and efficiently handle various IT systems, creating a strong foundation for your future career in technology.",
        "Want to take your IT career to the next level? Sign up for our systems administrator course.",
      ]
    },
    {
      title: "The Job Role of an IT Systems Administrator",
      description: [
        "Whether you’re already a systems administrator or aiming to become one, you need to have a clear grasp of what this job role includes.",
        "As an IT systems administrator, you’ll be responsible for managing and maintaining an organisation's IT infrastructure. Your key duties will include installing, configuring, and updating hardware and software systems, as well as monitoring system performance to prevent issues.",
        "You’ll manage servers, networks, and data storage solutions, ensuring security measures are in place to protect against cyber threats and data breaches. You’ll also troubleshoot technical problems, provide support to end-users, and maintain system documentation.",
        "Your job will involve collaborating with other IT professionals to develop and implement technology solutions that meet organisational needs and improve efficiency. You’ll also have to ensure compliance with IT policies and industry regulations, performing regular system audits and backups to safeguard data integrity.",
        "As a systems administrator, you’ll be partly or solely responsible in maintaining the stability and security of your organisation's IT environment, enabling your workplace to operate smoothly and supporting its technological needs.",
        "If you’re still trying to find your footing in this job role, you could benefit from the expert guidance of a systems administrator mentor.",
      ]
    },
    {
      title: "Challenges Faced by IT Systems Administrators in the Industry",
      description: [
        "As a systems administrator, you’re likely to encounter numerous challenges as you climb the ladder in your career.",
        "One significant challenge is keeping up with rapidly evolving technologies. With constant advancements in hardware, software, and security protocols, staying current requires you to continuously learn and adapt.",
        "Cybersecurity is another major concern. You’ll need to protect systems from an ever-increasing variety of cyber threats, including malware, ransomware, and phishing attacks. This may involve implementing and maintaining robust security measures while also ensuring system performance and availability.",
        "You’ll also need to ensure minimal system downtime, which requires planning, maintenance, and quick problem resolution. Any unexpected outages could disrupt business operations, leading to financial losses and reputational damage, so your role in preventing this is key.",
        "Managing and securing large volumes of data is also challenging. As an administrator, you’ll be expected to implement effective data management strategies, including regular backups and disaster recovery plans. This can help to safeguard against data loss and ensure compliance with data protection regulations.",
        "You’ll also need to address diverse technical issues and provide timely assistance to end-users with varying levels of technical expertise. Balancing these support demands with other responsibilities can be demanding, which is why you need proper systems administrator training.",
        "Lastly, budget constraints often limit the resources available for system upgrades and improvements, which means you may be forced to do more with less—something that requires a creative problem-solving mindset. ",
        "Overall, as an IT systems administrator, you’ll be required to expertly and resiliently navigate these challenges to ensure the smooth operation and security of your company’s IT systems. System administrator mentoring can help you develop and improve the skills needed in this role.",

      ]
    },
    {
      title: "Why do IT Systems Administrators Need Mentoring?",
      description: [
        "Regardless of your level of experience in this specific job role, our systems administrator course will help you enhance your professional development and achieve success in the industry.",
        "Our systems administrator training programme will provide you with guidance and support from experienced professionals who have first-hand experience in the industry. This guidance can help you develop a deeper understanding of best practices, advanced troubleshooting techniques, and effective system management strategies.",
        "System administrator mentoring will also accelerate your skill acquisition. With a mentor's assistance, you can quickly grasp new technologies, tools, and methodologies, staying current with industry advancements. This continuous learning is essential in a field where technology keeps evolving.",
        "Additionally, mentoring can help you to work on your critical thinking and problem-solving skills. Your systems administrator mentor will present real-world scenarios and challenges, encouraging you to think strategically and develop solutions. This hands-on experience is invaluable in building your confidence and competence.",
        "Your systems administrator mentor will also provide career development advice, helping you navigate your career path, set achievable goals, and identify opportunities for growth. They’ll offer insights into industry trends and networking opportunities, expanding your professional horizon.",
        "System administrator mentoring can also enhance your job satisfaction and reduce stress. Having a mentor to turn to for advice and support can alleviate the pressures of your job, providing you with a sense of community and shared experience. This support system will help you maintain your motivation and give you a positive outlook in this demanding field.",
        "If you need effective career guidance and support to grow in this industry, sign up for our systems administrator course today.",
      ]
    },
    {
      title: "What to Expect from IT Systems Administrator Training Online?",
      description: [
        "Career Navig8r’s online systems administrator training programme offers a comprehensive and flexible learning experience.",
        "Your chosen systems administrator mentor will cover essential topics such as network management, server configuration, cybersecurity, and data backup solutions. Your training sessions may often include hands-on work and virtual simulations, giving you practical experience in managing real-world IT environments.",
        "Our systems administrator course is self-paced, allowing you to progress according to your schedule. This flexibility is ideal if you’re seeking to enhance your skills without disrupting your current job. Your mentor may also conduct interactive assessments and quizzes to test your understanding and keep you engaged.",
        "You’ll gain access to experts and industry professionals during the course, who can offer their own valuable insights and support. As a mentee, you can expect to improve your skills and enhance your career prospects.",
        "Overall, system administrator mentoring online will equip you with the knowledge and practical skills needed to excel in the fast-paced and evolving field of IT.",

      ]
    },
    {
      title: "Find an IT Systems Administrator Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a new career or keen to excel in your existing job role, you may not know the best path forward. Moreover, if you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You may have numerous questions about the industry you’re breaking into, such as what to expect, which skills are needed, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const operationsAssistantData = {
  mainTitle: "Operations Assistant Career Mentor",
  accordionData: [
    {
      title: "About Operations Assistant Mentoring",
      description: [
        "Want to learn how to get an operations assistant role? Or do you already work in this role and want to improve your skills?",
        "Career Navig8r’s operations assistant mentoring programme is designed to guide entry-level operations assistants like you through the essentials of this role. It’ll give you personalised support, enabling you to efficiently handle your daily tasks, prioritise your responsibilities, and streamline your processes.",
        "Your chosen operations assistant mentor is an experienced professional and can offer insights into best practices for inventory management, scheduling, vendor coordination, and problem-solving. Additionally, they’ll focus on your soft skills like communication, time management, and adaptability.",
        "Our operations assistant training course will help you improve your technical skills and improve your practical knowledge, so you’re more confident and competent in your position. It’ll also help you grow personally and professionally and prepare you for future leadership roles in operations.",
        "Ready to learn how to work as an operations assistant? Sign up for our operations assistant training course today.",
      ]
    },
    {
      title: "The Job Role of an Operations Assistant",
      description: [
        "As an operations assistant, your main job is to ensure the smooth functioning of your organisation’s daily operations. Your role will involve assisting operations managers and other team members with a variety of administrative and logistical tasks.",
        "Your key responsibilities will include managing schedules, coordinating meetings, handling phone and email communication, and maintaining records. You’ll also be tasked with inventory management, tracking supplies, and placing orders when necessary, ensuring that all resources are available and efficiently allocated.",
        "You’ll be expected to support project execution by organising documents, monitoring timelines, and reporting progress and, you may need to help troubleshoot operational issues as they arise, working with different departments to ensure seamless workflows.",
        "To learn how to be an operations assistant, you need to pay strong attention to detail and have effective communication skills, as well as the ability to multitask and meet tight deadlines.",
        "With your administrative and operational duties, you’ll help create an organised, productive environment, and you’ll often serve as a key support system for your entire team.",
      ]
    },
    {
      title: "Challenges Faced by Operations Assistants in the Industry",
      description: [
        "As an operations assistant, you’ll probably encounter a range of challenges in your industry, due to the fast-paced and multifaceted nature of your role.",
        "A key challenge you’ll face is managing your time effectively, as you’ll often juggle multiple responsibilities, from administrative tasks to supporting project execution. You’ll need to prioritise your tasks in order to meet tight deadlines, which can be difficult, especially when there are unexpected issues that require your immediate attention.",
        "Another common hurdle is communication across departments. You’ll regularly coordinate with various teams, and any miscommunication or delay can disrupt workflows and lead to bottlenecks. You’ll also face challenges when you’re managing inventory and resources, where even minor errors can cause shortages or overstock, impacting your business’ overall efficiency.",
        "Adapting to technological tools and systems is something you’ll be expected to do, and this can be a struggle. You’ll need to learn and operate new software for scheduling, tracking, and reporting as and when needed.",
        "To overcome these challenges and keep your workplace’s operations running smoothly, you’ll need excellent problem-solving abilities, which can take years to develop. But you can fast-track many of these skills with the guidance of an experienced operations assistant mentor.",
        "Our operations assistant training course will give you a chance to learn from someone who has worked in the same job and dealt with the same challenges in their career. They can draw from their own experiences to help you understand the ups and downs of the industry.",
        "Through our operations assistant mentoring programme, you’ll learn everything you need to know about how to be an operations assistant, from avoiding common mistakes to improving your productivity.",
        "Want expert tips on how to get an operations assistant role or improve in your existing role? Sign up for our operations assistant training course today.",
      ]
    },
    {
      title: "What to Expect from Operations Assistant Training Online?",
      description: [
        "Career Navig8r’s online operations assistant mentoring can help you build essential skills to succeed in your operations role.",
        "In your virtual operations assistant training sessions, you’ll gain valuable insights into your daily responsibilities, such as task management, scheduling, and communication with different departments.",
        "Your chosen operations assistant mentor will guide you through using essential tools like scheduling software, inventory management systems, and project tracking platforms. They’ll ensure that you get all the hands-on, practical knowledge you need to do the job well.",
        "You can also expect personalised guidance to address specific areas for improvement, such as time management, organisation, and problem-solving skills. Your mentor may also conduct assessments to track your progress and give you feedback.",
        "As these sessions are online, you can schedule them at your convenience, fitting them around your daily routine. You’ll receive real-time feedback on any challenges you encounter in your role, helping you adapt and refine your skills quickly. ",
        "You’ll also get access to a network of other operations professionals, which opens doors to additional support and collaboration opportunities. Overall, this course is ideal if you’re a busy professional looking to enhance your capabilities without sacrificing your work commitments.",
        "Ready to learn how to work as an operations assistant? Enrol in our operations assistant training course.",
      ]
    },
    {
      title: "Find an Operations Assistant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const productionWorkerData = {
  mainTitle: "Production Worker Career Mentor",
  accordionData: [
    {
      title: "About Production Worker Mentoring",
      description: [
        "Are you wondering how to get a production worker role? Or are you already working in this role and want to improve your job skills?",
        "Career Navig8r’s production worker mentoring programme will give you a strategic way to develop your skills and grow as an entry-level or junior employee in a manufacturing setting. You’ll be paired with an experienced mentor who’ll help you hone your technical skills and learn all the important safety protocols. They’ll also share various techniques to achieve maximum efficiency in your role.",
        "Beyond job-related skills, your chosen production worker mentor will also help you build your confidence and teach you how to smoothly integrate yourself into your work environment, especially if you’re new to the industry.",
        "In our personalised production worker training course, you’ll learn how to reduce errors, become more productive, and continuously improve your skills.  By the end, you’ll have gained essential industry knowledge, putting you in line for career advancement opportunities in your workplace.",
        "Ready to learn how to work as a production worker? Sign up for our production worker mentoring course today.",
      ]
    },
    {
      title: "The Job Role of a Production Worker",
      description: [
        "As a production worker, you’re essential to the manufacturing process. In your job, you’ll focus on creating products efficiently and maintaining quality standards.",
        "You’ll work on assembly lines or with machinery, handling tasks such as operating equipment, monitoring production output, and ensuring that components meet specifications. You’ll also need to follow safety guidelines and wear protective equipment to minimise hazards associated with industrial environments.",
        "To learn how to be a production worker, you need to understand the skills the job requires. Attention to detail is essential, as you’ll be inspecting products for defects and reporting malfunctions, and you may need to make minor adjustments to machinery to prevent delays.",
        "Alongside your physical tasks, you’ll often have to keep records of your activities, tracking your completed work and any issues you encounter. Teamwork skills are valuable here, as you’ll frequently collaborate with your supervisors and other team members to meet production targets.",
        "Physical stamina, dexterity, and a commitment to quality are the key aspects required for this role. You’ll be contributing directly to your company’s operational success, helping them meet production schedules and ensuring all customers are satisfied.",
      ]
    },
    {
      title: "Challenges Faced by Production Workers in the Industry",
      description: [
        "In your job as a production worker, you’ll likely face various challenges, often related to physical demands and workplace conditions.",
        "Long shifts, repetitive tasks, and the need for precision can lead you to experience physical strain, fatigue, and injuries. You’ll also need to operate heavy machinery or handle hazardous materials, which means your role has a high risk of accidents, particularly if you don’t strictly follow safety protocols or the equipment isn’t maintained properly.",
        "Another challenge is adapting to technological advancements. Automation and digital tools are constantly changing production processes, so you’ll need to keep updating your skills to stay relevant and efficient. Additionally, high production quotas can be stressful, as you’ll need to meet targets without sacrificing quality.",
        "Inconsistent schedules, fewer opportunities for career advancement, and job security concerns can affect your morale, and this presents another challenge in your role. You may feel undervalued and have limited support for growth or skill development.",
        "To address these challenges, you’ll need proper production worker training, preferably from someone who truly understands the industry from years of experience in the role and dealing with these issues. That’s where our production worker mentoring programme comes in.",
        "Your chosen production worker mentor will give you all the advice and expertise you need on how to work as a production worker. They can help you refine your skills and avoid common mistakes, ultimately advancing your career in the manufacturing industry.",
        "Through our production worker training course, you’ll also gain more confidence in your abilities and learn about the ups and downs of the industry. With the guidance of an expert, you’re likely to see an increase in your motivation to progress in your role. ",
        "Want the best tips on how to get a production worker role? Sign up for our production worker training course today.",
      ]
    },
    {
      title: "What to Expect from Production Worker Training Online?",
      description: [
        "Career Navig8r’s production worker mentoring online will give you a structured, accessible way to gain industry-specific skills. Here, you’ll receive guidance from an experienced mentor on how to be a production worker.",
        "Unlike traditional in-person production worker training, our online programme is flexible, enabling you to attend virtual sessions and learn at your own pace. If you have varying schedules and responsibilities, this format allows you to learn and apply skills without disrupting your daily routine.",
        "With our online production worker training course, you can expect lessons on technical skills, safety protocols, and best practices tailored to manufacturing processes. You’ll also receive one-on-one feedback, participate in interactive exercises, and track your progress over time.",
        "Our platform allows you to communicate in real time, so you can ask questions and talk through issues that arise as you’re dealing with them. Additionally, your production worker mentor can help you to access new networking opportunities, enabling you to advance your career.",
        "Overall, our production worker mentoring will give you a supportive environment where you can gain confidence and proficiency, and be prepared to tackle industry challenges.",
        "Ready to climb the corporate ladder with the support of an experienced mentor? Enrol in our production worker training course.",
      ]
    },
    {
      title: "Find a Production Worker Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in the production industry or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const dataAnalystData = {
  mainTitle: "Data Analyst Career Mentor",
  accordionData: [
    {
      title: "About Data Analyst Mentoring",
      description: [
        "Do you want to learn how to get a data analyst role? Or are you already working in this role and want to upskill?",
        "Career Navig8r's data analyst mentoring programme offers personalised guidance to aspiring and current data analysts. It’s designed to help you sharpen your skill set and excel in data-driven roles.",
        "Our data analyst training course covers essential tools like SQL, Python, Excel, and Tableau, along with data visualisation, statistical analysis, and problem-solving strategies. Through one-on-one sessions with your chosen data analyst mentor, you’ll gain insights into industry best practices and refine your analytical mindset. You’ll also learn how to interpret complex datasets effectively.",
        "Your mentor will share their real-world knowledge and constructive feedback on how to work as a data analyst. They’ll help you confidently tackle data challenges and advance your career in the growing field of data analytics.",
        "Ready to learn how to be a data analyst? Sign up for our data analyst training course today.",
      ]
    },
    {
      title: "The Job Role of a Data Analyst",
      description: [
        "As a data analyst, your main job is to interpret and turn raw data into actionable insights that drive business decisions. You’ll be tasked with collecting, cleaning, and analysing data to help your organisation understand trends, measure performance, and optimise its processes.",
        "Your key responsibilities will include using tools like SQL, Excel, Python, and visualisation software (such as Tableau or Power BI) to perform data extractions, transformations, and visualisations. ",
        "You’ll also often collaborate closely with stakeholders to understand business needs and identify key metrics, as well as design reports or dashboards that communicate your findings in a clear and meaningful way.",
        "To learn how to work as a data analyst, you need to be adept at identifying patterns, anomalies, and opportunities within data sets, which will support informed decision-making. Your role requires technical and analytical skills, as well as a keen attention to detail and problem-solving abilities.",
        "Ultimately, your job will focus on bridging the gap between raw data and strategic action, helping your company stay competitive and innovative.",
      ]
    },
    {
      title: "Challenges Faced by Data Analysts in the Industry",
      description: [
        "In your job as a data analyst, you’ll likely face many challenges in the industry, often stemming from the quality of data, limited resources, and evolving technology.",
        "One major challenge is dealing with inconsistent or incomplete data. Many datasets contain errors and missing values, or they lack standardisation, making it time-consuming to clean and organise them for analysis. You’ll also be tasked with handling large volumes of data, so you may find it difficult to scale your analysis efficiently without compromising on its accuracy.",
        "Additionally, you may struggle to keep up with the evolving tools and techniques used in your role. With new data analytics tools and machine learning frameworks constantly being introduced, you’ll be expected to adapt quickly and continuously upgrade your skill set.",
        "Another hurdle is trying to communicate clearly with non-technical stakeholders. Presenting your complex findings in an accessible way can be difficult, especially when the stakeholders have different priorities or expectations. You’ll need to find a balance between deep analysis and simple reporting so your insights can be impactful and actionable.",
        "All these challenges can be incredibly overwhelming, especially if you don’t have anyone to guide you. If you can identify with any of the challenges mentioned above, you’ll greatly benefit from the expertise of a data analyst mentor who has extensive experience in the same role and has faced the same issues in their career.",
        "In our data analyst training course, you’ll gain all the knowledge you need on how to get a data analyst role and be good at it. Your chosen mentor will guide you through the ups and downs of the industry and help you build your confidence.",
        "With proper data analyst training from an industry expert, you’ll feel more motivated to advance your career. If you want the best tips on how to be a data analyst, sign up for our data analyst training course today.",
      ]
    },
    {
      title: "What to Expect from Data Analyst Training Online?",
      description: [
        "Career Navig8r’s online data analyst mentoring programme gives you a structured, flexible way to develop your practical data skills and gain industry insights. Once you sign up, you’ll undertake a personalised learning experience tailored to your level, whether you’re just starting or you want to refine your current techniques.",
        "In regular virtual sessions, your data analyst mentor will provide hands-on guidance with core tools like SQL, Excel, Python, and data visualisation platforms. Your data analyst training sessions will also include real-world exercises, portfolio projects, and feedback to build your confidence and proficiency.",
        "If applicable to you, our online data analyst training course will help you learn how to get a data analyst role. Your mentor will offer tips to create the best resume along with insights on interview prep and advice on the job market. This will position you as a competitive candidate. They’ll also share valuable context into current industry trends, challenges, and best practices.",
        "The online format of our course also makes it a convenient option if you want to learn whilst on a job. You can schedule the sessions in a way that doesn’t affect your daily routine.",
        "Overall, with a supportive data analyst mentor by your side, you’ll be well-equipped to confidently tackle data challenges and advance in your industry. Enrol in our data analyst training course now to reap the benefits of expert insights.",
      ]
    },
    {
      title: "Find a Data Analyst Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const juniorEngineerData = {
  mainTitle: "Junior Engineer Career Mentor",
  accordionData: [
    {
      title: "About Junior Engineer Mentoring",
      description: [
        "Want to know how to get an engineering role? Or do you have an engineering job but want to learn how to excel and become indispensable to your employer?",
        "Career Navig8r’s junior engineer mentoring programme can guide engineers like you through the early stages of their careers. When you sign up, you’ll be able to choose an experienced professional as your engineering mentor, who’ll give you personalised advice, support, and insights into industry standards and best practices.",
        "Through regular one-on-one engineer training sessions, you’ll gain practical knowledge, develop your problem-solving skills, and build confidence in your abilities. Our engineering training programme also offers networking opportunities, exposing young engineers like you to a broader professional community and potential career paths.",
        "Your engineer mentor will create a supportive environment, empowering you to succeed and grow as a junior engineer. If you’re ready to learn how to be an engineer, sign up for our engineer training course today.",
      ]
    },
    {
      title: "The Job Role of a Junior Engineer",
      description: [
        "As a junior engineer, your main job is to support senior engineers in designing, developing, and implementing projects. Your exact role depends on your speciality—you may work in a specific field such as mechanical, civil, electrical, or software engineering.",
        "If you’ve recently started your career after earning an engineering degree, you’ll most likely start in a role where you’re tasked with drafting plans, running simulations, and gathering data to solve technical problems. You’ll also often be responsible for analysing blueprints, creating reports, and ensuring compliance with industry standards and safety regulations.",
        "Communication is key in this role, as you’ll need to collaborate closely with team members, project managers, and other stakeholders to understand project requirements and report on their progress. You’ll also be tasked with maintaining project documentation, and you may be asked to assist in troubleshooting issues that come up during development or testing phases.",
        "To learn how to work in engineering, you’ll need to focus on building your critical technical skills and industry experience. This will set a strong foundation for advancing your career into senior roles.",
      ]
    },
    {
      title: "Challenges Faced by Junior Engineers in the Industry",
      description: [
        "When you’re working as a junior engineer, you’ll likely face a unique set of challenges as you enter the industry and start to apply your academic knowledge to real-world situations.",
        "One of the most common challenges you’ll encounter is the steep learning curve. You’ll need to quickly familiarise yourself with complex systems, specialised software, and hands-on technical skills that aren’t always covered in school.",
        "You’ll also have to balance multiple projects or deadlines, which can be quite difficult. This is where you’ll need strong organisational and time-management skills that you may still be developing.",
        "Adapting to team dynamics can also be challenging, as you’ll be expected to collaborate effectively with more experienced professionals and communicate technical needs clearly despite your more limited experience.",
        "Adjusting to industry standards and regulatory requirements can add another layer of complexity, as even small errors can have major consequences. In this role, it’s common to experience imposter syndrome, feeling uncertain about your abilities and knowledge.",
        "To overcome these challenges and effectively learn how to be an engineer, you’ll need to be resilient. Many junior engineers could greatly benefit from ongoing guidance from an engineering mentor who’s experienced in the same role and has dealt with the same issues in their career.",
        "In our engineer training course, you’ll be able to learn from professionals in the industry. Your chosen engineer mentor will provide personalised advice and encourage you to keep growing, which will help you build confidence and competence in your field.",
        "With proper engineering training, you’ll gain a better understanding of how the industry works and how you can avoid making mistakes that could affect your career. Our engineer mentoring course will help you improve your job skills and steadily advance in your role.",
        "Do you want the best tips on how to get an engineering role or how to work in engineering? Sign up for our engineer training course today.",
      ]
    },
    {
      title: "What to Expect from Junior Engineering Training Online?",
      description: [
        "Career Navig8r’s junior engineer mentoring course online will give you a structured yet flexible way to gain valuable insights and skills from experienced mentors.",
        "Through our virtual sessions, you’ll receive personalised guidance on technical topics, industry best practices, and career development strategies. Your engineering mentor will help you identify areas for improvement and track your progress. They’ll also offer constructive feedback to help improve your confidence and competence.",
        "Our digital engineer training course gives you access to a wide network of professionals, exposing you to diverse perspectives across various engineering disciplines. You’ll also get to work on real-world scenarios, which will help you deepen your understanding and apply what you learn.",
        "Flexibility is another major advantage of our course on how to be an engineer. You can schedule sessions that fit your availability, allowing you to balance your work or study commitments.",
        "With our online engineering training, you’ll learn how to work in engineering effectively, so you can advance and succeed in your career. Enrol today to unlock all the amazing benefits of expert insights and advice.",
      ]
    },
    {
      title: "Find a Junior Engineer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const marketingCoordinatorData = {
  mainTitle: "Marketing Coordinator Career Mentor",
  accordionData: [
    {
      title: "About Marketing Coordinator Mentoring",
      description: [

        "Are you aiming to become a marketing coordinator? Or have you recently taken on this role and want to learn how to excel in it?",
        "Career Navig8r’s marketing coordinator mentoring programme is designed to support you in your professional growth. You’ll be paired with an experienced mentor who’ll offer guidance on essential skills like campaign planning, content creation, data analysis, and digital strategy.",
        "Our marketing coordinator training course offers an environment where you can openly communicate and learn from the best. Your marketing coordinator mentor will also help you tackle challenges you may face in your role, develop your confidence, and implement industry trends into your own work.",
        "Through regular sessions, you’ll receive advice on projects you’re undertaking, gain insights into career advancement, and build a strong foundation for your future in marketing. Our marketing coordinator course will help improve your skills and prepare you for more leadership roles.",
        "Want to climb the corporate ladder in your industry? Sign up for our marketing coordinator training course today.",
      ]
    },
    {
      title: "The Job Role of a Marketing Coordinator",
      description: [
        "As a marketing coordinator, your job is to support the execution of your company’s marketing strategies and campaigns. You’re responsible for coordinating various marketing activities, from social media management and content creation to event planning and brand promotion.",
        "You’ll work closely with the marketing team to ensure that your company’s campaigns align with its business goals. This may involve overseeing timelines, budgets, and creative assets to keep projects running smoothly.",
        "Aside from these organisational tasks, you’ll be tasked with gathering data from campaigns, tracking metrics like engagement and conversions, and preparing reports to evaluate the campaigns’ effectiveness. You’ll also need to maintain relationships with vendors, partners, and internal departments to facilitate smooth communication across all marketing channels.",
        "To be an effective marketing coordinator, you should be detail-oriented, adaptable, and comfortable juggling multiple projects in a fast-paced environment. You should also have a strong interest in marketing, excellent communication skills, and a passion for helping brands reach their target audience successfully.",
      ]
    },
    {
      title: "Challenges Faced by Marketing Coordinators in the Industry",
      description: [

        "In your job as a marketing coordinator, you’re likely to encounter a variety of challenges as you work to keep campaigns organised and effective in the industry.",
        "One major challenge you’ll face is managing multiple projects simultaneously. With campaigns often overlapping, you’ll need to coordinate deadlines, resources, and stakeholders, so you’ll need to have great organisational skills.",
        "Marketing trends and digital platforms continue to evolve, and it’s also essential for you to continuously update your skills and adapt your strategies to stay competitive.",
        "Another common challenge is balancing your creativity with achieving data-driven results. You’ll have to ensure your campaigns resonate with audiences while also meeting measurable objectives, which involves analysing metrics and adapting your content for maximum impact. ",
        "Limited budgets and resources can also constrain creative ideas, which means you’ll often need to find cost-effective solutions without compromising quality.",
        "Finally, you’ll frequently need to collaborate across departments and with external vendors. For this, you’ll need strong communication skills so your project can flow smoothly.",
        "To tackle these complexities while maintaining project momentum, you need to be resilient and flexible in this role. If you’re just starting out or have identified areas that you could improve, you’ll benefit from the expertise of someone who has held the same role and faced the same challenges. That’s where our marketing coordinator training course comes in.",
        "Your chosen marketing coordinator mentor can guide you through the ups and downs of the industry. They can teach you how to avoid mistakes and help you become more confident in your job.",
        "Ready to advance in your career? Sign up for our marketing coordinator course today.",
      ]
    },
    {
      title: "What to Expect from Marketing Coordinator Training Online?",
      description: [
        "Career Navig8r’s online marketing coordinator mentoring course will give you a flexible, tailored approach to advancing your career in marketing. When you sign up, you’ll have access to guidance from an experienced mentor who’ll provide insights on managing campaigns, honing your communication skills, and understanding digital marketing trends.",
        "You can discuss the projects you’re working on, receive feedback, and learn best practices to enhance your performance and strategic thinking. Your marketing coordinator mentor will also draw from their own experiences to help you address specific challenges in the industry.",
        "In our marketing coordinator course, you’ll gain practical skills and expert career advice, helping you set goals and explore paths for advancement. Our course also opens the door to numerous networking opportunities that can benefit you.",
        "Our online marketing coordinator mentoring course is typically conducted through virtual meetings, allowing you to learn from anywhere at your own pace. That means you can schedule your sessions in a way that doesn’t disrupt your daily routine.",
        "Looking to grow professionally, gain confidence, and stay current in a fast-changing industry? Enrol in our marketing coordinator training course today.",
      ]
    },
    {
      title: "Find a Marketing Coordinator Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const engineerData = {
  mainTitle: "Engineer Career Mentor",
  accordionData: [
    {
      title: "About Engineer Mentoring",
      description: [
        "Are you looking to learn how to get an engineering role? Or have you just started working in this job and want to learn how to improve your skills?",
        "Career Navig8r offers an engineer mentoring programme that connects you with an experienced engineer. It’s a structured solution aimed at providing you with expert knowledge, skills, and industry insights to tackle challenges and achieve your career goals.",
        "Your chosen engineering mentor will offer guidance on technical expertise and professional development while encouraging your innovative and problem-solving skills. This relationship allows you to learn from a pro, tap into networking opportunities, and build your confidence.",
        "Through regular interactions with your engineer mentor, you’ll gain valuable advice on certifications, project management, and leadership. These engineering training sessions will help advance your career and teach you how to become a better asset to the engineering community.",
        "Ready to learn how to work in engineering? Sign up for our engineer training course today.",
      ]
    },
    {
      title: "The Job Role of an Engineer",
      description: [
        "To learn how to be an engineer, you’ll need to start with a good understanding of what this role entails.",
        "Engineers are problem-solvers who apply scientific principles, mathematics, and creativity to design and develop a broad range of products, from structures to technologies.",
        "Your role will vary depending on which industry you work in (such as civil, mechanical, electrical, software, or chemical engineering). However, your main responsibilities will remain consistent. You’ll be tasked with analysing requirements, as well as identifying challenges and devising innovative solutions to improve a product’s functionality, safety, and efficiency.",
        "Your key tasks will often include drafting plans, running simulations, conducting experiments, and collaborating with your team to bring projects to life. You’ll be expected to follow strict standards and regulations to ensure your designs are cost-effective and reliable. ",
        "Beyond your technical expertise, you’ll also manage timelines and resources, ensuring that projects are completed within the allocated budget, and you may be required to communicate complex ideas to stakeholders. As an engineer today, your projects may involve addressing global challenges like climate change and automation, as well as infrastructure development.",
        "Overall, your job is all about driving progress, which makes you indispensable to advancing industries.",
      ]
    },
    {
      title: "Challenges Faced by Engineers in the Industry",
      description: [
        "You’re likely to encounter various challenges in your job as an engineer, because of the complexity and evolving nature of your work.",
        "One significant challenge is staying updated with rapidly advancing technologies. Your role may require you to constantly learn new tools and methods, which can be overwhelming, particularly when you need to meet tight project deadlines.",
        "No matter where you work, you’ll probably also be faced with resource constraints, including limited budgets and materials, which will often require you to innovate without compromising quality. You’ll be expected to balance these constraints while meeting standards for performance, sustainability, and safety, which can be daunting.",
        "Time pressures are another common challenge for engineers, and you may struggle to manage strict deadlines and overlapping projects. To overcome this challenge, you’ll need to have excellent multitasking skills and be able to prioritise your projects as needed.",
        "Communication is another hurdle, as you’ll be tasked with explaining intricate technical concepts to non-technical stakeholders, ensuring everyone is aligned on the project goals. Global challenges such as climate change and energy demands will also push you to find sustainable and eco-friendly solutions.",
        "To tackle these obstacles, you’ll benefit from the creativity and expertise of someone who has more experience in this industry. That’s where our engineer mentoring course comes in.",
        "With proper engineering training, you can improve your skills and avoid making common mistakes in your job. Your chosen engineering mentor will teach you all about how to overcome the challenges you’ll face in this industry. They’ll help you learn how to get an engineering role and how to excel in it.",
        "Want the best tips on how to work in engineering? Sign up for our engineer training course today.",
      ]
    },
    {
      title: "What to Expect from Engineer Training Online?",
      description: [
        "Career Navig8r’s online engineer mentoring programme offers a flexible and accessible way to gain valuable guidance from an experienced engineering mentor. When you sign up, you’ll receive personalised mentoring tailored to your career goals, whether you're a student, recent graduate, or mid-career professional.",
        "Your engineering training sessions will cover a wide range of topics, including technical skills, project management, career planning, and industry trends. Your engineer mentor will offer constructive feedback on your work, help you refine your problem-solving abilities, and share insights into overcoming common challenges in the field.",
        "As our engineer training course is conducted virtually, it allows you to schedule sessions around your work or study from anywhere in the world, giving you access to mentors from diverse backgrounds and industries. This means you can learn how to be an engineer without disrupting your daily routine.",
        "Our online engineering mentoring course also gives you access to networking opportunities, helping you build valuable connections within the engineering community. Providing consistent support and practical advice, this engineering training programme will give you the tools you need to excel in your career and stay ahead in a competitive industry.",
        "Enrol in our engineer training course today.",
      ]
    },
    {
      title: "Find an Engineer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const regionalSalesManagerData = {
  mainTitle: "Regional Sales Manager Career Mentor",
  accordionData: [
    {
      title: "About Regional Sales Manager Mentoring",
      description: [
        "Are you aiming to become a regional sales manager? Or have you already secured this role and want to learn how to be better at your job?",
        "Career Navig8r offers a regional sales manager mentoring programme tailored to improve your leadership, strategy, and performance within your regional sales team. ",
        "You’ll receive one-on-one guidance from an experienced regional sales manager mentor, helping you to refine your skills in driving revenue and building client relationships. They’ll also teach you how to lead your team effectively.",
        "Our regional sales manager training course focuses on problem-solving and market analysis, providing personalised coaching to address your unique challenges. Your chosen mentor will share their insights and proven strategies, giving you the tools and confidence you need to exceed your targets in a collaborative environment.",
        "Our regional sales manager mentoring approach will help you strengthen your overall sales network and become more successful at your job. ",
        "Ready to take your sales career to the next level? Sign up for our regional sales manager course today.",
      ]
    },
    {
      title: "The Job Role of a Regional Sales Manager",
      description: [
        "As a regional sales manager, your main job is to improve sales performance and revenue growth across your designated geographical area. Your responsibilities will include developing strategic sales plans and setting ambitious yet achievable targets. You’ll also be tasked with overseeing the execution of these plans by your sales team.",
        "You’ll be the bridge between your company's leadership and regional sales team, ensuring your sales efforts align with the overall business objectives. Your key duties will involve analysing market trends to identify growth opportunities and nurturing strong relationships with your clients. You’ll also be responsible for recruiting, training, and supporting fellow sales representatives to ensure your team is performing well.",
        "Other important aspects of your role will include monitoring sales metrics, preparing reports, and presenting insights to your senior management. When necessary, you’ll need to address challenges such as competition or market shifts and adapt your strategies as needed.",
        "To succeed in this role, you should be a strong leader with excellent communication skills, and be able to motivate your team to consistently exceed expectations. You’ll also need to build and maintain long-term customer relationships.",
      ]
    },
    {
      title: "Challenges Faced by Regional Sales Managers in the Industry",
      description: [
        "When you’re working as a regional sales manager, you’re likely to face a range of challenges as you strive to meet your organisational goals while managing a diverse team in your region.",
        "One key challenge you’ll encounter is adapting your strategies to stay ahead of the competitors, often in quickly changing market environments.",
        "Managing a geographically dispersed sales team is another challenge that you may have to face, depending on your role. To ensure your team is working effectively, you’ll need to coordinate your efforts and maintain consistent communication across multiple locations. ",
        "You’ll also face pressure to meet ambitious sales targets, which can be particularly complex during economic downturns or if you’re working in regions with limited growth opportunities.",
        "Balancing your client relationships with internal team responsibilities is something that can be difficult to get right. You’ll also be expected to stay informed and adaptable to continue to meet fluctuating customer preferences and industry trends. ",
        "Finally, managing your resources effectively while ensuring your team’s actions are aligned with organisational goals can be a delicate task.",
        "All these challenges require you to think strategically, adapt to different situations, and lead your team confidently. These skills often take time to develop, especially if you don’t have an expert to guide you.",
        "With our regional sales manager course, you’ll have the opportunity to learn from someone with years of experience in the same job role, who has faced (and overcome) the same challenges. Your chosen regional sales manager mentor will teach you all about the role and the obstacles that come with it.",
        "Through our regional sales manager training, you’ll be able to improve your skills and handle your team better, reducing the likelihood of making mistakes. You’ll also learn more effective ways to strategise and achieve your branch’s sales targets.",
        "Want to reap the benefits of regional sales manager mentoring? Sign up for our regional sales manager training course today.",
      ]
    },
    {
      title: "What to Expect from Regional Sales Manager Training Online?",
      description: [
        "Career Navig8r’s online regional sales manager mentoring course will give you a flexible and effective way to develop your leadership skills and refine your strategies. When you sign up, you’ll receive personalised guidance tailored to your specific goals and the unique dynamics of your sales region.",
        "Our regional sales manager training course will typically include virtual one-on-one sessions with an experienced mentor, who’ll share insights on improving your sales performance, including building strong client relationships and managing your team effectively. These sessions will also cover topics like market analysis, strategic planning, and techniques for exceeding your sales targets.",
        "Your regional sales manager mentor will also work with you to address real-world scenarios, helping you come up with actionable solutions and make decisions more confidently",
        "You can schedule your online regional sales manager training sessions at your convenience, so you can learn and work without disrupting your daily routine.",
        "Our regional sales manager course will help you grow personally and professionally. You’ll gain the skills and confidence to improve your performance and inspire your team to drive regional success. You’ll gain access to a wider network, which can benefit your future in this career.",
        "Enrol in our regional sales manager mentoring programme today.",
      ]
    },
    {
      title: "Find a Regional Sales Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const rAndDManagerData = {
  mainTitle: "R&D Manager Career Mentor",
  accordionData: [
    {
      title: "About R&D Manager Mentoring",
      description: [
        "Looking to learn how to be an R&D manager? Or have you recently started your career in this role and need research and development manager training to improve your skills?",
        "Career Navig8r’s R&D manager mentoring programme is designed to guide research and development leaders like you to achieve their full potential. You’ll learn to improve your innovative thinking and decision-making skills, essential for effectively leading a team.",
        "Our research and development manager training course will give you insights into balancing your technical expertise and leadership demands while encouraging your team to collaborate and tap into their creativity.",
        "Your chosen research and development manager mentor will share their industry knowledge and best practices, gained from their personal experiences, to also help you tackle challenges and stay ahead of trends.",
        "Our R&D manager training course provides structured guidance, allowing you to drive impactful results and improve your products’ development processes. As you participate in this course, you’ll learn to align your innovative ideas with your organisation’s goals.",
        "Ready to learn how to be a research and development manager? Sign up for our research and development manager mentoring course today.",
      ]
    },
    {
      title: "The Job Role of an R&D Manager",
      description: [
        "As an R&D manager, your job is to drive innovation and give your organisation a competitive edge.",
        "You’re responsible for overseeing research initiatives and guiding the development of new products or technologies. Your role will involve strategic planning, managing R&D budgets, and coordinating cross-functional teams to ensure that the projects align with the business goals.",
        "You’ll also be expected to keep track of emerging trends, including identifying opportunities for innovation, as well as managing intellectual property to protect your organisation's advancements. You’ll need to encourage the scientists, engineers, and marketing professionals working with your business to partner collaboratively, translating your research into practical solutions that meet market demands.",
        "Leadership is an essential part of your role, as you’ll need to inspire your team to work creatively within project timelines and effectively address any challenges that come your way. You’ll also be tasked with ensuring your projects remain compliant with industry standards and regulations.",
        "If you want to learn how to be an R&D manager, you’ll need to be able to balance your technical expertise with your business acumen, so you can fuel your organisation’s growth.",
      ]
    },
    {
      title: "Challenges Faced by R&D Managers in the Industry",
      description: [
        "When you’re working as an R&D manager, you’re likely to encounter some unique challenges that will require you to think strategically and adapt to different situations.",
        "One of the main challenges you’ll face is managing limited budgets while ensuring your company is keeping up with competitors and market trends. This will involve learning to prioritise projects with the highest potential return on investment.",
        "You’ll also be expected to keep up with rapid technological advancements and stay informed about emerging trends to ensure your projects remain relevant and competitive. ",
        "Balancing creativity with compliance is equally demanding, as you’ll need to ensure that your innovations comply with strict regulatory requirements and industry standards.",
        "Team management is another challenging aspect of your job, especially when you're managing a team with a mix of skills and backgrounds. You’ll be responsible for encouraging your team to collaborate while mitigating conflicts. Changes in the market and unpredictable customer needs can also throw off your plans and schedules.",
        "All of these challenges can make your job more difficult, and you’ll learn to tackle them effectively with the expertise of someone who has years of experience in this role. That’s where our research and development manager course comes in.",
        "In our R&D manager training course, you’ll get to learn how to be an R&D manager from an expert in the industry. Your R&D manager mentor can teach you how to overcome common challenges in the role, making you more confident in handling your duties.",
        "Want expert career advice on how to be a research and development manager? Sign up for our research and development manager course today.",
      ]
    },
    {
      title: "What to Expect from R&D Manager Training Online?",
      description: [
        "Career Navig8r’s online R&D manager mentoring course offers a flexible, personalised solution to improve your leadership and innovation skills.",
        "Through virtual research and development manager training sessions, you’ll receive guidance from experienced industry leaders. Your research and development manager mentor will teach you how to optimise your processes and encourage creativity in your team.",
        "Our research and development manager training course is tailored to address your individual goals, such as improving your strategic planning or managing diverse teams. You can also ask your mentor for help in staying ahead of technological trends.",
        "Your R&D manager mentor will often share actionable advice on how to handle the demands of your role, like balancing your technical expertise with your leadership responsibilities. They’ll also teach you how to manage R&D budgets and align your innovation strategies with business objectives.",
        "When you enrol in our research and development manager course, you’ll gain exposure to your mentor’s best practices, tools, and techniques that can help you handle various complexities in your role. Our online research and development manager mentoring also gives you the opportunity to network, connecting you with a broader professional community.",
        "The virtual format of our R&D manager course allows you to schedule sessions at your convenience. This means your R&D manager training sessions won’t disrupt your daily routine.",
        "By the end of our research and development manager mentoring programme, you’ll be better equipped to lead R&D initiatives and overcome challenges in your role. Want guidance on how to be an R&D manager? Sign up for our R&D manager course today.",
      ]
    },
    {
      title: "Find an R&D Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const medicalAssistantData = {
  mainTitle: "Medical Assistant Career Mentor",
  accordionData: [
    {
      title: "About Medical Assistant Mentoring",
      description: [
        "Wondering how to get a medical assistant role? Or have you recently landed the role and are looking to improve your skills?",
        "Career Navig8r’s medical assistant mentoring programme is designed to guide and support medical assistants in achieving their career goals. You’ll connect with a professional who’ll tap into their own experience to share their valuable insights, guidance, and practical advice.",
        "Your chosen medical assistant mentor will help you improve your clinical skills and build confidence in your abilities. If you haven’t yet secured your desired role, they’ll also offer career development tips, such as building your resume and preparing for interviews.",
        "In our medical assistant training course, you’ll receive all the support you need to learn, grow, and succeed in the fast-paced healthcare environment. By sharing their real-world experiences, your medical assistant mentor will inspire you to excel in your role and make meaningful contributions to your industry.",
        "Want to learn how to be a medical assistant? Sign up for our medical assistant training course today.",
      ]
    },
    {
      title: "The Job Role of a Medical Assistant",
      description: [
        "As a medical assistant, you’ll act as the bridge between patients and healthcare providers. Your responsibilities may range from clinical and administrative tasks to direct patient communication to ensure your medical facility can operate smoothly.",
        "On the clinical side, you may be tasked with taking vital signs, preparing patients for examinations, assisting with minor procedures, administering medications, and collecting laboratory samples. Administratively, you’ll handle scheduling, maintain patient records, process insurance forms, and manage billing tasks.",
        "There are numerous healthcare settings that you may work in, and you’ll need to adapt to the unique demands of your environment. You’ll also often be the first point of contact for patients, which means you should have excellent communication and interpersonal skills to create a welcoming and compassionate experience.",
        "Are you keen to learn how to work as a medical assistant? Keep in mind that this role requires you to be a capable multitasker, with good attention to detail and a strong understanding of medical terminology and protocols. ",
        "As the healthcare sector continues to evolve, you need to consistently support your patients and providers in delivering quality care.",
      ]
    },
    {
      title: "Challenges Faced by Medical Assistants in the Industry",
      description: [
        "When you’re working as a medical assistant, you’ll likely face numerous challenges in your role.",
        "If you’re new to the role, you may struggle at first to manage heavy workloads, as you’ll often juggle multiple responsibilities like patient care, administrative tasks, and assisting providers. ",
        "Adapting to ever-changing healthcare regulations and technologies is another challenge you’ll probably face. You’ll need to stay updated on electronic health records (EHR) systems and best practices, and ensure your protocols remain compliant with local and national regulations, to maintain your efficiency and accuracy.",
        "You’ll also encounter emotional challenges, as you’ll often work closely with patients who may be anxious, upset, or in pain. This is where you’ll need exceptional empathy and resilience.",
        "Working in a role that’s viewed as entry-level in the healthcare industry, you may struggle with career growth opportunities. Your superiors might fail to give you the recognition you expect for your efforts, which can impact your morale.",
        "Despite these challenges, you’ll still be integral to the healthcare system, which is why you need to be adaptable and dedicated in your daily work. This is where you could benefit from the expertise of a medical assistant mentor.",
        "With our medical assistant training course, you’ll have the opportunity to learn how to be a medical assistant from someone who has first-hand experience in the role. Your chosen mentor will provide all the knowledge and guidance you need to handle your duties effectively.",
        "Want the best tips on how to get a medical assistant role and excel at it? Sign up for our medical assistant mentoring programme.",
      ]
    },
    {
      title: "What to Expect from Medical Assistant Training Online?",
      description: [
        "Career Navig8r’s online medical assistant mentoring course will give you convenient and personalised support to excel in your career. It’ll teach you everything you need to know about how to work as a medical assistant.",
        "When you sign up, you’ll receive one-on-one virtual sessions led by your chosen medical assistant mentor, who’ll offer tailored guidance based on their own experience. These sessions will cover practical topics, from improving your clinical skills to carrying out administrative tasks and preparing for certifications or job interviews.",
        "Our medical assistant training course is conducted through video calls, allowing you to schedule the sessions to suit your busy lifestyle. Your medical assistant mentor will assess your learning to track your progress, as well as give you access to valuable networking opportunities.",
        "If you’re still job hunting, your mentor can offer career advice, including tips for building your resume and insights into the job market. Your mentor may also share their personal experiences and solutions for common workplace challenges, helping you build your confidence and become more adaptable.",
        "Overall, our online medical assistant mentoring course will provide you with a supportive environment where you can grow, stay motivated, and gain the tools you need to thrive in the medical assistant field.",
        "To learn how to be a medical assistant and effectively tackle the challenges that come with this job, enrol in our medical assistant training course today.",
      ]
    },
    {
      title: "Find a Medical Assistant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const researchAssistantData = {
  mainTitle: "Research Assistant Career Mentor",
  accordionData: [
    {
      title: "About Research Assistant Mentoring",
      description: [
        "Looking to learn how to get a research assistant role? Or are you already working in this role and want to improve your skills?",
        "Career Navig8r’s research assistant mentoring programme will help you nurture your skills and expertise through guidance and knowledge sharing. Your chosen research assistant mentor will give you hands-on training, helping you get to grips with complex methodologies and understand data analysis. They’ll also help refine your academic writing.",
        "Beyond your technical skills, our research assistant training course will improve your critical thinking and teach you ethical research practices. It’ll also help you communicate more effectively and align your work with broader project goals while encouraging your personal growth.",
        "Your research assistant mentor will establish a supportive environment, allowing you to build your confidence and strengthen your research quality.",
        "Want to learn how to work as a research assistant and achieve professional success? Sign up for our research assistant training course today.",
      ]
    },
    {
      title: "The Job Role of a Research Assistant",
      description: [
        "As a research assistant, your job is to support academic, scientific, or professional research projects. ",
        "Your responsibilities will include collecting and analysing data, conducting literature reviews, preparing reports, and maintaining accurate records. You’ll also assist researchers by performing experiments and managing databases, as well as using statistical tools to interpret findings.",
        "You’ll often work closely with the lead researcher or a team, and you’ll be expected to contribute to the project’s design, execution, and documentation phases. You’ll be tasked with ensuring that data is accurate and reliable, and managing resources while also following ethical research practices.",
        "Alongside your technical skills, you should also have excellent organisational, problem-solving, and communication abilities. You should be capable of multitasking and meeting deadlines, as well as being able to adapt to the needs of the research process.",
        "Once you’re clear on exactly how to be a research assistant, your knowledge and experience will open doors to further academic or professional growth.",
      ]
    },
    {
      title: "Challenges Faced by Research Assistants in the Industry",
      description: [
        "In your job as a research assistant, you’ll often face a variety of challenges that will test your skills and resilience.",
        "One of the main challenges is learning to manage your time effectively, as you’ll be expected to balance multiple tasks such as data collection, analysis, and reporting under tight deadlines. You may also have limited access to resources, including funding and advanced equipment, which can further complicate your job and make it difficult to conduct thorough research.",
        "Another common challenge is handling the pressure to produce accurate and meaningful results, which requires you to have meticulous attention to detail. You’ll need to learn complex methodologies and keep up with advancements in your field, which isn’t easy, especially if you’re new to the role.",
        "You may also face communication barriers with your supervisors or team members, impacting your ability to effectively collaborate on the projects’ objectives. You might also face ethical challenges that call for careful decisions, ensuring that your projects adhere to strict guidelines.",
        "Facing these challenges can be stressful and exhausting, especially if you’re new to the role and don’t have the guidance of an expert. That’s where our research assistant mentoring programme comes in.",
        "In our research assistant training course, you’ll have the opportunity to receive career advice from someone who has years of experience in this role. Your chosen research assistant mentor will guide you in overcoming the common challenges you’ll face on the job, teaching you all you need to know about how to work as a research assistant.",
        "If you want the best tips on how to get a research assistant role and advance your career, sign up for our research assistant training course.",
      ]
    },
    {
      title: "What to Expect from Research Assistant Training Online?",
      description: [
        "Career Navig8r’s online research assistant mentoring programme will give you all the guidance you need on how to be a research assistant effectively.",
        "In your virtual research assistant training sessions, you’ll receive personalised feedback and practical advice on research methodologies, data analysis, and report writing. Your research assistant mentor will support you as you learn complex topics and refine your critical thinking skills, helping you master tools like statistical software or citation management systems.",
        "During the sessions, your mentor will cover a range of topics, from ethical research practices to problem-solving strategies, and will share their own tips for managing deadlines effectively. They’ll conduct assessments to track your progress and provide feedback to help you improve your skills. Your sessions will focus on building your confidence and improving your communication abilities, helping you to work towards your career goals.",
        "Our research assistant training course is delivered in an online format, which allows you the flexibility to learn at your own pace and access expertise from mentors worldwide. You’ll also have access to a wide range of networking opportunities.",
        "Overall, our virtual research assistant mentoring will give you the tools and knowledge you need to excel in your role as a research assistant. Enrol in our research assistant training course today.",
      ]
    },
    {
      title: "Find a Research Assistant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const environmentAndAgricultureData = {
  mainTitle: "Environment and Agriculture Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Environment and Agriculture Mentoring",
      description: [
        "A career in an environmental or agricultural role has the potential to be fast-paced and fulfilling, but there’s a lot to learn along the way. An environment and agriculture career mentor can help you translate your existing skills in these fields into a fulfilling career.",
        "At Career Navig8r, we offer job-specific environment and agriculture mentorship programmes. Our experienced mentors offer guidance to those looking to level up in the industry, sharing their knowledge, and helping them develop the necessary skills for their specific job roles.",
        "In agriculture, this can range from teaching sustainable farming practices to understanding modern agricultural technologies. For environmental roles, career mentoring may include training in conservation techniques, environmental regulations, and eco-friendly business practices.",
        "Agriculture mentoring boasts a plethora of benefits. You’ll gain practical, hands-on experience that complements your academic learning, and receive personalised advice and feedback that helps you accelerate your career growth. ",
        "These effective mentorship programmes typically include online one-on-one training sessions, providing opportunities for you to observe and prepare for real-world projects. They also help you build a professional network, improve your problem-solving skills, and gain confidence in your abilities.",
        "Overall, job-specific mentoring in the environment and agriculture sectors helps ensure that the next generation of professionals is well-prepared to tackle the challenges of their industries.",
        "Thinking of getting an agriculture mentor? Sign up for our environmental and agricultural mentorship programme and find the right mentor for your goals.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Environment and Agriculture Professionals",
      description: [
        "As a budding professional in the environment and agriculture sector, you’re likely to face a number of significant challenges.",
        "One major challenge is the rapidly changing climate, which affects weather patterns, crop yields, and natural resources. As a new professional, you’ll have to quickly learn to adapt to these changes and find innovative solutions to mitigate their impacts.",
        "Another challenge is the need for sustainable practices. With growing awareness of environmental issues, there is increased pressure to adopt eco-friendly methods. This often requires additional training and investment in new technologies, which can be daunting if you’re just starting your career.",
        "Access to funding and resources is also a common hurdle. As a young professional, you might struggle to secure the necessary financial support for your projects or research, limiting your ability to implement effective solutions. Getting to grips with complex regulations and policies can be overwhelming, requiring you to have an expert understanding of legal and compliance issues.",
        "Additionally, networking and mentorship opportunities can be limited for newcomers. Building a professional network is essential for career growth, but you may struggle to find the right agriculture mentors and make the best industry connections.",
        "Finally, you’ll have to learn how to balance economic viability with environmental responsibility. As an emerging professional, it’s your job to find ways to make sustainable practices profitable to ensure long-term success.",
        "To properly tackle these challenges, you’ll greatly benefit from the support and guidance of an experienced professional in the industry. That’s where our agricultural mentorship programme comes in.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Environment and Agriculture?",
      description: [
        "A career mentor in the environment and agriculture sectors can be beneficial for everyone, at every level, who wants to grow or push themselves in their career.",
        "If you’re a student or recent graduate entering these fields, you can get a whole lot out of agriculture mentorship. You’ll need guidance to use your academic knowledge for practical application, and a mentor can provide valuable insights, advice, and real-world experience.",
        "If you’re a young professional early in your career, you can also find working with a mentor invaluable. You’ll likely face challenges in progressing in your career path, understanding the ins and outs of the industry, and developing specialised skills. An agriculture mentor can offer personalised support, helping you make informed career decisions and avoid common pitfalls.",
        "People transitioning from a different sector into environment and agriculture can benefit from a mentor's expertise to quickly adapt to their new role. Mentors can provide you with the specific knowledge and connections you need to succeed in your new career.",
        "Additionally, if you’re an entrepreneur or innovator looking to start your own venture in sustainable agriculture or environmental technologies, an agriculture mentorship programme can help. Mentors with experience in the industry can offer guidance on business strategies, funding opportunities, and networking.",
        "Finally, even as a seasoned professional, you can benefit from having a mentor, especially when facing new challenges or aiming for higher leadership roles. A career mentor in agriculture can provide fresh perspectives and ongoing support, and help you stay updated with industry trends and advancements.",
      ]
    },
    {
      title: "What To Expect from an Environment and Agriculture Mentorship Programme?",
      description: [
        "Our environment and agriculture mentorship programme offers numerous benefits and learning opportunities.",
        "First, you can expect personalised guidance from experienced professionals in your field. This guidance will help you develop essential skills, gain industry-specific knowledge, and navigate your career path effectively.",
        "You’ll engage in regular one-on-one online sessions with your job-specific agriculture mentor, giving you a platform for discussing challenges, setting goals, and receiving constructive feedback. These sessions will help you build confidence and improve your problem-solving abilities.",
        "Through our agricultural mentorship programme, you’ll receive proper training and learn about practical, real-world experiences from your mentor. This will help you gain a better understanding of the complexities of the environment and agriculture sectors.",
        "Networking is another key component. As a mentee, you’ll connect with industry professionals and may have the opportunity to attend workshops and events related to your field. These connections can lead to job opportunities, collaborations, and a better understanding of industry trends.",
        "You’ll also learn about the latest technologies and sustainable practices. You’ll also stay updated with advancements in environmental conservation, climate change mitigation, and innovative agricultural techniques.",
        "Overall, an environmental and agricultural mentorship programme can equip you with the knowledge, skills, and professional network needed to succeed and make meaningful contributions to your industry.",
      ]
    },
    {
      title: "Find an Environment and Agriculture Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or attempting to reach new heights in your existing role, you may not have a clear path to take. Especially if you’ve just begun your career in a new industry, trying to succeed without professional guidance can feel overwhelming.",
        "You may have a lot of questions about the industry you’re breaking into, such as what to expect, which skills are needed, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can help you navigate challenges and grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const engineeringAndManufacturingData = {
  mainTitle: "Engineering and Manufacturing Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Engineering and Manufacturing Mentoring",
      description: [
        "The engineering and manufacturing industry is a gateway to a wide range of career opportunities, from designing and developing advanced machinery and infrastructure to producing consumer goods and high-tech products. Key areas include aerospace, automotive, electronics, and pharmaceuticals.",
        "This industry is continuously evolving, but it’s highly competitive, making it a challenging field for newcomers to enter, as well as being difficult to grow and excel in.",
        "At Career Navig8r, we offer a job-specific engineering and manufacturing mentoring programme. Our experienced professionals are available to share their knowledge and expertise with those seeking learning and development opportunities, including people in entry-level positions and graduates. Our intention is to help mentees develop the skills and understanding they need to succeed in their chosen job roles.",
        "This tailored approach means that you’ll have access to one-on-one training sessions with your own dedicated engineering mentor. These sessions will help you to gain and develop technical skills, such as using specific machinery or software, as well as soft skills like problem-solving, communication, and project management. Manufacturing mentors will also provide insights into industry best practices, safety standards, and the latest technological advancements.",
        "As a mentee, you’ll gain valuable insights and practical knowledge that can accelerate your career growth and improve your job performance. ",
        "Effective job-specific mentoring can help bridge the gap between the knowledge learned in theory and the skills that can be applied to real-world situations. If you’re a new engineer or manufacturing professional, a mentorship will ensure that you’re well-prepared to meet industry demands.",
        "Want to improve your job-specific skills to grow in your industry? Sign up for our manufacturing and engineering mentorship programme and connect with the right mentor for your situation.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Engineering and Manufacturing Professionals",
      description: [
        "If you’ve chosen a career in engineering or manufacturing, you’re likely to face a range of challenges as you enter the industry.",
        "One significant challenge is the rapidly evolving technology landscape. You’ll need to stay updated with the latest advancements, such as automation, artificial intelligence, and additive manufacturing, which require continuous learning and adaptation.",
        "Another challenge comes from a lack of practical experience. As a new professional, you may find that the theoretical concepts that you learned in school don’t fully prepare you for real-world scenarios. To properly bridge this gap, you’ll need on-the-job training and a supportive career mentor.",
        "Workplace expectations can also be challenging. As a newcomer, you have to quickly learn to manage multiple tasks, meet tight deadlines, and work within budget constraints. You’ll often face high expectations for productivity and efficiency, which can be stressful.",
        "Additionally, soft skills like communication, teamwork, and problem-solving are essential but sometimes overlooked during formal education. You’ll have to develop these skills to effectively collaborate with your colleagues and effectively present ideas.",
        "Finally, career progression can be difficult in this industry. Understanding the pathways for advancement, finding the right manufacturing mentor, and setting realistic career goals are all essential for long-term success, but can be overwhelming initially.",
        "Despite these challenges, you will have the opportunity to grow and succeed in this fast-paced industry by continuously learning, leveraging engineering mentorship programmes, and developing technical and soft skills.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Engineering and Manufacturing?",
      description: [
        "In the engineering and manufacturing industry, a career mentor can be beneficial for professionals at all stages. ",
        "If you’re a new graduate or you’re just getting started in your career, you’ll benefit significantly from an engineering mentor who can help you put your theoretical knowledge to use in the real world. You’ll gain immensely from their guidance on navigating your first job, understanding industry expectations, and developing practical skills.",
        "As a mid-career professional, you can also benefit from manufacturing mentors. If you want to advance to higher positions, a mentor can provide valuable advice on leadership, management, and strategic thinking. They can offer insights on how to tackle complex projects and improve your efficiency.",
        "If you’re a professional transitioning into engineering or manufacturing from another field, you’ll need a mentor to help you understand the specifics of your new job role. An engineering mentor can assist you in learning new technologies, adapting to industry standards, and integrating into your new workplace culture.",
        "Even if you’re an experienced professional, you can still benefit from a mentorship programme. Technology and industry practices continue to evolve, and a mentor can help you stay informed and maintain your competitive edge. They can also provide fresh perspectives and strategies for tackling new challenges.",
        "Overall, anyone looking to enhance their career growth, skill set, and industry understanding can benefit from a career mentor in engineering and manufacturing. Mentors can provide valuable support, knowledge, and connections that are crucial for career development at any stage.",
      ]
    },
    {
      title: "What To Expect from an Engineering and Manufacturing Mentorship Programme?",
      description: [
        "At Career Navig8r, our engineering and manufacturing mentorship programme is designed to help you excel in the industry by giving you access to a mentor based on your specific job role. ",
        "Firstly, your mentor can advise you on career development, helping you set goals and create a clear path for achieving them. They’ll also support you with any challenges you may face in your job role.",
        "You can also expect to learn both technical and soft skills from your mentor. Your chosen expert in mentoring young engineers will share their expertise on specific engineering and manufacturing processes, including the use of machinery, software, and industry best practices. They’ll also help improve your communication, teamwork, and problem-solving skills.",
        "Moreover, your manufacturing mentor will share practical knowledge that goes beyond textbooks. They’ll guide you based on their own experiences, including lessons learned from past projects, industry trends, and strategies for overcoming common challenges.",
        "An engineering mentorship programme can also open doors to a broader professional network. As a mentee, you’ll be able to connect with industry experts, attend relevant events, and learn about new job opportunities.",
        "Your mentor will offer constructive feedback on your performance, helping you identify areas for improvement and build your confidence. They’ll provide regular evaluations to ensure that you’re progressing and meeting your career objectives.",
        "With guidance and positive reinforcement from a proper engineering mentor, you’ll also gain confidence in your ability to tackle complex tasks and make informed decisions. This will help you progress faster in your career.",
        "Our engineering and manufacturing mentorship programme enables you to accelerate your professional growth, enhance your skills, and access the necessary tools for a successful career in the industry.",
      ]
    },
    {
      title: "Find an Engineering and Manufacturing Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re starting a new career or you’re trying to climb the ranks in your current role, it’s common to feel lost and apprehensive. As an emerging professional, achieving your desired level of success might seem like a far-fetched dream.",
        "You may be unsure about what to expect, which skills are needed, and what employers are looking for when hiring in this industry. That’s where you could benefit from working with a qualified and experienced career mentor. They’ll work closely with you to help you overcome obstacles and grow in your career.",
        "If you’ve just begun your career in engineering or manufacturing, or you’re looking to climb the corporate ladder, our mentorship programmes can be incredibly beneficial!",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and connect with an experienced mentor now.",
      ]
    },

  ]
};

export const marketingAdvertisingAndPRData = {
  mainTitle: "Marketing, Advertising and PR Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Marketing, Advertising and PR Mentoring",
      description: [
        "The marketing, advertising, and public relations industries help shape consumer perceptions and drive business growth. Professionals in these sectors create campaigns that resonate with target audiences, build brand loyalty, and enhance reputation. Good sales, marketing and PR help industries gain a competitive advantage in today's market.",
        "If you’re a budding professional in marketing, advertising, or PR, climbing the ranks in your company can often be challenging, especially without the right guidance. This is where a job-specific marketing, PR or advertising mentor programme could benefit you.",
        "At Career Navig8r, we offer a focused approach to career development, where an experienced professional will guide you in your chosen career path. You’ll receive mentoring that will help you gain practical knowledge and skills tailored to your specific job role and industry needs.",
        "For instance, a marketing mentor can help you build upon your existing skills in creating effective strategies, understanding consumer behaviour, and using analytics to measure success. They can provide insights on market research, product positioning, and campaign management that you may otherwise not have access to.",
        "Similarly, an advertising mentor can provide guidance on creative aspects, media planning, and the latest trends in digital and traditional advertising. They can help you become better skilled at developing strong messaging and understanding the nuances of different advertising platforms.",
        "A PR mentor can guide you on how to build and maintain a positive public image for your clients or companies. They’ll offer expertise in areas that you may want to improve in, including media relations, crisis communication, and event planning. You’ll improve your ability to craft press releases, manage social media, and develop strategic communication plans.",
        "Job-specific mentoring in these areas provides personalised guidance, real-world experience, and industry-specific insights. You’ll build confidence, develop professional networks, and gain the upper hand compared to other professionals with your level of experience, enabling you to advance your career more effectively.",
        "This hands-on learning approach ensures you’re well-prepared to respond to challenges and take on new opportunities in the fast-paced world of marketing, advertising, or PR.",
        "Want to find a marketing mentor, an advertising mentor, or a PR mentor online? Sign up with Career Navig8r now! ",
      ]
    },
    {
      title: "Challenges Faced by Emerging Marketing, Advertising and PR Professionals",
      description: [
        "If you’re an emerging professional in marketing, advertising, or PR, you’ll need to prepare to face a whole host of challenges as you navigate your career.",
        "One major challenge is keeping up with rapidly evolving technology and digital platforms. The rise of social media, analytics tools, and digital marketing trends means that you’ll have to continuously learn and adapt to new tools and strategies. This is where a digital marketing agency mentor could help.",
        "Another challenge is standing out in the job market. This field is extremely competitive, with many candidates vying for the same positions. That’s why having a unique skill set and strong portfolio is essential if you want to stand out and secure lucrative job opportunities. While building a personal brand and gaining relevant experience through internships or projects can help, it will take time and effort.",
        "Balancing creativity with data-driven decision-making isn’t easy. You’ll need to create innovative campaigns while also analysing metrics to ensure that your strategies are effective. This requires a mix of creative thinking and analytical skills, which can be difficult to master.",
        "Managing client expectations and tight deadlines can also be stressful. As an emerging professional, you’ll often need to juggle multiple projects simultaneously, which requires strong organisational and time management skills.",
        "Lastly, networking is essential but can be daunting. Building a professional network will help you find job opportunities and connect with like-minded professionals, but it requires confidence and effective communication skills.",
        "To overcome these challenges and succeed in the fast-paced and evolving fields of marketing, advertising, or PR, you’ll benefit substantially from the guidance of a career mentor who has been exactly where you are now.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Marketing, Advertising and PR?",
      description: [
        "Anyone pursuing a career in Marketing, Advertising, and PR can benefit from a career mentor.",
        "For example, if you’re a new graduate entering the field, you’ll find online marketing mentors invaluable for gaining practical knowledge and navigating your first job. Mentors in marketing can help you understand industry dynamics, develop key skills, and build professional networks.",
        "Even as a mid-career professional, you may need advice from an advertising mentor or public relations mentor to help you further develop your abilities and advance to higher positions. A marketing, advertising or PR mentor can provide guidance on leadership, strategy, and career transitions. They can help you refine your skills, set goals, and identify opportunities for growth and advancement.",
        "If you’re a freelancer or entrepreneur in marketing, advertising, or PR, a mentor can teach you how to successfully manage and grow your businesses. Marketing mentors can offer advice on client acquisition, project management, and scaling operations. They can share their own experiences and lessons learned, helping you avoid common pitfalls.",
        "Finally, if you’re looking to switch careers into marketing, advertising, or PR, finding the right mentor will help you quickly build your understanding of industry specifics and required skills. A PR or advertising mentor can assist you in making a smooth career transition by offering insights into your area of interest and helping you build relevant expertise.",
        "So, career mentors are valuable for anyone at any stage of their career in marketing, advertising, and PR. They provide personalised guidance, support professional development, and help mentees achieve their career goals.",
      ]
    },
    {
      title: "What To Expect from a Marketing, Advertising and PR Mentor Programme?",
      description: [
        "At Career Navig8r, our Marketing, Advertising, and PR mentor programme gives you access to personalised guidance and support to help you advance in your career.",
        "Firstly, you can expect to receive advice from experienced professionals who have worked in your specific job role. They’ll share their industry insights and best practices, which can help you navigate challenges and make informed decisions.",
        "In the Advertising mentoring programme, your mentor will assist you in developing essential skills, such as strategic thinking, creativity, communication, and data analysis. They’ll provide feedback on your work, helping you improve and grow.",
        "Through these mentorship programmes, you can also gain access to a network of industry contacts, opening doors to job opportunities, partnerships, and collaborations.",
        "Your digital marketing agency mentor or PR mentor will help you set realistic career goals and create a roadmap to achieve them. This structured approach ensures that you stay focused and motivated.",
        "Through a marketing or advertising mentor programme, you'll also gain a deeper understanding of the latest trends, tools, and technologies in marketing, advertising, and PR. This knowledge is crucial for staying competitive in the field.",
        "Moreover, your online marketing mentor will provide one-on-one support tailored to your unique needs and career aspirations. This personalised approach will help you address specific challenges and leverage your strengths.",
        "Overall, a mentorship programme in marketing, advertising, and PR can be a valuable investment in your professional development, giving you the tools and support you need to succeed.",
        "To find a marketing mentor, advertising mentor or PR mentor online, sign up for our marketing, PR and advertising mentoring programmes today.",
      ]
    },
    {
      title: "Find Your Marketing, Advertising or PR Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re starting a new career, aiming for a promotion, or just trying to better yourself in a marketing, advertising, or PR role, it’s not uncommon to feel lost and apprehensive. As a budding professional, you might feel that the road to success is long and overwhelming.",
        "You may be unsure of what to expect, what skills are needed, and what employers are looking for when hiring in your field. That’s where you could benefit from having a qualified and experienced career mentor. They’ll serve as your experienced, reliable source of information and guidance, helping you overcome obstacles and grow in your career.",
        "If you’ve just begun your career in marketing, advertising or PR, or you’re looking to climb the corporate ladder, our mentorship programmes are perfect for you.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and connect with an experienced mentor today.",
      ]
    },

  ]
};

export const businessConsultingAndManagementData = {
  mainTitle: "Business, Consulting and Management",
  accordionData: [
    {
      title: "About Job-Specific Business and Management Mentoring",
      description: [
        "The Business and Management industry is all about ensuring the effective operation of companies and organisations, and it is an essential part of the global economy.",
        "Professionals in this industry work to improve efficiency, drive innovation, and achieve organisational goals. Excelling in this industry requires skills like adaptability, leadership, and strategic thinking.",
        "If you’re a budding business professional, you can find diverse career opportunities, from management consulting to entrepreneurship. However, to advance your way up the corporate ladder, you may need a more personalised approach to professional development. That’s where business mentoring comes in.",
        "At Career Navig8r, our business mentorship programme will provide you with experienced mentors who can guide you in your specific job role within the business and management field. This type of business coaching and mentoring focuses on offering tailored advice and support for unique challenges and skills required for a particular position.",
        "Your entrepreneur mentor will provide insights based on their own experiences, helping you navigate your career path more effectively. They’ll assist you in developing your leadership, strategic thinking, problem-solving, and communication skills. By sharing their knowledge and offering constructive feedback, your management mentor will help you enhance your performance and achieve your professional goals.",
        "One major benefit of job-specific business management mentoring is that it accelerates learning and growth. As a mentee, you’ll gain practical, relevant advice that you can apply immediately in your role, making you more effective and confident in your job. This business growth mentoring will also allow you to keep improving as a professional within your organisation.",
        "You can build strong professional relationships and enhance your networking opportunities, while benefiting from your mentors' connections and insights into industry trends and best practices.",
        "So, if you’re looking to succeed in your specific job role and advance in your career, business and management mentoring can give you the targeted support you need for personal and professional growth. Sign up with us and find a business mentor today!",
      ]
    },
    {
      title: "Challenges Faced by Emerging Entrepreneurs",
      description: [
        "If you’re an emerging entrepreneur, you’re likely to face a variety of challenges as you establish and grow your business.",
        "One of the biggest hurdles is securing funding. As a budding entrepreneur, you may struggle to find investors or obtain loans, which would make it difficult to cover initial costs and sustain operations until your business becomes profitable. This is where you could benefit from the valuable support and advice of a startup mentor who has previously dealt with the same problems.",
        "Another major challenge is market competition. Your new business will need to compete with established companies, which often have more resources and a loyal customer base. This can make it hard for you to attract and retain customers.",
        "Building a strong team is also challenging, especially without the guidance of an experienced business management mentor. Finding skilled employees who share your vision can be difficult, and managing a team effectively requires leadership skills that, as a new entrepreneur, you’re probably still developing.",
        "Time management is another common issue. As a business owner, you’ll often need to juggle multiple roles, from product development to marketing, leaving little time for strategic planning and personal life. This can lead to burnout and decreased productivity. However, through a business mentor coaching session, you could learn how to balance these duties to avoid feeling overwhelmed.",
        "Finally, adapting to market changes and technological advancements requires flexibility and continuous learning. An experienced entrepreneur mentor can help you stay informed about industry trends and be prepared to change your strategies to remain competitive.",
        "Despite these challenges, with business coaching and mentoring, you’ll be able to persevere, innovate, and learn from your failures. ",
      ]
    },
    {
      title: "Who Needs a Business Career Mentor?",
      description: [
        "A business mentor can be invaluable for a wide range of professionals at various stages of their careers.",
        "If you’re a recent graduate looking for a job, you can benefit greatly from a startup mentor's guidance. From gaining practical insights into your industry, learning about workplace expectations, and receiving advice on navigating your career path, there are many benefits.",
        "Similarly, if you’re a young professional looking to advance in your current role or switch to a new field, finding a mentor is invaluable. A business and management mentor can help you develop the necessary skills, set career goals, and make informed decisions about your professional growth.",
        "Even as a mid-career professional facing stagnation or seeking a career change, you’re likely to find business growth mentors particularly helpful. They can provide fresh perspectives, help you identify new opportunities, and offer strategies to overcome your career challenges.",
        "Additionally, if you’re an entrepreneur or aspiring business owner, career mentors can guide you through the complexities of starting and running a business. Entrepreneur mentors are well-equipped to offer advice on avoiding common pitfalls, share their own experiences, and provide support in developing your business plans and strategies.",
        "Even as a senior executive or business professional, you could benefit from a business mentorship programme. It can help you gain new insights, refine your leadership skills, and stay updated with industry trends.",
        "Overall, anyone looking to enhance their career, navigate challenges, or achieve their professional goals can benefit from the guidance and support of a business mentor. If you find yourself in any of these categories, sign up for our business mentor coaching sessions at Career Navig8r now!",
      ]
    },
    {
      title: "What To Expect from a Business Mentorship Programme?",
      description: [
        "At Career Navig8r, our business mentorship programme will provide personalised guidance to help you grow professionally and achieve your career goals.",
        "Firstly, you can expect to receive tailored advice and feedback from an experienced business and management mentor who understands your industry and career aspirations. This will help to address your specific needs and challenges.",
        "This business coaching and mentoring programme will focus on developing key business skills such as leadership, communication, strategic thinking, and problem-solving. Your startup mentor will help you identify areas for improvement and provide strategies to enhance these skills.",
        "Additionally, your entrepreneur mentor can introduce you to their professional network, opening doors to new connections, collaborations, and career opportunities. Networking is an important aspect of career advancement so this will be highly beneficial for your future.",
        "A management mentor will also assist you in setting realistic and achievable career goals. They will work with you to create a plan for reaching these goals, offering guidance and support along the way.",
        "Moreover, regular business mentor coaching sessions will help ensure that you stay on track with your career objectives. Your business growth mentor will hold you accountable for your progress and help you stay focused.",
        "Furthermore, your business mentor will offer valuable insights based on their experiences. They can provide different perspectives on your career challenges and help you navigate complex situations.",
        "With our business mentorship programmes, you’ll have a comprehensive support system, fostering professional growth, confidence, and success in your career. ",
      ]
    },
    {
      title: "Find a Business Mentor at Career Navig8r Today!",
      description: [
        "When you’re starting a new career or trying to grow and excel in your current industry, it’s not uncommon to feel lost and apprehensive. As a budding professional, it might seem like the road to success is long and overwhelming.",
        "Regardless of your industry, particularly as a recent graduate or when searching for a new career, you’re likely to have business-related questions, including what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "If you’ve just begun your career in the business, management and consulting industry or you’re looking to climb the corporate ladder, our mentorship programme is for you!",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!",
      ]
    },

  ]
};

export const lawData = {
  mainTitle: "Law Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Lawyer Mentoring",
      description: [
        "The law industry includes various sectors like corporate, criminal, civil, environmental, and family law. It also involves legal professionals like lawyers, paralegals, and judges who interpret, enforce, and defend laws.",
        "The industry plays an important role in maintaining order and ensuring justice. Law firms, ranging from large international entities to small local practices, serve different types of clients, from individuals to corporations.",
        "If you’re an eager lawyer within a particular field of law, such as criminal, corporate, or family law, you could benefit from a legal mentorship programme. In this type of mentoring, you’ll be paired up with an experienced lawyer who will provide personalised advice, support, and practical knowledge tailored to your job role.",
        "The mentor will help you understand the nuances of your specific legal practice, offering insights into handling cases, managing client relationships, and navigating courtroom procedures. They’ll also share tips on how to draft legal documents, develop negotiation skills, and stay updated with changes in laws relevant to your speciality.",
        "One key aspect of job-specific law mentoring is the hands-on experience. As a mentee, you may shadow your legal mentor, observe real cases, and participate in discussions about strategy and decision-making. This practical exposure can be invaluable for building your confidence and competence.",
        "Additionally, your lawyer mentor will provide career guidance, helping you set professional goals, network within the industry, and identify opportunities for growth and advancement. They’ll also offer moral support and encouragement, which can be essential in a demanding industry like law.",
        "Overall, job-specific lawyer mentoring encourages professional development by combining theory with practice. It can help you become proficient in your chosen field, enhance your career prospects, and improve the quality of legal services you provide.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Law Professionals",
      description: [
        "As a law professional, it’s not uncommon to face some challenges as you begin your career in this industry. One major challenge is the intense competition in the legal job market. With many graduates vying for limited positions, it can be rather difficult to secure a job. This often leads to high levels of stress and anxiety.",
        "Another challenge is the significant student loan debt that many new lawyers carry. . This financial burden can be overwhelming, limiting your career choices and impacting your personal life decisions.",
        "As a new lawyer, you’ll also need to adapt to the fast-paced and demanding nature of legal work. Long hours, heavy workloads, and tight deadlines are common, and they require strong time management and resilience. Balancing work with your personal life can be difficult, leading to burnout and reduced job satisfaction.",
        "To add to this, when you start your career in law, you’ll need to quickly develop practical skills that may not have been covered in law school or at university. This includes understanding courtroom procedures, client interactions, and real-world case management. Without adequate legal mentorship and support, this learning curve can be steep.",
        "Finally, keeping up with constant changes in laws and regulations can be challenging. You’ll need to stay informed and continuously update your legal knowledge to succeed in this career, which requires ongoing effort and dedication.",
        "While a legal career can be rewarding, if you’re new to it, working with a law mentor is an excellent decision. Looking to thrive in the law industry? Sign up for our legal mentorship programme now.",
      ]
    },
    {
      title: "Who Needs a Law Career Mentor?",
      description: [
        "Law mentors are helpful for a wide range of individuals within the legal field. If you’re a law student, you can greatly benefit from a mentor as you navigate your studies and plan your career. Your mentors can guide you through your coursework, internships, and career paths, helping you make informed decisions about your future.",
        "Similarly, if you’re a recent law graduate or a new attorney, you’ll also need a mentor to help you transition smoothly from an academic environment to a professional practice. A lawyer mentor can give you practical advice on handling real cases, managing client relationships, and understanding the day-to-day operations of a law firm. This support helps new lawyers like you build confidence and develop essential skills.",
        "If you’re a lawyer switching specialities or moving into new areas of law, you can still benefit from law firm mentors. A legal mentor with experience in your new field can share insights, resources, and networking opportunities that are important for a successful transition.",
        "If you’re a mid-career professional looking to advance or overcome career plateaus, you’ll find mentorship to be invaluable. Mentors in the law industry can help you with setting career goals, improving leadership skills, and navigating workplace challenges.",
        "Even as an experienced attorney, you can still benefit from a lawyer mentorship programme, particularly when you want to further your skills, streamline operations and become more time-efficient. Your law firm mentor will encourage ongoing professional development and become someone you can bounce ideas off.",
        "In essence, anyone at any stage of their legal career can benefit from the guidance, support, and expertise of a lawyer mentor.",
      ]
    },
    {
      title: "What To Expect from a Lawyer Mentorship Programme?",
      description: [
        "A lawyer mentorship programme can offer invaluable support and guidance for those new to the legal profession or looking to advance their careers. As a mentee, you can expect several benefits from such a programme.",
        "Firstly, you’ll receive personalised advice from experienced lawyers. This includes practical insights into handling cases, client management advice and tips for presenting yourself professionally in a courtroom setting. Your law mentor will share their knowledge and expertise, helping you develop essential legal skills.",
        "Moreover, lawyer mentorship programmes often provide opportunities for networking. That means you’ll have a chance to connect with other legal professionals, expanding your professional network and opening doors to potential job opportunities and collaborations.",
        "You can also expect to gain a deeper understanding of your specific area of law. Your law firm mentor will help you stay updated on the latest legal developments, trends, and best practices, ensuring that you remain competitive in your field.",
        "You may also get the opportunity to overshadow your law mentor and observe them in action. This hands-on experience is essential for learning how to apply theoretical knowledge in real-world situations.",
        "Career guidance is another important component of legal mentorship programmes. A legal mentor can assist you in setting and achieving your career goals, whether it's securing a job, transitioning to a new speciality, or advancing within your current firm.",
        "A legal mentorship programme also offers emotional support and encouragement. The legal profession is often demanding, and having a law firm mentor who has experienced the same challenges can be extremely beneficial. Their advice, feedback, and motivation can make a major difference in your confidence and job satisfaction.",
        "Overall, a lawyer mentorship programme can equip you with the tools, knowledge, and support needed to excel in your career.",
      ]
    },
    {
      title: "Find the Best Law Mentors at Career Navig8r Today!",
      description: [
        "Whether you’ve recently chosen a new career or you’re trying to grow and excel in your current industry, it’s not uncommon to feel lost and apprehensive. As a budding professional, the path to success sometimes seems long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "If you’ve just begun your career in the law industry or you’re looking to climb the corporate ladder, this mentorship programme is for you!",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!",
      ]
    },

  ]
};

export const seniorMarketingManagerData = {
  mainTitle: "Senior Marketing Manager Career Mentor",
  accordionData: [
    {
      title: "About Senior Marketing Manager Mentoring",
      description: [
        "Do you want to gain the skills and knowledge to become a senior marketing manager? Or are you already working in this role and want to enhance your skills?",
        "Career Navig8r’s senior marketing manager mentoring course is designed for professionals aspiring to excel in marketing leadership. You’ll have the opportunity to learn from mentors with years of experience in developing strategies, managing teams, and creating successful campaigns.",
        "Through personalised senior marketing manager career guidance from your chosen mentor, you’ll be able to enhance your skills in market analysis and brand positioning, as well as learn how to manage your team better. Your senior marketing manager mentor will also give you insights into facing challenges and teach you about industry trends, so you can grow in your career.",
        "Our senior marketing manager training programme will help build your critical thinking and innovation skills, giving you the confidence to excel in your job. Whether you're transitioning into leadership or looking to refine your expertise, you’ll be able to take your marketing career to the next level with a senior marketing manager mentor by your side.",
        "If you want to climb the corporate ladder in marketing, sign up with us for senior marketing manager mentorship today."
      ]
    },
    {
      title: "The Job Role of a Senior Marketing Manager",
      description: [
        "As a senior marketing manager, your job is to improve your company's marketing strategies and achieve business objectives.",
        "You’ll be tasked with overseeing the planning, development, and execution of marketing initiatives to enhance brand awareness and generate leads. You’ll need to collaborate with cross-functional teams, including sales and product development, as well as creative departments, to align your marketing efforts with your company’s goals.",
        "Your responsibilities will include conducting market research to identify trends, so you can target the right audiences and position the brand competitively.",
        "You’ll need to develop and manage campaigns across various channels, such as digital, print, and social media, ensuring consistency in the messaging and branding. You’ll also be required to analyse the campaign’s performance metrics to optimise your strategies and improve ROI.",
        "Strong leadership skills are essential for this job, as you’ll be expected to manage teams and delegate various tasks, while also mentoring junior marketers. Budget management and communication with stakeholders will also be key aspects of your role."
      ]
    },
    {
      title: "Challenges Faced by Senior Marketing Managers in the Industry",
      description: [
        "When you’re working as a senior marketing manager, you’re likely to face some challenges in the industry.",
        "One major issue is keeping up with ever-changing consumer behaviours and market trends. As digital platforms continue to evolve, you’ll need to learn and use new technologies, tools, and channels.",
        "Another challenge is maintaining consistency in brand messaging across different platforms while personalising campaigns to resonate with various audiences. You’ll also deal with financial pressure as you try to manage budgets effectively with increasing demands for innovative marketing strategies.",
        "Data overload is another issue, as you’ll need to analyse large amounts of information to get actionable insights, which can be time-consuming and complicated. You’ll also need to have excellent communication and leadership skills to ensure your marketing efforts fit with your company’s objectives.",
        "In your role, you’ll need to be aware of global competition, cultural differences, and the growing emphasis on sustainability and ethical practices. Using your creativity while delivering measurable results is a balance that can be difficult to get right, and you’ll need to be adaptable and have strategic foresight to handle this.",
        "Facing these challenges is easier when you have the guidance of someone who has dealt with them before. That’s where you could benefit from expert senior marketing manager career support.",
        "With proper senior marketing manager training, you’ll get a better understanding of your role and industry. Your senior marketing manager mentor can give you valuable knowledge to help you grow in your career.",
        "Want career mentorship from a marketing professional? Sign up to get essential senior marketing manager career advice."
      ]
    },
    {
      title: "What to Expect from Senior Marketing Manager Training Online?",
      description: [
        "With Career Navig8r’s senior marketing manager mentorship online, you’ll get personalised guidance to help you refine your marketing expertise and leadership abilities. When you sign up, you’ll receive insights from a seasoned mentor who’ll share real-world strategies based on their own experience, along with industry trends and best practices to improve your skills.",
        "Your senior marketing manager training sessions will typically focus on areas like campaign planning, data-driven decision-making, team management, and mastering new marketing technologies. You’ll get senior marketing manager career advice to overcome challenges, from improving a brand’s positioning or maximising ROI in your campaigns.",
        "Your senior marketing manager mentor will help you excel in your career by giving you tips on how to advance into higher leadership roles and build a strong professional network. They can be an excellent sounding board for your ideas and concerns, and they’ll provide constructive feedback and practical solutions that you can apply immediately in your role.",
        "Our senior marketing manager career guidance programme is conducted virtually, which means you’ll have the flexibility to schedule sessions around your commitments. You can choose from a diverse pool of mentors from different industries, providing broader perspectives.",
        "Through interactive discussions with your mentor, you’ll receive the senior marketing manager career support needed to become confident and impactful in your role. If you’re ready to advance your career in marketing, enrol in our senior marketing manager mentorship programme."
      ]
    },
    {
      title: "Find a Senior Marketing Manager Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const chiefInformationOfficerData = {
  mainTitle: "Chief Information Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Information Officer Mentoring",
      description: [
        "Want to know how to become a chief information officer? Or have you already acquired this role and want to improve your job skills?",
        "Career Navig8r’s chief information officer mentoring programme is designed to guide IT leaders like you in strategic technology management. As a CIO, your role is pivotal in aligning technology with business goals, and mentoring can help you develop critical leadership, decision-making, and innovation skills.",
        "Through one-on-one chief information officer career guidance, you’ll learn how to handle organisational challenges and manage digital transformation. You’ll also get tips on how to encourage collaboration within your team.",
        "Your chosen chief information officer mentor will share practical advice, with real-world scenarios to learn from. They’ll also teach you about industry trends to prepare you for the evolving tech landscape.",
        "Our chief information officer career mentorship will build your confidence and adaptability. It’ll also improve your strategic thinking skills, so you can lead with vision and make an impact in your organisation.",
        "Ready to take your IT career to the next level with CIO career guidance? Sign up with us for expert chief information officer training today."
      ]
    },
    {
      title: "The Job Role of a Chief Information Officer",
      description: [
        "As a chief information officer (CIO), you’ll play an important part in your organisation's leadership by overseeing its technology strategy and aligning it with business goals.",
        "You’ll manage the IT infrastructure, ensuring operations run smoothly while maintaining strong cybersecurity. Your role will also involve evaluating emerging technologies, leading digital transformation initiatives, and optimising processes for improved productivity.",
        "You’ll be required to collaborate closely with other executives to develop strategies that use technology as a competitive advantage. You’ll also be expected to oversee IT budgets and manage vendor relationships, as well as ensure your operations are compliant with regulatory standards.",
        "To succeed in this role, you should be an effective team leader. You’ll need to inspire and guide IT professionals and encourage collaboration across various departments.",
        "With technology evolving quickly, you’ll need to anticipate industry trends and ensure your organisation adapts to them. Your role as a CIO ultimately comes down to improving your business’ performance through strategic, innovative, and secure technology management."
      ]
    },
    {
      title: "Challenges Faced by Chief Information Officers in the Industry",
      description: [
        "When you’re working as a chief information officer, you’ll face numerous challenges, especially as you climb higher up the corporate ladder.",
        "One major challenge is balancing the quick pace of technological advancements with your organisation’s budgets and resources. You’ll need to find and invest in the right technologies while ensuring they’re cost-efficient and offer a good return on investment.",
        "You’ll also need to stay constantly vigilant to cybersecurity threats and data breaches. That means having good strategies in place to protect your company’s sensitive information and maintain compliance with the evolving regulations.",
        "Additionally, you’ll be managing digital transformation initiatives, which can be difficult, often involving making big changes in your workflows, employee training, and customer experiences.",
        "Another challenge is collaborating across departments, so you’re aligning IT with the overall business objectives. You’ll come across employees who are resistant to change, so engaging stakeholders and effectively managing change is essential. Not only that, but the shortage of skilled IT talent may also put you under more pressure to build and retain effective teams.",
        "You’ll be better equipped to handle these challenges with an experienced IT professional to guide you. That’s where our chief information officer mentorship comes in.",
        "Our chief information officer training programme lets you learn the ins and outs of the industry from someone with vast experience in your specific job role. Your chosen chief information officer mentor can help you tackle any issues you face and teach you how to avoid mistakes.",
        "If you need help moving ahead in your career as a CIO, sign up to get chief information officer career advice and expertise today."
      ]
    },
    {
      title: "What to Expect from Chief Information Officer Training Online?",
      description: [
        "Career Navig8r’s online chief information officer mentoring programme offers a flexible and valuable way for aspiring or current CIOs to develop their skills and tackle challenges.",
        "When you enrol, you’ll receive tailored chief information officer career support from a professional who has years of experience in IT leadership. Through virtual sessions with your chief information officer mentor, you’ll get insights into managing digital transformations, innovating in your role, and aligning your tech strategies with business goals.",
        "Our chief information officer training gives you access to personalised career advice on leadership development and strategic decision-making. You’ll also learn how to effectively communicate with stakeholders.",
        "Your chief information officer mentor can help you handle various challenges, drawing from their own experience to give you real-world examples.",
        "The virtual format of our chief information officer mentorship course means you can work and learn at your own pace around your current work schedule.",
        "Whether you want to address immediate issues or discuss your long-term career goals, your online career mentor can give you practical solutions and help you develop a deeper understanding of the technology landscape. They can also give you numerous networking opportunities, which will benefit your career in the long run.",
        "If you want CIO career advice from experts in your specific industry, enrol in our chief information officer career guidance course."
      ]
    },
    {
      title: "Find a Chief Information Officer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};


export const chiefSalesOfficerData = {
  mainTitle: "Chief Sales Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Sales Officer Mentoring",
      description: [
        "Have you recently become a chief sales officer, or are you aiming to find a job as a CSO? Are you looking for CSO training to help you excel in this role?",
        "Career Navig8r’s chief sales officer mentorship is tailored to support and guide sales leaders like you in their roles.",
        "CSO mentoring can help you improve your leadership skills, refine your sales processes, and adapt to market challenges. With expert advice from your chosen chief sales officer mentor, you can develop strategies to improve your sales performance and build strong client relationships. You’ll also learn how to manage your team better and encourage them to work collaboratively.",
        "Whether you need help addressing challenges in your role or you want to pursue growth opportunities, our CSO guidance can give you actionable insights and the confidence to lead effectively.",
        "Want to improve your skills in this high-level sales role? Sign up for our chief sales officer training today."
      ]
    },
    {
      title: "The Job Role of a Chief Sales Officer",
      description: [
        "As a chief sales officer, your main job is to improve your company’s revenue and help it grow.",
        "You’ll lead a sales team, which means you’ll be responsible for developing and executing strategic sales plans that align with your organisation’s overall goals. This will involve identifying target markets and setting sales objectives, while ensuring your team meets or exceeds its targets.",
        "You’ll be expected to oversee key client relationships and negotiate major deals, as well as keep up with market trends to adapt your strategies as needed. You’ll work closely with other executives, such as the CEO and marketing leaders, to ensure you deliver strategies cohesively across all departments.",
        "A big part of your role will be building and leading a high-performing sales team. This means you’ll need to train and motivate staff and provide the resources they need to excel. You’ll also be tasked with analysing sales data to identify opportunities and challenges and using your insights to guide major decisions.",
        "Ultimately, your job is to position your company as a market leader by keeping the revenue high and ensuring your customers are always satisfied."
      ]
    },
    {
      title: "Challenges Faced by Chief Sales Officers in the Industry",
      description: [
        "When you’re working as a CSO, you’ll likely face numerous challenges due to the demanding nature of this role.",
        "One of the biggest challenges is staying ahead of ever-changing market trends and customer expectations. To adapt to these changes, you’ll need to constantly innovate and be able to quickly pivot your strategies.",
        "Balancing your short-term sales targets with long-term growth is another common challenge. You’ll be expected to achieve your immediate revenue goals, but focusing solely on them can affect your company’s sustainable development and strategic planning.",
        "You’ll need to be prepared to deal with intense competition, and that means knowing how to differentiate your offerings and retain your customers’ loyalty. Managing a diverse sales team comes with its own difficulties, and you’ll need to align varying skill levels and motivations.",
        "As technology advances, you’ll need to be willing to learn and integrate new tools, which can be costly and complex.",
        "Finally, you’ll be required to maintain open communication with other departments to ensure they collaborate effectively. This will often test your adaptability and interpersonal skills.",
        "These challenges are much easier to tackle when you receive proper CSO mentoring from an expert. This is where you can benefit from our structured chief sales officer training online.",
        "In our CSO mentorship, you’ll have the opportunity to learn from a chief sales officer mentor with years of experience in this role. Your chosen CSO mentor can help you better understand the industry and the job, giving you all the CSO training you need to perform better.",
        "If you’re keen to take your career to the next level, sign up for our chief sales officer training."
      ]
    },
    {
      title: "What to Expect from Chief Sales Officer Training Online?",
      description: [
        "Career Navig8r’s online chief sales officer mentorship is the ideal solution if you want personalised guidance to excel in your role.",
        "Through your virtual CSO training sessions, you’ll get expert insights tailored to your specific challenges and goals. Our CSO coaching focuses on sharpening your leadership skills, teaching you how to develop effective sales strategies and navigate difficult markets.",
        "Your chief sales officer mentor will give you valuable advice on managing your teams, meeting your revenue targets, and building lasting client relationships. They can also be a sounding board for addressing your immediate concerns and long-term goals.",
        "Throughout your mentoring timeline, your CSO mentor will assess your progress and provide actionable feedback, helping you grow personally and professionally. They’ll give you real-world examples and teach you about industry best practices, often relying on their own experiences to guide you.",
        "The flexibility of our online chief sales officer training means you can learn at your convenience, scheduling your chief sales officer coaching sessions to avoid clashes with your daily responsibilities.",
        "Whether you’re looking to improve your performance, overcome competitive pressures, or prepare for future challenges in your role, our online CSO mentorship will give you the tools you need to lead confidently and succeed.",
        "Need effective CSO training? Enrol in our chief sales officer training today."
      ]
    },
    {
      title: "Find a Chief Sales Officer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const chiefHumanResourcesOfficerData = {
  mainTitle: "Chief Human Resources Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Human Resources Officer Mentoring",
      description: [
        "Want to learn how to become a chief human resources officer? Or are you already working in this role and want CHRO training to improve your skills?",
        "Career Navig8r’s chief human resources officer mentoring programme provides tailored guidance to HR leaders like you who are looking to tackle the complexities of their role.",
        "As a CHRO, your responsibilities range from aligning your HR strategies with business goals to creating a positive workplace culture. Our CHRO mentorship will support your growth in these areas by giving you access to expert insights, industry knowledge, and personalised advice.",
        "Whether you need to sharpen your leadership skills or address workforce challenges, having access to chief human resources officer career advice will help you identify solutions and refine your approach. Your CHRO mentor will also help you improve your ability to plan strategically.",
        "Our one-on-one CHRO career guidance programme will teach you how to confidently tackle your responsibilities and make a big impact in your organisation.",
        "If you’re keen to get better at your job with CHRO training, sign up for our chief human resources officer mentorship today."
      ]
    },
    {
      title: "The Job Role of a Chief Human Resources Officer",
      description: [
        "As a chief human resources officer, you’ll be responsible for overseeing your organisation's HR strategies and ensuring they align with business objectives. Your primary role will involve developing and implementing policies that attract, retain, and engage top talent while maintaining a positive work culture.",
        "You’ll also need to manage recruitment, training, employee relations, and benefits, as well as ensure you’re compliant with labour laws. Additionally, you’ll play a strategic role in diversity initiatives and succession planning.",
        "You’ll often be required to collaborate with other executives to ensure the HR initiatives support overall business growth. As a trusted advisor, you’ll have a say in matters like employee engagement, conflict resolution, and organisational change.",
        "To succeed in this role, you should be a strong leader with excellent communication skills. You should also have a deep understanding of industry trends and be able to balance your company’s strategic goals with employee well-being."
      ]
    },
    {
      title: "Challenges Faced by Chief Human Resources Officers in the Industry",
      description: [
        "When you’re working as a CHRO, you’ll face numerous challenges as you get to grips with the complexities of your organisation.",
        "One major challenge you’ll likely face is attracting and retaining top talent in this competitive job market. Employees have high expectations, so you’ll often find yourself trying to balance offering competitive compensation and benefits with ensuring the office is a positive place to work.",
        "Adapting to technological advancements is another challenge. You’ll need to integrate tools like HR software and AI-driven analytics, ensuring employees are trained to use them effectively.",
        "You’ll also need to manage organisational change, such as mergers, restructuring, or workforce downsizing, which can further complicate your job. This is where strong communication and conflict resolution skills can come in handy.",
        "Another challenging aspect of your job is to stay compliant with changing labour laws and regulations. You’ll also need to address diversity and equity initiatives to build an inclusive workplace.",
        "In all, this is a highly demanding role that can be difficult to manage, especially without the right CHRO career guidance. That’s where our chief human resources officer training sessions come in.",
        "Our CHRO career support programme will give you a chance to learn from someone who has years of experience in this role. Your chosen chief human resources officer mentor will teach you all you need to know to excel in this job.",
        "Want to reap the benefits of expert CHRO mentoring? Sign up with us to get chief human resources officer career guidance today."
      ]
    },
    {
      title: "What to Expect from Chief Human Resources Officer Training Online?",
      description: [
        "Career Navig8r’s online chief human resources officer mentorship will give you tailored guidance and support to excel in your leadership role.",
        "Through our virtual CHRO training sessions, you can connect with an experienced CHRO mentor who understands the complexities of managing people, culture, and organisational strategies. They’ll give you personalised CHRO career advice on tackling key challenges like talent acquisition and employee engagement.",
        "Your chief human resources officer mentor will also provide insights on technological changes and teach you how to improve diversity and inclusion and handle sensitive issues such as workforce restructuring. CHRO mentoring will also allow you to discuss your unique challenges and goals, and you’ll get constructive feedback to improve your approach.",
        "The online format of our CHRO career support programme also makes it flexible and convenient, so you can schedule sessions and learn at your own pace. You’ll also get a wide range of networking opportunities that can help advance your career.",
        "In all, if you need guidance and valuable perspectives from an expert in this role, our CHRO training course is for you. It’ll equip you with the expertise and confidence you need to thrive in your industry.",
        "Enrol in our programme to get chief human resources officer training and career guidance today."
      ]
    },
    {
      title: "Find a Chief Human Resources Officer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const teacherTrainingAndEducationData = {
  mainTitle: "Teacher Training and Education Mentor",
  accordionData: [
    {
      title: "About Job-Specific Teacher Mentoring",
      description: [
        "The teaching and education industry is extremely important for societal development. This sector includes various institutions, and it helps shape future generations by sharing knowledge, improving critical thinking, and nurturing students' intellectual and emotional growth.",
        "The industry is characterised by diverse roles, with teachers at the forefront, delivering lessons and guiding students through their educational journeys, while administrators ensure that the operational side of things runs smoothly.",
        "If you’re a new teacher looking to advance in your career, a teacher mentor programme may be just what you need. At Career Navig8r, our mentorship programme is designed to equip teachers like you with the specialised skills and knowledge needed to excel in your specific teaching role.",
        "Whether you’re working in elementary education, secondary education, special education, or vocational training, you can choose a teaching mentor based on your current job role or one that you want to achieve.",
        "Beyond formal training, a mentorship can provide new teachers with ongoing support from experienced educators. Your mentor will offer guidance, share best practices, and help you navigate the complexities of the teaching profession.",
        "This relationship will help you grow as a professional and adapt to your school environment. It will also enhance your teaching effectiveness and job satisfaction.",
        "With proper training and an effective teacher mentor programme, you can become a more prepared teacher who is better able to meet diverse student needs and implement innovative teaching strategies.",
        "Our programme is all about teachers mentoring teachers, which means that you get to benefit from the experience and expertise of experienced educators in the industry. Want to improve your teaching skills? Sign up with us now!"
      ]
    },
    {
      title: "Challenges Faced by Emerging Teaching Professionals",
      description: [
        "As a new teacher, you’re likely to face some challenges as you begin your career in education. One of the main issues is classroom management, which requires a good balance of authority and approachability. This is where you may struggle with maintaining discipline and creating a conducive learning environment.",
        "Another significant challenge is the workload. Teaching is not just about delivering lessons but also planning, grading, and meeting administrative demands, which can be overwhelming for beginners. Moreover, you need to tailor your teaching style to meet different student needs, including those with learning disabilities, language barriers, and varying academic levels.",
        "While the school or university where you work may provide support and mentorship, it might not always be enough, leaving you feeling isolated and underprepared. Furthermore, balancing your professional responsibilities with your personal life can lead to stress and burnout, especially in the first few years of your teaching career.",
        "You may also have to deal with the challenge of continuously keeping up with educational research, new teaching methodologies, and policy changes. All of this requires ongoing learning and adaptation, which can be difficult to tackle alongside your daily teaching responsibilities.",
        "This is where our programme for mentoring new teachers becomes so impactful. It helps to address common and personal challenges, ensuring your effectiveness in the classroom and ultimately benefiting your students and the educational system."
      ]
    },
    {
      title: "Who Needs a Teaching Career Mentor?",
      description: [
        "A teaching mentor can be helpful for educators at various stages of their careers. If you’re a new teacher, fresh out of a teacher training programme or university course, you can benefit significantly from mentors who can help you navigate the complexities of classroom management, curriculum planning, and student engagement.",
        "As a beginner in the industry, you’re also bound to face unique situations and unforeseen problems, and having a mentor can provide support, guidance, and practical advice to help you transition smoothly into your role.",
        "On the other hand, if you’re an experienced teacher, you can still benefit from a teacher mentor programme, particularly as you grow in your role and take on new challenges or responsibilities. For instance, if you’re moving into a leadership position, such as department head or curriculum coordinator, you can gain insights from a mentor who has previously held similar roles. Your mentor can teach you all about effective leadership, decision-making, and conflict resolution.",
        "Additionally, if you’re undergoing a career change, such as shifting from a different profession into education or moving to teach a new subject or grade level, you’ll find mentorship to be extremely helpful. A mentor can provide tailored advice and resources, helping you adapt your skills and knowledge to your new role.",
        "Mentorship can also be beneficial if you’re facing career stagnation or burnout. A mentor can help you rediscover your passion for teaching, set professional goals, and explore new teaching methods or professional development opportunities.",
        "Overall, any teacher looking to enhance their skills, overcome challenges, or advance their career can benefit from the guidance and support of a dedicated career mentor. Sign up for our programme that’s all about mentoring student teachers."
      ]
    },
    {
      title: "What To Expect from a Teacher Mentorship Programme?",
      description: [
        "A teacher mentor programme gives educators the support they need to grow professionally and enhance their teaching effectiveness. As a mentee, you can expect a structured yet flexible framework tailored to your specific needs and career stages.",
        "Firstly, you’ll receive personalised guidance from experienced teaching mentors who’ll offer advice about classroom management, lesson planning, and student engagement strategies. If you’re a new teacher, this one-on-one support will help you tackle the initial challenges of the profession and develop practical skills.",
        "Regular feedback is a key component of programmes for teachers mentoring teachers. That means you get to discuss your teaching experiences with other educators and receive constructive feedback on your performance. This ongoing evaluation can help you identify areas for improvement in your process and celebrate each other’s successes.",
        "Additionally, mentoring new teachers often includes training sessions covering a wide range of topics, from the latest educational technologies to inclusive teaching practices. This ensures that you’re keeping up with educational advancements and methodologies.",
        "Collaboration and networking opportunities are another significant benefit. As a mentee, you can connect with other educators, share experiences, and build a supportive professional community. This network can provide you with ongoing support and resources even after the formal mentorship period ends.",
        "Overall, a teacher mentor programme equips new or experienced educators with the tools, confidence, and support needed to thrive in their careers, ultimately leading to improved student outcomes and greater job satisfaction."
      ]
    },
    {
      title: "Find the Best Teaching Mentors at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, you’re likely to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "If you’re in the education industry and considering being a teacher mentor or finding a teaching mentor, this is the programme for you!",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!"
      ]
    }
  ]
};


export const marketingDirectorData = {
  mainTitle: "Marketing Director Career Mentor",
  accordionData: [
    {
      title: "About Marketing Director Mentoring",
      description: [
        "Want to learn how to become an excellent marketing director? Or are you keen to improve your skills in this particular job role?",
        "Career Navig8r’s marketing director manager mentoring programme is designed to help professionals like you become leaders in marketing. This mentorship will equip you with strategic insights and decision-making skills, and teach you how to take innovative approaches to create impactful campaigns.",
        "By learning from an experienced marketing director mentor, you’ll gain expertise in brand management, analytics, and team leadership. All of these skills are essential for succeeding in the marketing industry.",
        "Our marketing director career guidance course gives you the insights and knowledge to grow through real-world advice and personalised feedback, as well as unique networking opportunities. Our mentorship will also help build your confidence and make you more adaptable in your role.",
        "Whether you want to refine your strategies or understand industry trends, our marketing director mentorship programme will help you deliver measurable results in your professional journey.",
        "To learn and grow as a marketing leader in your organisation, sign up with us and get access to expert marketing director career advice today."
      ]
    },
    {
      title: "The Job Role of a Marketing Director",
      description: [
        "As a marketing director, your job is to shape your organisation’s brand identity and improve its market presence. As the head of the marketing department, you’ll need to oversee the strategic planning, execution, and performance analysis of all marketing campaigns.",
        "Your responsibilities will include developing long-term marketing strategies and managing budgets, while also ensuring your campaigns fit with the business objectives. You’ll need to collaborate with various teams - creative, sales, and product development - to ensure your marketing messaging is consistent across all channels.",
        "You’ll be tasked with analysing market trends and consumer behaviour, as well as keeping an eye on competitors’ activities to find new opportunities and adapt your strategies. Leadership is a core aspect of this role, as you’ll need to guide and inspire your marketing team, encouraging them to innovate and collaborate.",
        "Finally, you’ll need to ensure your brand remains relevant digitally by using tools like data analytics and social media to engage your audiences. To excel in this job, you should have a creative mind and great analytical thinking skills."
      ]
    },
    {
      title: "Challenges Faced by Marketing Directors in the Industry",
      description: [
        "In your job as a marketing director, you’re likely to encounter some challenges due to the complicated nature of your industry.",
        "One of the main challenges you’ll face is staying ahead of the rapidly advancing technologies. With new tools and platforms constantly emerging, you’ll need to quickly adapt your strategies and integrate relevant tools into your existing workflows.",
        "Another challenge is managing diverse consumer expectations. Your brand’s audiences will often be segmented across various demographics and preferences, which means you’ll need to create personalised campaigns that resonate universally. This is where you’ll have to use your creativity and precision.",
        "On the subject of creativity, you’ll often need to balance your creative ideas with data-driven decisions. Every innovative idea your team generates should result in the brand’s desired outcomes.",
        "Budget constraints will test your ability to achieve maximum impact with limited resources. Plus, the fierce competition will require you to consistently differentiate your brand in oversaturated markets.",
        "Finally, you’ll need to collaborate with cross-functional teams and make sure all projects align with your organisation’s goals, which will only add to the complexity of your job.",
        "To overcome these challenges, you need to be adaptable, think strategically, and be proactive in your approach. That’s where you can benefit from having marketing director career support. It’ll give you the opportunity to learn from someone who has experience in this job role and has dealt with similar challenges in their career.",
        "Through marketing director manager mentoring, you’ll be able to understand your industry better, so you can make informed decisions and avoid making mistakes. If you’re ready to level up your marketing skills, sign up for our marketing director training today."
      ]
    },
    {
      title: "What to Expect from Marketing Director Training Online?",
      description: [
        "Career Navig8r’s online marketing director mentorship is ideal if you’re looking for a flexible and personalised learning experience. When you sign up, you can expect to receive tailored marketing director career guidance from a seasoned professional who’ll cover key aspects like strategic planning, campaign management, and leadership development.",
        "Through your virtual one-on-one sessions, your marketing director mentor will provide actionable insights and help you work through real-world challenges. They’ll draw from their own experience to help you refine your decision-making and problem-solving skills.",
        "During this marketing director training programme, you can discuss emerging industry trends and the effective use of analytics with your mentor. You’ll also learn how to master various digital tools to build marketing strategies that make an impact.",
        "Your marketing director mentor will help you to gain exposure to diverse perspectives and networking opportunities. They can also give you practical advice on how to manage a team and communicate with stakeholders.",
        "Our marketing director mentorship allows for convenient scheduling, which means you can learn at your own pace while balancing your personal and professional commitments.",
        "With expert marketing director career advice and constructive feedback, you’ll gain the confidence and knowledge you need to excel in your role. If you’re ready to learn from some of the best in the industry, enrol in our marketing director training course."
      ]
    },
    {
      title: "Find a Marketing Director Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const chiefMarketingOfficerData = {
  mainTitle: "Chief Marketing Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Marketing Officer Mentoring",
      description: [
        "Do you want to learn how to become a chief marketing officer? Or are you already working in this role and want CMO career support to improve your skills?",
        "Career Navig8r’s chief marketing officer mentoring programme is designed to guide aspiring or newly appointed CMOs like you in high-level marketing leadership. With CMO training, you’ll learn to hone your strategic thinking and decision-making skills, so you can become a leader who drives business growth and brand value.",
        "You’ll be mentored by a seasoned chief marketing officer mentor, who’ll give you insights into managing cross-functional teams and aligning your marketing goals with corporate objectives. They’ll also offer tips on staying ahead of industry trends.",
        "Whether you want to master data-driven strategies or create innovative campaigns, our chief marketing officer career guidance programme will equip you with the tools to excel in this competitive industry. It’ll prepare you to lead with confidence and creativity and make a lasting impact.",
        "To be the best marketing leader in your organisation, sign up for chief marketing officer training sessions today."
      ]
    },
    {
      title: "The Job Role of a Chief Marketing Officer",
      description: [
        "As a chief marketing officer, you’ll be responsible for overseeing your company’s marketing efforts to promote your brand, products, and services. Your main goal will be to attract and retain customers while ensuring your business stays competitive in the market.",
        "You’ll be tasked with developing marketing strategies and planning advertising campaigns, as well as deciding how to use different platforms like social media, TV, or online ads. Your role will involve analysing market trends and customer preferences, so you can make informed decisions about what works best for the business.",
        "You’ll be expected to confidently lead a team of marketers and guide them to execute campaigns that fit with the company’s goals. You’ll also be required to collaborate with other departments, like sales and product development, to ensure your branding remains consistent and successful.",
        "As well as your creativity, you’ll need to use data and analytics to measure the performance of your campaigns and adjust your strategies as needed. Your role is essential for growing a business and building strong brand loyalty."
      ]
    },
    {
      title: "Challenges Faced by Chief Marketing Officers in the Industry",
      description: [
        "When you’re working as a chief marketing officer, you’re likely to face some challenges, especially as you progress in your career.",
        "One major challenge you’ll encounter is keeping up with rapidly changing technology and trends. With new marketing tools and platforms emerging constantly, you’ll need to stay updated to ensure your strategies remain effective.",
        "Another challenge is meeting high customer expectations. Modern consumers have higher demands than ever before, and it can be difficult to deliver consistently.",
        "You’ll also be expected to deal with intense competition. Standing out requires you to have innovative ideas and execute them effectively. To add to the pressure, you’ll likely face budget constraints, meaning you’ll need to achieve significant results with limited resources.",
        "Measuring the success of your marketing efforts can also be complicated. With so much data available, you’ll often struggle to identify what truly matters so you can make the best data-driven decisions.",
        "Balancing your short-term goals with long-term brand building is another hurdle. This is where you’ll be required to think strategically and be adaptable.",
        "All these obstacles are much easier to tackle when you have an experienced mentor to guide you. That’s where chief marketing officer career support can be beneficial.",
        "Your chosen chief marketing officer mentor can use their own experience to help you understand exactly how the industry works. They’ll work with you to handle any challenges that come your way and prevent you from making avoidable mistakes.",
        "If you want to learn how to excel at your job from someone who knows best, sign up with us to get chief marketing officer career advice."
      ]
    },
    {
      title: "What to Expect from Chief Marketing Officer Training Online",
      description: [
        "Career Navig8r’s online chief marketing officer mentorship is ideal if you want to enhance your skills and advance your career in this industry.",
        "Through virtual sessions, you’ll receive personalised advice tailored to your unique challenges and goals. Your chief marketing officer mentor will give you practical insights on how to develop effective marketing strategies and manage teams to help your business grow.",
        "These chief marketing officer career guidance sessions will also focus on building your critical skills, like data analysis and brand positioning. You can choose what to discuss with your mentor, including the real-world marketing problems you’re currently encountering, and they can give you CMO career advice based on their own experiences.",
        "Your mentor will also help you improve your leadership qualities and align your marketing efforts with your business objectives. Plus, you’ll gain access to valuable resources, tools, and networking opportunities, all of which can enhance your career.",
        "Our chief marketing officer training programme is conducted online, which means you can learn at your own pace and access expertise from anywhere. You’ll be able to schedule your sessions in a way that won’t disrupt your daily routine.",
        "Overall, chief marketing officer mentorship is an excellent way to grow professionally, gain fresh perspectives, and build your confidence to tackle the complex demands of your marketing role.",
        "If you’re ready to take your career to the next level, enrol in our programme to get chief marketing officer career support today."
      ]
    },
    {
      title: "Find a Chief Marketing Officer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const salesDirectorData = {
  mainTitle: "Sales Director Career Mentor",
  accordionData: [
    {
      title: "About Sales Director Mentoring",
      description: [
        "Looking to become a sales director? Or are you already a sales director and want to get better at your role?",
        "Career Navig8r’s sales director mentoring focuses on guiding professionals like you toward excelling in high-level sales leadership roles. It’ll give you valuable insights on strategic planning and team management, and teach you how to achieve your revenue goals.",
        "Through personalised coaching, your sales director mentor will help you refine your decision-making skills and develop effective sales strategies. They’ll also help you adapt to changing market trends.",
        "Our sales director training will build your communication and leadership abilities, so you can inspire and manage your sales teams effectively. With your mentor’s real-world advice and actionable feedback, you’ll be able to grow in your career and help your organisation succeed.",
        "Ready to move up the ranks in your sales career? Sign up for our sales director mentorship today."
      ]
    },
    {
      title: "The Job Role of a Sales Director",
      description: [
        "As a sales director, you’ll play a key role in driving your company’s revenue and growing the business. You’ll be responsible for developing and executing effective sales strategies to meet and exceed your sales targets.",
        "Your job will also include analysing market trends and looking for new business opportunities, as well as creating plans to expand your company’s customer base.",
        "You’ll be tasked with leading and managing your sales teams, guiding them and setting performance goals to ensure they’re motivated and have everything they need to succeed. You’ll also need to oversee the sales pipeline, track its progress, and evaluate the results to ensure they fit with your company’s objectives.",
        "Your role will likely involve collaborating with other departments, such as marketing and product development, to align all strategies and improve the overall business performance.",
        "To succeed as a sales director, you should be a strong leader with great communication skills and an analytical mind. You’ll need to encourage teamwork and maintain client relationships, as well as stay informed about industry changes at all times. If you’re successful in your role, you’ll help your company grow and stay competitive in the long run."
      ]
    },
    {
      title: "Challenges Faced by Sales Directors in the Industry",
      description: [
        "Being a sales director is demanding, and you’re likely to encounter some challenges along the way.",
        "One major challenge is meeting ever-increasing sales targets in a highly competitive market. You’ll need to constantly analyse market trends and adapt your strategies to stay ahead. Balancing your short-term revenue goals with long-term business growth can also be tricky.",
        "Another challenge is managing and motivating diverse sales teams. You’ll need to ensure your team members stay productive and aligned with your company’s goals, and that they’re equipped with the necessary skills to succeed. Your role will likely also involve recruiting and retaining top sales talent, which is an added difficulty, especially in competitive industries.",
        "Customer behaviour is constantly evolving, so as a sales director, you may find it challenging to maintain strong client relationships and deliver tailored solutions. To add to the challenge, you’ll be required to stay updated with all new tools and platforms that can improve your efficiency while keeping costs in check.",
        "Finally, you’ll be expected to work collaboratively with other departments, like marketing and operations, which is important for your organisation to succeed.",
        "To tackle these challenges, you’ll benefit from the expertise of someone who has dealt with similar issues before. That’s where our sales director guidance can help.",
        "With our thorough sales director mentorship and training, you’ll feel much more confident in how you handle your job. Your chosen sales director mentor will teach you everything you need to know about the role, so you can carry out your duties effectively and avoid making mistakes.",
        "If you’d like to reap the benefits of sales director mentoring, sign up for our sales director training today."
      ]
    },
    {
      title: "What to Expect from Sales Director Training Online?",
      description: [
        "Career Navig8r’s online sales director mentoring advice is tailored to your career goals. When you sign up, you’ll receive one-on-one sessions with a seasoned sales director mentor who’ll share their own experience-backed insights on leadership, strategic planning, and sales techniques.",
        "Your sales director training sessions will be personalised to your needs, which means they’ll focus on areas you’d like to improve in. For instance, if you want to learn how to improve your team’s performance or achieve your sales targets, your mentor can give you the targeted advice you need.",
        "Our online sales director training lets you learn at your pace, with flexible scheduling that fits into your routine. You’ll also get a chance to network with industry professionals through a virtual platform.",
        "You can expect to gain actionable advice on market trends, innovative tools, and methods to stay ahead in a competitive industry. Whether you're preparing for a sales director role or just want to broaden your current skill set, your sales director mentor will draw from their own experiences to address your real-world challenges and help you find solutions.",
        "You’ll have plenty of opportunities for interactive discussions, where you can learn from situations your mentor has encountered, and ask for feedback to improve your practical knowledge and build your confidence. With sales director mentoring, you’ll develop leadership skills and gain the expertise to excel in your role.",
        "Enrol in our sales director mentorship today."
      ]
    },
    {
      title: "Find a Sales Director Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const hrGeneralistData = {
  mainTitle: "HR Generalist Career Mentor",
  accordionData: [
    {
      title: "About HR Generalist Mentoring",
      description: [
        "Want to learn how to be a HR generalist? Or are you already working in this role and want to improve your skills?",
        "Career Navig8r’s HR generalist mentoring programme provides tailored guidance to help HR professionals like you excel in their multifaceted roles. As a HR generalist, you’ll need to handle diverse responsibilities, from recruiting and engaging employees to resolving conflicts, and mentoring can give you invaluable support in these areas.",
        "You’ll learn from a skilled HR generalist mentor, who’ll draw on their own experience to share industry insights and best practices, enabling you to tackle complex challenges confidently.",
        "Our HR generalist mentorship will build your communication and decision-making skills, helping you to evolve into an exceptional leader. You’ll also receive personalised feedback to address your unique goals.",
        "In all, HR generalist training will improve your knowledge about the role and boost your confidence, helping you succeed and advance your career effectively.",
        "If you’re keen to climb the corporate ladder in the HR industry, sign up for our HR generalist training today."
      ]
    },
    {
      title: "The Job Role of a HR Generalist",
      description: [
        "As a HR generalist, your main job is to manage the core functions of your company’s human resources department. You’ll be responsible for a wide range of tasks, including recruiting and onboarding new employees, maintaining employee records, and ensuring your company complies with labour laws and company policies.",
        "You’ll also need to manage payrolls and employee benefits and set up workplace safety programmes to ensure a smooth and efficient work environment. Your role will typically involve handling employee relations, including offering support during conflicts and encouraging positive communication in the office.",
        "You’ll often need to act as a bridge between management and employees, ensuring that both parties' needs are met. Your role will require balancing administrative tasks with strategic thinking, such as implementing policies to improve employee engagement and retention.",
        "As a HR generalist, you’ll wear many hats to help your organisation operate efficiently, and you’ll contribute to a positive and productive company culture. To succeed in this role, you should have excellent communication and organisational skills and be able to solve all manner of employee-related issues quickly and calmly."
      ]
    },
    {
      title: "Challenges Faced by HR Generalists in the Industry",
      description: [
        "When you’re working as a HR generalist, you’ll encounter a variety of challenges due to the complicated nature of their role.",
        "One major hurdle is managing the balance between your administrative duties and strategic responsibilities. When you’re handling routine tasks like payroll and compliance, you’ll be left with little time for initiatives like improving employee engagement or workforce planning.",
        "Another challenge is keeping up with the UK’s changing labour laws and regulations, which means you’ll need to constantly stay updated to ensure your company remains compliant. You’ll also deal with sensitive employee issues, such as resolving conflicts and addressing grievances, which will require you to be empathetic and put your excellent communication skills to use.",
        "Managing different employees’ expectations while making sure they’re aligned with your organisation’s goals can also be difficult. Plus, you’ll often face high workloads and tight deadlines, which can lead to stress and burnout.",
        "Another common challenge is adapting to technology advancements, such as new HR software, and using data-driven decision-making. Both of these will require you to continuously upskill in your role.",
        "To tackle these obstacles effectively, you’ll need the expertise of someone who has dealt with them before. That’s where our HR generalist mentorship can help.",
        "When you sign up for our training, you’ll receive advice and guidance from a HR generalist mentor who has years of experience in this role. Your chosen mentor will teach you all about the industry and the job, helping you handle challenges and improve your confidence.",
        "Want to enhance your skill set and grow in the HR industry? Sign up for our HR generalist mentorship."
      ]
    },
    {
      title: "What to Expect from HR Generalist Training Online?",
      description: [
        "Career Navig8r’s online HR generalist mentoring is a convenient way to get personalised guidance from a HR expert.",
        "In your virtual HR generalist training sessions, you’ll connect with an experienced mentor, who’ll teach you how to manage diverse HR responsibilities, from recruitment and compliance to employee engagement and conflict resolution.",
        "When you sign up, you’ll receive tailored advice that addresses your unique challenges, whether you’re unsure how to handle sensitive employee issues or you’re struggling to stay updated with labour laws and industry trends.",
        "Our online HR generalist coaching also focuses on developing your skills, such as communication, problem-solving, and leadership, which are essential for the job. You’ll gain practical tools and strategies to streamline your tasks like payroll management and performance evaluations.",
        "Your HR generalist mentor may help you to learn proactively by giving you real-world scenarios based on their own experiences and training you to find solutions. They’ll assess your progress regularly and offer you constructive feedback on how to improve.",
        "The flexible online format of our HR generalist mentorship means you can learn at your own pace, access resources anytime, and apply your newfound knowledge at work without disrupting your daily routine.",
        "In all, HR generalist mentoring can help build your skills and confidence, allowing you to make a meaningful impact in your organisation.",
        "Ready to learn from the best in HR? Enrol in our HR generalist mentorship today."
      ]
    },
    {
      title: "Find a HR Generalist Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const hrDirectorData = {
  mainTitle: "HR Director Career Mentor",
  accordionData: [
    {
      title: "About HR Director Mentoring",
      description: [
        "Do you want to learn how to be a HR director? Or are you already working in this role and want to work towards a promotion or a raise?",
        "Career Navig8r’s HR director mentoring programme is designed to guide HR leaders who oversee human resources at a senior level. You’ll learn to refine your strategies and manage complex workforce challenges, allowing you to align your HR goals with the overall business objectives.",
        "Through one-on-one HR director training, you’ll gain insights into leadership techniques, resolving conflicts, managing talent, and honing your decision-making skills. Your chosen HR director mentor will share their own real-world perspectives and proven methods for handling high-level responsibilities.",
        "Whether you want to learn how to handle organisational changes or build a strong company culture, our HR director mentorship will teach you how to excel in your role.",
        "Ready to improve your HR skills? Sign up for our HR director training guidance today."
      ]
    },
    {
      title: "The Job Role of a HR Director",
      description: [
        "To learn how to be a HR director, you should first have a good understanding of what this job entails.",
        "Your main duties in this role will involve shaping your organisation’s workforce and culture. As a senior leader, you’ll need to oversee the entire human resources function, ensuring it supports your company’s strategic goals.",
        "Your responsibilities will include developing and implementing HR policies as well as managing recruitment and acquiring new talent. You’ll also be tasked with finding innovative ways to keep employees engaged and satisfied.",
        "Your job will focus on building a positive workplace culture and promoting diversity and inclusion. You’ll also be expected to ensure your company complies with all labour laws and regulations.",
        "As a strategic advisor to senior management, you’ll often be required to provide insights on workforce planning, leadership development, and organisational change. Within your role, you’ll likely have to handle complex challenges, such as resolving workplace conflicts and managing performance issues.",
        "Besides overseeing employee training and development programmes, your job will also include designing compensation and benefits structures that attract and retain top talent.",
        "Overall, your role is essential for creating a productive, motivated, and well-supported workforce."
      ]
    },
    {
      title: "Challenges Faced by HR Directors in the Industry",
      description: [
        "Throughout your career as a HR director, you’ll likely face some challenges, especially since this industry can be competitive and the regulations are always changing.",
        "One of the biggest challenges you’ll encounter is managing a diverse workforce while trying to create an inclusive and equitable workplace culture. Balancing the needs of employees from varied backgrounds and ensuring they’re treated fairly can be difficult.",
        "Another challenge you’ll probably face is getting to grips with technological advancements. You’ll need to learn how to use tools like HR management software and adapt to new trends such as remote work. Additionally, to retain top talent in this competitive job market, you’ll need to use unique strategies and create effective employee engagement initiatives.",
        "Ensuring your company complies with the changing labour laws and regulations is also a constant concern, because failing to do so could lead to serious legal and financial consequences. You’ll also need to manage organisational change, such as mergers or restructuring, while keeping disruptions to a minimum.",
        "As a director of HR, you’ll often face high-pressure demands to align your HR objectives with business goals. This means you’ll need to find the right balance between keeping your employees satisfied and taking action to succeed as a company. These challenges can be difficult to tackle, especially if you’ve never faced them before. That's where you could benefit from the guidance of someone who has years of experience in this role.",
        "Our HR director training offers the opportunity to gain knowledge and expertise from some of the best professionals in the industry. Your chosen HR director mentor will guide you through the ups and downs of the job, helping you avoid making common mistakes.",
        "If you’re interested in receiving thorough HR director mentoring to advance your career, sign up for our HR director training today."
      ]
    },
    {
      title: "What to Expect from HR Director Training Online?",
      description: [
        "Career Navig8r’s online HR director mentoring is a convenient way to gain valuable insights and enhance your leadership skills.",
        "Our HR Director mentorship is designed to help you tackle high-level HR challenges, including strategic workforce planning and building a strong organisational culture. You’ll also learn more about the complicated employment laws, so you can ensure your company abides by them.",
        "After signing up, you can expect to receive personalised guidance tailored to your unique industry, company size, and goals.",
        "Your HR director mentor will also give you practical advice and teach you about some of the best practices in your role, sharing real-world examples based on their own experiences.",
        "Working with your mentor, you'll learn to make better decisions, improve your conflict resolution skills, and manage employee relations more effectively. Your mentor will assess your progress and provide feedback to help you improve.",
        "One of the biggest benefits of our HR director training is that it’s conducted virtually. This format makes it easy to schedule your sessions, access resources, and connect with experienced mentors globally, as well as tapping into excellent networking opportunities.",
        "In all, our HR director mentoring will help you build your confidence in handling critical responsibilities while also providing a sounding board for your innovative ideas. If you’re ready to learn how to be a HR director, enrol in our HR director training today."
      ]
    },
    {
      title: "Find a HR Director Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const prDirectorData = {
  mainTitle: "PR Director Career Mentor",
  accordionData: [
    {
      title: "About PR Director Mentoring",
      description: [
        "Do you want to learn how to be a PR director? Or are you already working in this role and need PR director career advice to advance in your profession?",
        "Career Navig8r’s PR director mentoring programme pairs experienced public relations (PR) leaders with aspiring or new PR directors like you, with the aim of helping them develop their skills and succeed in their roles.",
        "When you sign up for our programme, you’ll be able to choose a PR director mentor who’ll give you industry insights and teach you strategic communication techniques. They’ll provide the training you need to be a good leader.",
        "Through PR director career guidance, you’ll learn all about crisis management, media relations, and branding strategies. Our PR director mentorship will also give you access to valuable networking opportunities and help you tackle challenges confidently.",
        "Whether you’re looking to improve your storytelling skills or manage high-profile campaigns, you can benefit from expert PR director training sessions. With the right PR director career support, you can refine your approach and build strong industry connections.",
        "To learn from an expert in PR, sign up for our public relations director training programme today."
      ]
    },
    {
      title: "The Job Role of a PR Director",
      description: [
        "As a PR director, you’ll be responsible for managing your company’s public image and communication strategies. You’ll need to create and oversee campaigns to promote brand awareness, while also handling media relations and managing crisis communication.",
        "Your role will generally include writing press releases and organising press conferences, and you’ll need to build strong relationships with journalists and influencers. You’ll also work closely with marketing teams to ensure your brand’s marketing messaging is consistent across all channels.",
        "All PR directors are expected to monitor the public perception of a brand and respond to media inquiries, ensuring a company maintains a positive reputation. In your role, you may also be tasked with training executives on public speaking and interview techniques.",
        "To learn how to be a PR director, you’ll need to work on being a good leader. You’ll be required to manage PR teams and set goals for them, while also developing long-term PR strategies for your company.",
        "Strong communication and creativity are key aspects of this job. You’ll also need to be capable of solving problems effectively, as your team will often come to you for solutions.",
        "Overall, your efforts will influence how the public and media perceive your brand, and that means doing everything in your power to ensure its image remains strong and trustworthy."
      ]
    },
    {
      title: "Challenges Faced by PR Directors in the Industry",
      description: [
        "Being a PR director isn’t an easy job, and you’re likely to face numerous challenges as you try to manage your responsibilities.",
        "One major challenge you’ll encounter is crisis management. Negative press or social media backlash can damage your brand’s reputation, so in these cases, you’ll need to take quick action and respond strategically.",
        "Another challenge is staying relevant in a constantly changing media landscape. With digital platforms evolving, you’ll be expected to adapt to new trends, tools, and audience behaviours. You may also struggle to manage media relationships, as journalists and influencers receive countless pitches daily, making it harder to get coverage.",
        "Plus, you’ll need to balance transparency and confidentiality, which means sharing enough information to maintain trust while protecting sensitive company details. Given the fast-paced nature of the industry, you’ll often work under tight deadlines and high-pressure situations, so you’ll need to have strong problem-solving skills.",
        "Finally, since PR success isn’t always immediately measurable, you’ll need to find effective ways to convince executives of its importance.",
        "All these challenges can be difficult to get to grips with, especially if you don’t have the necessary PR director career support. If you’re struggling to deal with any of these challenges, you’ll greatly benefit from the expert guidance of someone with experience in this particular role. That’s where our PR director mentoring programme comes in.",
        "With our public relations director training, you’ll learn everything you need to know about this industry. Your chosen PR director mentor will help you understand which skills you should improve on and how you can avoid common mistakes.",
        "Want to learn how to be a PR director? Or do you need PR director career advice to excel in this role? Sign up for our PR director mentorship today."
      ]
    },
    {
      title: "What to Expect from PR Director Training Online?",
      description: [
        "With Career Navig8r’s online PR director mentoring programme, you’ll get PR director career guidance from experienced professionals through virtual sessions.",
        "When you sign up, you can expect to receive personalised PR director career advice on whatever you might need help with, from communication strategies and crisis management to media relations and leadership skills.",
        "Through our PR director training, you’ll be able to improve your storytelling techniques and press release writing, and learn how to develop effective PR campaigns. Your mentor may also share tips on handling media interviews and building strong industry connections.",
        "Your PR director mentor will draw from their own experiences to give you real-world examples, helping you to apply your learning to actual situations. They’ll also assess your progress and provide constructive feedback to help you improve.",
        "All our public relations director training sessions are held online, giving you the flexibility to learn at your own pace. You’ll get access to complete PR director career support without having to compromise on your personal or professional responsibilities.",
        "Ready to learn how to confidently tackle challenges and advance in the PR industry? Gain expert knowledge by enrolling in our PR director mentorship programme."
      ]
    },
    {
      title: "Find a PR Director Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const prSpecialistData = {
  mainTitle: "PR Specialist Career Mentor",
  accordionData: [
    {
      title: "About PR Specialist Mentoring",
      description: [
        "Are you looking to become a PR specialist? Or have you recently started working in this role and need PR specialist mentorship to grow in your career?",
        "Career Navig8r’s PR specialist mentoring programme is designed to help professionals like you build strong communication skills and media relationships for a successful career in public relations.",
        "Whether you're new to this role or looking to advance towards a promotion or a raise, our PR specialist training sessions will help you become the best at what you do.",
        "Your chosen PR specialist mentor will draw from their own real-world experiences to support you in your role. They’ll teach you to craft press releases and manage crises, and they’ll share tips on how to create impactful campaigns. They’ll also provide personalised career advice to help you tackle challenges and seize opportunities.",
        "With the right PR specialist career support, you can refine your brand’s storytelling and enhance your messaging, as well as become more confident in handling media interactions.",
        "If you want to learn everything you should know and more about public relations, our PR specialist mentoring is exactly what you need. To get PR specialist guidance from an expert, sign up with us today."
      ]
    },
    {
      title: "The Job Role of a PR Specialist",
      description: [
        "As a PR specialist, you’ll be responsible for managing your company’s public image and maintaining strong relationships with the media.",
        "Your main job will include creating and sharing positive stories about your brand through press releases and social media, as well as hosting and attending PR events. You’ll work closely with journalists, influencers, and stakeholders to ensure the right messages reach the public.",
        "Crisis management is a key aspect of your job. If your brand receives negative publicity, you’ll need to handle the situation by responding quickly and professionally. You’ll also be tasked with monitoring media coverage and tracking public perception, so you can adjust your communication strategies as needed.",
        "Your role will likely involve setting up promotional campaigns and press conferences, as well as planning and executing interviews to enhance your brand’s reputation. For this, you’ll need strong writing and communication skills, and you should be able to solve PR-related problems as and when they arise.",
        "Ultimately, you play an essential role in shaping how your company is perceived, ensuring its reputation remains positive and engaging with the right audience effectively."
      ]
    },
    {
      title: "Challenges Faced by PR Specialists in the Industry",
      description: [
        "When you’re working as a PR specialist, you’ll likely face many challenges in managing your company’s reputation and public image.",
        "One of the biggest challenges is handling negative publicity or crises. Whether it’s bad press, social media backlash, or misinformation, you’ll need to respond quickly and effectively to protect your brand’s reputation.",
        "Building strong connections with journalists and influencers also takes time and effort, and getting media coverage isn’t always easy. With so much competition for attention, you’ll need to create compelling stories to stand out.",
        "The PR industry is fast-paced and demanding, and this comes with its own challenges. Trends, social media algorithms, and public opinions are constantly changing, which means you need to stay updated and adapt your strategies quickly.",
        "Additionally, figuring out if your PR campaigns are successful can be difficult. Unlike direct sales, PR results aren’t always easy to quantify, so you’ll have a hard time proving the value of your efforts to clients or executives.",
        "To handle these challenges, you need a creative and strategic mindset, which can take years to develop. The good news is that our PR specialist training programme can help you get there faster.",
        "Your PR specialist mentor will have years of experience in your role, so they can expertly guide you through the ups and downs of the job. They’re on hand to provide exceptional PR specialist career advice, ensuring you avoid making mistakes along the way.",
        "Want to experience the benefits of getting PR specialist career support from an expert? Sign up for our PR specialist mentorship today."
      ]
    },
    {
      title: "What to Expect from PR Specialist Training Online?",
      description: [
        "Career Navig8r’s online PR specialist mentoring programme can give you all the guidance you need to grow and succeed in the field of public relations.",
        "After signing up, you can schedule one-on-one PR specialist training sessions with an experienced mentor who’ll help you develop your skills in media relations, crisis management, and PR strategy. Whether you want to focus on writing better press releases or developing effective PR campaigns, your mentor is here to give you targeted PR specialist career advice.",
        "Our PR specialist mentorship is conducted online, which means you have the flexibility and convenience of learning from anywhere. You’ll receive personalised feedback to improve your PR skills, and your PR specialist mentor can help you build confidence in handling media interviews and press events.",
        "A key benefit of our PR specialist guidance is networking. Your mentor may introduce you to their industry contacts, opening doors to more career opportunities in the future.",
        "Enrol in our PR specialist career support programme today as it’s a great way to gain expert industry knowledge and enhance your PR career."
      ]
    },
    {
      title: "Find a PR Specialist Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const complianceAnalystData = {
  mainTitle: "Compliance Analyst Career Mentor",
  accordionData: [
    {
      title: "About Compliance Analyst Mentoring",
      description: [
        "Do you want to learn how to become a compliance analyst? Or have you already started working in this role and are looking for compliance analyst career guidance to help improve your skills?",
        "Career Navig8r’s compliance analyst mentoring programme can help professionals like you get to grips with complex regulations, risk management, and ethical standards.",
        "You’ll be able to choose a compliance analyst mentor who’ll guide you through industry best practices and compliance frameworks, and teach you how to handle real-world challenges.",
        "Your mentor is on hand to offer compliance analyst career advice, helping you develop critical thinking and problem-solving skills. These one-on-one compliance analyst training sessions will strengthen your knowledge and make you more confident in regulatory compliance.",
        "Whether you're new to the field or looking to advance towards a promotion or raise, your mentor can give you all the compliance analyst career support you need to grow professionally. They’ll also help you stay updated with evolving laws and industry changes, making you more effective in your role.",
        "Ready to learn from the best in the industry? Sign up for our compliance analyst mentorship programme today."
      ]
    },
    {
      title: "The Job Role of a Compliance Analyst",
      description: [
        "As a compliance analyst, your main job is to ensure that your company follows all the applicable laws, regulations, and internal policies.",
        "You’ll be responsible for assessing risks and reviewing procedures, as well as recommending improvements to avoid legal or financial issues. You’ll also need to monitor business activities and conduct audits.",
        "If your company violates any regulations or laws, you’ll need to conduct investigations to get to the root of the issue. You’ll then be expected to suggest solutions and help implement corrective actions.",
        "Given the responsibility of your role, you’ll be expected to stay updated on new regulations to keep your company compliant. Additionally, it may fall to you to train employees on policies and ethical standards, ensuring everyone understands their responsibilities.",
        "To succeed in this role, you’ll need to have great attention to detail and excellent problem-solving skills. You should also have deep and thorough knowledge of industry regulations, which vary depending on the field you work in.",
        "Your work will help protect your company from legal trouble and build trust with customers and regulators. You’ll need to keep your business safe, ethical, and legally sound by ensuring all operations meet the necessary guidelines."
      ]
    },
    {
      title: "Challenges Faced by Compliance Analysts in the Industry",
      description: [
        "When you’re working as a compliance analyst, you’re bound to face many unique challenges in your role, especially as you climb higher up the corporate ladder.",
        "One major challenge is keeping up with constantly changing regulations. Laws and industry rules tend to evolve frequently, which means you’ll need to stay updated to ensure your company remains compliant.",
        "You’ll also be tasked with managing complex and time-consuming compliance processes. This means you’ll need to review large amounts of data, conduct audits, and ensure every department follows regulations, which can be overwhelming.",
        "There may be times when you have to deal with resistance from employees. Some employees may see compliance rules as restrictive or unnecessary and might refuse to comply, making it difficult for you to enforce policies.",
        "Additionally, handling compliance violations can be stressful. You’ll be expected to investigate issues and report your findings, as well as recommend corrective actions, often under tight deadlines.",
        "Another challenge is balancing your business’ goals with compliance requirements. Your company will likely be focused on growing and making profits, but you need to ensure that your teams follow strict rules while doing so.",
        "Overall, tackling these challenges requires you to have a deep understanding of the industry. For this, you’ll greatly benefit from compliance analyst career guidance from someone who has dealt with similar problems in their career. That’s where our compliance analyst mentorship comes in.",
        "With compliance analyst training from an expert, you’ll learn everything you need to know about your job. Your chosen compliance analyst mentor can teach you how to excel in this role and avoid making common mistakes.",
        "If you’re looking for compliance analyst career support to advance in your job, sign up for our compliance analyst mentoring programme today."
      ]
    },
    {
      title: "What to Expect from Compliance Analyst Training Online?",
      description: [
        "Career Navig8r’s online compliance analyst mentoring is ideal if you’re looking to excel in your field. After enrolling, you’ll be able to schedule one-on-one compliance analyst training sessions with an experienced professional, who’ll share their own industry insights and best practices.",
        "Your compliance analyst mentor will help you understand everything from complex regulations and risk management to compliance frameworks. They’ll also give you tips on handling audits and enforcing policies, as well as teaching you how to tackle ethical challenges.",
        "You’ll receive exceptional compliance analyst career advice, including resume tips and interview guidance, along with professional development strategies. Your mentor will also offer you practical insights tailored to your industry, whether it’s finance, healthcare, or technology.",
        "During your sessions, you can ask questions and discuss your challenges regarding the various compliance processes you may be dealing with. Your mentor will draw from their own experiences to provide compliance analyst career guidance, including real-world examples.",
        "With the virtual format of our compliance analyst mentorship programme, you can learn at your own pace through video calls, emails, or chats. You’ll have access to mentors from around the world, expanding your professional network.",
        "In all, with compliance analyst career support, you can build your confidence and improve your skills, making you more effective and knowledgeable in your role."
      ]
    },
    {
      title: "Find a Compliance Analyst Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const retailData = {
  mainTitle: "Retail Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Retail Mentoring",
      description: [
        "Are you looking to work in the retail industry? Or have you already started your job in this field and want to improve your skills through retail career guidance from an expert?",
        "Career Navig8r gives you access to job-specific retail mentoring to help you develop the skills needed to succeed in your role. Whether you’re interested in learning about customer service, sales techniques, or inventory management, your chosen retail mentor can give you guidance tailored to the real workplace challenges you’ll come up against.",
        "With this hands-on method of learning, you’ll gain the skills and knowledge needed to become more confident in your role and improve your job performance. Through your mentor’s expert retail career advice, you’ll learn what it takes to grow as an employee and climb the corporate ladder within your company.",
        "If you’re a new hire, you can benefit from working with an experienced retail mentor who’ll share their insights. Equally, if you’re a seasoned employee, this retail training will help you refine your expertise to progress further in your role.",
        "Our retail mentoring programme will give you all the retail career support you need. If you’re ready to advance your career in retail, sign up with us to get retail career mentorship today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Retail Professionals",
      description: [
        "As a new retail professional, you’ll likely face many challenges as you start your career in the industry.",
        "Something you may struggle with at first is learning how to provide excellent customer service while handling difficult situations, such as complaints or demanding shoppers. You’ll also need to quickly understand how your store operates in terms of inventory management, sales strategies, and teamwork.",
        "You might also feel in over your head as you adjust to the fast-paced retail environment and the long working hours, which can be physically and mentally exhausting. As a new retail worker, you’ll likely have a low starting salary and limited career growth opportunities, making it tough to stay motivated.",
        "Technology is another issue, as modern retail relies on digital tools like POS systems and online sales, as well as customer databases. To be an efficient and well-organised employee, you’ll need to learn to confidently use all these systems quickly.",
        "Finally, there’s strong competition in the retail job market, which is why you need to stand out by gaining experience and improving your communication skills. You’ll find this easier to do with one-on-one mentor support in our retail training programme.",
        "If you’re ready to overcome these challenges and build a successful career in retail, sign up with us to find a retail mentor who can give you expert retail career guidance."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Retail Roles?",
      description: [
        "Anyone looking to grow in the retail industry can benefit from a retail mentor, regardless of the exact role you have or the area you’re keen to work in.",
        "If you’re a new hire, you’ll benefit from a mentor’s retail career advice to understand store operations, improve your customer service skills, and adapt to this fast-paced industry. Our online retail career mentorship programme can help you gain confidence and avoid common mistakes as you get started in your role.",
        "If you’re a mid-level retail professional who wants to move into a management role, you’ll also gain a lot from retail mentoring. Your mentor will work with you to build your leadership and team management skills, and you’ll have the opportunity to learn the best sales strategies from an expert. This will help you prepare for promotions and bigger responsibilities.",
        "Even as an experienced retail worker, you could benefit from getting retail career support from a mentor, especially when you’re transitioning to a new role, such as corporate retail, merchandising, or e-commerce. Your retail mentor can provide insights on industry trends and career planning, while also guiding you through developing your skills.",
        "Plus, if you’re a freelancer or entrepreneur in retail, such as a boutique owner or an online seller, you can also use retail training to handle the unique challenges you’ll face as a business owner.",
        "Regardless of your career level in this industry, having a retail mentor will make it easier for you to succeed and reach your career goals in retail."
      ]
    },
    {
      title: "What To Expect from Our Retail Training Programme Online?",
      description: [
        "Career Navig8r’s online retail mentoring programme can give you all the guidance and support you need to grow in your retail career. After signing up, you’ll be able to schedule one-on-one retail training sessions with an experienced retail mentor who’ll teach you about customer service, sales techniques, and store operations.",
        "During your retail mentorship, your chosen mentor will help you identify your career objectives and create action plans to achieve them. They’ll give you practical retail career advice on how to handle difficult customers and manage inventory, as well as train you to use digital sales tools effectively.",
        "You’ll have access to numerous networking opportunities, allowing you to connect with industry professionals who can open doors to new jobs in the future. Your retail mentor will also give you feedback and real-world examples to help you refine your skills and stay updated on the latest retail trends.",
        "Ultimately, our retail mentoring programme offers complete retail career support and knowledge, keeping you motivated as you grow in this industry."
      ]
    },
    {
      title: "Find Your Retail Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const salesData = {
  mainTitle: "Sales Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Sales Mentoring",
      description: [
        "Are you considering starting a career in sales? Or do you already work in sales and need sales career advice to excel in this highly competitive role?",
        "Career Navig8r’s sales mentoring programme will help you develop essential skills and confidence, so you can easily smash your sales targets.",
        "You’ll have the opportunity to choose a sales mentor with experience in your specific role, who can work closely with you, offering sales career guidance and teaching you everything from closing deals and handling objections to understanding your customers’ needs. Your mentor will share their real-world insights and strategies, helping you tackle challenges more confidently.",
        "With this personalised sales career support, you’ll be able to perform better and grow faster in your sales role. Whether you're new to sales or looking to refine your skills, your sales mentor will offer all the valuable tips and motivation you need to succeed.",
        "With the right sales training, you can improve your communication, negotiation, and relationship-building abilities. Ready to advance your sales career? Sign up for our sales mentorship programme today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Sales Professionals",
      description: [
        "Regardless of your experience in the sales industry, you’ll likely encounter numerous challenges as you work your way up the corporate ladder.",
        "One of the main challenges that you might struggle with, especially when you’re starting out in sales, is handling rejection. You’ll often hear 'no' from potential customers, which can be discouraging. You need to learn how to avoid taking rejections personally and stay motivated even when business is slow.",
        "Another challenge is understanding your customers’ needs. If you’ve recently become a salesperson, you may struggle to ask the right questions and offer suitable solutions, which can hinder your ability to make a sale.",
        "Building confidence in this role is also difficult. Without experience, you may feel nervous when you’re pitching or negotiating deals. Time management is another issue you may face, as you’ll need to balance prospecting, follow-ups, and meetings, all of which require strong organisational skills.",
        "Plus, as a new sales professional, you’ll need to adapt to different sales techniques and technologies, such as CRM software, which can feel overwhelming at first. You’ll also be under pressure to hit your sales targets, as your company will expect results quickly.",
        "To overcome these challenges, you’ll benefit significantly from sales career guidance from a sales mentor with experience in your role. Your mentor is also on hand to give you all the sales training you need to improve your skills and excel in your career.",
        "Enrol in our sales mentoring programme today."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Sales Roles?",
      description: [
        "Anyone working in the sales industry can benefit from having a sales mentor, whether you’re a beginner or an experienced professional looking to grow.",
        "If you’re fresh into a sales role, you’ll need to learn how to handle objections and close deals, as well as how to build customer relationships. Your chosen sales mentor can give you real-world sales career advice and motivation to help you gain confidence and improve your performance.",
        "If you’re a mid-level salesperson looking to advance in your career, you can also benefit from sales training. Your mentor will help you refine your strategies and improve your negotiation skills, as well as teach you how to become a better leader and give you insights into industry trends.",
        "Even if you’re an experienced sales professional, you’ll benefit from sales career support when you’re transitioning to a leadership role or entering a new market. Through sales mentorship, you’ll learn to adapt to changing sales techniques, so you can stay ahead and continue growing.",
        "No matter the experience level you’re at, having access to expert sales career guidance and knowledge can make a big difference in your career success."
      ]
    },
    {
      title: "What To Expect from a Sales Training Programme Online?",
      description: [
        "Career Navig8r’s online sales mentoring programme is an ideal way to improve your sales skills. After signing up, you’ll have access to virtual, one-on-one sales training sessions with your chosen job-specific sales mentor.",
        "Your mentor will work with you to help you succeed in your role, offering expert sales career advice and guiding you through any concerns you may be facing in your job. They’ll also offer personalised feedback, helping you refine your sales techniques and become a more confident salesperson.",
        "You can expect your mentor to draw from their own experience to give you real-world sales scenarios, helping you to learn better and progress faster. They may also conduct regular assessments to test your progress.",
        "Flexibility is a big benefit of our sales mentorship. As our programme is entirely online, you can learn at your own pace and schedule sessions based on your availability. You’ll also get access to unique networking opportunities, allowing you to connect with industry experts and other sales professionals.",
        "In all, our online sales mentoring programme can keep you motivated and help you become more efficient at your job, so you can hit targets and achieve your career goals quickly. It’s a valuable investment for anyone working in sales.",
        "Sign up with us to get expert sales career guidance today."
      ]
    },
    {
      title: "Find Your Sales Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const hospitalityData = {
  mainTitle: "Hospitality and Events Management Mentor",
  accordionData: [
    {
      title: "About Job-Specific Hospitality and Events Management Mentoring",
      description: [
        "Do you want to work in the hospitality and events management industry? Or are you already working in this field and need hospitality training or events management training to get better at your job?",
        "Career Navig8r offers job-specific hospitality mentoring and events management mentoring to help professionals like you gain practical skills and insights in this industry. You’ll be able to handpick a mentor who has experience in your job and can guide you through real-world challenges, from customer service and event planning to crisis management and leadership.",
        "Our hospitality mentorship programme offers a hands-on way to improve your problem-solving and communication skills, teaching you to work better with your team. No matter how long you’ve been in the industry, getting hospitality career advice or events management career advice from an expert can do wonders for your confidence.",
        "Through your hospitality and events management mentorship, you’ll have the opportunity to learn from someone who has been in your shoes. You’ll also be able to take advantage of our programme’s unique networking opportunities and connect with industry experts.",
        "Want hospitality career guidance or events management career guidance to help you develop the skills needed to succeed in this industry? Sign up for our events management and hospitality mentoring programme today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Hospitality and Events Management Professionals",
      description: [
        "When you’re working in the hospitality and events management industry, you’ll likely face many challenges in your role.",
        "The first roadblock you’ll encounter is gaining hands-on experience. When you’re looking for jobs, you’ll find that many roles require practical skills. As a newcomer, you’ll often struggle to get opportunities without prior experience.",
        "Another challenge you’ll probably face in your role is managing high-pressure situations. The events and hospitality industry is fast-paced, with tight deadlines, demanding clients, and unexpected issues. That means you’ll need to learn to stay calm and think quickly.",
        "You’ll also often deal with long and irregular working hours, which can be tough mentally and physically. You may need to work on weekends and major holidays, as well as late nights, which can affect your work-life balance.",
        "Building a strong network is another issue that you might face. Your ability to succeed in hospitality and events management often depends on your connections, but if you’re new to the role, you may not know where to start.",
        "Finally, it can be quite difficult to stand out in this competitive industry. You’ll need to develop strong communication and problem-solving skills and learn to be an excellent leader.",
        "Overall, tackling these obstacles takes patience and hard work. Many people working in this industry could benefit from having expert hospitality career support or events management career support, and that’s where our hospitality training programme comes in.",
        "To advance your career with hospitality career advice and events management career advice, sign up with us."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Hospitality and Events Management?",
      description: [
        "Anyone looking to grow in the hospitality and events management industry can benefit from a career mentor. Whether you’re a recent graduate or an experienced professional aiming to climb the corporate ladder, our hospitality mentorship programme can help you.",
        "As a newcomer to the industry, you’ll struggle with gaining experience and handling pressure, as well as understanding industry expectations. Your chosen hospitality and events management mentor can help you deal with these challenges, helping you to build confidence in your role.",
        "If you’re a mid-career professional looking to get promoted or change your career, you’ll also benefit from hospitality training. Your hospitality mentor can offer advice on advancing in the industry and improving your management skills, as well as helping you expand your professional network.",
        "In the case that you’re an entrepreneur looking to start your own business, you can gain industry-specific hospitality career advice and events management career advice from your mentor. They’ll also provide tips on how to avoid common mistakes as you’re starting out and building your company.",
        "In all, hospitality career support and events management career support can boost your motivation and give you all the practical knowledge you need, and online mentoring is invaluable to your career growth in this industry.",
        "Enrol in our hospitality and events management mentorship programme today."
      ]
    },
    {
      title: "What To Expect from a Hospitality and Events Management Training Programme Online?",
      description: [
        "Career Navig8r’s online hospitality mentoring and events management mentoring programmes provide you with the guidance and support you need to grow in your career.",
        "Once you sign up, you’ll be able to schedule one-on-one hospitality training sessions with your chosen mentors, who’ll share real-world advice on handling challenges and improving your skills.",
        "You can discuss topics and challenges related to your existing or desired role, including event planning, customer service, crisis management, and leadership. Your hospitality and events management mentor may also give you feedback on your resume and help you prepare for interviews, as well as provide career strategies.",
        "You’ll get numerous networking opportunities throughout your mentorship, with the chance to connect with industry experts and potential employers as well as other like-minded professionals, helping you build valuable relationships that will benefit you in the future.",
        "Flexibility is a major advantage of our online hospitality and events management mentoring programme. With its online format, you can schedule the sessions at convenient times, making it easier for you to participate without disrupting your routine.",
        "If you’re ready to reap the benefits of hospitality career guidance and events management career guidance, sign up with us today."
      ]
    },
    {
      title: "Find Your Hospitality and Events Management Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const socialCareData = {
  mainTitle: "Social Care Mentor",
  accordionData: [
    {
      title: "About Job-Specific Social Care Mentoring",
      description: [
        "Are you looking to become a social care worker? Or are you already in this profession and need social care training to improve your work?",
        "Career Navig8r’s job-specific social care mentoring programme can help professionals like you develop the skills and confidence needed to succeed in this industry. You’ll get social care career guidance tailored to real-life challenges, such as handling sensitive situations and improving your communication.",
        "Your chosen social care mentor can help you with specific areas of learning, like better understanding care regulations. If you’re working towards a promotion, your mentor will give you practical social care career advice, helping you adapt and progress quickly.",
        "Our social care mentorship programme will help you feel supported, reducing your stress and improving your job satisfaction. With the skills you gain, you’ll be better equipped to provide better quality of care to vulnerable people.",
        "Through your mentor’s expert social care career advice based on real-world scenarios, you’ll be able to make informed decisions and provide compassionate, effective support to those who need it most.",
        "Need social care career support to grow in the industry? Sign up for our social care mentoring programme today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Social Care Professionals",
      description: [
        "When you’re working as a social care professional, you’re likely to face many challenges, regardless of your experience in your role.",
        "One of the biggest difficulties is managing emotional stress. You’ll be working with vulnerable people, which can be overwhelming, especially when they’re dealing with sensitive issues like illness, abuse, or mental health struggles.",
        "Another challenge is understanding the complex regulations and legal requirements you’ll need to adhere to. Social care involves strict policies, so if you’re new to the role, you’ll need to learn to follow guidelines correctly while providing compassionate support.",
        "You may also struggle with time management. Many social care roles involve handling multiple cases or clients, so you might find it difficult to balance your workload and personal well-being.",
        "Plus, it takes time to learn how to make decisions confidently. As a new professional, you may feel uncertain when it comes to handling difficult situations or communicating with your clients and their families. The social care industry is also known for its issues with limited resources and staff shortages, which can make the job even harder.",
        "To tackle these challenges, you’ll benefit greatly from proper social care training from someone with experience in your specific role. That’s where our social care mentorship programme can help.",
        "To get social care career guidance from an expert in the industry, sign up with us."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Social Care Roles?",
      description: [
        "Anyone working in social services can benefit from a social care mentor, especially if you’re new to the field or looking to grow in your role.",
        "If you’re just getting started in social care, you’ll particularly need social care career guidance to handle the emotional and practical challenges of the job. Your mentor can help you build confidence and improve your communication skills, as well as teach you all about complex care regulations.",
        "If you’re a mid-career professional who wants to advance, you can also benefit from social care training. Whether you’re keen to take on a leadership role or a specialised position, your mentor can give you social care career advice on how to develop new skills and where to look for opportunities.",
        "Even as an experienced social care worker, you’ll find value in a mentor’s social care career support. With ever-changing policies and new care techniques emerging, there’s always something to learn. Your social care mentor can offer fresh perspectives and solutions to any challenges you may face.",
        "In all, our social care mentoring programme can help you at any stage of your career in this industry. If you want to provide better care for those who rely on your support, sign up for our social care mentorship."
      ]
    },
    {
      title: "What To Expect from a Social Care Training Programme Online?",
      description: [
        "Career Navig8r’s online social care mentoring programme can give you all the guidance and support you need to advance your career.",
        "When you sign up for our course, you’ll be connected with an experienced social care mentor who’ll offer practical social care career advice, helping you handle challenges in your job. Through your virtual social care training sessions, you’ll be able to build your skills and learn how to solve problems easily.",
        "Working with your mentor, you’ll receive personalised social care career guidance tailored to your specific needs. Your mentor will draw from their own experiences in the role to help you become more confident and perform better, avoiding work-related stress.",
        "With its online format, our social care mentoring programme is flexible, allowing you to learn at your own pace. Plus, you’ll have access to industry experts from around the world, helping you broaden your professional network.",
        "Want to reap the benefits of this learning opportunity? Enrol in our social care mentorship programme today."
      ]
    },
    {
      title: "Find Your Social Care Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const sciencePharmaceuticalsData = {
  mainTitle: "Science and Pharmaceuticals Mentor",
  accordionData: [
    {
      title: "About Job-Specific Science and Pharmaceuticals Mentoring",
      description: [
        "Are you looking to get a job in the science and pharmaceutical industry? Or are you already working in the industry and want to improve your skills with science and pharmaceuticals training?",
        "Career Navig8r’s job-specific science and pharmaceuticals mentoring can help you gain practical knowledge and improve your skills, so you can easily handle your career challenges.",
        "Our mentoring course gives you the opportunity to handpick an experienced science and pharmaceuticals mentor who’ll guide you on industry trends, research methods, regulatory requirements, and career growth strategies.",
        "Getting science and pharmaceuticals career guidance can be especially helpful given that this is a highly regulated field where staying updated is essential.",
        "Whether you’re a lab technician learning new techniques or a researcher trying to get a leadership role, our science and pharmaceuticals mentorship will provide tailored support. You’ll gain more confidence and enhance your problem-solving abilities, while also getting access to unique networking opportunities. With the right science and pharmaceuticals career support, you can advance your career and contribute effectively to your company.",
        "Need science and pharmaceuticals career advice to climb the corporate ladder? Sign up for our science and pharmaceuticals mentoring programme today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Science and Pharmaceutical Professionals",
      description: [
        "When you’re working in the science and pharmaceuticals industry, you’ll likely encounter many challenges.",
        "One major hurdle is gaining practical experience. Most jobs in this field require hands-on skills that aren’t always covered in academic studies. That means you’ll probably struggle to get a job as a graduate without experience.",
        "Understanding complex regulations and compliance rules is another challenge, as the industry is highly regulated. You’ll also be expected to constantly learn and keep up with rapid scientific advancements, which can be overwhelming.",
        "Networking as a new face in the industry can also be difficult, and with the field attracting highly qualified candidates, many newcomers struggle with job competition. You may also feel stressed and burnt out as a result of trying to balance your work pressure, long hours, and research deadlines.",
        "The best way to deal with these challenges is by getting science and pharmaceuticals career advice from someone with years of experience in the industry. That’s where our science and pharmaceuticals mentorship programme comes in.",
        "Want to develop your skills and excel in your career? Sign up with us and get access to science and pharmaceuticals career guidance today."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Science and Pharmaceuticals Roles?",
      description: [
        "Working with a career mentor can benefit anyone in the science and pharmaceuticals industry, from students to experienced professionals.",
        "If you’re a recent graduate entering the workforce, you may struggle with gaining practical skills or even applying for jobs. Your science and pharmaceuticals mentor can guide you on how to pick the right career path and build your resume, as well as offer strategies for searching for jobs.",
        "If you’re an early-career or mid-career professional, our science and pharmaceuticals mentorship can help you tackle your workplace challenges and improve your technical skills. Whether you’re a researcher or a scientist looking to transition into a leadership role, you can benefit from science and pharmaceuticals career advice.",
        "If you’re an experienced professional, you can still learn from a science and pharmaceuticals mentor when you’re shifting to a new specialisation, handling complex projects, or aiming to advance in your career. Even if you’re an entrepreneur in the pharmaceutical sector, you can work with a mentor to gain valuable insights into business strategies and regulatory requirements.",
        "No matter what career stage you’re at, having a mentor will give you the clarity and confidence you need to grow. With science and pharmaceuticals career support, you’ll be able to make informed decisions and succeed in the industry."
      ]
    },
    {
      title: "What To Expect from a Science and Pharmaceuticals Training Programme Online?",
      description: [
        "Career Navig8r’s online science and pharmaceuticals mentoring programme gives you a chance to learn from an experienced professional in the field.",
        "After you sign up, you can schedule one-on-one science and pharmaceuticals training sessions through video calls. Your chosen science and pharmaceuticals mentor will give you real-world scenarios based on their own experiences.",
        "Depending on where you’re at in your career, your mentor may provide personalised science and pharmaceuticals career advice, or teach you about research techniques, lab skills, and project management. Additionally, you’ll get access to opportunities with industry experts, which can open doors to new career prospects.",
        "Flexibility is a key benefit of our science and pharmaceuticals mentorship. With our programme’s online format, you can learn at your own pace and connect with mentors worldwide.",
        "By joining our online science and pharmaceuticals mentoring programme, you can enhance your skills and confidently advance your career in this competitive industry."
      ]
    },
    {
      title: "Find Your Science and Pharmaceuticals Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const creativeArtsDesignData = {
  mainTitle: "Creative Arts and Design Mentor",
  accordionData: [
    {
      title: "About Job-Specific Creative Arts and Design Mentoring",
      description: [
        "Are you looking to enter the creative arts and design industry? Or are you already in this field and need creative arts and design training to get better at your job?",
        "Career Navig8r’s job-specific creative arts and design mentoring programme is ideal if you’re looking to refine your skills and tackle challenges in your career. Unlike general mentoring, our online mentoring lets you pick a creative arts and design mentor based on your specific role.",
        "Your mentor, often an experienced artist or designer, will provide expert career guidance drawing from their experience in the creative arts and design industry. They’ll teach you exactly what you want to learn, from building a strong portfolio to adapting to new design tools.",
        "With your mentor’s valuable insights and real-world advice, you’ll learn to get better at solving problems and think more creatively, allowing you to grow faster in the industry.",
        "This creative arts and design career support can help you develop more confidence and improve your technical abilities, while also opening doors to new opportunities. Whether you're an aspiring graphic designer, illustrator, or filmmaker, having a mentor can help you grow and succeed in this field.",
        "Ready to develop your unique style and gain practical creative arts and design career advice? Sign up for our creative arts and design career mentorship programme today."
      ]
    },
    {
      title: "Challenges Faced by Emerging Creative Arts and Design Professionals",
      description: [
        "In the creative arts and design industry, you’re likely to face some challenges as you build your career.",
        "One major challenge is competition. This industry is crowded, which means it can be difficult to stand out and find opportunities. Another issue is staying financially stable, especially if you’re freelancing and have irregular income.",
        "A lack of experience can also be a problem. Many jobs will expect you to have a strong portfolio, so as a beginner, you’ll often struggle to get involved in paid practical projects. You’ll need to network to get ahead in this career, but building industry connections can take time.",
        "Any creative arts professional will tell you that keeping up with new tools and technology is essential, and given that design trends and software are constantly evolving, this can be challenging. You may face creative burnout, as you’ll be working long hours to meet deadlines while trying to maintain originality.",
        "Lastly, balancing your creative freedom with your clients’ expectations can be tricky, as clients may request changes that limit your artistic expression.",
        "To overcome these challenges, you need to be patient and persistent. You’ll also benefit significantly from creative arts and design career guidance from someone who has experience in your role and has dealt with similar issues.",
        "When you enrol in our creative arts and design training programme, you can choose a mentor who’ll guide you through the ups and downs of the industry. They’ll provide all the creative arts and design career advice you need to excel in your job and avoid making mistakes.",
        "Want to reap the benefits of creative arts and design career support? Sign up with us to find your creative arts and design mentor today."
      ]
    },
    {
      title: "Who Needs a Career Mentor for Creative Arts and Design Roles?",
      description: [
        "Anyone in the creative arts and design industry can benefit from having a career mentor, whether you’re a beginner or an experienced professional.",
        "If you’re an aspiring artist or designer, you’ll find creative arts and design career guidance invaluable when you’re building a strong portfolio and finding job opportunities. Your mentor can help you understand the industry expectations and improve your technical skills.",
        "If you’re a freelancer, you can also benefit from creative arts and design mentorship. Since you work independently, you may struggle with managing your clients, as well as pricing and marketing your services. A mentor with experience can give you valuable creative arts and design career advice on handling these challenges.",
        "Even if you’re a mid-career professional looking to advance or switch your speciality, you’ll find our creative arts and design training useful. Your chosen mentor can provide insights into industry trends and new tools that you can leverage to become more competitive. They can also help expand your professional network.",
        "Regardless of your experience in this field, having a creative arts and design mentor can help you build on your confidence and open doors to better opportunities. Sign up with us to get complete creative arts and design career support."
      ]
    },
    {
      title: "What To Expect from a Creative Arts and Design Training Programme Online?",
      description: [
        "Career Navig8r’s online creative arts and design mentoring programme will give you all the support you need to advance your career. Once you choose your mentor, you’ll be able to get started with virtual one-on-one creative arts and design training sessions to build your skills.",
        "Your mentor will offer you personalised feedback on your work, helping you improve your creative skills and refine your artistic style. They may also guide you on freelancing and networking to grow your career, and assign you real-world projects to enhance your experience.",
        "As our mentoring course is entirely online, you’ll be able to schedule sessions around your career and access mentors from different locations. This also means your creative arts and design career guidance sessions won’t disrupt your routine.",
        "With our creative arts and design mentorship, you can build the confidence you need to navigate your creative career more effectively, as well as expand your professional network.",
        "Want expert creative arts and design career advice? Sign up with us today."
      ]
    },
    {
      title: "Find Your Creative Arts and Design Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now."
      ]
    }
  ]
};

export const transportAndLogisticsData = {
  mainTitle: "Transport and Logistics",
  accordionData: [
    {
      title: "About Job-Specific Transport and Logistics Mentoring",
      description: [
        "Are you looking to get a job in the transport and logistics industry? Or are you already working in this industry and need transport and logistics training to excel at your job?",
        "Career Navig8r’s job-specific transport and logistics mentoring programme can help you gain the skills and knowledge needed for your role. Your chosen mentor will guide you on industry standards and safety regulations and teach you the best practices for efficiency.",
        "Whether you’re a truck driver, warehouse manager, or supply chain coordinator, our transport and logistics mentorship will give you access to hands-on learning and real-world insights. You’ll have a real opportunity to grow as you receive transport and logistics career advice on problem-solving and technology use.",
        "With the right transport and logistics career guidance, you can improve your work performance and become more confident in this fast-paced role. Sign up with us to find your transport and logistics mentor today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Transport and Logistics Professionals",
      description: [
        "When you’re working in the transport and logistics industry, you’re likely to face many challenges, especially as you climb the corporate ladder.",
        "One of the most common struggles you may face is understanding complex regulations and compliance requirements, which vary by region and industry standards. You’ll also need to learn how to manage supply chains efficiently and handle unexpected delays, such as weather disruptions or vehicle breakdowns, which can be overwhelming.",
        "Technology is another challenge, as modern logistics rely on advanced tracking systems, automation, and data management. To adapt to these tools, you’ll need proper training and experience.",
        "Additionally, you may be required to work long hours or have to deal with high-pressure situations. This can lead to stress and fatigue, especially if you’re a truck driver or a warehouse worker.",
        "Communication and coordination in this field are essential, as you’ll be working with multiple teams, suppliers, and customers. This can be challenging if you’re a newcomer. ",
        "Finally, your career growth may feel slow due to the competitive job market.",
        "To overcome these challenges, you’ll benefit greatly from transport and logistics training from someone with practical experience in this industry. That’s where our transport and logistics mentoring programme comes in.",
        "Your chosen transport and logistics mentor will guide you through the ups and downs of your specific role. They’ve likely dealt with similar issues to the ones you’re currently facing, which means they can help you find better solutions and avoid making mistakes.",
        "Want transport and logistics career support to build your confidence and expertise in the industry? Sign up for our transport and logistics mentorship today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Transport and Logistics?",
      description: [
        "Getting transport and logistics career guidance from a mentor can benefit anyone in the industry, from newcomers to experienced professionals looking to grow.",
        "If you’re a fresh graduate or an entry-level worker, you may struggle to get to grips with industry regulations, technology, and supply chain management. This is where a transport and logistics mentor can help you gain practical knowledge and help you build your confidence.",
        "If you’re a mid-career professional, like a truck driver, warehouse manager, or logistics coordinator, you can also benefit from transport and logistics career support. Your mentor can help you improve your leadership skills and teach you how to adapt to new industry trends like automation and digital tracking systems.",
        "Even if you’re a business owner or an entrepreneur in logistics, you can use your mentor’s transport and logistics career advice to improve your operational efficiency and customer service, using your new knowledge to expand your business. As a seasoned professional, you can still benefit from your mentor’s fresh perspectives and industry insights.",
        "Overall, anyone looking to improve their skills or advance their career in the industry can benefit from a transport and logistics mentor. ",
      ]
    },
    {
      title: "What To Expect from a Transport and Logistics Training Programme Online?",
      description: [
        "With Career Navig8r’s online transport and logistics mentorship, you’ll get valuable guidance at any stage of your career.",
        "Once you enrol, you can schedule one-on-one transport and logistics training sessions, where a professional with experience in your role will share their insights on important topics like supply chain management and transport regulations.",
        "Your chosen mentor will help you develop your problem-solving skills and become more efficient in your role. You can also expect to receive expert transport and logistics career advice on how to set goals and grow in your career.",
        "Another key benefit of our transport and logistics training programme is the networking opportunities it provides. You can connect with industry experts, allowing you to gain exposure to different perspectives and best practices. You’ll also learn from real-world scenarios presented by your mentor, helping you apply your knowledge effectively.",
        "Since our programme is online, you can learn at your own pace while balancing your work commitments. In all, with the right transport and logistics career support, you’ll gain the skills and knowledge needed to succeed in your job.",
      ]
    },
    {
      title: "Find Your Transport and Logistics Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const charityAndVoluntaryWorkData = {
  mainTitle: "Charity and Voluntary Work",
  accordionData: [
    {
      title: "About Job-Specific Charity and Voluntary Work Mentoring",
      description: [
        "Are you interested in doing charity and voluntary work? Or are you already working in a related role and need charity and voluntary work training to perform better?",
        "Career Navig8r’s job-specific charity and voluntary work mentoring programme can help you develop the skills and knowledge needed for this rewarding line of work. This type of mentoring will offer you practical guidance on everything from fundraising strategies to volunteer management.",
        "You’ll be able to hand-select an experienced charity and volunteer work mentor from our pool of professionals, who’ll offer insights on handling challenges unique to the sector, such as limited resources and community engagement. They’ll also help you build confidence and grow professionally.",
        "Whether you're new to the field or looking to advance, your mentor can provide valuable charity and voluntary work career advice tailored to your role. Learning from someone with experience will help you make a greater impact and navigate your career more effectively.",
        "Ready to reap the benefits of getting expert charity and voluntary work career guidance? Sign up with us for charity and voluntary work mentorship today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Charity and Voluntary Work Professionals",
      description: [
        "Getting involved in charity and volunteer work can be rewarding, but it does have its challenges, especially if you’re trying to build a career in this industry.",
        "One of the main issues you’ll face is limited funding, which can make it difficult to run projects effectively and secure stable salaries. You may also struggle with gaining relevant experience, as most roles require practical skills in fundraising, event planning, or even community outreach.",
        "Another challenge you’ll have to deal with is emotional strain. Working with vulnerable communities is a unique experience, but can also be mentally exhausting. ",
        "In many roles within this industry, you’ll probably experience periods where you feel burnt out due to heavy workloads and high expectations. Additionally, your career growth may be slow, as job opportunities in the sector are often competitive.",
        "Networking can be particularly complicated for charity and voluntary careers. Unlike corporate industries, charity work relies heavily on connections, so you’ll need to work hard to find charity and volunteer work mentors and build relationships.",
        "To succeed in this industry, you need to be passionate and adaptable, and stay committed to your cause. You’ll find it easier to overcome these obstacles with the right charity and voluntary work career support.",
        "Our charity and voluntary work training programme allows you to choose a mentor with experience in your specific role. Your mentor can teach you everything you need to know to excel in your job and act as a sounding board for all the concerns and questions you may have.",
        "If you’re keen to get charity and voluntary work career advice from an expert, sign up with us today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Charity and Voluntary Work?",
      description: [
        "A career mentor can benefit anyone working in the charity and voluntary sector, especially those looking to grow and succeed.",
        "If you’re new to the industry, charity and voluntary work mentoring can help you understand how things work, from fundraising to managing volunteers. Your mentor can also help you find the right opportunities and build essential skills.",
        "If you’re a mid-career professional who wants to take on a leadership role, you’ll also benefit from having a charity and volunteer work mentor. They can teach you how to handle challenges and improve your decision-making skills.",
        "Our charity and voluntary work mentorship can also expand your professional network. Plus, if you’re struggling with burnout or feeling stuck, a mentor can offer advice to keep you motivated and maintain a healthy work-life balance.",
        "If you’re an experienced professional looking to switch roles within the sector or start your own nonprofit, you can find value in mentorship. Having someone to share knowledge and provide charity and voluntary work career support makes a big difference.",
        "No matter what career level you’ve reached, getting charity and voluntary work career guidance can help you grow and make a bigger impact. Sign up for our charity and voluntary work mentorship today.",
      ]
    },
    {
      title: "What To Expect from a Charity and Voluntary Work Training Programme Online?",
      description: [
        "Career Navig8r’s online charity and voluntary work mentoring programme will give you all the guidance, support, and skill-building opportunities you need to excel at your job and climb the corporate ladder.",
        "Once you sign up, you’ll be able to schedule virtual meetings with an experienced charity and volunteer work mentor who understands the challenges of the sector. They’ll guide you on topics like fundraising, volunteer management, networking, and career growth.",
        "Your mentor will conduct structured charity and voluntary work training sessions, helping you set goals and track your progress. You’ll also receive practical tips on handling real-world challenges, as your mentor can give you charity and voluntary work career advice based on their own experiences.",
        "Since our programme is online, you can connect with mentors from different locations, gaining diverse insights and perspectives. You’ll also get many networking opportunities with other professionals in the field, which can benefit your career in the future.",
        "Overall, you can benefit significantly from charity and voluntary work career support when it comes to improving your confidence and your job skills, helping you succeed in the industry.",
      ]
    },
    {
      title: "Find Your Charity and Voluntary Work Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const lawEnforcementAndSecurityData = {
  mainTitle: "Law Enforcement and Security",
  accordionData: [
    {
      title: "About Job-Specific Law Enforcement and Security Mentoring",
      description: [
        "Do you want to become a law enforcement officer? Or are you already working in this industry and need law enforcement and security training to improve your skills?",
        "Career Navig8r’s job-specific law enforcement and security mentoring programme focuses on providing tailored guidance and support to people in these demanding fields. When you sign up, you’ll be able to choose a law enforcement and security mentor from our pool of professionals. ",
        "Your mentor will be on hand to share their practical knowledge and give you law enforcement and security career advice, so you can excel in your role. From handling high-pressure situations to understanding legal protocols, you’ll learn everything you need to know from their real-world insights, going beyond basic training.",
        "With your mentor’s law enforcement and security career guidance, you’ll be able to build your mental resilience and leadership skills, and learn how to make more ethical decisions.",
        "Your mentor will work with you to address the unique challenges you may be facing in your job. They’ll help you improve your performance and become more confident in handling various situations effectively and responsibly.",
        "To grow your expertise through law enforcement and security career support, sign up with us today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Law Enforcement and Security Professionals",
      description: [
        "When you’re working in the law enforcement and security industry, you’re likely to face many challenges at different stages of your career.",
        "One major challenge is managing high-pressure situations, such as emergencies, conflicts, or criminal incidents, which can be stressful and overwhelming. You’ll also need to adapt quickly to constantly changing laws and procedures to stay effective and compliant in your role.",
        "You may find it difficult to build trust within your community, especially when you’re dealing with public concerns about safety and fairness. Plus, if you’re new to the role, you’ll often face ethical dilemmas that require strong judgment and integrity.",
        "Your physical and mental health may be affected due to demanding work hours and exposure to traumatic events. Plus, the stress of the job can make it tough to balance your personal life with your professional responsibilities.",
        "Overall, gaining the right skills to progress in your career can be difficult without proper law enforcement and security career guidance. That’s where our law enforcement and security mentorship comes in.",
        "Your chosen mentor will be able to refer back to their own experience in your specific role to discuss how they dealt with similar challenges. They can give you all the law enforcement and security career advice you need to excel in your role and avoid making common mistakes.",
        "Want law enforcement and security training to climb the corporate ladder? Sign up for our law enforcement and security mentorship today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Law Enforcement and Security?",
      description: [
        "A career mentor can be valuable for anyone in the law enforcement and security field, whether you’re just starting out or looking to grow your career.",
        "If you’re a new professional entering the industry, you can benefit from a law enforcement and security mentor who’ll provide guidance on handling stressful situations. Your mentor can also help you understand legal protocols and build essential skills like communication and leadership.",
        "If you’re looking to transition to a higher position or take on a specialised role, you can gain valuable insights from an experienced mentor who has been through similar challenges. Your mentor can offer law enforcement and security career advice on ethical judgment and strategic thinking, which are both essential if you want to advance in this industry.",
        "Additionally, if you’re a higher-level officer facing work-related stress or seeking a better work-life balance, you could benefit from getting law enforcement and security career support from a mentor. Your mentor can guide you on how to manage your mental and emotional well-being in your demanding role.",
        "Overall, whether you need help dealing with challenges in your job or you just want to expand your skill set, having access to expert law enforcement and security career guidance can be extremely beneficial for your personal and professional growth.",
      ]
    },
    {
      title: "What To Expect from a Law Enforcement and Security Training Programme Online?",
      description: [
        "Career Navig8r’s online law enforcement and security mentoring programme offers convenient, structured guidance to help you grow in your career. ",
        "Through our virtual law enforcement and security training sessions, you can connect with an experienced mentor who can offer their advice on how to handle real-world challenges you might face.",
        "Your law enforcement and security mentor will give you practical tips and feedback based on your specific goals and needs. They’ll help you understand theoretical concepts by giving you real examples from their own experiences.",
        "Our online law enforcement and security mentorship is designed for flexible learning, so you can go at your own pace while still keeping up with your demanding work schedule. Your mentor may also help you expand your networking opportunities, giving you a chance to build connections with other professionals in the industry.",
        "Ready to boost your confidence and advance your career? Sign up with us to get law enforcement and security career support today.",
      ]
    },
    {
      title: "Find Your Law Enforcement and Security Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const publicServicesAndAdministrationData = {
  mainTitle: "Public Services and Administration",
  accordionData: [
    {
      title: "About Job-Specific Public Services and Administration Mentoring",
      description: [
        "Do you want to work in a public service or admin role? Or have you already started your career in this industry and need public services and administration training to improve your job performance?",
        "Career Navig8r’s job-specific public services and administration mentoring programme is designed to help professionals excel in roles like public policy, government administration, and social services. After signing up, you’ll be able to pick a mentor with experience in your specific role. ",
        "Your chosen public services and administration mentor will be on hand to give you practical insights and industry knowledge, helping you build the skills needed to succeed in your job. They’ll help you tackle challenges you may face in your role and teach you all about policy development and administrative processes.",
        "Through this public services and administration career guidance, you’ll learn how to make smarter decisions and communicate better. With your newfound knowledge, you’ll be better prepared to confidently handle real-world scenarios and advance your career.",
        "If you want to improve your ability to serve your community and drive positive change, sign up for our public services and administration mentorship programme today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Public Services and Administration Professionals",
      description: [
        "When you’re working in public services and administration, you’re likely to come across various challenges throughout your career.",
        "One of the biggest challenges you’ll face is getting to grips with complex policies and regulations, which often change year-by-year. You’ll also need to learn bureaucracy and administrative processes, which can feel overwhelming, especially when you’re dealing with multiple departments and stakeholders.",
        "To succeed in this industry, you’ll be expected to have great communication and leadership skills. Public service roles often involve working with diverse groups, so you’ll need to make decisions that impact your community. That means you’ll need to be capable of finding ways to balance conflicting interests while maintaining transparency and fairness, which can be tough.",
        "In most roles, there are limited resources to work with, and you’ll be under the pressure of high expectations. If you’re new to the role, you may struggle to meet your goals with tight budgets, strict guidelines, or even political influences.",
        "To overcome these hurdles, you’ll benefit significantly from public services and administration career support from someone with extensive experience in your role. That’s where our public services and administration mentorship comes in.",
        "Your chosen mentor has likely dealt with similar challenges to the ones you’re facing now, so they can help you find the best solutions without making common mistakes. They can also teach you all about the industry and give you expert public services and administration career advice to help you grow.",
        "If you’d like to advance your career with public services and administration training, sign up with us today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Public Services and Administration?",
      description: [
        "Anyone working in public services and administration can benefit from a career mentor, especially if you’re just starting out in your career or looking to advance.",
        "If you’re new to this industry, there’s a good chance you’ll often find yourself overwhelmed by complex policies and regulations, as well as bureaucratic processes. Your public services and administration mentor can help you understand these systems and develop the skills needed to implement them effectively.",
        "If you’re a mid-career professional looking to grow or transition to a leadership role, you can also benefit from mentorship. Your mentor will provide valuable public services and administration career advice on how to build your leadership and communication skills and make better decisions.",
        "Plus, if you’re looking to switch your specialisation within public services or aiming for a higher-level position, your mentor will give you public services and administration career guidance on how to approach these changes confidently.",
        "Even as a seasoned professional, you can benefit from public services and administration career support when you’re trying to adapt to new policies or technologies.",
        "In short, no matter what career stage you’ve reached, if you want to enhance your job skills or overcome career challenges, our public services and administration mentorship is for you.",
      ]
    },
    {
      title: "What To Expect from a Public Services and Administration Training Programme Online?",
      description: [
        "Career Navig8r’s online public services and administration mentoring programme is designed to give you valuable guidance and support to grow in your career. ",
        "After enrolling, you can expect to receive personalised public services and administration career advice from an experienced mentor who understands the industry's challenges and requirements.",
        "Through your one-on-one public services and administration training sessions, you’ll be able to develop skills like leadership, communication, policy management, and decision-making. Your mentor will also guide you through complex processes you’ll be expected to know, provide feedback on your progress, and offer tried-and-tested strategies to deal with challenges you may face.",
        "Our online public services and administration mentorship is designed for flexible learning, so you can virtually connect with mentors from anywhere at a time that suits you. You’ll also have access to valuable networking opportunities, which can benefit your career in the future.",
        "Overall, receiving expert public services and administration career guidance can help you gain more confidence and improve your skills, so you can do better in your role and confidently work towards your dream career.",
      ]
    },
    {
      title: "Find Your Public Services and Administration Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const leisureSportsAndTourismData = {
  mainTitle: "Leisure, Sports and Tourism",
  accordionData: [
    {
      title: "About Job-Specific Leisure, Sports and Tourism Mentoring",
      description: [
        "Are you looking for a job in the leisure, sports and tourism industry? Or are you already working in the industry but need leisure training, sports training or tourism training to improve your skills and progress in your career?",
        "Career Navig8r’s job-specific leisure mentoring, sports mentoring, and tourism mentoring focus on providing guidance and support tailored to helping professionals in these fields. They’ll help you gain practical knowledge and enhance your skills, enabling you to handle the challenges of your industry.",
        "You can pick a mentor with relevant experience in your specific job who will offer valuable insights on customer service, event management, marketing, and leadership. This type of mentoring also helps you understand industry trends and improve your networking abilities, making you more confident in what you do.",
        "Whether you're a tour guide or a sports coach, having a mentor who understands your field can significantly enhance your career growth. With their expertise, you’ll be able to excel and achieve your professional goals.",
        "Need sports career advice or tourism career advice? Sign up for our leisure, sports and tourism mentorship today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Leisure, Sports and Tourism Professionals",
      description: [
        "When you’re working in the leisure, sports, and tourism industry, you’ll likely face many challenges as you advance in your career.",
        "One of the biggest challenges is high competition, with many talented people vying for limited positions. Additionally, this industry often demands irregular working hours, especially during peak seasons, making it difficult to have a good work-life balance.",
        "Not having enough practical experience can also be a hurdle, as employers often prefer candidates with hands-on knowledge. Plus, you’ll need to quickly adapt to changing trends and technologies, such as digital marketing and online booking systems, adding to the complexity of your job.",
        "Furthermore, networking is essential in this industry, but you may struggle to establish valuable connections if you're a newcomer. Getting a low entry-level salary can also be discouraging, especially when combined with the high cost of gaining certifications or specialised leisure training, sports training, and tourism training.",
        "To overcome these common challenges, you’ll need leisure, sports, and tourism career guidance from someone with experience in your role. They’ve likely dealt with similar issues and can help you find the best solutions.",
        "Need leisure career support, sports career support or tourism career support from an expert? Sign up for our leisure, sports and tourism mentoring programme today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Leisure, Sports and Tourism?",
      description: [
        "A career mentor can be helpful for anyone starting or growing their career in the leisure, sports, and tourism industry.",
        "Mentorship is a good career hack if you’re a fresh graduate trying to break into the field. It’ll give you access to leisure career guidance, sports career guidance or tourism career guidance to help you find the right opportunities.",
        "Mentoring is also valuable if you’re a mid-level professional looking to advance your career or switch to a different area within the industry. You can learn all the ins and outs of your industry from a leisure mentor, sports mentor or tourism mentor.",
        "If you’re a business owner or manager running a tourism company, sports facility, or leisure service, you can also benefit from mentorship. Getting leisure career advice, sports career advice, or tourism career advice is a great way to enhance your leadership skills and learn effective business strategies.",
        "Overall, whether you're an athlete or a hospitality manager, a mentor can provide all the valuable insights, feedback, and support you need to grow in your career.",
      ]
    },
    {
      title: "What to Expect From a Leisure, Sports and Tourism Training Programme Online?",
      description: [
        "Career Navig8r’s online leisure mentoring, sports mentoring, and tourism mentoring programme will guide and support you as you climb the corporate ladder.",
        "When you join our programme, you can expect to connect virtually with an experienced mentor who understands your industry. They’ll give you valuable advice on career planning, skill development, networking, and tackling industry-specific challenges.",
        "Your chosen mentor can help you build confidence, improve your communication skills, and guide you in setting achievable career goals. You’ll also receive constructive feedback on your work and networking opportunities to take your career to the next level.",
        "Our online leisure, sports and tourism mentorship is flexible, allowing you to schedule sessions that suit your availability. These sessions are usually one-on-one video calls and chat-based support to enhance your learning experience.",
        "If you’re looking for a convenient and effective way to advance your career, enrol with us to get leisure career support, sports career support and tourism career support from experts.",
      ]
    },
    {
      title: "Find Your Leisure, Sports and Tourism Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you might find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const mediaAndInternetData = {
  mainTitle: "Media and Internet",
  accordionData: [
    {
      title: "About Job-Specific Media and Internet Mentoring",
      description: [
        "Do you want to work in the media and internet industry? Or are you already in this industry and want media and internet training to improve your job performance?",
        "Career Navig8r’s job-specific media and internet mentoring focuses on providing guidance and support to help professionals tackle the unique challenges of these fields. It can help you build relevant skills and enhance your creativity, so you stay updated with rapidly evolving trends.",
        "You’ll have the opportunity to choose a media and internet mentor with experience in your specific role. They can give you valuable insights into content creation, digital marketing, journalism, or social media management.",
        "Your mentor will also help you understand audience preferences, improve your storytelling techniques, and teach you how to navigate various platforms effectively. With their practical media and internet career advice and feedback, you’ll feel more empowered to excel in this competitive landscape.",
        "Need media and internet career guidance to take your career to the next level? Sign up for our media and internet mentorship today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Media and Internet Professionals",
      description: [
        "When you’re working in the media and internet industry, you’ll encounter various challenges as you climb the corporate ladder.",
        "One major challenge is staying updated with quickly changing trends, technologies, and algorithms, especially in social media and digital marketing. If you’re a content creator, the pressure to consistently create engaging and relevant content can also be incredibly overwhelming.",
        "Additionally, building a personal brand and establishing a strong online presence takes time and effort, making it difficult to stand out in a crowded space. Competition is fierce, with countless creators and professionals aiming for the same audience or opportunities as you.",
        "Another challenge is handling feedback and criticism, which can be harsh or unconstructive, especially on social platforms. You’ll also have to deal with the stress of unstable job security and unpredictable income, particularly if you’re a freelancer.",
        "The best way to thrive in this demanding industry is to get media and internet career support from someone with experience in your specific job. That’s where our media and internet mentoring programme comes in.",
        "Your chosen mentor may have dealt with similar issues in their career, which means they can draw from their experiences to help you find better solutions. They can give you expert media and internet career advice on how to grow without making mistakes.",
        "Need media and internet career guidance to get better at your job? Sign up for our media and internet training programme today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Media and Internet Jobs?",
      description: [
        "Anyone starting their career in the media and internet industry or someone simply looking to grow in this sector can benefit from a career mentor. This includes content creators, journalists, digital marketers, social media managers, writers, graphic designers, and influencers.",
        "If you’re a beginner in any of these fields, you’ll need media and internet career guidance to understand industry standards, build your skills, and expand your network. A mentor can help you avoid common mistakes, making you more confident in your role.",
        "On the other hand, if you’re a mid-level professional aiming to level up your career, you’ll also benefit from media and internet mentorship. Whether you’re looking to transition to a new role or build a strong personal brand, getting media and internet career support from an expert can be extremely helpful.",
        "Even if you’re an established professional, you can take advantage of your mentor’s media and internet career advice. They’ll help you stay updated with changing trends and improve your leadership abilities, enabling you to achieve your goals.",
        "Overall, no matter what your career level is, it’s great to have a media and internet mentor who provides constructive feedback and personalised advice. This support makes it much easier for you to navigate challenges and succeed.",
      ]
    },
    {
      title: "What to Expect from a Media and Internet Training Programme Online?",
      description: [
        "Career Navig8r’s online media and internet mentoring programme will offer personalised guidance to help you grow and succeed in the industry.",
        "Once you enrol, you can expect one-on-one media and internet training sessions with an experienced mentor who understands your career goals and challenges. They’ll give you tailored advice, constructive feedback, and practical tips to improve your skills.",
        "Through media and internet mentorship, you’ll learn more about content creation, digital marketing, social media strategies, branding, and storytelling. You’ll also receive insights on how to build a strong online presence and opportunities to expand your network.",
        "Our virtual media and internet mentoring programme is also flexible, allowing you to schedule sessions to fit your routine. If you’re ready to advance your career with media and internet career guidance, sign up with us today.",
      ]
    },
    {
      title: "Find Your Media and Internet Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const energyAndUtilitiesData = {
  mainTitle: "Energy and Utilities",
  accordionData: [
    {
      title: "About Job-Specific Energy and Utilities Mentoring",
      description: [
        "Are you looking to work in the energy and utility industry? Or have you already secured a role in this industry and need energy and utilities career guidance to improve your job performance?",
        "Career Navig8r’s job-specific energy and utilities mentoring focuses on providing guidance tailored to the unique challenges and skills required in this field. You’ll be able to choose from a selection of energy and utilities mentors with industry experience, and they’ll help you develop the technical knowledge, safety practices, and leadership abilities needed for your role.",
        "Our energy and utilities mentorship will help you grow in your career by giving you practical insights and hands-on training. You’ll also have access to expert energy and utilities career advice, which will build your confidence and teach you problem-solving skills, so you can adapt easily.",
        "If you’re ready to connect with experienced professionals and gain valuable energy and utilities career support to excel in your job, sign up for our energy and utilities training programme today.",
      ]
    },
    {
      title: "Challenges Faced by Emerging Energy and Utilities Professionals",
      description: [
        "You’ll likely face many challenges throughout your career in the energy and utilities industry.",
        "One major challenge is keeping up with quickly evolving technologies, such as smart grids, renewable energy systems, and digital infrastructure. You’ll need to stay updated with these changes by continuously learning and developing your skills.",
        "You might also struggle to understand complex regulations and compliance requirements that vary across regions. If you’re new to the industry, getting to grips with these rules can be overwhelming. Safety is another main concern in your role, and you might find it challenging to maintain high safety standards when you’re still learning the ropes.",
        "Your role might not give you the opportunity to network, which can make it difficult to build meaningful connections that could potentially help you to grow in your career. This can present challenges if you’re interested in climbing the corporate ladder.",
        "Finally, you’ll be required to balance environmental sustainability with industry demands, which is a tough task, requiring you to come up with innovative solutions and maintain a clear understanding of the long-term impact of your actions.",
        "The best way to tackle these challenges is to work with an energy and utilities mentor. Getting energy and utilities career guidance from someone with experience in your specific role will help you to grow and excel in your industry.",
        "Your mentor can offer you all the energy and utilities career advice you need to face any obstacles that come your way. Since they’ve probably dealt with similar issues throughout their own careers, they can use their experience to guide you and help you find solutions.",
        "Want to reap the benefits of having expert energy and utilities career support? Sign up for our energy and utilities mentorship today.",
      ]
    },
    {
      title: "Who Needs a Career Mentor for Energy and Utilities?",
      description: [
        "Regardless of your career level in the energy and utilities industry, you can benefit from having a career mentor.",
        "If you’re a recent graduate starting your first job, a mentor can give you valuable energy and utilities career guidance. They can help you understand everything from technical processes and safety protocols to complex regulations.",
        "As a mid-level professional looking to transition to a leadership role, you can also benefit from energy and utilities mentorship, especially if you want to learn how to better manage projects and improve your communication skills.",
        "Additionally, if you’re aiming to switch your career within the industry, you’ll find energy and utilities career support extremely helpful. With the sector quickly evolving, having a mentor who offers practical energy and utilities career advice and shares real-world experiences can make a huge difference.",
        "Overall, our energy and utilities mentoring programme can help you overcome challenges and grow professionally. ",
      ]
    },
    {
      title: "What To Expect from an Energy and Utilities Training Programme Online?",
      description: [
        "Career Navig8r’s online energy and utilities mentoring programme offers convenient, guided support for professionals at all levels. Once you sign up, you can connect with an experienced mentor who’ll provide valuable advice and training tailored to your career goals.",
        "You’ll be able to attend virtual, one-on-one energy and utilities training sessions, where your chosen mentor will share practical knowledge based on their own experiences. They’ll also assess your progress and give you feedback on how you can improve your skills and excel at your job.",
        "Our online energy and utilities mentorship offers the flexibility of remote access, allowing you to learn at your own pace and access mentors from anywhere. As you’ll work one-on-one with your chosen mentor, the feedback and guidance you receive will be personalised for your situation.",
        "Plus, you’ll get to network with other professionals in your field, which can help you get better job prospects in the future.",
        "Whether you’re new to the industry or looking to advance in your role, our energy and utilities career guidance programme can help you take your career to a new level. ",
      ]
    },
    {
      title: "Find Your Energy and Utilities Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const vicePresidentEngineeringCareerMentorData = {
  mainTitle: "Vice President of Engineering Career Mentor",
  accordionData: [
    {
      title: "About Engineering Vice President Mentoring",
      description: [
        "Are you working towards becoming a vice president of engineering? Or have you recently achieved this high-level position and want engineering vice president career guidance to improve your skills?",
        "Career Navig8r’s engineering vice president mentoring programme is designed to guide engineers and future leaders. You’ll have the opportunity to learn from an experienced mentor who’ll help you develop technical expertise and improve your problem-solving skills, allowing you to be a better leader for your team.",
        "Your chosen engineering vice president mentor will share industry insights, best practices, and their personal experiences, helping you grow in your career. They’ll give you valuable engineering vice president career advice, teaching you how to be innovative and think strategically.",
        "Through one-on-one engineering vice president training sessions, you’ll get all the knowledge and support needed to become more confident and progress in your career.",
        "Would you like to experience the benefits of our engineering VP mentoring? Sign up with us to get engineering vice president career support from experts today.",
      ]
    },
    {
      title: "The Job Role of a Vice President of Engineering",
      description: [
        "As a VP of engineering, you’ll be the senior leader responsible for overseeing your organisation’s engineering team and projects.",
        "You’ll need to set technical strategies and ensure product development runs smoothly while keeping your engineering goals aligned with your business’s objectives. You’ll also need to manage teams, mentor young engineers, and innovate to drive your company’s growth.",
        "Your role will also include hiring top talent and improving internal processes. Plus, you’ll need to ensure that all projects meet the deadlines and necessary quality standards. ",
        "You’ll also often be required to collaborate with other departments, like product management and operations, to develop successful products. Additionally, you’ll be tasked with managing budgets, adopting new technologies, and ensuring your team follows industry best practices.",
        "To excel in this position, you have to be a strong leader and have the technical expertise to easily solve all kinds of problems. Your expertise will help shape your company’s technical direction.",
      ]
    },
    {
      title: "Challenges Faced by Engineering Vice Presidents in the Industry",
      description: [
        "When you’re working as an engineering VP, you’ll likely face many challenges in the industry, especially when it comes to balancing leadership, technology, and business needs.",
        "One major challenge will be to manage a growing team while maintaining productivity and motivation. As your team expands, you’ll need to ensure clear communication and alignment across all departments, which can be difficult.",
        "Another issue is keeping up with technological advancements. You’ll need to stay updated on new tools, trends, and best practices while ensuring your team does the same. Dealing with budget constraints can also add pressure to your job, as you’ll need to deliver high-quality projects within financial limits.",
        "You’ll also have to find ways to recruit and retain top engineering talent. With the competition being so fierce, you’ll need to work towards creating an environment that allows young engineers to be innovative and grow, keeping them satisfied in their jobs.",
        "Additionally, you’ll have to learn to balance your short-term project goals with your company’s long-term strategy, which requires careful decision-making. Plus, you should be adaptable enough to deal with unexpected technical failures, market shifts, or changing business priorities.",
        "To effectively tackle these challenges and navigate the industry, you’ll need engineering vice president career advice from someone with experience in this role. A mentor who has dealt with these issues is likely the best person to ask for engineering vice president career guidance.",
        "Our engineering vice president mentorship can give you access to all the knowledge and support you need to excel in your job. Your chosen mentor can guide you through the ups and downs of the industry, helping you find the right solutions to any challenges you may be dealing with.",
        "Need engineering vice president career support to climb the corporate ladder? Sign up for our engineering vice president mentoring programme today.",
      ]
    },
    {
      title: "What to Expect From Engineering Vice President Training Online?",
      description: [
        "Career Navig8r’s online engineering vice president mentoring programme provides valuable guidance to engineers and leaders looking to grow in their careers.",
        "Through virtual engineering vice president training sessions, you’ll receive expert advice on how to improve your technical skills, grow as a leader, and take your career forward. Your mentor will share their real-world experiences, helping you handle challenges and get better at solving problems.",
        "Your mentor will also give you engineering vice president career guidance on topics ranging from managing engineering teams and project strategies to adopting new technologies and improving decision-making skills.",
        "Additionally, you can expect personalised engineering vice president career advice and feedback, as well as networking opportunities that can help you advance professionally. Getting engineering vice president career support from an expert will also make you more confident in your role.",
        "Our online engineering vice president mentorship also offers flexibility, allowing you to connect with experienced professionals from anywhere. Plus, you can schedule sessions at your convenience, ensuring your learning doesn’t disrupt your routine.",
        "Whether you're an aspiring leader or an experienced engineer, you’ll need guidance to excel in the field. Enrol in our engineering vice president mentorship today.",
      ]
    },
    {
      title: "Find an Engineering Vice President Mentor at Career Navig8r Today",
      description: [
        "Whether starting your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const businessIntelligenceAnalystCareerMentorData = {
  mainTitle: "Business Intelligence Analyst Career Mentor",
  accordionData: [
    {
      title: "About Business Intelligence Analyst Mentoring",
      description: [
        "Want to learn how to become a business intelligence analyst? Or have you recently started working in this role and need business intelligence analyst training to excel at your job?",
        "Career Navig8r’s business intelligence analyst mentoring programme can help professionals like you develop the skills needed to analyse data, create reports, and provide insights for business decisions.",
        "You can choose a mentor who’ll guide you in using tools like SQL, Power BI, and Tableau while helping you improve your problem-solving and critical thinking abilities.",
        "Your chosen mentor will also use their real-world industry knowledge to give you business intelligence analyst career advice and teach you the best practices for handling data effectively.",
        "You’ll gain confidence in working with data and making data-driven decisions through regular discussions and hands-on business intelligence analyst career guidance. ",
        "Whether you're new to BI or looking to advance, having a business intelligence analyst mentor can speed up your learning process and career growth in this industry.",
        "Ready to climb the corporate ladder with help from an expert? Sign up for our business intelligence analyst mentorship today.",
      ]
    },
    {
      title: "The Job Role of a Business Intelligence Analyst",
      description: [
        "As a business intelligence analyst, you’ll be responsible for helping your company make smart decisions by analysing data. Your job will be collecting, organising, and studying data to find trends and patterns that can improve your business’s performance.",
        "You’ll be tasked with using tools like SQL, Power BI, and Tableau to create reports and dashboards, as well as visualisations that can help teams understand data easily. You’ll also work closely with different departments, such as marketing, finance, and operations, to identify business needs and provide data-driven solutions.",
        "You’ll also need to clean and organise data to ensure it’s accurate and reliable. Plus, you’ll have to use your problem-solving and critical thinking skills for this role, as you’ll be required to interpret data and suggest improvements.",
        "Along with technical skills, you’ll also need strong communication skills to explain this complex data in simple terms. Your insights will help your company make informed decisions regarding how it can reduce its costs and improve its efficiency.",
        "With data becoming more important in every industry, your role as a BI analyst is essential to a business’s success. If you’d like to excel at this role, sign up for our business intelligence analyst mentoring programme today.",
      ]
    },
    {
      title: "Challenges Faced by Business Intelligence Analysts in the Industry",
      description: [
        "When you’re working as a business intelligence analyst, you’ll likely face many challenges in your role.",
        "One of the biggest challenges is dealing with large amounts of raw data. You’ll often gather data from different sources, some of which may be incomplete or inconsistent, making it difficult to analyse accurately.",
        "Another problem is ensuring data security and privacy. You’ll need to follow strict rules to protect sensitive business and customer information while making data accessible for decision-making.",
        "Keeping up with the changing technology can also be a struggle. New tools, software, and techniques are constantly emerging, and you’ll need to learn and adapt continuously.",
        "You may also often face communication challenges. You’ll be required to explain complex data insights in easy terms to managers and teams who may not have a technical background.",
        "Lastly, high expectations from your business can put you under immense pressure. Your company will rely on you to provide fast, accurate insights, but you may have to deal with unexpected issues in data quality or system performance, which can slow down the results.",
        "The best way to tackle such issues is to get business intelligence analyst career support from an experienced professional. That’s where our business intelligence analyst mentorship programme comes in.",
        "Your chosen business intelligence analyst mentor will have dealt with similar issues in their career, meaning they can help you find the ideal solutions. They can also teach you more about the industry and what your role entails, so you learn how to avoid mistakes.",
        "Want to get business intelligence analyst career guidance from the best in the industry? Sign up for our business intelligence analyst training programme today.",
      ]
    },
    {
      title: "What to Expect From Business Intelligence Analyst Training Online?",
      description: [
        "Career Navig8r’s online business intelligence analyst mentoring programme will allow you to learn essential skills from someone with experience in this role. ",
        "You’ll get expert business intelligence analyst career advice on using various tools to analyse data and create reports. Your mentor will also teach you how to solve problems more easily and develop your critical thinking skills.",
        "Your mentor will draw from their own real-world insights to enhance your learning. They’ll also provide business intelligence analyst career support as you work on various projects, offering constructive feedback to help you improve and grow. Plus, they can help you with resume building and interview preparation for BI roles.",
        "These business intelligence analyst training sessions are usually flexible, allowing you to learn at your own pace through video calls or chat support. Since our online business intelligence analyst mentorship removes location barriers, you can connect with industry experts from different backgrounds.",
        "If you’re looking to become more confident in working with data and steadily advance your career, enrol in our programme to receive business intelligence analyst career support from an expert.",
      ]
    },
    {
      title: "Find a Business Intelligence Analyst Mentor at Career Navig8r Today",
      description: [
        "Whether starting your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const chiefDataOfficerCareerMentorData = {
  mainTitle: "Chief Data Officer (CDO) Career Mentor",
  accordionData: [
    {
      title: "About Chief Data Officer Mentoring",
      description: [
        "Are you looking to become a chief data officer? Or have you already started in this role and need CDO training to improve your work performance?",
        "Career Navig8r’s chief data officer mentoring programme can help aspiring and current CDOs like you develop leadership, data strategy, and decision-making skills. You’ll be able to pick a CDO mentor who’ll guide you in managing data governance and analytics.",
        "Your mentor will also provide chief data officer career support, helping you work your way through various business challenges. Plus, they’ll share their real-world experiences and give you tailored chief data officer career advice to help you excel in your role.",
        "With expert chief data officer career guidance, you can grow personally and professionally. It’ll also make you more confident in handling complex data environments and teach you how to refine your strategies, enabling you to be a better team leader.",
        "Want to find your ideal chief data officer mentor? Sign up for our CDO mentoring programme today.",
      ]
    },
    {
      title: "The Job Role of a Chief Data Officer",
      description: [
        "As a chief data officer, you’ll be responsible for managing your organisation’s data strategy, ensuring the data is used effectively to drive business success.",
        "You’ll be tasked with overseeing data governance, security, and analytics while ensuring compliance with regulations. Your job will also include developing policies for data management, and you’ll need to improve data quality and make it accessible for making decisions.",
        "You’ll also work closely with IT, marketing, finance, and other departments to use data for innovation and business growth. Plus, you’ll play a key role in digital transformation, helping your company leverage artificial intelligence, machine learning, and big data insights.",
        "To excel in this role, you need to be a strong leader with exceptional problem-solving and technical skills. You should also be able to balance your business goals with data security and ethical considerations.",
        "If you want to be a successful CDO and help your organisation make smarter, data-driven decisions, enrol in our chief data officer training programme.",
      ]
    },
    {
      title: "Challenges Faced by Chief Data Officers in the Industry",
      description: [
        "When working as a chief data officer, you’ll encounter various challenges in managing and using data effectively.",
        "One of the biggest challenges you’ll face is maintaining data quality - you’ll need to ensure that data is accurate, consistent, and reliable for decision-making. Like most organisations, you may struggle with outdated or incomplete data, making it difficult to gain meaningful insights.",
        "Another challenge is dealing with data security and compliance. With strict regulations like GDPR and data privacy concerns, you’ll need to ensure the data is protected while still being accessible for business use. Balancing security with usability isn’t easy.",
        "You’ll also be required to integrate data from different sources, which can get complicated. Many businesses use multiple systems that don’t communicate well, leading to data silos. That’s why you’ll need to create the best strategies to connect and unify data for better insights.",
        "Additionally, getting leadership buy-in for data-driven strategies can be difficult. Many organisations still rely on traditional methods, so you’ll need to prove the value of data in driving business success.",
        "To tackle these challenges, chief data officer career guidance from someone with experience in this role will be invaluable. That’s where our chief data officer mentorship programme comes in.",
        "Your chosen mentor has likely faced similar challenges, so they can draw from their experiences to help you find solutions. They’ll be able to give you the best chief data officer career advice to grow and get better at your job.",
        "Want to reap the benefits of CDO mentoring? Sign up with us to get all the chief data officer career support you need.",
      ]
    },
    {
      title: "What to Expect From Chief Data Officer Training Online?",
      description: [
        "Career Navig8r’s online chief data officer mentoring programme can give you valuable guidance to help you improve your leadership and data management skills.",
        "Through virtual chief data officer training sessions, you can expect personalised advice on handling data governance, analytics, and digital transformation challenges. Your mentor will provide insights on best practices and industry trends, and they’ll teach you how to overcome common obstacles, such as data security and integration.",
        "You can also gain networking opportunities, and you’ll learn from real-world experiences and case studies shared by your CDO mentor. Additionally, your mentor will assess your progress and offer expert chief data officer career advice and feedback to help you improve.",
        "Our online chief data officer mentorship is also flexible, allowing you to connect with experienced professionals from anywhere in the world. Plus, you can schedule your sessions at your convenience, so your routine doesn’t get disrupted.",
        "Need CDO training to sharpen your skills and become more confident? Enrol in our chief data officer career guidance programme.",
      ]
    },
    {
      title: "Find a Chief Data Officer Mentor at Career Navig8r Today",
      description: [
        "Whether you’re starting your career or striving to reach new heights in your existing role, the best route to success may be unclear. If you’ve just begun your career in a new industry, you’ll find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};





