import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import axios from 'axios';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_WORDPRESS_POSTS_REQUEST = 'app/CMSPage/FETCH_WORDPRESS_POSTS_REQUEST';
export const FETCH_WORDPRESS_POSTS_SUCCESS = 'app/CMSPage/FETCH_WORDPRESS_POSTS_SUCCESS';
export const FETCH_WORDPRESS_POSTS_ERROR = 'app/CMSPage/FETCH_WORDPRESS_POSTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  wordpressPosts: [],
  fetchWordpressPostsInProgress: false,
  fetchWordpressPostsError: null,
};

const CMSPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WORDPRESS_POSTS_REQUEST:
      return {
        ...state,
        fetchWordpressPostsInProgress: true,
        fetchWordpressPostsError: null,
      };
    case FETCH_WORDPRESS_POSTS_SUCCESS:
      return {
        ...state,
        wordpressPosts: payload,
        fetchWordpressPostsInProgress: false,
      };
    case FETCH_WORDPRESS_POSTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchWordpressPostsInProgress: false,
        fetchWordpressPostsError: payload
      };

    default:
      return state;
  }
};

export default CMSPageReducer;

// ================ Action creators ================ //

const MAX_WORDPRESS_POSTS_PER_PAGE = 6;

export const fetchWordpressPostsRequest = () => ({ type: FETCH_WORDPRESS_POSTS_REQUEST });
export const fetchWordpressPostsSuccess = wordpressPosts => ({
  type: FETCH_WORDPRESS_POSTS_SUCCESS,
  payload: wordpressPosts
});
export const fetchWordpressPostsError = error => ({
  type: FETCH_WORDPRESS_POSTS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchWordpressPosts = () => (dispatch, getState, sdk) => {
  dispatch(fetchWordpressPostsRequest());
  return axios.get(`${process.env.REACT_APP_WORDPRESS_ROOT_URL}/wp-json/wp/v2/posts?_embed`)
    .then(response => {
      const wordpressPosts = response.data;

      if (wordpressPosts.length > MAX_WORDPRESS_POSTS_PER_PAGE) {
        wordpressPosts.splice(MAX_WORDPRESS_POSTS_PER_PAGE, wordpressPosts.length - MAX_WORDPRESS_POSTS_PER_PAGE);
      }

      dispatch(fetchWordpressPostsSuccess(wordpressPosts));
    })
    .catch(e => {
      dispatch(fetchWordpressPostsError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const pageId = params.pageId;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent))
    .then(() => dispatch(fetchWordpressPosts()));
};
